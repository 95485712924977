/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useSurvey } from 'client/hooks/survey/useSurvey';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { QuestionForm } from './QuestionForm';

export interface SurveyPanelProps {
    procedureId?: number;
}

export const SurveyPanel: React.FC<SurveyPanelProps> = ({ procedureId }) => {
    const { question, getNextQuestion, isLoading, refetch, questionsAvailable } = useSurvey({ procedureId });

    //Question Form Dialog State
    const [isActive, setIsActive] = useState(true);
    const [surveyOpen, setSurveyOpen] = useState(false);

    //Question Form Dialog Interval Timer
    const [seconds, setSeconds] = useState(0);
    const surveyInterval = 600;

    useEffect(() => {
        questionsAvailable ? setIsActive(true) : setIsActive(false);
    }, [questionsAvailable]);

    const reset = useCallback(() => {
        setSeconds(0);
        getNextQuestion();
    }, [getNextQuestion]);

    useEffect(() => {
        let interval: NodeJS.Timer | undefined = undefined;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(originalSeconds => originalSeconds + 1);
            }, 1000);
            if (seconds === surveyInterval && !surveyOpen) {
                reset();
                setSurveyOpen(true);
            } else if (seconds === surveyInterval / 3 && surveyOpen) {
                setSurveyOpen(false);
            }
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, reset, seconds, surveyOpen, surveyInterval]);

    if (!questionsAvailable) {
        return null;
    }

    if (isLoading) {
        return <ProcessingOverlay />;
    }

    const handleOnClose = () => {
        setSurveyOpen(false);
    };

    return <>{surveyOpen && <QuestionForm procedureId={procedureId} surveyQuestion={question} onClose={handleOnClose} onSubmit={refetch} />}</>;
};
