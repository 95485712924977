import React, { useState } from 'react';
import { Box, Button, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useListMyOrders } from 'client/hooks/orders/useListMyOrders';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';
import Panel from 'common/components/UI/Panel';
import { Order } from 'common/types';
import CommonUtils from 'common/utils/CommonUtils';
import { PrintIcon } from 'common/utils/Icons';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import PurchaseDetails from '../Details/PurchaseDetails';

const styles = (theme: Theme) => ({
    container: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },
    table: {
        minWidth: 650,
    },
    tableHeaderSeparator: {
        padding: '16px 10px',
        backgroundColor: theme.palette.primary.light,
    },
    paper: {
        padding: '16px 10px',
    },
});
const useStyles = makeStyles(styles);

interface DetailsData {
    orderId: number;
    open: boolean;
}

const PurchaseHistory = () => {
    const classes = useStyles();
    // const [searchTerm, setSearchPurchase] = useState<string | undefined>(undefined);
    // const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const { reset } = useQueryErrorResetBoundary();

    // const [allOrders, setAllOrders] = useState<Order[]>(orders);
    // const handleSearchPurchaseData = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchPurchase((event.target as HTMLInputElement).value as any);
    // };

    return (
        <div className={classes.container}>
            <Paper>
                {/* <Box py={2} px={3} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">Purchases</Typography>
                    <FormControl variant="outlined" size="small">
                        <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
                        <OutlinedInput
                            labelWidth={55}
                            id="outlined-adornment-amount"
                            value={searchTerm}
                            onChange={handleSearchPurchaseData}
                            type="search"
                        />
                    </FormControl>
                </Box> */}
                {/* <Box className={classes.tableHeaderSeparator}></Box> */}
                <ErrorBoundary
                    onReset={reset}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    fallbackRender={({ resetErrorBoundary }) => (
                        <Panel>
                            <p>Oops! Something went wrong...</p>
                            <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                                Try again
                            </Button>
                        </Panel>
                    )}
                >
                    <React.Suspense fallback={<Skeleton />}>
                        <PurchaseHistoryTable />
                    </React.Suspense>
                </ErrorBoundary>
            </Paper>
        </div>
    );
};

const PurchaseHistoryTable = ({ searchTerm }: { searchTerm?: string }) => {
    const classes = useStyles();
    const [detailsData, setDetailsData] = useState<DetailsData>({
        orderId: -1,
        open: false,
    });
    const { orders: allOrdersData } = useListMyOrders({ searchTerm });
    const onCloseDetailsData = () => {
        setDetailsData({ open: false, orderId: -1 });
    };
    return (
        <EmptyStateHandler data={allOrdersData} keyword="orders">
            <Box width={1} py={2} px={3}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Facility</TableCell>
                            <TableCell>License Type</TableCell>
                            <TableCell>License #</TableCell>
                            <TableCell>License Details</TableCell>
                            <TableCell>Purchase date</TableCell>
                            <TableCell>Expiration date</TableCell>
                            <TableCell>Order status</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allOrdersData.map((order: Order) => (
                            <TableRow key={order.id}>
                                <TableCell component="th" scope="row">
                                    {order.license.facility.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {order.license.licenseType.name}
                                </TableCell>
                                <TableCell> {order.license.id}</TableCell>
                                <TableCell component="th" scope="row" width={'35%'}>
                                    {order?.license?.licenseDetails}
                                </TableCell>
                                <TableCell>{CommonUtils.formatDate(order.payment.createdAt, 'long')}</TableCell>
                                <TableCell>{order.expirationDate ?? '-'}</TableCell>
                                <TableCell>{order.activationDate ? 'ACTIVATED' : order.orderStatus}</TableCell>
                                <TableCell>{order.payment.total ? `$ ${order.payment.total / 100}` : ''}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="text"
                                        fullWidth
                                        color="primary"
                                        onClick={() => setDetailsData({ open: true, orderId: order.id })}
                                    >
                                        <PrintIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <PurchaseDetails open={detailsData.open} orderId={detailsData.orderId} onClose={onCloseDetailsData} />
        </EmptyStateHandler>
    );
};

export default PurchaseHistory;
