import { useEffect, useState } from 'react';
import { config } from 'circulator/hooks/config';
import type { Campaign, CampaignSettings } from 'common/types';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.ListCampaignsResponse;

export function useListCampaigns(campaignSettings: CampaignSettings | null | undefined, admin: boolean = false) {
    const { key, path } = config.campaign.list();

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const result = useQuery(key, () => Api.get<Response>({ path }), { refetchInterval: 1000 * 60 });

    // Filter campaigns based on campaign settings.
    // Use interval to filter results periodically.
    useEffect(() => {
        function filter() {
            const filtered = result.data?.filter(campaign => showCampaign(campaign, campaignSettings, admin)) ?? [];
            setCampaigns(filtered);
        }
        filter();
        // Set interval to 15 minutes.
        const interval = setInterval(filter, 15 * 60 * 1000);
        return () => clearInterval(interval);
    }, [admin, campaignSettings, result.data]);

    return {
        campaigns,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}

/**
 * Determine whether to show the campaign based on premium campaign settings.
 */
function showCampaign(campaign: Campaign, campaignSettings: CampaignSettings | null | undefined, admin: boolean): boolean {
    if (admin) {
        // Show all campaigns for admins.
        return true;
    }
    if (campaign.maxViews > 0 && campaign.views >= campaign.maxViews) {
        // Do not show premium campaigns if max views is already reached.
        return false;
    }
    if (!campaign.isPremium) {
        // Always show non-premium campaigns.
        return true;
    }
    if (!campaignSettings?.premiumSettings) {
        // Do not show premium campaigns if premium settings are undefined.
        return false;
    }
    const { startTime, endTime, showOnSunday, showOnMonday, showOnTuesday, showOnWednesday, showOnThursday, showOnFriday, showOnSaturday } =
        campaignSettings.premiumSettings;
    const now = new Date();
    const showArray = [showOnSunday, showOnMonday, showOnTuesday, showOnWednesday, showOnThursday, showOnFriday, showOnSaturday];
    const showToday = showArray.at(now.getDay()) ?? false;
    if (!showToday) {
        // Do not show premium campaigns today. Ex: today is Sunday and showOnSunday is false.
        return false;
    }
    const time = now.toTimeString();
    const start = startTime ?? new Date(0, 0, 1, 0, 0, 0).toTimeString();
    const end = endTime ?? new Date(0, 0, 1, 23, 59, 59).toTimeString();
    // Show premium campaigns if current time is between start and end times.
    return start <= time && time <= end;
}
