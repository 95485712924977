import React from 'react';
import { Fade, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

const UiTooltip = props => {
    const { children, title } = props;

    return (
        <Tooltip title={title} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow>
            {children}
        </Tooltip>
    );
};

UiTooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    title: PropTypes.string.isRequired,
};

export default UiTooltip;
