import type { CognitoUser } from '@aws-amplify/auth';
import type { ISignUpResult } from 'amazon-cognito-identity-js';
import * as amplify from 'aws-amplify';
import { AmplifyStandardResponse } from 'common/types/amplify';

export interface UserAttributes {
    readonly sub: string;
    readonly email: string;
    readonly email_verified: string;
    readonly phone_number: string;
    readonly phone_number_verified: string;
    readonly given_name: string;
    readonly family_name: string;
    readonly address: string;
    readonly 'custom:position': string;
    readonly 'custom:companyName': string;
    readonly 'custom:companyLogo': string;
    // this is a boolean value stored as a string
    readonly 'custom:notifyProcStart': string;
    readonly 'custom:doctorAccount': string;
    readonly 'custom:facilityName': string;
    readonly 'custom:facilityCity': string;
    readonly 'custom:facilityState': string;
    readonly 'custom:canUseCuts': string;
    readonly picture: string;
}

export interface ExtendedCognitoUser extends CognitoUser {
    readonly attributes: UserAttributes;
}

type CreateUser = {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    doctorAccount: boolean;
    facilityName?: string;
    facilityCity?: string;
    facilityState?: string;
};
export const createUser = async (credentials: CreateUser): Promise<ISignUpResult> =>
    amplify.Auth.signUp({
        username: credentials.username.trim().toLowerCase(),
        password: credentials.password.trim(),
        attributes: {
            email: credentials.username.trim().toLowerCase(),
            given_name: credentials.firstName.trim(),
            family_name: credentials.lastName.trim(),
            'custom:notifyProcStart': 'true',
            'custom:doctorAccount': credentials.doctorAccount.toString(),
            'custom:facilityName': credentials.facilityName,
            'custom:facilityCity': credentials.facilityCity,
            'custom:facilityState': credentials.facilityState,
            'custom:canUseCuts': 'false',
        },
    });

export const getCurrentUserWithAttrs = async (): Promise<ExtendedCognitoUser | undefined> => {
    try {
        return (await amplify.Auth.currentAuthenticatedUser()) as ExtendedCognitoUser;
    } catch (e) {
        return undefined;
    }
};

export const getUserGroups = (user: ExtendedCognitoUser | undefined): string[] => {
    return user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'] ?? [];
};

export function getUserInGroup(user: ExtendedCognitoUser | undefined, group: string): boolean {
    return getUserGroups(user)
        .map(userGroup => userGroup.toLowerCase())
        .includes(group);
}
export function getUserInAdminUsers(user: ExtendedCognitoUser | undefined): boolean {
    return getUserInGroup(user, 'admin');
}

export function getUserInRegisteredUsers(user: ExtendedCognitoUser | undefined): boolean {
    return getUserInGroup(user, 'registered');
}

export function getUserInDeviceGroup(user: ExtendedCognitoUser | undefined): boolean {
    return getUserInGroup(user, 'device');
}

type ConfirmSignUp = { username: string; emailConfirmCode: string };
export const confirmSignUp = async ({ username, emailConfirmCode }: ConfirmSignUp): Promise<AmplifyStandardResponse> => {
    try {
        const result = await amplify.Auth.confirmSignUp(username.trim().toLowerCase(), emailConfirmCode.trim());
        console.log(result);
        return {
            success: true,
            message: 'Registration Confirmation Successful',
        };
    } catch (err) {
        console.log(err);
        return {
            success: false,
            message: 'Registration Confirmation Failed',
        };
    }
};

type ResetUserPassword = { username: string };
export const resetUserPassword = async ({ username }: ResetUserPassword): Promise<unknown> =>
    amplify.Auth.forgotPassword(username.trim().toLowerCase());

type ConfirmResetUserPassword = { username: string; password: string; recoveryCode: string };
export const confirmResetUserPassword = async ({ username, password, recoveryCode }: ConfirmResetUserPassword): Promise<string> =>
    amplify.Auth.forgotPasswordSubmit(username.trim().toLowerCase(), recoveryCode.trim(), password.trim());

type ChangePassword = { user: ExtendedCognitoUser; oldPassword: string; password: string };
export const changePassword = async ({ user, oldPassword, password }: ChangePassword): Promise<'SUCCESS'> =>
    amplify.Auth.changePassword(user, oldPassword.trim(), password.trim());

type LogInUser = { username: string; password: string };
export const logInUser = async ({ username, password }: LogInUser): Promise<CognitoUser | unknown> =>
    amplify.Auth.signIn(username.trim().toLowerCase(), password.trim());

export const logOutUser = async (): Promise<unknown> => amplify.Auth.signOut();
