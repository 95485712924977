import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Request = api.SearchProceduresRequest;
type Response = api.SearchProceduresResponse;

export const useSearchProcedures = (body: Request) => {
    const { key, path } = config.procedure.search(body);

    const result = useQuery(key, () => Api.post<Response>({ path, body }), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
    });

    return {
        procedures: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isFetched: result.isFetched,
    };
};
