import { config } from 'client/hooks/config';
import { IVideoClipComment } from 'common/types';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export function useClipComment(jobId: string) {
    const { key, path } = config.clips.comments(jobId);
    const result = useQuery([key], () => Api.get<IVideoClipComment[]>({ path }));
    return {
        data: result?.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        refetch: result.refetch,
    };
}
