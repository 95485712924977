import React from 'react';
import { useListAppSettings } from 'circulator/hooks/appSettings/useListAppSettings';
import { useListCampaigns } from 'circulator/hooks/campaign/useListCampaigns';
import { IdleTimerProvider } from 'react-idle-timer';
import { CampaignOverlay } from './CampaignOverlay';

interface Props {
    debug?: boolean;
}

export const Campaign: React.FC<Props> = ({ debug }) => {
    const [isIdle, setIsIdle] = React.useState(false);
    const { campaignSettings } = useListAppSettings();
    const { campaigns } = useListCampaigns(campaignSettings, debug);
    const delay = (campaignSettings?.delay ?? 30) * 1000;

    const onIdle = () => {
        setIsIdle(true);
    };

    const onActive = () => {
        setIsIdle(false);
    };

    const onAction = () => {
        setIsIdle(false);
    };

    return (
        <IdleTimerProvider startOnMount timeout={delay} onIdle={onIdle} onActive={onActive} onAction={onAction}>
            <CampaignOverlay debug={debug} campaigns={campaigns} isIdle={debug ? false : isIdle} />
        </IdleTimerProvider>
    );
};
