import { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useGetAttendee } from 'client/hooks/attendee/useGetAttendee';
import { useGetProcedure } from 'client/hooks/procedure/useGetProcedure';
import { StreamType } from 'common/types';
import { PrimaryAttendeePlayer } from '../PrimaryAttendeePlayer';
import { PrimaryAttendeePublisher } from '../PrimaryAttendeePublisher';
import { AudioOnlyToggleList } from './AudioOnlyToggleList';
import { AVPlayer } from './AVPlayer';

interface Props {
    procedureId: number;
}

export function AVPanel({ procedureId }: Props) {
    const { attendee, isLoading: attendeeIsLoading } = useGetAttendee(procedureId);
    const { procedure, isLoading: procedureIsLoading } = useGetProcedure(procedureId);
    const { streams = [] } = procedure?.device ?? {};
    const { isShadeDown: obscure = false } = procedure ?? {};

    const circulatorStreams = useMemo(
        () =>
            streams
                .filter(stream => {
                    return stream.isCirculator;
                })
                .sort((a, b) => a.order - b.order),
        [streams]
    );

    const nonFeaturedVideoStreams = useMemo(
        () =>
            streams
                .filter(stream => {
                    return !stream.isFeatured && stream.type !== StreamType.AUDIO_ONLY && !stream.isCirculator;
                })
                .sort((a, b) => a.order - b.order),
        [streams]
    );

    const nonFeaturedAudioOnlyStreams = useMemo(
        () =>
            streams
                .filter(stream => {
                    return !stream.isFeatured && stream.type === StreamType.AUDIO_ONLY && !stream.isCirculator;
                })
                .sort((a, b) => a.order - b.order),
        [streams]
    );

    const sortedAndFilteredCirculatorAndVideoStreams = useMemo(
        () => [...circulatorStreams, ...nonFeaturedVideoStreams],
        [circulatorStreams, nonFeaturedVideoStreams]
    );

    // display order is
    //   audio only, primary/featured user, circulator, video (with or without audio)
    //   within each group, display order is based on the order property value of the stream object, ascending

    return (
        <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
                {attendeeIsLoading || procedureIsLoading ? (
                    <>
                        <Skeleton variant="rectangular" width={200} height={120} />
                        <Skeleton variant="rectangular" width={200} height={120} />
                        <Skeleton variant="rectangular" width={200} height={120} />
                        <Skeleton variant="rectangular" width={200} height={120} />
                    </>
                ) : (
                    <>
                        {nonFeaturedAudioOnlyStreams.length > 0 && (
                            <AudioOnlyToggleList streams={nonFeaturedAudioOnlyStreams} iceServers={procedure?.device.iceServers} />
                        )}
                        {attendee?.publishAccess === true ? (
                            <PrimaryAttendeePublisher key="PrimaryAttendeePublisher" procedureId={procedureId} />
                        ) : (
                            <PrimaryAttendeePlayer key="PrimaryAttendeePlayer" procedureId={procedureId} />
                        )}
                        {sortedAndFilteredCirculatorAndVideoStreams.map(stream => (
                            <AVPlayer key={stream.id} stream={stream} obscure={obscure} iceServers={procedure?.device.iceServers} />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}
