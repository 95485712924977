import BasicArray from './BasicArray';
import Camera from './Camera';

export default class CameraArray extends BasicArray {
    get myClass() {
        return CameraArray;
    }

    get myItemClass() {
        return Camera;
    }

    constructor(items = []) {
        super(items);
    }
}
