import React, { useMemo } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { CloseIcon, PrintIcon } from 'common/utils/Icons';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQueryClient } from 'react-query';

interface PurchaseDetailsProps {
    open: boolean;
    orderId: number;
    onClose: () => void;
}

const styles = (theme: Theme) => ({
    caredHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
});
const useStyles = makeStyles(styles);

const PurchaseDetails = ({ open, orderId, onClose }: PurchaseDetailsProps) => {
    const classes = useStyles();
    const queryClient = useQueryClient();
    const order = queryClient.getQueryData(['order', orderId]) as any;

    const handleClose = () => {
        onClose();
    };

    const purchaseDate = useMemo(() => (order?.payment?.createdAt ? new Date(order?.payment?.createdAt) : null), [order?.payment?.createdAt]);
    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md" fullWidth>
            <DialogTitle id="customized-dialog-title">Purchase details</DialogTitle>
            <DialogContent dividers>
                <Box py={2}>
                    <Card>
                        <CardHeader
                            className={classes.caredHeader}
                            title={order?.license.licenseType.name}
                            titleTypographyProps={{ variant: 'h5', noWrap: true, align: 'center' }}
                        />
                        <CardContent>
                            <Box px={3} py={2}>
                                <Box display="flex" flexDirection="row" textAlign="center" flexWrap="wrap" mb={1}>
                                    <Box minWidth="200px" textAlign="left">
                                        <Typography variant="body1">
                                            <b>Facility:</b>
                                        </Typography>
                                    </Box>

                                    <Typography variant="body1">{order?.license.facility.name}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" textAlign="center" flexWrap="wrap" mb={1}>
                                    <Box minWidth="200px" textAlign="left">
                                        <Typography variant="body1">
                                            <b>Pass Type:</b>
                                        </Typography>
                                    </Box>

                                    <Typography variant="body1">{order?.license.licenseType.name}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" textAlign="center" flexWrap="wrap" mb={1}>
                                    <Box minWidth="200px" textAlign="left">
                                        <Typography variant="body1">
                                            <b>License #:</b>
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">{order?.license.id}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" mb={3}>
                                    <Box minWidth="200px" textAlign="left">
                                        <Typography variant="body1">
                                            <b>License Details:</b>
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" align="justify">
                                        {order?.license.licenseDetails}
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" textAlign="center" flexWrap="wrap" mb={1}>
                                    <Box minWidth="200px" textAlign="left">
                                        <Typography variant="body1">
                                            <b>Purchase date:</b>
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">{`${purchaseDate}`}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" textAlign="center" flexWrap="wrap" mb={1}>
                                    <Box minWidth="200px" textAlign="left">
                                        <Typography variant="body1">
                                            <b>Expiration date:</b>
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">{order?.expirationDate}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" textAlign="center" flexWrap="wrap" mb={1}>
                                    <Box minWidth="200px" textAlign="left">
                                        <Typography variant="body1">
                                            <b>Amount:</b>
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">
                                        {isNotNullOrUndefined(order?.payment?.total) ? `$ ${order?.payment?.total / 100}` : ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box px={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width={1}>
                    <Button variant="outlined" color="primary" startIcon={<CloseIcon />} size="medium" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" startIcon={<PrintIcon />} size="medium">
                        Print
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default PurchaseDetails;
