import { useState, useEffect } from 'react';
import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack } from '@mui/material';
import {
    GridColDef,
    GridActionsCellItem,
    GridRenderCellParams,
    GridRowId,
    GridToolbar,
    getGridSingleSelectOperators,
    GridFilterItem,
    GridCellParams,
    GridComparatorFn,
    getGridStringOperators,
} from '@mui/x-data-grid';
import theme from 'App/theme';
import { useListFacilitiesAll } from 'client/hooks/facilities/useListFacilitiesAll';
import { useListUsers } from 'client/hooks/user/useListUsers';
import { useUpsertUserCuts } from 'client/hooks/user/useUpsertUserCuts';
import DataGrid from 'common/components/DataGrid/DataGrid';
import Panel from 'common/components/UI/Panel';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { UserCognitoInfo } from 'common/types';
import { PersonAddIcon, GroupAddIcon, FacilityIcon } from 'common/utils/Icons';
import moment from 'moment';
import { FacilityAssignmentDialog, type IUserFacilitiesData } from './FacilityAssignmentDialog';
import { GroupAssignmentDialog, cognitoGroups, type IUserGroupData } from './GroupAssignmentDialog';
import { RoleAssignmentDialog, type IUserRolesData } from './RoleAssignmentDialog';

const styles = {
    root: {
        margin: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    cellBorder: {
        margin: theme.spacing(2),
        paddingTop: theme.spacing(2),
        '& .MuiDataGrid-columnHeader': {
            borderTop: '2px solid #f0f0f0',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '1px solid #f0f0f0',
        },
    },
};

const useStyles = makeStyles(styles);

const groupsSortComparator: GridComparatorFn<any> = (group1: string[], group2: string[]) => {
    return group1.length - group2.length;
};

const groupsFilterOperators = getGridSingleSelectOperators()
    .filter(operator => operator.value === 'isAnyOf')
    .map(operator => {
        const newOperator = { ...operator };
        const newGetApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef) => {
            return (params: GridCellParams): boolean => {
                let isOk = true;
                filterItem?.value?.forEach((fv: string) => {
                    if (fv === 'None') {
                        isOk = isOk && params.row.groups.length === 0;
                    } else {
                        isOk = isOk && params.row.groups.includes(fv);
                    }
                });
                return isOk;
            };
        };
        newOperator.label = 'In List';
        newOperator.getApplyFilterFn = newGetApplyFilterFn;
        return newOperator;
    });

const buildNotInFilter = () => {
    const newOperator = { ...getGridStringOperators()[0] };
    const newGetApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef) => {
        return (params: GridCellParams): boolean => {
            if (filterItem.value) {
                return !params.row[filterItem.field].toLowerCase().includes(filterItem.value.toLowerCase());
            }
            return true;
        };
    };

    newOperator.label = 'Is not';
    newOperator.value = 'notEqual';
    newOperator.getApplyFilterFn = newGetApplyFilterFn;
    return newOperator;
};

const buildNotInGroupFilter = () => {
    const newOperator = { ...getGridSingleSelectOperators().filter(operator => operator.value === 'isAnyOf')[0] };

    const newGetApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef) => {
        return (params: GridCellParams): boolean => {
            let isOk = true;
            filterItem?.value?.forEach((fv: string) => {
                if (fv === 'None') {
                    isOk = isOk && params.row.groups.length === 0;
                } else {
                    isOk = isOk && !params.row.groups.includes(fv);
                }
            });
            return isOk;
        };
    };

    newOperator.label = 'Is not';
    newOperator.value = 'notEqual';
    newOperator.getApplyFilterFn = newGetApplyFilterFn;
    return newOperator;
};

const notInFilterOperator = getGridStringOperators().concat(buildNotInFilter());
groupsFilterOperators.push(buildNotInGroupFilter());

const Users = () => {
    const classes = useStyles();
    const { users, isLoading } = useListUsers();
    const { facilities } = useListFacilitiesAll();
    const [roleModalOpen, setroleModalOpen] = useState(false);
    const [openGroupDialog, setOpenGroupDialog] = useState(false);
    const [openFacilityDialog, setOpenFacilityDialog] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>([]);
    const [selectedUserGroups, setSelectedUserGroups] = useState<string[]>([]);
    const [selectedUserFacilities, setSelectedUserFacilities] = useState<number[]>([]);
    const [rows, setRows] = useState<UserCognitoInfo[]>([]);
    const { updateCuts } = useUpsertUserCuts();
    const { notify } = useNotifier();

    const handleAssignRoles = (id: GridRowId) => () => {
        setSelectedUserId(id.toString());
        const curRoles = users.find(r => r.userId === id.toString())?.roles ?? [];
        setSelectedUserRoles(curRoles);
        setroleModalOpen(true);
    };

    const handleRoleDialogClose = () => {
        setSelectedUserId('');
        setroleModalOpen(false);
    };

    const handleRoleUpdate = (data: IUserRolesData) => {
        const curIndex = users.findIndex(u => u.userId === data.userId);
        if (curIndex >= 0) {
            users[curIndex].roles = [];
            users[curIndex].roles.push(...data.roles);
        }
        setSelectedUserId('');
        setroleModalOpen(false);
    };

    const handleAssignGroups = (id: GridRowId) => () => {
        setSelectedUserId(id.toString());
        const curGroups = users.find(r => r.userId === id.toString())?.groups ?? [];
        setSelectedUserGroups(curGroups);
        setOpenGroupDialog(true);
    };

    const handleGroupDialogClose = () => {
        setSelectedUserId('');
        setOpenGroupDialog(false);
    };

    const handleGroupUpdate = (data: IUserGroupData) => {
        const curIndex = users.findIndex(u => u.userId === data.userId);
        if (curIndex >= 0) {
            users[curIndex].groups = [];
            users[curIndex].groups.push(...data.groups);
        }
        setSelectedUserId('');
        setOpenGroupDialog(false);
    };

    const handleAssignFacilities = (id: GridRowId) => () => {
        setSelectedUserId(id.toString());
        const arr = users.find(r => r.userId === id.toString())?.facilities ?? [];
        setSelectedUserFacilities(arr);
        setOpenFacilityDialog(true);
    };

    const handleFacilityDialogClose = () => {
        setSelectedUserId('');
        setOpenFacilityDialog(false);
    };

    const handleFacilityUpdate = (data: IUserFacilitiesData) => {
        const curIndex = users.findIndex(u => u.userId === data.userId);
        if (curIndex >= 0) {
            users[curIndex].facilities = [];
            users[curIndex].facilities.push(...data.facilities);
        }
        setSelectedUserId('');
        setOpenFacilityDialog(false);
    };

    const handleToggleCuts = (id: GridRowId) => async () => {
        const user = users.find(r => r.userId === id.toString());

        if (user) {
            // update user data
            try {
                const res = await updateCuts({ userId: user.userId, canUseCuts: !user.canUseCuts });

                if (res.success === true) {
                    user.canUseCuts = !user.canUseCuts;
                }
            } catch (err) {
                notify(new Message({ title: 'Something went wrong in your request. Please see the logs for more information', type: 'error' }));
            }
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'actionsRoles',
            type: 'actions',
            headerName: '',
            width: 50,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<PersonAddIcon />}
                        label="Roles"
                        className="textPrimary"
                        title="Roles Assignment"
                        onClick={handleAssignRoles(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 50,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<GroupAddIcon />}
                        label="Groups"
                        className="textPrimary"
                        title="Groups Assignment"
                        onClick={handleAssignGroups(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: 'actionsFacility',
            type: 'actions',
            headerName: '',
            width: 50,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<FacilityIcon />}
                        label="Facility"
                        className="textPrimary"
                        title="Facilities Assignment"
                        onClick={handleAssignFacilities(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: 'actionsCUTS',
            type: 'actions',
            headerName: 'CUTS',
            width: 60,
            cellClassName: 'actions',
            getActions: ({ id, row, columns }) => {
                return [
                    <GridActionsCellItem
                        icon={row.canUseCuts ? <CheckCircleIcon /> : <CancelIcon />}
                        label="CUTS"
                        className="textPrimary"
                        title="Can Use CUTS"
                        onClick={handleToggleCuts(id)}
                        color={row.canUseCuts ? 'success' : 'error'}
                    />,
                ];
            },
        },
        {
            field: 'userId',
            headerName: 'User ID',
            width: 300,
            sortable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterOperators: notInFilterOperator,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            sortable: true,
            hideSortIcons: false,
            disableColumnMenu: true,
            filterOperators: notInFilterOperator,
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 150,
            sortable: true,
            hideSortIcons: false,
            disableColumnMenu: true,
            filterOperators: notInFilterOperator,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 150,
            sortable: true,
            hideSortIcons: false,
            disableColumnMenu: true,
            filterOperators: notInFilterOperator,
        },
        {
            field: 'doctorAccount',
            headerName: 'Identified as Doctor?',
            width: 150,
            sortable: true,
            hideSortIcons: false,
            disableColumnMenu: true,
            filterOperators: notInFilterOperator,
            renderCell: params => <Typography>{params.row.doctorAccount ? 'Yes' : 'No'}</Typography>,
        },
        {
            field: 'facilityName',
            headerName: 'Identified Facility',
            width: 150,
            sortable: true,
            hideSortIcons: false,
            disableColumnMenu: true,
            filterOperators: notInFilterOperator,
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            width: 150,
            sortable: true,
            hideSortIcons: false,
            disableColumnMenu: true,
            renderCell: params => <Typography>{params.row.createdDate ? moment(params.row.createdDate).format('MM/DD/YYYY') : ''}</Typography>,
        },
        {
            field: 'groups',
            headerName: 'Groups',
            width: 200,
            sortable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            cellClassName: 'Cell-Multi-Chips',
            type: 'singleSelect',
            valueOptions: [...cognitoGroups, 'None'],
            renderCell: params => (
                <Stack direction="row" spacing={0.25}>
                    {params.row.groups.map((group: string) => (
                        <Chip label={group} />
                    ))}
                </Stack>
            ),
            sortComparator: groupsSortComparator,
            filterOperators: groupsFilterOperators,
        },
        {
            field: 'roles',
            headerName: 'Roles',
            width: 600,
            sortable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            cellClassName: 'Cell-Multi-Chips',
            renderCell: (params: GridRenderCellParams<string[]>) => params.value.map((role: string) => <Chip label={role}></Chip>),
            type: 'string',
        },
        {
            field: 'facilities',
            headerName: 'Facilities',
            width: 600,
            sortable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            cellClassName: 'Cell-Multi-Chips',
            renderCell: (params: GridRenderCellParams<number[]>) =>
                params.value.map((id: number) => {
                    const info = facilities.find(f => f.id === id)?.name;
                    return <Chip label={info}></Chip>;
                }),
            type: 'string',
        },
    ];

    useEffect(() => {
        setRows(users);
    }, [users]);

    return (
        <>
            <DataGrid
                loading={isLoading}
                actions={[]}
                rows={rows}
                getRowId={(row: UserCognitoInfo) => row.userId}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                slots={{ toolbar: GridToolbar }}
                hideFooterSelectedRowCount={true}
                className={classes.cellBorder}
                sx={{ bgcolor: 'background.paper' }}
            />
            <RoleAssignmentDialog
                open={roleModalOpen}
                userId={selectedUserId}
                userRoles={selectedUserRoles}
                close={handleRoleDialogClose}
                update={handleRoleUpdate}
            />
            <GroupAssignmentDialog
                open={openGroupDialog}
                userGroups={selectedUserGroups}
                userId={selectedUserId}
                close={handleGroupDialogClose}
                update={handleGroupUpdate}
            />
            <FacilityAssignmentDialog
                facilityList={facilities}
                open={openFacilityDialog}
                userFacilities={selectedUserFacilities}
                userId={selectedUserId}
                close={handleFacilityDialogClose}
                update={handleFacilityUpdate}
            />
        </>
    );
};

export const UserManagementPage = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Panel>
                <Box width="100%">
                    <Users />
                </Box>
            </Panel>
        </div>
    );
};
