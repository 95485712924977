import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import UserForm from 'client/components/AccountForm/UserForm';
import Profile from 'common/models/Profile';

const PersonalInformation = ({
    user,
    onSave,
    onAssignAssistant,
    assistants,
    selectedAssistant,
    showAllValidations,
}: {
    user: Profile;
    onSave: (profile: Profile) => void;
    onAssignAssistant: (assistantId: string) => void;
    assistants: object[];
    selectedAssistant: string | undefined;
    showAllValidations: boolean;
}) => {
    const [nextUser, setNextUser] = useState(new Profile());

    useEffect(() => {
        setNextUser(user.clone() as unknown as Profile);
    }, [user]);

    const handleChange =
        (name: keyof Profile): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event.target.type === 'checkbox') {
                setNextUser(nextUser.clone().value(name, event.target.checked));
            } else {
                setNextUser(nextUser.clone().value(name, event.target.value));
            }
        };

    const handleAssistantChange = (assistantId: string) => {
        onAssignAssistant(assistantId);
    };

    const handleSave = async () => {
        const profile = new Profile(nextUser);
        return onSave(profile);
    };

    return (
        <>
            <UserForm
                user={nextUser}
                assistants={assistants}
                disabled={['email', 'country']}
                onChange={handleChange}
                onAssistantChange={handleAssistantChange}
                selectedAssistant={selectedAssistant}
                showAllValidations={showAllValidations}
            />
            <Button
                variant="contained"
                aria-label="Save"
                color="primary"
                fullWidth
                onClick={handleSave}
                disabled={!nextUser.isDifferentOrIsNotificationChange(user)}
            >
                Save
            </Button>
        </>
    );
};

export default PersonalInformation;
