import React, { useState } from 'react';
import { Button, CircularProgress, FormHelperText, makeStyles, TextField, Typography } from '@material-ui/core';
import theme from 'App/theme';
import CommonUtils from 'common/utils/CommonUtils';
import { GoIcon } from 'common/utils/Icons';
import type { Mode } from './Layout';

export interface LoginCredentials {
    username: string;
    password: string;
}

interface Props {
    onLogin: (credentials: LoginCredentials) => void;
    onChangeMode: (mode: Mode) => void;
    isLoggingIn: boolean;
}

type Key = keyof LoginCredentials;
const initialCredentials: LoginCredentials = { username: '', password: '' };

export const LoginPanel: React.FC<Props> = ({ onLogin, onChangeMode, isLoggingIn }) => {
    const classes = useStyles();

    const [credentials, setCredentials] = useState(initialCredentials);
    const [focus, setFocus] = useState('');

    const handleChange = (key: Key) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(e => ({ ...e, [key]: event.target.value }));
    };

    const handleSetFocus = (key: Key) => () => setFocus(key);

    const handleSetBlur = (key: Key) => () => {
        if (key === focus) {
            setFocus('');
        }
    };

    const handleLogin = () => onLogin(credentials);

    return (
        <form className={classes.form}>
            <Typography component="h1" variant="h5">
                Sign In
            </Typography>
            <TextField
                id="username"
                name="username"
                label="Username / Email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="email"
                value={credentials.username}
                onChange={handleChange('username')}
                onFocus={handleSetFocus('username')}
                onBlur={handleSetBlur('username')}
            />
            <FormHelperText
                error={true}
                hidden={CommonUtils.isEmailValid(credentials.username) || credentials.username.trim().length <= 0 || focus === 'username'}
            >
                Please enter a valid email.
            </FormHelperText>
            <TextField
                id="password"
                name="password"
                label="Password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="current-password"
                type="password"
                value={credentials.password}
                onChange={handleChange('password')}
                onFocus={handleSetFocus('password')}
                onBlur={handleSetBlur('password')}
            />
            <Button
                variant="contained"
                aria-label="Login"
                color="primary"
                fullWidth
                className={classes.submit}
                disabled={!CommonUtils.isEmailValid(credentials.username) || !CommonUtils.isPasswordValid(credentials.password) || isLoggingIn}
                onClick={handleLogin}
            >
                Sign In
                <GoIcon />
                {isLoggingIn && (
                    <>
                        &nbsp;&nbsp; <CircularProgress size={15} thickness={8} color="primary" />
                    </>
                )}
            </Button>
            <div className={classes.links}>
                <Button size="small" onClick={() => onChangeMode('reset')}>
                    Forgot password?
                </Button>
                <Button size="small" onClick={() => onChangeMode('register')}>
                    Need an account? Sign Up
                </Button>
            </div>
        </form>
    );
};

const useStyles = makeStyles({
    form: {
        width: '100%',
    },
    submit: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        transition: 'color .01s',
    },
    links: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
