import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { config } from 'mobile/hooks/config';
import { useMutation } from 'react-query';

type Request = api.JoinProcedureRequest;
type Response = api.JoinProcedureResponse;

function getJoinProcedureMachineId() {
    let machineId = localStorage.getItem('JoinProcedureMachineId');

    if (!machineId) {
        machineId = crypto.randomUUID();
        localStorage.setItem('JoinProcedureMachineId', machineId);
    }

    return machineId;
}

export function useJoinProcedure() {
    const { path } = config.mobile.joinProcedure();
    const machineId = getJoinProcedureMachineId();
    const update = useMutation((body: Omit<Request, 'machineId'>) => Api.post<Response>({ path, body: { ...body, machineId } }), {});

    return {
        joinProcedure: update.mutateAsync,
    };
}
