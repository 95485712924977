import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import theme from 'App/theme';
import { ProcedureRequestsPanel } from 'circulator/components/Procedure/ProcedureRequestsPanel';
import { ProcedureSubscribersPanel } from 'circulator/components/Procedure/ProcedureSubscribersPanel';
import { useListAttendees } from 'circulator/hooks/attendee/useListAttendees';
import { MessageBox } from 'common/components/UI/MessageBox';
import Panel from 'common/components/UI/Panel';
import Message from 'common/models/Message';
import { AttendeeStatus } from 'common/types';
import type { Procedure } from 'common/types/circulator';
import useOrientation from '../../../common/hooks/useOrientation';
import { SpecialAccessButton } from './SpecialAccessButton';

const drawerWidth = 300;
// @ts-ignore
const useStyles = (props: { isLandscape: boolean }) =>
    makeStyles({
        content: {
            flexGrow: 1,
            width: 'auto',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginRight: 0,
        },
        contentShifted: {
            flexGrow: 1,
            width: 'auto',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
            },
            [theme.breakpoints.up('md')]: {
                marginRight: drawerWidth,
            },
        },
        btn: {
            float: 'right',
            marginRight: theme.spacing(2),
        },
        msg: {
            margin: '8px 16px',
        },
        hrule: {
            borderTop: '1px solid rgb(0, 0, 0, 0.12)',
        },
        titleBox: {
            position: 'relative',
            textAlign: 'left',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        title: {
            display: 'inline-block',
        },
        headerBox: {
            position: 'absolute',
            right: 0,
            paddingRight: theme.spacing(2),
        },
        header: {
            display: 'inline-block',
            width: 150,
            textAlign: 'center',
            marginLeft: theme.spacing(),
        },
        attendeePanel: {
            maxHeight: props.isLandscape ? '100%' : '50vh',
            overflowY: 'auto',
            minHeight: props.isLandscape ? '100%' : '35vh',
        },
    });

const { REQUESTED, REJECTED, ACCEPTED, JOINED } = AttendeeStatus;

interface Props {
    procedure: Procedure;
}

export const ProcedureAttendeesPanel = ({ procedure }: Props) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape })();

    const { attendees } = useListAttendees(procedure.id);
    const requested = useMemo(() => attendees.filter(attendee => attendee.status === REQUESTED), [attendees]);
    const rejected = useMemo(() => attendees.filter(attendee => attendee.status === REJECTED), [attendees]);
    const accepted = useMemo(() => attendees.filter(attendee => attendee.status === ACCEPTED), [attendees]);
    const joined = useMemo(() => attendees.filter(attendee => attendee.status === JOINED), [attendees]);
    // don't allow/enable publish buttons while someone is already publishing
    const computedEnablePublishActions = !joined.some(attendee => attendee.publishAccess) && !accepted.some(attendee => attendee.publishAccess);
    const [delayedEnablePublishActions, setDelayedEnablePublishActions] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (computedEnablePublishActions && !delayedEnablePublishActions) {
            // delay the enabling ot publish actions
            // so that ant media, the database, and other clients have time to update
            // before a new user is set to publish
            timeout = setTimeout(() => setDelayedEnablePublishActions(computedEnablePublishActions), 3000);
        } else {
            setDelayedEnablePublishActions(computedEnablePublishActions);
        }

        return () => {
            // clean up timeout
            if (timeout) {
                clearInterval(timeout);
            }
        };
    }, [computedEnablePublishActions, delayedEnablePublishActions]);

    return (
        <Panel elevation={0}>
            <div className={(classes.titleBox, classes.attendeePanel)}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={4}>
                        <Typography variant="h5" align="left">
                            Attendees
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <SpecialAccessButton attendees={requested} />
                    </Grid>
                </Grid>
                {attendees.length > 0 ? (
                    <>
                        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <Typography variant="h6" align="left">
                                Joined
                            </Typography>
                            <Divider />
                            <ProcedureSubscribersPanel
                                procedureId={procedure.id}
                                attendees={joined}
                                enablePublishActions={delayedEnablePublishActions}
                            />
                            <Typography variant="h6" align="left">
                                Accepted
                            </Typography>
                            <Divider />
                            <ProcedureSubscribersPanel
                                procedureId={procedure.id}
                                attendees={accepted}
                                enablePublishActions={delayedEnablePublishActions}
                            />
                            <Typography variant="h6" align="left">
                                Requested
                            </Typography>
                            <Divider />
                            <ProcedureRequestsPanel procedureId={procedure.id} attendees={requested} />
                            <Typography variant="h6" align="left">
                                Rejected
                            </Typography>
                            <Divider />
                            <ProcedureRequestsPanel procedureId={procedure.id} attendees={rejected} />
                        </div>
                    </>
                ) : (
                    <MessageBox
                        message={
                            new Message({
                                title: 'No Requests',
                                details: 'There are no attendees at this time. You will be notified as requests arrive.',
                            })
                        }
                        className={classes.msg}
                    />
                )}
            </div>
        </Panel>
    );
};
