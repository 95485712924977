/* eslint-disable flowtype/require-valid-file-annotation */

import { makeStyles } from '@material-ui/core';
import { Add, Remove } from '@mui/icons-material';
import { Stack, Slider, Typography } from '@mui/material';

const useStyles = makeStyles({
    sliderIcon: {
        cursor: 'pointer',
    },
});
export const PercentageSlider = ({ value, setValue, min = 0 }: { value: number; setValue: (val: number) => void; min?: number }) => {
    const classes = useStyles();
    const handleChange = (value: number) => {
        setValue(value);
    };
    function valueLabelFormat(value: number) {
        return `${value}%`;
    }

    return (
        <>
            <Stack spacing={2} direction="row" alignItems={'center'}>
                <Remove
                    className={classes.sliderIcon}
                    onClick={() => {
                        if (value <= 0) return handleChange(0);
                        return handleChange(value - 5);
                    }}
                />
                <Slider
                    value={value}
                    min={min}
                    step={5}
                    max={100}
                    getAriaValueText={valueLabelFormat}
                    valueLabelFormat={valueLabelFormat}
                    onChange={(e, value) => handleChange(value as number)}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                />
                <Add
                    className={classes.sliderIcon}
                    onClick={() => {
                        if (value >= 100) return;
                        return handleChange(value + 5);
                    }}
                />
            </Stack>
            <Typography style={{ display: 'flex', justifyContent: 'center' }} id="non-linear-slider" gutterBottom>
                Speed: {valueLabelFormat(value)}
            </Typography>
        </>
    );
};

export default PercentageSlider;
