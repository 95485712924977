import React, { useState, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { ProcedureCard } from 'client/components/ProcedureCard';
import { useUpsertAttendee } from 'client/hooks/attendee/useUpsertAttendee';
import { useUser } from 'client/hooks/user/useUser';
import { MessageBox } from 'common/components/UI/MessageBox';
import Panel from 'common/components/UI/Panel';
import usePlayerControlsStore from 'common/hooks/usePlayerControlsStore';
import Message from 'common/models/Message';
import { getUserInAdminUsers } from 'common/services/userServices';
import { AttendeeStatus, Procedure } from 'common/types';

const useStyles = makeStyles({
    root: {
        padding: theme.spacing(0, 3.5, 3.5),
        textAlign: 'center',
    },
});

const { REQUESTED, CANCELED, ACCEPTED, JOINED } = AttendeeStatus;

interface Props {
    procedures: Procedure[];
}

export const ProcedureList = ({ procedures }: Props) => {
    const classes = useStyles();

    const { user } = useUser();
    const userAdmin = useMemo(() => getUserInAdminUsers(user), [user]);

    const { upsertAttendee } = useUpsertAttendee();
    const [declinedProcedures, setDeclinedProcedures] = useState<number[]>([]);
    const procedureCleanup = usePlayerControlsStore(state => state.procedureCleanup);

    const joinableProcedures = procedures.filter(
        procedure =>
            (procedure.attendee?.status === ACCEPTED || procedure.attendee?.status === JOINED) &&
            !declinedProcedures.includes(procedure.id) &&
            procedure.attendee.joinTimes.length === 0
    );

    // const { activateLicense } = useActivateLicense();
    // const [activated, setActivated] = useState(false);
    // TODO: Add an update to the order to set the expiration date based on the license that's being used here
    // await activateLicense({ procedureId });
    //

    const handleRequestAccess = async (procedureId: number) => {
        // if (activated)
        await upsertAttendee({ procedureId, body: { status: REQUESTED } });
        // else if show dialog that allows the user to activate a license and retry this step
    };

    const handleCancelRequest = async (procedureId: number) => {
        await upsertAttendee({ procedureId, body: { status: CANCELED } });
    };

    const handleJoinRequest = async (procedureId: number) => {
        procedureCleanup();
        await upsertAttendee({ procedureId, body: { status: JOINED } });
    };

    const handleJoinRequestDecline = (procedureId: number) => {
        setDeclinedProcedures(prev => prev.concat(procedureId));
    };

    return (
        <>
            {procedures.length > 0 ? (
                <div className={classes.root}>
                    <Grid container justifyContent="flex-start" spacing={2} direction="row">
                        {procedures.map(procedure => (
                            <Grid item key={procedure.id} xs={12} sm={6} md={4} lg={4} xl={3}>
                                <ProcedureCard
                                    procedure={procedure}
                                    onRequestAccess={handleRequestAccess}
                                    onCancelRequest={handleCancelRequest}
                                    onJoinRequest={handleJoinRequest}
                                    isAdmin={userAdmin}
                                    isCutsJoin={false}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ) : (
                <Panel>
                    <MessageBox
                        message={
                            new Message({
                                title: 'Procedures',
                                details: 'There are no procedures at this time.',
                            })
                        }
                    />
                </Panel>
            )}
            {joinableProcedures.map(procedure => (
                <Dialog key={procedure.id} open fullScreen={false}>
                    <DialogTitle>Request Accepted</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            {`You've been given access to the "${procedure.title}" procedure, would you like to join now?`}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={() => handleJoinRequest(procedure.id)}>
                            Yes
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleJoinRequestDecline(procedure.id)}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            ))}
        </>
    );
};
