import React, { useState } from 'react';
import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useListDoctors } from 'client/hooks/doctors/useListDoctors';
import Panel from 'common/components/UI/Panel';
import { Doctor } from 'common/types/doctor';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DoctorCard } from './DoctorCard';

// @ts-ignore
const styles = theme => ({
    root: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    toolbar: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: '1em',
        marginBottom: '1em',
        marginLeft: 0,
        marginRight: 0,
        background: theme.palette.primary.light,
    },
    relatedFacilitesBox: {
        background: theme.palette.info.light,
    },
    filterHeader: {
        paddingTop: '.2rem',
        paddingBottom: '.2rem',
    },
    filterContent: {
        paddingTop: '.6rem',
        paddingBottom: '.6rem !important',
    },
    websiteLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        cursor: 'pointer',
    },
    facilityBoxSeparator: {
        borderRight: `1px solid ${theme.palette.primary.main}`,
    },
    outlinedWhite: {
        '& .MuiFormLabel-root': {
            color: 'white !important',
        },
        '& > .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white !important',
            },
            color: 'white !important',
        },
    },
});

// @ts-ignore
export const useStyles = makeStyles(styles);

export const truncate = (text: string) => (text.length > 250 ? `${text.substring(0, 350)}...` : text);
export const getDoctorInitials = (doctorName: string) =>
    `${doctorName.split(' ')[1][0]}${doctorName.split(' ')[2] ? doctorName.split(' ')[2][0] : ''}`;
const DoctorsPage = () => {
    const [searchParams] = useSearchParams();
    const classes = useStyles();
    const { reset } = useQueryErrorResetBoundary();

    let doctorInfo: Doctor | undefined;
    const doctorId = searchParams.get('id');
    const { doctors } = useListDoctors({ name: '', specialty: '' });

    if (doctorId) {
        doctorInfo = doctors.find(d => d.id.toString() === doctorId);
    }

    const [filterName, setFilterName] = useState<string | undefined>(doctorInfo?.name);
    const [filterSpecialty, setFilterSpecialty] = useState<string | undefined>(undefined);
    const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName((event.target as HTMLInputElement).value as any);
    };
    const handleFilterSpecialty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterSpecialty((event.target as HTMLInputElement).value as any);
    };

    return (
        <ErrorBoundary
            onReset={reset}
            // eslint-disable-next-line react/no-unstable-nested-components
            fallbackRender={({ resetErrorBoundary }) => (
                <Panel>
                    <p>Oops! Something went wrong...</p>
                    <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                        Try again
                    </Button>
                </Panel>
            )}
        >
            <div className={classes.root}>
                <Box display="flex" flexDirection="row" py={2}>
                    <Box>
                        <TextField
                            id={`input-name`}
                            name={'name'}
                            label={'Name'}
                            variant="outlined"
                            size="small"
                            margin="none"
                            color="secondary"
                            fullWidth
                            value={filterName}
                            onChange={handleFilterName}
                            className={classes.outlinedWhite}
                        />
                    </Box>
                    <Box ml={2}>
                        <TextField
                            id={`input-specialty`}
                            name={'specialty'}
                            label={'Specialty'}
                            variant="outlined"
                            size="small"
                            margin="none"
                            fullWidth
                            value={filterSpecialty}
                            onChange={handleFilterSpecialty}
                            className={classes.outlinedWhite}
                        />
                    </Box>
                </Box>
                <React.Suspense fallback={<Skeleton />}>
                    <Doctors name={filterName} specialty={filterSpecialty} />
                </React.Suspense>
            </div>
        </ErrorBoundary>
    );
};

interface DoctorProps {
    name: string | undefined;
    specialty: string | undefined;
}

const Doctors = ({ name, specialty }: DoctorProps) => {
    const { doctors } = useListDoctors({ name, specialty });
    const navigate = useNavigate();

    const go = (path: string) => {
        navigate(path);
    };

    const goToFacilityDetails = (facilityId: number) => {
        go(`/facilities/${facilityId}`);
    };

    return (
        <Grid container spacing={2}>
            {doctors.map((doctor: Doctor) => (
                <Grid item xs={12} md={12} lg={6} key={doctor.id}>
                    <Box style={{ borderRadius: '10px', height: '100%', background: '#fff' }}>
                        <DoctorCard doctor={doctor} goToFacilityDetails={goToFacilityDetails} key={doctor.id} />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default DoctorsPage;
