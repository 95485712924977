import { useEffect, useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { useConsent } from 'client/hooks/consent/useConsent';
import { useMutateUser } from 'client/hooks/user/useMutateUser';
import { useUser } from 'client/hooks/user/useUser';
import { RequireAdmin } from 'common/components/Auth/RequireAdmin';
import { RequireExportView } from 'common/components/Auth/RequireExportView';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import {
    AboutIcon,
    AccountIcon,
    CloseIcon,
    DoctorIcon,
    DraftIcon,
    AnalyticsIcon,
    FacilityIcon,
    HomeIcon,
    LogoutIcon,
    MenuIcon,
    PurchaseIcon,
    VideoExportIcon,
} from 'common/utils/Icons';
import logo from 'img/logo-color.svg';
import logoWhite from 'img/logo-white.svg';
import { useNavigate, useLocation } from 'react-router-dom';

export function Header() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('VSURGIC');
    const [icon, setIcon] = useState(<HomeIcon />);
    const { user } = useUser();
    const { logout } = useMutateUser();
    const { notify } = useNotifier();
    const { ConsentUpdate } = useConsent();
    const paidUser = true;

    const go = (path: string) => {
        setOpen(false);
        navigate(path);
    };

    const handleRedirect = (to: string) => go(to);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleLogout = () => {
        ConsentUpdate(false);
        setOpen(false);
        logout.mutate();
        go('/');
        notify(new Message({ title: 'Logged Out' }));
    };

    useEffect(() => {
        if (location.pathname === '/') {
            setTitle('Home');
            setIcon(<HomeIcon />);
        } else if (location.pathname === '/about') {
            setTitle('About');
            setIcon(<AboutIcon />);
        } else if (location.pathname === '/account') {
            setTitle('Account');
            setIcon(<AccountIcon />);
        } else if (location.pathname === '/user-management') {
            setTitle('User Management');
            setIcon(<AccountIcon />);
        } else if (location.pathname === '/analytics') {
            setTitle('Analytics');
            setIcon(<AnalyticsIcon />);
        } else if (location.pathname === '/video-export') {
            setTitle('Video Export');
            setIcon(<VideoExportIcon />);
        } else if (location.pathname.includes('/doctors')) {
            setTitle('Doctors');
            setIcon(<DoctorIcon />);
        } else if (location.pathname.includes('/facilities')) {
            setTitle('Facilities');
            setIcon(<FacilityIcon />);
        } else if (location.pathname === '/purchase/history') {
            setTitle('Purchase History');
            setIcon(<PurchaseIcon />);
        } else if (location.pathname === '/templates') {
            setTitle('Templates');
            setIcon(<DraftIcon />);
        } else {
            setTitle('VSURGIC');
            setIcon(<HomeIcon />);
        }
    }, [location]);

    if (!user) {
        return <></>;
    }

    return (
        <div className={`${classes.container} ${classes.lowPadTop}`}>
            <div className={classes.top}>
                <div>
                    <IconButton className={classes.menu} color="secondary" onClick={handleOpen}>
                        <MenuIcon />
                    </IconButton>
                </div>
                <img className={classes.marginAuto} src={logoWhite} alt="logo" width={200} />
                {/* @todo: re-add Buy Access button */}
                {/* <Button className={classes.thin} variant="outlined" color="secondary">
                    <TicketIcon />
                    &nbsp;
                    <Typography>Buy Access</Typography>
                </Button> */}
            </div>
            <div className={classes.bottom}>
                {icon}
                <Typography variant="h5" color="textSecondary">
                    {title}
                </Typography>
            </div>

            {paidUser && (
                <Drawer open={open} onClose={handleClose} classes={{ paper: classes.drawerPaper }}>
                    <List>
                        <ListItem button key="Top" className={classes.drawerHeader}>
                            <ListItemIcon onClick={handleClose}>
                                <CloseIcon />
                            </ListItemIcon>
                            <img src={logo} alt="logo" width={150} />
                        </ListItem>
                        <ListItem button key="Home" onClick={() => handleRedirect('/')}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button key="Doctors" onClick={() => handleRedirect('/doctors')}>
                            <ListItemIcon>
                                <DoctorIcon />
                            </ListItemIcon>
                            <ListItemText primary="Doctors" />
                        </ListItem>
                        <ListItem button key="Facilities" onClick={() => handleRedirect('/facilities')}>
                            <ListItemIcon>
                                <FacilityIcon />
                            </ListItemIcon>
                            <ListItemText primary="Facilities" />
                        </ListItem>
                        <ListItem button key="Purchase History" onClick={() => handleRedirect('/purchase/history')}>
                            <ListItemIcon>
                                <PurchaseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Purchase History" />
                        </ListItem>
                        <ListItem button key="About" onClick={() => handleRedirect('/about')}>
                            <ListItemIcon>
                                <AboutIcon />
                            </ListItemIcon>
                            <ListItemText primary="About" />
                        </ListItem>
                        <ListItem button key="Account" onClick={() => handleRedirect('/account')}>
                            <ListItemIcon>
                                <AccountIcon />
                            </ListItemIcon>
                            <ListItemText primary="Account" />
                        </ListItem>
                        <RequireExportView>
                            <ListItem button key="Video-Export" onClick={() => handleRedirect('/video-export')}>
                                <ListItemIcon>
                                    <VideoExportIcon />
                                </ListItemIcon>
                                <ListItemText primary="Video Export" />
                            </ListItem>
                        </RequireExportView>
                        <RequireAdmin>
                            <ListItem button key="Analytics" onClick={() => handleRedirect('/analytics')}>
                                <ListItemIcon>
                                    <AnalyticsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Analytics" />
                            </ListItem>
                        </RequireAdmin>
                        <RequireAdmin>
                            <ListItem button key="User-Management" onClick={() => handleRedirect('/user-management')}>
                                <ListItemIcon>
                                    <AccountIcon />
                                </ListItemIcon>
                                <ListItemText primary="User Management" />
                            </ListItem>
                            <ListItem button key="Templates" onClick={() => handleRedirect('/templates')}>
                                <ListItemIcon>
                                    <DraftIcon />
                                </ListItemIcon>
                                <ListItemText primary="Templates" />
                            </ListItem>
                        </RequireAdmin>
                        <ListItem button key="Logout" onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </Drawer>
            )}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(4),
    },
    lowPadTop: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    top: {
        display: 'grid',
        gap: theme.spacing(2),
        gridTemplateColumns: '1fr 3fr 1fr',

        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    bottom: {
        display: 'flex',
        alignItems: 'center',
        borderBlockEnd: 'medium solid white',
        borderEndEndRadius: 60,
        color: theme.palette.text.secondary,
        gap: theme.spacing(2),
        padding: theme.spacing(2, 0),
    },
    menu: {
        justifyContent: 'start',
        padding: 0,
    },
    marginAuto: {
        margin: 'auto',
    },
    thin: {
        padding: theme.spacing(0, 4),
    },
    drawerPaper: {
        borderBottomRightRadius: 60,

        '& .MuiListItemText-primary': {
            fontSize: 'x-large',
        },
    },
    drawerHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6),
    },
}));
