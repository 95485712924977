import { makeStyles } from '@material-ui/core';
import logoWhiteMobile from 'img/white-logo-mobile.png';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import { BackButton } from '../BackButton';

interface IHeaderProps {
    backButtonVisible?: boolean;
    backButtonOnclick?: () => void;
    backButtonHref?: string;
    headerImage?: string;
    headerImageWidth?: number;
    children?: JSX.Element;
}

const useStyles = makeStyles(theme => ({
    subtitle: {
        fontSize: '1.5rem',
        fontWeight: 400,
    },
    backButton: {
        flex: '0 0 auto',
    },
}));

export function Header({
    backButtonVisible,
    backButtonOnclick,
    backButtonHref,
    headerImage = logoWhiteMobile,
    headerImageWidth = 200,
    children,
}: IHeaderProps) {
    const classes = useStyles();
    const layouts = useMobileLayouts();
    return (
        <div className={`${layouts.titleContainer}`}>
            <div>
                {backButtonVisible && (
                    <div className={classes.backButton}>
                        <BackButton href={backButtonHref} onClick={backButtonOnclick} />
                    </div>
                )}
            </div>
            <div>
                <img src={headerImage} alt="logo" width={headerImageWidth} />
            </div>
            <div>{children}</div>
        </div>
    );
}
