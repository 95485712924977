import { config } from 'client/hooks/config';
import { getUserInRegisteredUsers } from 'common/services/userServices';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';
import { useUser } from '../user/useUser';

type Response = api.GetAcceptanceResponse;

export function useGetAcceptance() {
    const { key, path } = config.acceptance.me();
    const { user, isLoading } = useUser();
    const isUserRegistered = isLoading ? false : getUserInRegisteredUsers(user);

    const result = useQuery(key, () => Api.get<Response>({ path }), {
        refetchOnWindowFocus: false,
        enabled: isUserRegistered,
        suspense: true,
    });
    return {
        acceptance: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
