import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.ListProceduresResponse;

export function useListProcedures() {
    const { key, path } = config.procedure.list();

    const result = useQuery(key, () => Api.get<Response>({ path }), {
        refetchInterval: 4000,
    });

    return {
        procedures: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
