import { useEffect, useRef, useState } from 'react';
import { Box, Button, Chip, CircularProgress, makeStyles, TextField, Theme } from '@material-ui/core';
import { useSendTemplate } from 'client/hooks/templates/useSendTemplate';
import { useUpdateTemplate } from 'client/hooks/templates/useUpdateTemplate';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const styles = (theme: Theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    label: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    labelBody: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    tags: {
        height: 'auto',
        padding: 3,
        borderRadius: 0,
        border: 0,
        color: '#607d8b',
    },
});
const useStyles = makeStyles(styles);

export interface MergeTag {
    value: string;
    title: string;
}

export interface params {
    id?: number;
    name?: string;
    subject?: string;
    body?: string;
    tags: MergeTag[];
    original?: string;
}

const TemplateEditor = ({ id, name, subject, body, original, tags }: params) => {
    const classes = useStyles();
    const editor = useRef<ReactQuill | null>(null);
    const [isProgress, setisProgres] = useState(false);
    const [bodyValue, setBodyValue] = useState(original);
    const [subjectValue, setSubjectValue] = useState('');
    const { updateTemplate } = useUpdateTemplate();
    const { sendTemplate } = useSendTemplate();
    const { notify } = useNotifier();

    const handleSaveTemplate = async () => {
        try {
            setisProgres(true);
            const resultUdate = await updateTemplate({
                id: id ?? 0,
                subject: subjectValue,
                body: bodyValue ?? '<>No email body was created</>',
            });
            if (resultUdate.success) {
                notify(new Message({ title: resultUdate.message, type: 'success' }));
            }
        } catch (err) {
            notify(new Message({ title: err, type: 'error' }));
        } finally {
            setisProgres(false);
        }
    };

    const handleSendTemplate = async () => {
        try {
            setisProgres(true);
            const resultSend = await sendTemplate({ id: id ?? 0 });
            if (resultSend.success) {
                notify(new Message({ title: resultSend.message, type: 'success' }));
            }
        } catch (err) {
            notify(new Message({ title: err, type: 'error' }));
        } finally {
            setisProgres(false);
        }
    };
    useEffect(() => {
        if (body) {
            setBodyValue(body);
        }
        if (subject) {
            setSubjectValue(subject);
        }
    }, [body, subject]);

    return (
        <>
            <Box className={classes.container}>
                <Box display="flex" flexDirection="row">
                    <div className={classes.label}>
                        <strong>Template Name: </strong>
                    </div>
                    <div className={classes.label}>{name}</div>
                </Box>
                <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
                    <TextField
                        id="outlined-controlled"
                        label="Subject"
                        variant="outlined"
                        value={subjectValue}
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSubjectValue(event.target.value);
                        }}
                    />
                </Box>
                <Box className={classes.labelBody} sx={{ m: 1 }}>
                    <strong>Body</strong>
                    {id && (
                        <Box style={{ float: 'right' }}>
                            <span>Tags:</span>
                            {tags.map(t => (
                                <Chip
                                    key={t.value}
                                    label={`{${t.value}}`}
                                    variant="outlined"
                                    className={classes.tags}
                                    title={`Add tag {${t.value}}`}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <ReactQuill theme="snow" value={bodyValue} onChange={setBodyValue} ref={editor} />
                <Box display="flex" flexDirection="row" paddingTop={2} justifyContent="space-between">
                    {id && (
                        <>
                            <Button size="medium" variant="contained" color="primary" disabled={isProgress} onClick={handleSendTemplate}>
                                Send Test Email
                                {isProgress && (
                                    <>
                                        &nbsp;&nbsp; <CircularProgress size={15} thickness={8} color="primary" />
                                    </>
                                )}
                            </Button>
                            <Button size="medium" variant="contained" color="primary" disabled={isProgress} onClick={handleSaveTemplate}>
                                Save Template
                                {isProgress && (
                                    <>
                                        &nbsp;&nbsp; <CircularProgress size={15} thickness={8} color="primary" />
                                    </>
                                )}
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default TemplateEditor;
