import { config } from 'client/hooks/config';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type useAddFavoriteMutationProps = {
    procedureId: number;
    jobId?: string;
};

type Response = any;
export const useAddFavoriteMutation = () => {
    let procId = 0;
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key, path } = config.videoexport.addFavorite();
    const { mutateAsync } = useMutation(
        key,
        async ({ procedureId, jobId }: useAddFavoriteMutationProps) => {
            procId = procedureId;
            const results = await Api.post<Response>({ path, body: { procedureId, jobId } });
            return results;
        },
        {
            onSuccess: () => {
                notify(new Message({ title: 'Favorite added successfully', type: 'success' }));
                queryClient.invalidateQueries({ queryKey: key });
                queryClient.invalidateQueries({ queryKey: config.videoexport.jobList().key });
                queryClient.invalidateQueries({ queryKey: config.videoexport.getFavoriteInfo(procId).key });
            },
            onError: () => {
                notify(new Message({ title: 'Unable to add favorite', type: 'error' }));
                queryClient.invalidateQueries(key);
            },
        }
    );
    return { mutateAsync };
};

export const useRemoveFavoriteMutation = (procedureId: number) => {
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key: favoriteKey, path } = config.videoexport.deleteFavorite();
    const { mutateAsync } = useMutation(
        favoriteKey,
        async (favoriteId: number) => {
            const favPath = path(favoriteId);
            const results = Api.delete({ path: favPath });
            return results;
        },
        {
            onSuccess: data => {
                notify(new Message({ title: 'Favorite removed successfully', type: 'success' }));
                queryClient.invalidateQueries({ queryKey: favoriteKey });
                queryClient.invalidateQueries({ queryKey: config.videoexport.jobList().key });
                queryClient.invalidateQueries({ queryKey: config.videoexport.getFavoriteInfo(procedureId).key });
            },
            onError: () => {
                notify(new Message({ title: 'Unable to remove favorite', type: 'error' }));
                queryClient.invalidateQueries({ queryKey: favoriteKey });
            },
        }
    );
    return { mutateAsync };
};
