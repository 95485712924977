import { config } from 'client/hooks/config';
import { FacilityInfo } from 'common/types';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export function useListFacilitiesAll() {
    const { key, path } = config.facilities.listAll();
    const result = useQuery(key, () => Api.get<FacilityInfo[]>({ path }), {
        refetchOnWindowFocus: false,
        suspense: true,
    });

    return {
        facilities: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
}
