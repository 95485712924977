import { createTheme } from '@material-ui/core/styles';
import type { TypographyOptions } from '@material-ui/core/styles/createTypography';
import type { Overrides } from '@material-ui/core/styles/overrides';

const typography: TypographyOptions = {};

const palette = {
    type: 'light',
    primary: {
        light: '#98b4bf',
        main: '#617c8b',
        dark: '#677d8b',
        contrastText: '#ffffff',
    },
    secondary: {
        main: '#ffffff',
        dark: '#c1c1c1',
        contrastText: '#000',
    },
    success: {
        light: '#9ad69a',
        main: '#64c064',
        dark: '#3e993e',
        contrastText: '#ffffff',
    },
    info: {
        light: '#ABC8D6',
        main: '#1964f2',
        contrastText: '#fff',
    },
    warning: {
        light: '#f5ce97',
        main: '#eead51',
        dark: '#dd8a16',
        contrastText: '#ffffff',
    },
    error: {
        light: '#e18c88',
        main: '#d2504b',
        dark: '#a72e2a',
        contrastText: '#ffffff',
    },
    disabled: {
        light: '#cfcfcf',
        main: '#9e9e9e',
        dark: '#707070',
        contrastText: '#000000',
    },
    text: {
        primary: '#617c8b',
        secondary: '#fff',
    },
} as const;

const overrides: Overrides = {
    MuiButtonBase: {
        // disableRipple: true,
    },
    MuiButton: {
        disableElevation: {
            boxShadow: 'none',
        },
        root: {
            fontSize: '1rem',
            borderRadius: '32px',
            textTransform: 'capitalize',
            boxShadow: 'none',
            padding: '12px 40px',
            '&.MuiButton-contained.Mui-disabled': {
                color: '#ffffff',
                opacity: 0.65,
            },
            '&.Mui-disabled': {
                color: '#ffffff',
            },
        },
        text: {
            fontSize: '1.1rem',
            padding: 12,
        },
        contained: {
            background: 'linear-gradient(90deg, rgba(96,125,139,1) 0%, rgba(97,124,139,1) 30%, rgba(113,140,154,1) 55%, rgba(152,180,191,1) 100%);',
            fontWeight: 600,
            letterSpacing: 0,
            disabled: {
                color: '#fff !important',
            },
        },
        outlined: {
            padding: '12px 40px',
        },
        outlinedSecondary: {
            border: '1px solid #ffffff',
            color: '#ffffff',
        },
    },
    MuiTextField: {
        root: {
            '& .MuiFormLabel-root': {
                color: '#617c8b',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#617c8b',
                },
                color: '#000',
            },
        },
    },
    MuiTooltip: {
        tooltip: {
            fontSize: '0.875em',
            letterSpacing: 2,
        },
    },
    MuiCard: {
        // disableElevation: true,
    },
    MuiIcon: {
        root: {},
        colorSecondary: {
            color: '#ffffff',
        },
    },
    MuiIconButton: {
        colorSecondary: {
            color: '#ffffff',
        },
    },
    MuiListItemIcon: {
        root: {
            color: palette.primary.main,
        },
    },
    MuiCheckbox: {
        colorPrimary: {
            color: palette.primary.main,
        },
    },
    MuiPaper: {
        root: {
            boxShadowColor: '#fffbfb',
        },
    },
};

export default createTheme({
    typography,
    palette,
    overrides,
});
