import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useMutatePromotion } from 'client/hooks/promotions/useMutatePromotion';
import { usePromotionDialog } from 'client/hooks/promotions/usePromotionDialog';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            '& .MuiDialog-paper': {
                width: '100%',
                maxWidth: '500px',
            },
        },
        infoText: {
            color: 'black',
        },
        successText: {
            color: theme.palette.success.main,
        },
    })
);

const defaultPromoCodeErrorMessage = 'Invalid Promotion Code';

export const PromotionForm: React.FC = () => {
    const classes = useStyles();
    const { promotionDialogOpen, openPromotionDialog, closePromotionDialog } = usePromotionDialog();
    const { redeemPromotion } = useMutatePromotion();
    const { notify } = useNotifier();
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState(false);
    const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState(defaultPromoCodeErrorMessage);
    const [promoCodeSuccessMessage, setPromoCodeSuccessMessage] = useState<string | null>(null);

    const handleRedeemPromotion = () => {
        if (promoCode.length === 0) {
            setPromoCodeError(true);
            setPromoCodeErrorMessage(defaultPromoCodeErrorMessage);
        } else {
            setPromoCodeError(false);
            redeemPromotion.mutate(
                {
                    code: promoCode,
                },
                {
                    onSuccess: () => {
                        setPromoCode('');
                        setPromoCodeSuccessMessage('Promotion Redeemed');
                        setPromoCodeError(false);
                        openPromotionDialog();
                        notify(new Message({ title: 'Promotion Redeemed' }));
                    },
                    onError: (error: any) => {
                        setPromoCodeError(true);
                        setPromoCodeSuccessMessage(null);
                        if (error?.response?.data?.message) {
                            setPromoCodeErrorMessage(error?.response?.data?.message);
                        } else {
                            setPromoCodeErrorMessage(defaultPromoCodeErrorMessage);
                        }
                        notify(new Message({ title: 'Unable to Redeem Promotion', type: 'error' }));
                    },
                }
            );
        }
    };

    return (
        <>
            <Button onClick={openPromotionDialog}>Redeem Code</Button>
            <Dialog open={promotionDialogOpen} onClose={closePromotionDialog} aria-labelledby="form-dialog-title" className={classes.dialog}>
                <DialogTitle id="form-dialog-title">Redeem Code</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.infoText}>Enter the promotion code you received.</DialogContentText>
                    <Typography variant="caption">
                        After entering your promotional code, you will see confirmation in the bottom left of your screen. Close this window once you
                        are finished entering all of your codes.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="promoCode"
                        label="Promotion Code"
                        type="text"
                        fullWidth
                        value={promoCode}
                        onChange={e => setPromoCode(e.target.value)}
                        error={promoCodeError}
                        helperText={promoCodeError ? promoCodeErrorMessage : ''}
                    />
                    {promoCodeSuccessMessage && <DialogContentText className={classes.successText}>{promoCodeSuccessMessage}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePromotionDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleRedeemPromotion} color="primary">
                        Redeem
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
