import { useEffect, useState, useCallback } from 'react';
import { Typography } from '@material-ui/core';

/**
 * Retures a JSX element that displays the time elapsed since the start time.
 * This is written as its own element to limit the amount of rerenders of other components.
 * @param startTime
 * @format 00:00:00:00 (days:hours:minutes:seconds)
 * @returns JSX.Element
 */
export const TimeElapsed = ({ startTime, timeZone }: { startTime: string | Date; timeZone?: string | null | undefined }): JSX.Element => {
    //Check for the start time being either a string or invalid date, and default to the current time that this rendered otherwise; we don't expect the startTime to be either null or undefined but this was will fix that too.
    const safeStartTime = isNaN((startTime instanceof Date ? startTime : new Date(startTime)).valueOf())
        ? new Date().valueOf()
        : new Date(new Date(startTime).toLocaleString('en-us', { timeZone: timeZone ?? undefined })).valueOf();

    const getCurrentDateTime = useCallback(
        () =>
            new Date(
                new Date().toLocaleString('en-us', {
                    timeZone: timeZone ?? undefined,
                })
            ),
        [timeZone]
    );

    const [timeElapsed, setTimeElapsed] = useState(getCurrentDateTime().valueOf() - safeStartTime);
    useEffect(() => {
        //setInterval is not guaranteed to be accurate (it can get pushed by long running functions, etc), so we simply use it to update the timeElapsed state every second based on the diff.
        const intervalId = setInterval(() => {
            setTimeElapsed(getCurrentDateTime().valueOf() - safeStartTime);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [safeStartTime, getCurrentDateTime]);
    const seconds = Math.floor(timeElapsed / 1000) % 60; //1000 milliseconds to a second
    const minutes = Math.floor(timeElapsed / 60000) % 60; //60000 milliseconds to a minute
    const hours = Math.floor(timeElapsed / 3600000) % 24; //3600000 milliseconds to an hour
    const days = Math.floor(timeElapsed / (3600000 * 24)); //86400000 milliseconds to a day (some surgeries are longer than a day.)
    let formattedString: string = `${minutes.toString().padStart(2, '00')}:${seconds.toString().padStart(2, '00')}`;
    if (days > 0) {
        formattedString = `${days.toString().padStart(2, '00')}:${hours.toString().padStart(2, '00')}:${formattedString}`;
    } else if (hours > 0) {
        formattedString = `${hours.toString().padStart(2, '00')}:${formattedString}`;
    }
    return <Typography>{formattedString}</Typography>;
};
