import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useStopProcedure } from 'circulator/hooks/procedure/useStopProcedure';
import { ProcedureNotesDialog } from 'client/components/ProcedureNotesDialog';
import * as types from 'common/types';
import { CardNotesIcon, FacilityIcon, PersonAddIcon, CloseIcon, VideoCamIcon } from 'common/utils/Icons';
import StopWatchIcon from 'img/duration-icon.svg';
import procedure from 'img/stock-procedure.jpg';
import { TimeElapsed } from 'mobile/components/TimeElapsed';

const ProcedureCardRequestAccessButton = React.lazy(() => import('./ProcedureCardAccessRequestButton/ProcedureCardRequestAccessButton'));

// @ts-ignore
const useStyles = (props: { isCutsJoin: boolean }) =>
    makeStyles(theme => ({
        alignItems: {
            display: 'flex',
            alignItems: 'center',
            '& > div': {
                margin: '0 5px',
            },
        },
        // new styles here
        root: {
            textAlign: 'left',
            color: theme.palette.primary.main,
            minHeight: props.isCutsJoin ? '210px' : '310px',
            marginBottom: props.isCutsJoin ? '10px' : undefined,
        },
        detail: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(props.isCutsJoin ? 0.5 : 1),
        },
        icon: {
            margin: theme.spacing(props.isCutsJoin ? 0.5 : 1),
            lineHeight: 1,
            color: 'inherit',
        },
        text: {
            marginLeft: theme.spacing(2),
        },
        header: {
            background: theme.palette.primary.light,
        },
        avatar: {
            background: theme.palette.primary.main,
        },
        cardImage: {
            backgroundSize: 'cover',
            height: props.isCutsJoin ? '155px' : '200px',
            backgroundImage: `url('${procedure}')`,
        },
        imageOverlay: {
            background: 'linear-gradient(0deg, rgba(29,29,29,0.9) 0%, rgba(6,6,6,0.02) 81%)',
        },
        cardContent: {
            padding: 0,
        },
        cardContentBottom: {
            gap: theme.spacing(props.isCutsJoin ? 0.5 : 1),
            padding: theme.spacing(props.isCutsJoin ? 0.5 : 2),
        },
        cardActions: {
            gap: theme.spacing(props.isCutsJoin ? 0.5 : 1),
            padding: theme.spacing(props.isCutsJoin ? 0.5 : 2),
        },
        durationIcon: {
            filter: 'invert(98%) sepia(66%) saturate(0%) hue-rotate(10deg) brightness(101%) contrast(100%)',
            width: '18px',
        },
        elapsedTime: {
            alignItems: 'center',
            display: 'flex',
            gap: theme.spacing(props.isCutsJoin ? 1 : 2),
            border: '1px solid white',
            padding: '3px 8px 3px 3px',
            width: 'auto',
            borderRadius: '25px',
            '& > p': props.isCutsJoin
                ? {
                      fontSize: '0.6rem',
                  }
                : {},
        },
        facilityDetails: props.isCutsJoin
            ? {
                  fontSize: '0.6rem',
                  color: 'rgb(232, 232, 232)',
                  textShadow: '#000 1px 0 5px',
              }
            : {},
        facilityDetails2: props.isCutsJoin
            ? {
                  fontSize: '0.8rem',
                  color: 'rgb(232, 232, 232)',
                  textShadow: '#000 1px 0 5px',
              }
            : {},
        mainTitle: props.isCutsJoin
            ? {
                  fontSize: '1rem',
                  textShadow: '#000 1px 0 5px',
              }
            : {},
    }));

const { REQUESTED, CANCELED, ACCEPTED } = types.AttendeeStatus;

interface Props {
    procedure: types.Procedure;
    onRequestAccess: (procedureId: number) => Promise<void>;
    onCancelRequest: (procedureId: number) => Promise<void>;
    onJoinRequest: (procedureId: number) => Promise<void>;
    isAdmin: boolean;
    isCutsJoin: boolean;
}

export const ProcedureCard = ({ procedure, onRequestAccess, onCancelRequest, onJoinRequest, isAdmin, isCutsJoin }: Props) => {
    const classes = useStyles({ isCutsJoin })();
    const [notesOpened, setNotesOpened] = useState(false);
    const attendeeStatus = procedure.attendee?.status;
    const { device } = procedure;
    const { facility } = device;
    const { stopProcedure } = useStopProcedure(procedure.id);
    const [isStopping, setIsStopping] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const handleStop = async () => {
        setShowConfirmDialog(true);
    };

    const handleCancel = async () => {
        setShowConfirmDialog(false);
    };

    const handleConfirm = async () => {
        setShowConfirmDialog(false);
        setIsStopping(true);
        await stopProcedure();
    };

    return (
        <div key={`card-${procedure.id}`}>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    {/*TODO: Swap out to a video component later to show a preview*/}
                    <Box position="relative" className={classes.cardImage}>
                        <Box position="absolute" bottom={15} zIndex={2} px={isCutsJoin ? 0.5 : 2} width="100%">
                            <Grid container alignItems="center">
                                <Grid item xs={1}>
                                    {facility && (
                                        <Box color="#cacaca">
                                            <FacilityIcon />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={8}>
                                    {facility && (
                                        <Box ml={1} color="#cacaca" py={isCutsJoin ? 0 : 2}>
                                            <div>
                                                <Typography variant="caption" className={classes.facilityDetails}>
                                                    {facility.name} ({facility.idNumber})
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption" className={classes.facilityDetails}>
                                                    {`${[facility.city, facility.state].filter(Boolean).join(', ')}`}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="caption" className={classes.facilityDetails}>
                                                    {facility.phone}
                                                </Typography>
                                            </div>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align="right" variant="h6" color="secondary">
                                        <Box color="#cacaca">
                                            <Typography variant="caption" className={classes.facilityDetails2}>
                                                {device.roomName ? device.roomName : null} {device.tv ? <>({device.tv})</> : null}
                                            </Typography>
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={9}>
                                    <Typography variant="h6" color="secondary" className={classes.mainTitle}>
                                        {procedure.doctorName} <br /> {procedure.title}
                                    </Typography>
                                </Grid>
                                {facility?.timeZone && (
                                    <Grid item xs={3}>
                                        <Typography align="right" variant="h6" color="secondary">
                                            <Box color="#cacaca">
                                                <Typography variant="caption" className={classes.facilityDetails2}>{`${new Date().toLocaleTimeString(
                                                    'en-us',
                                                    {
                                                        timeZone: facility.timeZone,
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    }
                                                )}`}</Typography>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                {facility?.timeZone && (
                                    <Grid item xs={3}>
                                        <Typography align="right" variant="h6" color="secondary">
                                            <Box color="#FFF" className={classes.elapsedTime}>
                                                <img src={StopWatchIcon} alt="" className={classes.durationIcon} />
                                                <TimeElapsed startTime={procedure.startTime} timeZone={facility.timeZone}></TimeElapsed>
                                            </Box>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        <Box zIndex={1} position="absolute" minHeight={1 / 2} bottom={0} left={0} right={0} className={classes.imageOverlay}></Box>
                    </Box>
                    <Grid container className={classes.cardContentBottom}>
                        {procedure.patientInitials && <Chip label={procedure.patientInitials} size="small" color="primary" />}
                        {procedure.deviceName && <Chip label={procedure.deviceName} size="small" color="primary" />}
                        {procedure.companyName && <Chip label={procedure.companyName} size="small" color="primary" />}
                        {procedure.brandName && <Chip label={procedure.brandName} size="small" color="primary" />}
                        <Box py={1.5}></Box>
                    </Grid>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        {procedure.notes && procedure.notes.length > 0 && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    startIcon={<CardNotesIcon />}
                                    onClick={() => setNotesOpened(true)}
                                >
                                    Notes
                                </Button>
                            </Grid>
                        )}
                        {(!attendeeStatus || attendeeStatus === CANCELED) && !procedure.isDemo && !isCutsJoin && (
                            <Grid item>
                                <Box pl={2}>
                                    <ProcedureCardRequestAccessButton
                                        onRequestAccess={onRequestAccess}
                                        procedureId={procedure.id}
                                        isPublic={!procedure.isPremium || procedure.isDemo}
                                        facility={procedure.device.facility || undefined}
                                    />
                                </Box>
                            </Grid>
                        )}
                        {attendeeStatus === REQUESTED && !isCutsJoin && (
                            <>
                                <Grid item>
                                    <Button variant="outlined" color="primary" size="small" onClick={() => onCancelRequest(procedure.id)}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Box px={1}>
                                        <Typography variant="inherit">
                                            <CircularProgress size={10} />
                                            &nbsp; Waiting
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        {(attendeeStatus === ACCEPTED || procedure.isDemo) && !isCutsJoin && (
                            <>
                                <Grid item>
                                    {attendeeStatus === ACCEPTED && !procedure.isDemo && (
                                        <Button variant="outlined" color="primary" size="small" onClick={() => onCancelRequest(procedure.id)}>
                                            Cancel
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<PersonAddIcon />}
                                        onClick={() => onJoinRequest(procedure.id)}
                                    >
                                        Join
                                    </Button>
                                </Grid>
                            </>
                        )}
                        {isCutsJoin && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<VideoCamIcon />}
                                    onClick={() => onJoinRequest(procedure.id)}
                                >
                                    Join
                                </Button>
                            </Grid>
                        )}
                        {isAdmin === true && !isCutsJoin && (
                            <>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<CloseIcon />}
                                        onClick={handleStop}
                                        disabled={isStopping}
                                    >
                                        End Procedure
                                        {isStopping && (
                                            <>
                                                &nbsp;&nbsp;
                                                <CircularProgress size={15} thickness={8} color="primary" />
                                            </>
                                        )}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CardActions>
            </Card>
            <ProcedureNotesDialog enabled={notesOpened} onClose={() => setNotesOpened(false)} procedure={procedure} />
            <Dialog open={showConfirmDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>
                    <Typography variant="h6" component="h6" display="block" gutterBottom>
                        End Procedure
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color="primary">
                        <Typography variant="caption" display="block" gutterBottom>
                            Are you sure you want to end this procedure?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" size="small">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} autoFocus variant="contained" size="small">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
