import { config } from 'client/hooks/config';
import { Template } from 'common/types/template';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export interface TemplateListRespoonse {
    templates: Template[];
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    refetch: () => void;
}

export const useListTemplates = (): TemplateListRespoonse => {
    const { key, path } = config.templates.list();
    const result = useQuery(key, () => Api.get<Template[]>({ path }), {
        refetchOnWindowFocus: false,
        suspense: true,
        enabled: false,
    });
    return {
        templates: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
        refetch: result.refetch,
    };
};
