import { useCallback } from 'react';
import Message from 'common/models/Message';
import { useSnackbar } from 'notistack';

export const useNotifier = () => {
    const { enqueueSnackbar } = useSnackbar();

    const notify = useCallback(
        (message: Message) => {
            enqueueSnackbar(message.getLabel(), {
                variant: message.type,
                preventDuplicate: true,
                disableWindowBlurListener: true,
            });
        },
        [enqueueSnackbar]
    );

    return { notify };
};
