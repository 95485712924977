export function isNullOrUndefined(obj) {
    return obj === null || obj === undefined;
}
export function containsNullOrUndefined(obj) {
    let result = false;
    obj.forEach(element => {
        if (isNullOrUndefined(element)) {
            result = true;
        }
    });
    return result;
}

export function isNotNullOrUndefined(obj) {
    return !isNullOrUndefined(obj);
}
