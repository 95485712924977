import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.SearchDoctorsResponse;
type Request = api.SearchDoctorsRequest;

export function useListDoctors(body: Request) {
    const { key, path } = config.doctors.list();

    const result = useQuery([key, body], () => Api.post<Response>({ path, body }), {});

    return {
        doctors: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
