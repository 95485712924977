import React, { Fragment, useState } from 'react';
import { Avatar, Badge, Button, Grid, makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import theme from 'App/theme';
import * as amplify from 'aws-amplify';
import { ImageField, PhotoUploader } from 'client/components/PhotoUploader';
import { useAssignAssistant } from 'client/hooks/doctors/assistant/useAssignAssistant';
import { useListAssistants } from 'client/hooks/doctors/assistant/useListAssistant';
import { useGetDoctor } from 'client/hooks/doctors/useGetDoctor';
import { useProfile } from 'client/hooks/profile/useProfile';
import { useUpdateProfile } from 'client/hooks/profile/useUpdateProfile';
import Panel from 'common/components/UI/Panel';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import type Profile from 'common/models/Profile';
import CommonUtils from 'common/utils/CommonUtils';
import { EditIcon } from 'common/utils/Icons';
import companyHolder from 'img/company-placeholder.png';
import profilePlaceholder from 'img/profile-placeholder.png';
import PasswordReset from './PasswordReset';
import PersonalInformation from './PersonalInformation';
import Policies from './Policies';

const styles = {
    root: {
        margin: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    panel: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    msg: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    logoImg: {
        '& button': {
            transition: 'none',
            '&:hover': {
                backgroundColor: theme.palette.secondary.light,
            },
        },
    },
    profileImg: {
        textAlign: 'center',
        '& button': {
            transition: 'none',
            '&:hover': {
                borderRadius: '50%',
                backgroundColor: theme.palette.secondary.light,
            },
        },
    },
    highlightImage: {
        maxHeight: '150px',
        maxWidth: '150px',
        width: 'auto',
        height: '100%',
        margin: '0 auto',
    },
};
const EditBadge = withStyles(() => ({
    badge: {
        right: '18%',
        bottom: '18%',
        borderRadius: '50%',
        height: 32,
        width: 32,
        color: grey[300],
        backgroundColor: grey[500],
    },
}))(Badge);
// @ts-ignore
const useStyles = makeStyles(styles);

export const UserAccount = () => {
    const classes = useStyles();

    const { profile: profileData } = useProfile();
    const [profile, setProfile] = useState<Profile>(profileData);
    const { doctor } = useGetDoctor();
    const { assignAssistant } = useAssignAssistant();
    const { assistants } = useListAssistants(doctor?.id ?? 0);
    const { updateProfile } = useUpdateProfile();
    const profilePicture = useGetProtectedObject(profile.picture.url);
    const companyLogo = useGetProtectedObject(profile.companyLogo.url);

    const [imageField, setImageField] = useState<ImageField>();
    const { notify } = useNotifier();

    const userInfo = [profile.position, profile.email, profile.phone];
    const addressLevel = [[profile?.city, profile?.state].filter(e => !!e).join(', '), profile?.postalCode].filter(e => !!e);
    const address = [profile?.address1, profile?.address2, addressLevel.join(' '), addressLevel.length > 0 && profile.country];

    const openImageDialog = (imageField: ImageField) => {
        setImageField(imageField);
    };

    const closeImageDialog = () => {
        setImageField(undefined);
    };

    const handleUpload = async (imageField: ImageField, image: Blob) => {
        const { identityId } = await amplify.Auth.currentCredentials();
        const key = imageField;
        // Url is a combination of identityId and key separated by a vertical bar (|).
        const url = `${identityId}|${key}`;
        await amplify.Storage.put(key, image, {
            level: 'protected',
            contentType: image.type,
        });
        await updateProfile.mutateAsync(profile.clone().value(imageField, { name: imageField, url }));
    };

    const [hasFailedToSave, setHasFailedToSave] = useState(false);

    const handleSave = (updatedUser: Profile) => {
        setProfile(updatedUser.clone());
        if (!updatedUser.isProfileSavable()) {
            notify(new Message({ title: 'Please fill out all required fields.' }));
            setHasFailedToSave(true);
            return;
        }
        updatedUser.phone = CommonUtils.convert2StandardPhoneNumberFormat(updatedUser.phone);
        return updateProfile.mutate(updatedUser, {
            onSuccess: () => {
                setHasFailedToSave(false);
                notify(new Message({ title: 'Account information updated successfully.' }));
            },
            onError: (error: any) => {
                setHasFailedToSave(true);
                const message = error.message ?? 'An error occurred while trying to update account information.';
                notify(new Message({ title: message }));
            },
        });
    };

    const handleAssignAssistant = async (assistantId: string) => {
        const payload = {
            assistantId: assistantId,
            doctorId: doctor?.id || 0,
        };
        try {
            await assignAssistant(payload);
            notify(new Message({ title: 'Account information updated successfully.', type: 'success' }));
        } catch (err) {
            console.log(err);
        }
    };

    const handleNotifier = (message: Message) => {
        notify(message);
    };

    if (profile === undefined || profile === null || profile.id === null) {
        return <ProcessingOverlay enabled />;
    }
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={3}>
                    <Panel elevation={0} style={{ height: 'auto' }}>
                        <Typography variant="h5">{profile.getLabel()}</Typography>
                        <div className={classes.profileImg}>
                            <EditBadge badgeContent={<EditIcon />} overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                <Tooltip placement="bottom" title="Update Profile Image" aria-label="Update Profile Image">
                                    <Button onClick={() => openImageDialog(ImageField.picture)}>
                                        <Avatar
                                            alt={profile.getLabel()}
                                            src={profilePicture ?? profilePlaceholder}
                                            className={classes.highlightImage}
                                        />
                                    </Button>
                                </Tooltip>
                            </EditBadge>
                        </div>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                {userInfo
                                    .filter(e => !!e)
                                    .map(e => (
                                        <Fragment key={e}>
                                            {e}
                                            <br />
                                        </Fragment>
                                    ))}
                            </Typography>
                        </Grid>
                    </Panel>
                    <Panel elevation={0} style={{ height: 'auto' }}>
                        <Typography variant="h5">{profile.company || 'Company Logo'}</Typography>
                        <div className={classes.logoImg}>
                            <EditBadge badgeContent={<EditIcon />} overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                <Tooltip placement="bottom" title="Update Company Logo" aria-label="Update Company Logo">
                                    <Button onClick={() => openImageDialog(ImageField.companyLogo)}>
                                        <img alt={profile.getLabel()} src={companyLogo ?? companyHolder} className={classes.highlightImage} />
                                    </Button>
                                </Tooltip>
                            </EditBadge>
                        </div>
                        <Typography variant="caption">
                            {(address.filter(e => !!e) as string[]).map(e => (
                                <Fragment key={e}>
                                    {e}
                                    <br />
                                </Fragment>
                            ))}
                        </Typography>
                    </Panel>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Panel elevation={0} style={{ height: 'auto' }}>
                        <PersonalInformation
                            user={profile}
                            onSave={handleSave}
                            onAssignAssistant={handleAssignAssistant}
                            assistants={assistants}
                            selectedAssistant={doctor?.assistant}
                            showAllValidations={hasFailedToSave}
                        />
                    </Panel>

                    <Panel elevation={0} style={{ height: 'auto' }}>
                        <PasswordReset />
                    </Panel>

                    <Panel elevation={0} style={{ height: 'auto' }}>
                        <Policies />
                    </Panel>
                </Grid>
            </Grid>
            {imageField && <PhotoUploader imageField={imageField} onUpload={handleUpload} onNotifier={handleNotifier} onClose={closeImageDialog} />}
        </div>
    );
};
