import { Fragment, useState, useCallback, useMemo } from 'react';
import { TextField, Checkbox, Typography, FormControlLabel, withStyles, Select, MenuItem } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import { useListDoctorsUserAssists } from 'client/hooks/doctors/assistant/useListDoctorsUserAssists';
import { useListUsers } from 'client/hooks/user/useListUsers';
import { useUpsertUserRoles } from 'client/hooks/user/useUpsertUserRoles';
import { MessageBox } from 'common/components/UI/MessageBox';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import Profile from 'common/models/Profile';
import CommonUtils from 'common/utils/CommonUtils';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '& > div': {
            minWidth: '48%',
            maxWidth: '48%',
            [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
            },
        },
    },
    header: {
        maxWidth: '100% !important',
        width: '100%',
        marginBottom: theme.spacing(),
    },
    field: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2),
    },
    msg: {
        marginBottom: theme.spacing(3),
    },
    table: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2),
    },
    isDoctorField: {
        maxWidth: '100% !important',
        margin: '0 0',
    },
});

const DOCTOR_ROLE = 'DOCTOR';

const getLabel = field => {
    switch (field) {
        case 'fname':
            return 'First Name';
        case 'lname':
            return 'Last Name';
        case 'email':
            return 'Email Address';
        case 'phone':
            return 'Phone Number';
        case 'company':
            return 'Company Name';
        case 'position':
            return 'Position/Title';
        case 'address1':
            return 'Street Address';
        case 'address2':
            return 'Apt/Suite';
        case 'city':
            return 'City';
        case 'state':
            return 'State';
        case 'country':
            return 'Country';
        case 'postalCode':
            return 'ZIP Code';
        case 'notifyProcStart':
            return 'Receive notifications when a procedure starts';
        default:
            return field;
    }
};

const getInputMask = field => {
    if (field === 'phone') return '999-999-9999';
    return '';
};

const getHelperText = field => {
    if (field === 'phone') {
        return 'Please enter a valid phone number. Example: 555-555-5555';
    }
    return `${getLabel(field)} is required`;
};

const getRequired = field => {
    switch (field) {
        case 'fname':
        case 'lname':
        case 'email':
        case 'phone':
        case 'company':
            return true;
        default:
            return false;
    }
};

const getAutoComplete = field => {
    switch (field) {
        case 'fname':
            return 'given-name';
        case 'lname':
            return 'family-name';
        case 'email':
            return 'email';
        case 'phone':
            return 'tel';
        case 'company':
            return 'organization';
        case 'position':
            return 'organization-title';
        case 'address1':
            return 'address-line1';
        case 'address2':
            return 'address-line2';
        case 'city':
            return 'address-level2';
        case 'state':
            return 'address-level1';
        case 'country':
            return 'country-name';
        case 'postalCode':
            return 'postal-code';
        default:
            return null;
    }
};
/**
 * Given a field and value, determine if the field is invalid and return the updated list of invalid fields
 * If the field is invalid, it adds the field name to the invalid list if it is not already there
 * If the field is valid, it removes the field name from the invalid list if it is there
 * If it made changes, it returns a new list, it never modifies the old one. The new list will be sorted.
 * @param {Readonly<string>} field
 * @param {Readonly<string>} value
 * @param {Readonly<string>[]} invalid
 * @returns {string[]}
 */
const handleValidationRaw = (field, value, invalid) => {
    if (['fname', 'lname', 'phone', 'company'].indexOf(field) > -1) {
        if (value.trim() === '' && !invalid.includes(field)) {
            return [...invalid, field].sort();
        } else if (value.trim() !== '' && invalid.includes(field)) {
            return invalid.filter(e => e !== field);
        } else if (field === 'phone' && value.trim() !== '' && !CommonUtils.isPhoneValid(value)) {
            return [...invalid, field].sort();
        }
    }
    return invalid;
};

const UserForm = ({
    fields,
    disabled,
    user,
    onChange,
    classes,
    onAssistantChange,
    assistants,
    selectedAssistant,
    showAllValidations,
    onIsUserValidChange,
}) => {
    const [focus, setFocus] = useState(null);
    const [invalid, setInvalidRaw] = useState([]);
    const { doctors: doctorsUserAssists } = useListDoctorsUserAssists();
    const { updateUserRoles } = useUpsertUserRoles();
    const { users } = useListUsers();
    const { notify } = useNotifier();
    const curRoles = useMemo(() => {
        return users.find(r => r.userId === user.id)?.roles ?? [];
    }, [users, user.id]);

    const hasDoctorRole = curRoles.includes(DOCTOR_ROLE);

    const handleRoleChange = async e => {
        const addRole = e.target.checked;
        const updatedRoles = addRole ? [...curRoles, DOCTOR_ROLE] : curRoles.filter(r => r !== DOCTOR_ROLE);

        const res = await updateUserRoles({ userId: user.id, roles: updatedRoles });
        if (res.success === true) {
            notify(new Message({ title: 'Role Updated', type: 'success' }));
        }
    };
    /**
     * we put a sort on this so that comparing the list of invalid fields is easier;
     * because this is an array and we are going to recalculate it when showAllValidations is true,
     * manually compare to see if we actually want to set it;
     */
    const setInvalid = useCallback(
        invalidList => {
            let sortedList = Array.isArray(invalidList) ? invalidList.filter(k => typeof k === 'string' && fields.includes(k)).toSorted() : [];
            if (invalid.join(',') !== sortedList.join(',')) {
                setInvalidRaw(oldList => {
                    onIsUserValidChange && onIsUserValidChange(sortedList.length > 0);
                    if (oldList.join(',') !== sortedList.join(',')) {
                        return sortedList;
                    }
                    return oldList;
                });
            }
        },
        [setInvalidRaw, fields, invalid, onIsUserValidChange]
    );

    const handleValidation = useCallback(
        (field, value) => {
            setInvalid(handleValidationRaw(field, value, invalid));
        },
        [invalid, setInvalid]
    );

    //Looked at using the save token for this, but it returns a nested object and lands back where we started.
    const fieldValues = fields.map(field => user[field]).join('|');
    const currentInvalidList = useMemo(
        () => fields.reduce((invalidList, field) => handleValidationRaw(field, user[field], invalidList), []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fields, user, fieldValues]
    );
    if (onIsUserValidChange) {
        onIsUserValidChange(currentInvalidList.length === 0);
    }
    if (showAllValidations) {
        //this does call set in the render method, but it should only run once, and we do a manual comparison to see if we need to set it
        setInvalid(currentInvalidList);
    }

    const handleBlur = useCallback(
        (field, value) => {
            handleValidation(field, value);
            setFocus(null);
        },
        [setFocus, handleValidation]
    );

    const generateMessage = useCallback(
        field => {
            if (['email'].indexOf(field) > -1) {
                return (
                    <MessageBox
                        key="message-invalid-email"
                        message={
                            new Message({
                                title: 'Invalid Email Address',
                                type: 'error',
                            })
                        }
                        className={classes.msg}
                        enabled={!user.isEmailValid() && user?.user?.username?.trim().length > 0 && focus !== 'email'}
                    />
                );
            }
            return null;
        },
        [user, classes, focus]
    );

    const getValue = useCallback(
        field => {
            if (field === 'phone') return CommonUtils.convert2DashedPhoneNumberFormat(user['phone']);
            return user['phone'];
        },
        [user]
    );

    const handleAssistantChange = useCallback(
        event => {
            onAssistantChange(event.target.value);
        },
        [onAssistantChange]
    );

    return (
        <form className={classes.root}>
            {fields.map(field => {
                if (
                    [
                        'fname',
                        'lname',
                        'user.username',
                        'phone',
                        'company',
                        'position',
                        'address1',
                        'address2',
                        'city',
                        'state',
                        'postalCode',
                        'country',
                    ].indexOf(field) > -1
                ) {
                    const inputMask = getInputMask(field);
                    return (
                        <Fragment key={`input-${field}`}>
                            {field === 'fname' && (
                                <Typography variant="h5" className={classes.header}>
                                    Personal Information
                                </Typography>
                            )}
                            {field === 'company' && (
                                <Typography variant="h5" className={classes.header}>
                                    Company Information
                                </Typography>
                            )}
                            {inputMask && (
                                <InputMask
                                    mask={inputMask}
                                    value={getValue(field)}
                                    onChange={onChange(field)}
                                    onBlur={() => handleBlur(field, user[field])}
                                    onFocus={() => setFocus(field)}
                                    disabled={disabled.includes(field)}
                                >
                                    {() => (
                                        <TextField
                                            id={`input-${field}`}
                                            name={field}
                                            label={getLabel(field)}
                                            variant="outlined"
                                            margin="normal"
                                            required={getRequired(field)}
                                            fullWidth
                                            autoComplete={getAutoComplete(field)}
                                            className={classes.field}
                                            helperText={invalid.includes(field) && getHelperText(field)}
                                            error={invalid.includes(field)}
                                        />
                                    )}
                                </InputMask>
                            )}
                            {!inputMask && field !== 'isDoctor' && (
                                <TextField
                                    id={`input-${field}`}
                                    name={field}
                                    label={getLabel(field)}
                                    variant="outlined"
                                    margin="normal"
                                    required={getRequired(field)}
                                    fullWidth
                                    autoComplete={getAutoComplete(field)}
                                    value={user[field]}
                                    disabled={disabled.includes(field)}
                                    onChange={onChange(field)}
                                    onFocus={() => setFocus(field)}
                                    onBlur={() => handleBlur(field, user[field])}
                                    error={invalid.includes(field)}
                                    helperText={invalid.includes(field) && getHelperText(field)}
                                    className={classes.field}
                                />
                            )}
                            {generateMessage(field)}
                        </Fragment>
                    );
                }
                return null;
            })}
            <Typography variant="h5" align="center" className={classes.header}>
                Notification Preferences
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        id="input-notifyProcStart"
                        name="notifyProcStart"
                        label={getLabel('notifyProcStart')}
                        variant="outlined"
                        color="primary"
                        margin="normal"
                        required={false}
                        checked={user['notifyProcStart']}
                        disabled={disabled.includes('notifyProcStart')}
                        onChange={onChange('notifyProcStart')}
                        onFocus={() => setFocus('notifyProcStart')}
                        onBlur={() => handleBlur('notifyProcStart', user['notifyProcStart'])}
                        className={classes['notifyProcStart']}
                    />
                }
                label={getLabel('notifyProcStart')}
            />
            {generateMessage('notifyProcStart')}

            <Typography variant="h5" align="center" className={classes.header}>
                Facility
            </Typography>
            <div className={classes.isDoctorField}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="input-isDoctor"
                            name="isDoctor"
                            variant="outlined"
                            color="primary"
                            margin="normal"
                            required={false}
                            checked={hasDoctorRole}
                            onChange={e => handleRoleChange(e)}
                        />
                    }
                    label={'Doctor credentials requested'}
                />
                <p>
                    I would like my account to be registered as a doctor account. I have provided the information below on at least one facility where
                    I currently have privileges
                </p>
            </div>
            <TextField
                id={`facility-name`}
                name={`facility-name`}
                label={`Facility Name`}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                onChange={onChange('facilityName')}
                className={classes.field}
                value={user.facilityName}
            />
            <TextField
                id={`facility-city`}
                name={`facility-city`}
                label={`Facility City`}
                onChange={onChange('facilityCity')}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                className={classes.field}
                value={user.facilityCity}
            />

            <TextField
                id={`facility-state`}
                name={`facility-state`}
                label={`Facility State`}
                onChange={onChange('facilityState')}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                className={classes.field}
                value={user.facilityState}
            />
            <TextField
                id={`facility-zip`}
                name={`facility-zip`}
                label={`Facility Zip`}
                onChange={onChange('facilityZip')}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                className={classes.field}
                value={user.facilityZip}
            />
            <TextField
                id={`facility-country`}
                name={`facility-country`}
                label={`Facility Country`}
                onChange={onChange('facilityCountry')}
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                className={classes.field}
                value={user.facilityCountry}
            />
            {hasDoctorRole && (
                <>
                    <Typography variant="h5" align="center" className={classes.header}>
                        Assistant
                    </Typography>
                    <FormControl sx={{ m: 1, flex: 'auto' }} variant="filled" size="small">
                        <Select id="assistant-select" value={selectedAssistant || ''} onChange={handleAssistantChange}>
                            <MenuItem value="">None</MenuItem>
                            {assistants
                                .filter(x => x.firstName !== 'Device' && x.firstName !== '')
                                .map(d => (
                                    <MenuItem key={d.id} value={d.id}>
                                        {d.lastName}, {d.firstName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </>
            )}
            {!hasDoctorRole && doctorsUserAssists.length > 0 && (
                <>
                    <MUIDataTable
                        className={classes.table}
                        data={doctorsUserAssists}
                        columns={[
                            {
                                name: 'name',
                                label: 'Assistant To:',
                            },
                        ]}
                        options={{
                            selectableRows: 'none',
                            print: false,
                            download: false,
                            viewColumns: false,
                            filter: false,
                            search: false,
                            pagination: doctorsUserAssists?.length > 2,
                            rowsPerPage: 2,
                        }}
                    />
                </>
            )}
        </form>
    );
};

UserForm.propTypes = {
    user: PropTypes.instanceOf(Profile).isRequired,
    fields: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.arrayOf(PropTypes.string),
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onIsUserValidChange: PropTypes.func,
    assistants: PropTypes.arrayOf(PropTypes.object),
    selectedAssistant: PropTypes.string,
    showAllValidations: PropTypes.bool,
};

UserForm.defaultProps = {
    fields: ['fname', 'lname', 'email', 'phone', 'isDoctor', 'company', 'position', 'address1', 'address2', 'city', 'state', 'postalCode', 'country'],
    disabled: [],
};

export default withStyles(styles)(UserForm);
