import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

type Response = api.GetProcedureResponse;

export function useGetProcedure(procedureId: number) {
    const { key, path } = config.procedure.detail(procedureId);

    const result = useQuery(key, () => Api.get<Response>({ path }), {
        enabled: isNotNullOrUndefined(procedureId),
        refetchInterval: 5000,
    });

    return {
        procedure: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}

export function useGetDoctorProcedure(procedureId: number, enabled: boolean) {
    const { key, path } = config.procedure.detail(procedureId);
    const result = useQuery([key, 'doctor', enabled], () => Api.get<Response>({ path }), {
        enabled: isNotNullOrUndefined(procedureId) && enabled,
    });
    return {
        procedure: result.data ?? null,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
