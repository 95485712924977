import React, { lazy, useState } from 'react';
import { Box, FormControl, InputLabel, OutlinedInput, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';

const DoctorAnalyticsPanel = lazy(() => import('./DoctorAnalyticsPanel'));

const DoctorAnalytics = () => {
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [filterSpecialty, setFilterSpecialty] = useState<string | undefined>(undefined);
    const { reset } = useQueryErrorResetBoundary();

    const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName((event.target as HTMLInputElement).value as any);
    };
    const handleFilterSpecialty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterSpecialty((event.target as HTMLInputElement).value as any);
    };

    return (
        <>
            <Box display="flex" flexDirection="row">
                <Box>
                    <FormControl variant="outlined" size="small">
                        <InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
                        <OutlinedInput labelWidth={50} id="outlined-adornment-amount" value={filterName} onChange={handleFilterName} />
                    </FormControl>
                </Box>
                <Box ml={2}>
                    <FormControl variant="outlined" size="small">
                        <InputLabel htmlFor="outlined-adornment-amount">Specialty</InputLabel>
                        <OutlinedInput labelWidth={75} id="outlined-adornment-amount" value={filterSpecialty} onChange={handleFilterSpecialty} />
                    </FormControl>
                </Box>
            </Box>
            <ErrorBoundary
                onReset={reset}
                fallbackRender={({ resetErrorBoundary }) => (
                    <div>
                        <p>Oops! Something went wrong...</p>
                        <Button onClick={() => resetErrorBoundary()}>Try again</Button>
                    </div>
                )}
            >
                <React.Suspense fallback={<Skeleton />}>
                    <DoctorAnalyticsPanel name={filterName} specialty={filterSpecialty} />
                </React.Suspense>
            </ErrorBoundary>
        </>
    );
};

export default DoctorAnalytics;
