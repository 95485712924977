import { Button, makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        color: 'white',
        padding: '0 !important',
        gap: '30px',
    },
    icon: {
        width: '3rem !important',
        height: '3rem !important',
    },
}));

interface IProps {
    href?: string;
    onClick?: () => void;
}

export const BackButton = ({ href, onClick }: IProps) => {
    const styles = useStyles();
    const navigate = useNavigate();
    return (
        <Button className={styles.container} onClick={onClick || (() => (href ? navigate(href) : 0))}>
            <ChevronLeft className={styles.icon} />
        </Button>
    );
};
