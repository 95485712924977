/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon {...props}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M 22.994 15.023 L 22.994 15.809 C 22.994 16.117 22.746 16.366 22.438 16.366 L 11.284 16.366 L 11.284 17.751 L 11.61 17.751 C 11.916 17.751 12.166 18.001 12.166 18.308 L 12.166 20.546 L 20.797 20.546 C 21.105 20.546 21.355 20.796 21.355 21.104 L 21.355 22.874 C 21.355 23.182 21.105 23.431 20.797 23.431 L 2.867 23.431 C 2.559 23.431 2.309 23.182 2.309 22.874 L 2.309 21.104 C 2.309 20.796 2.559 20.546 2.867 20.546 L 7.488 20.546 L 7.488 18.308 C 7.488 18.001 7.738 17.751 8.046 17.751 L 8.37 17.751 L 8.37 16.366 L 1.227 16.366 C 0.919 16.366 0.67 16.117 0.67 15.809 L 0.67 15.023 C 0.67 14.716 0.919 14.465 1.227 14.465 L 2.044 14.465 C 2.018 14.34 2.004 14.211 2.004 14.078 C 2.004 13.033 2.855 12.183 3.899 12.183 C 4.858 12.183 5.652 12.901 5.776 13.828 C 6.392 12.538 8.13 11.629 10.233 11.629 L 17.043 11.629 C 19.532 11.629 21.536 12.837 21.914 14.465 L 22.438 14.465 C 22.746 14.465 22.994 14.716 22.994 15.023 Z M 7.841 5.193 L 5.278 6.5 C 5.254 6.46 5.229 6.423 5.195 6.389 L 3.296 4.473 L 7.049 2.012 C 7.307 1.844 7.379 1.499 7.21 1.241 C 7.042 0.983 6.696 0.912 6.439 1.08 L 2.116 3.916 C 1.977 4.006 1.885 4.156 1.867 4.323 C 1.85 4.488 1.907 4.654 2.026 4.772 L 4.253 7.021 L 1.862 8.24 C 1.588 8.379 1.479 8.715 1.619 8.989 C 1.718 9.181 1.912 9.293 2.116 9.293 C 2.2 9.293 2.287 9.274 2.369 9.232 L 8.347 6.186 C 8.621 6.045 8.729 5.711 8.591 5.436 C 8.45 5.162 8.115 5.054 7.841 5.193 Z M 21.727 7.845 L 19.086 6.5 L 19.849 2.801 C 19.898 2.561 19.786 2.318 19.571 2.2 L 16.174 0.334 C 15.905 0.187 15.566 0.285 15.419 0.555 C 15.271 0.824 15.369 1.163 15.639 1.311 L 18.675 2.978 L 18.057 5.976 L 15.6 4.723 C 15.326 4.585 14.992 4.693 14.851 4.967 C 14.712 5.241 14.821 5.576 15.095 5.717 L 21.22 8.838 C 21.302 8.879 21.389 8.899 21.473 8.899 C 21.677 8.899 21.873 8.788 21.971 8.594 C 22.11 8.321 22.001 7.986 21.727 7.845 Z" />
    </SvgIcon>
);
