/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, withStyles } from '@material-ui/core';
import { MessageBox } from 'common/components/UI/MessageBox';
import Message from 'common/models/Message';
import PropTypes from 'prop-types';

const styles = () => ({
    root: {},
    btn: {
        verticalAlign: 'baseline',
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: 'none',
    },
    documentWrapper: {
        position: 'relative',
        margin: 0,
        padding: 0,
        '&:first-child': {
            margin: 0,
            padding: 0,
        },
    },
    document: {
        position: 'absolute',
        top: 0,
        bottom: 52,
        width: '100%',
        height: '100%',
        minHeight: 400,
        margin: 0,
        padding: 0,
        borderSize: 1,
        borderStyle: 'solid',
        borderColor: '#efefef',
    },
});

class TCDialog extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        isOpen: false,
    };

    open = () => this.setState({ isOpen: true });

    close = () => this.setState({ isOpen: false });

    render() {
        const { classes } = this.props;
        const { isOpen } = this.state;

        return (
            <>
                <Button variant="text" className={classes.btn} onClick={this.open}>
                    Terms &amp; Conditions
                </Button>
                <Dialog open={isOpen} onClose={this.close} fullScreen>
                    <DialogContent className={classes.documentWrapper}>
                        <MessageBox
                            message={
                                new Message({
                                    title: 'Content Not Available',
                                    details: 'This content needs to be implemented before app release.',
                                    type: 'warn',
                                })
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={this.close}>
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(TCDialog);
