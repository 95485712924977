/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import theme from 'App/theme';
import { useGetUserById } from 'client/hooks/user/useGetUserById';
import { Address } from '../../../common/models/Address';

interface IAProcedureAttendeesPanelInfoProps {
    attendee: any;
}

const styles = {
    box: {
        border: '1px',
    },
    boxHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    boxAccepted: {
        backgroundColor: 'green',
    },
    boxAlarm: {
        backgroundColor: 'red',
    },
    boxHeaderText: {
        color: theme.palette.primary.contrastText,
    },
    w100: {
        width: '100%',
        display: 'block',
    },
};

const useStyles = makeStyles(styles);

const AProcedureAttendeesPanelInfo = ({ attendee }: IAProcedureAttendeesPanelInfoProps) => {
    const classes = useStyles();
    const { user, isLoading } = useGetUserById(attendee.userId);

    const address = Address.fromJsonString(user?.address);

    const cityAndState = `${address?.city ?? ''}${address?.city && address?.state ? ', ' : ''}${address?.state ?? ''}${
        !address?.state && !address?.city ? '-' : ''
    }`;

    if (isLoading) return <Skeleton sx={{ width: 100 }} />;

    return (
        <Grid container spacing={1} key={user?.userId}>
            <Grid item xs={4}>
                <Box className={classes.box}>
                    <Box className={attendee.status === 'REJECTED' || attendee.status === 'CANCELED' ? classes.boxAlarm : classes.boxAccepted}>
                        <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                            Requester - {attendee.status}
                        </Typography>
                    </Box>
                    <Typography align="center" variant="body1" noWrap>
                        {user?.firstName} {user?.lastName}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box className={classes.box}>
                    <Box className={classes.boxHeader}>
                        <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                            Company
                        </Typography>
                    </Box>
                    <Typography align="center" variant="body1" noWrap>
                        {user?.companyName ?? '-'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box className={classes.box}>
                    <Box className={classes.boxHeader}>
                        <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                            Position
                        </Typography>
                    </Box>
                    <Typography align="center" variant="body1" noWrap>
                        {user?.position ?? '-'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box className={classes.box}>
                    <Box className={classes.boxHeader}>
                        <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                            Location
                        </Typography>
                    </Box>
                    <Typography align="center" variant="body1" noWrap>
                        {cityAndState}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box className={classes.box}>
                    <Box className={classes.boxHeader}>
                        <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                            Time spent
                        </Typography>
                    </Box>
                    <Typography align="center" variant="body1" noWrap>
                        {Number.isFinite(attendee.timeSpent) ? `${attendee.timeSpent}m` : '-'}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AProcedureAttendeesPanelInfo;
