import { config } from 'client/hooks/config';
import { updateProfile } from 'common/services/profileServices';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdateProfile = () => {
    const queryClient = useQueryClient();

    const update = useMutation(updateProfile, {
        onSuccess: () => queryClient.invalidateQueries(config.user.all()),
    });

    return {
        updateProfile: update,
    };
};
