import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.GetDoctorResponse;

export function useGetDoctor() {
    const { key, path } = config.doctors.get();

    const result = useQuery([key], () => Api.get<Response>({ path }), {});

    return {
        doctor: result.data ?? undefined,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
