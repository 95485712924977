import React, { useCallback, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useGetPrimaryAttendee } from 'circulator/hooks/attendee/useGetPrimaryAttendee';
import { useGetActiveProcedure } from 'circulator/hooks/procedure/useGetActiveProcedure';
import { useGetUser } from 'circulator/hooks/user/useGetUser';
import { Player } from 'common/components/Player';
import usePlayerControlsStore from 'common/hooks/usePlayerControlsStore';
import type { Procedure } from 'common/types/circulator';
import logo from 'img/logo-black.svg';
import useOrientation from '../../../common/hooks/useOrientation';

const useStyles = (props: { isLandscape: boolean }) =>
    makeStyles(theme => ({
        feed: {
            maxWidth: '100%',
        },
        maxHeight40: {
            maxHeight: '40vh',
        },
        feedHidden: {
            maxHeight: '40vh',
            maxWidth: '100%',
            display: 'none',
        },
        shadeDown: {
            position: props.isLandscape ? 'relative' : 'absolute',
            zIndex: 1111,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(12px)',
            height: props.isLandscape ? '100%' : 'auto',
        },
        shadeMain: {
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60%',
        },
        userOverlay: {
            position: 'absolute',
            zIndex: 1111,
            bottom: 0,
            border: '1px',
            backgroundColor: '#efefef',
            width: '100%',
        },
        userSubSubhead: {
            color: 'black',
            position: 'relative',
            top: '40%',
            width: props.isLandscape ? 'fit-content' : '100%',
            letterSpacing: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            minWidth: props.isLandscape ? '100%' : '75vw',
            textAlign: props.isLandscape ? 'center' : 'left',
            maxWidth: '100%',
        },
        companyLogo: {
            maxHeight: 64,
            maxWidth: 128,
            paddingTop: theme.spacing(1),
            margin: 0,
        },
    }));

export interface FeaturedUserPlayerProps {}

export const FeaturedUserPlayer: React.FC<FeaturedUserPlayerProps> = props => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape })();
    const volume = usePlayerControlsStore(state => state.volume);
    const [isStreaming, setStreaming] = useState(false);
    const { procedure }: { procedure: Procedure } = useGetActiveProcedure();
    const { primaryAttendee } = useGetPrimaryAttendee(procedure?.id);
    const { user: primaryAttendeeUser } = useGetUser(primaryAttendee!.userId);
    const handleOnPlaying = useCallback(() => {
        setStreaming(true);
    }, [setStreaming]);
    const handleOnStopping = useCallback(() => {
        setStreaming(false);
    }, [setStreaming]);

    const src = procedure.device.streams.find(x => x.isFeatured)?.src;

    return (
        <>
            {(!isStreaming || !primaryAttendeeUser) && (
                <div className={`${classes.shadeDown} ${classes.shadeMain}`}>
                    <Typography align="center" variant="h5" className={classes.userSubSubhead}>
                        Waiting for moderator to begin streaming...
                    </Typography>
                </div>
            )}
            {isStreaming && primaryAttendeeUser && (
                <div className={`${classes.userOverlay}`}>
                    <Grid container alignContent="center" alignItems="center" justifyContent="flex-start">
                        <Grid item xs={3}>
                            <img
                                src={primaryAttendeeUser.companyLogo ?? ''}
                                alt={primaryAttendeeUser.companyName ?? ''}
                                className={classes.companyLogo}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography align="left" variant="h5" className={classes.userSubSubhead}>
                                {primaryAttendeeUser.firstName} {primaryAttendeeUser.lastName}
                                {primaryAttendeeUser.position ? ` - ${primaryAttendeeUser.position}` : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )}

            <Player
                isActive={false}
                isMainPlayer={false}
                width="100%"
                height="100%"
                src={src}
                iceServers={JSON.stringify(procedure.device.iceServers)}
                className={`${isStreaming ? classes.feed : classes.feedHidden} ${!isLandscape && classes.maxHeight40}`}
                obscure={false}
                muted={false}
                volume={volume}
                onPlaying={handleOnPlaying}
                onStopping={handleOnStopping}
                controls={false}
            />
        </>
    );
};
