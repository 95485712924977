import React, { useMemo } from 'react';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { useGetActiveProcedure } from 'circulator/hooks/procedure/useGetActiveProcedure';
import { useMutateProcedure } from 'circulator/hooks/procedure/useMutateProcedure';

const styles = {
    button: {
        height: 40,
        margin: '0.5em',
    },
};

const useStyles = makeStyles(styles);
export const PlayerShadePanel: React.FunctionComponent = () => {
    const classes = useStyles();
    const { procedure, isFetching } = useGetActiveProcedure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isShadeDown = useMemo(() => procedure?.isShadeDown || false, [procedure, procedure?.isShadeDown]);
    const captionMessage = useMemo(() => (isShadeDown ? 'Unobscure' : 'Obscure'), [isShadeDown]);
    const procedureMutation = useMutateProcedure();

    const handleToggleShade = () => {
        if (isShadeDown) {
            procedureMutation.unobscure.mutate(procedure?.id);
        } else {
            procedureMutation.obscure.mutate(procedure?.id);
        }
    };

    const shadeButton = useMemo(
        () => (
            <Button variant="contained" color="primary" onClick={() => handleToggleShade()} className={classes.button}>
                {captionMessage}
            </Button>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [captionMessage, isFetching, handleToggleShade]
    );

    return <Tooltip title="Obscure video feeds for all users. Audio is not impacted.">{shadeButton}</Tooltip>;
};
