import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';
type Direction = 'up' | 'down' | 'left' | 'right' | 'zoomin' | 'zoomout' | 'default';

interface PtzPayload {
    streamId: number | undefined;
    direction: Direction;
    speed: number;
}

const adjustPtzParameters = (ptzPayload: PtzPayload) => {
    const { path } = config.camera.changePosition(ptzPayload.streamId);
    return Api.post({ path, body: ptzPayload });
};

const gotoHomePosition = (streamId: number | undefined) => {
    const { path } = config.camera.gotoHomePosition(streamId);
    return Api.post({ path, body: { id: streamId } });
};

export const useMutateCameraPosition = () => {
    const changePosition = useMutation(adjustPtzParameters);
    const gotoHome = useMutation(gotoHomePosition);
    return { changePosition, gotoHome };
};
