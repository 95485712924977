import React from 'react';
import { Drawer, Grid, IconButton, makeStyles, Slider, TextField } from '@material-ui/core';
import theme from 'App/theme';
import { CirculatorPlayerPanel } from 'circulator/components/Procedure/CirculatorPlayerPanel';
import { PlayerShadePanel } from 'circulator/components/Procedure/PlayerShadePanel';
import usePlayerControlsStore from 'common/hooks/usePlayerControlsStore';
import type { Procedure } from 'common/types/circulator';
import { ExpandLess, ExpandMore } from 'common/utils/Icons';
import { shallow } from 'zustand/shallow';

const drawerWidth = '100vw';
const border = '1px solid rgba(0, 0, 0, 0.12)';

const styles = {
    drawerHandle: {
        marginRight: theme.spacing(),
        marginLeft: theme.spacing(),
    },
    drawerHandleBox: {
        marginLeft: '1em',
        marginBottom: '1em',
        position: 'absolute',
        display: 'flex',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'flex-end',
        bottom: 0,
        left: 0,
        height: 56,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: 48,
        },
        [theme.breakpoints.up('sm')]: {
            height: 64,
        },
        background: theme.palette.primary.light,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        //     height: 48,
        //     top: -48,
        // },
        // [theme.breakpoints.up('sm')]: {
        //     height: 64,
        //     top: -64,
        // },
        justifyContent: 'flex-start',
        left: 0,
        zIndex: 10000,
        width: drawerWidth,
        backgroundColor: '#efefef',
        borderLeft: border,
        borderBottom: border,
    },
    drawerPaper: {
        width: drawerWidth,
        position: 'absolute',
        bottom: 0,
        left: 0,
        // [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        //     top: 48,
        //     marginTop: 48,
        // },
        // [theme.breakpoints.up('sm')]: {
        //     top: 64,
        //     marginTop: 64,
        // },
        overflow: 'visible',
        backgroundColor: '#efefef',
        borderLeft: border,
    },
    cameraPanel: {
        padding: theme.spacing(2),
        textAlign: 'center',
        overflow: 'auto',
    },
};
// @ts-ignore
const useStyles = makeStyles(styles);

interface Props {
    procedure: Procedure;
}

export const CirculatorPlayerDrawer: React.FC<Props> = ({ procedure }) => {
    const classes = useStyles();

    const { volume, setVolume } = usePlayerControlsStore(
        state => ({
            volume: state.volume,
            setVolume: state.setVolume,
        }),
        shallow
    );
    const [drawerOpen, setDrawerOpen] = React.useState(true);

    const handleVolume = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        if (typeof value === 'number') {
            // Set volume to value if value is a number.
            setVolume(value);
        } else if (value.length > 0) {
            // Set volume to the first item in the array if value is an array with non-zero length.
            setVolume(value[0]);
        }
    };

    const handleTextVolume = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.currentTarget.value || '0', 10);
        const volume = Math.min(Math.max(value, 0), 100);
        setVolume(volume);
    };

    const handleToggleDrawer = () => {
        setDrawerOpen(e => !e);
    };

    return (
        <>
            <div className={classes.drawerHandleBox} style={{ display: drawerOpen ? 'none' : 'inherit' }}>
                <IconButton onClick={handleToggleDrawer} className={classes.drawerHandle}>
                    <ExpandMore />
                </IconButton>
            </div>
            <div style={{ display: drawerOpen ? 'inherit' : 'none' }}>
                <Drawer
                    anchor="bottom"
                    open={drawerOpen}
                    variant="persistent"
                    classes={{ paper: classes.drawerPaper }}
                    // Better open performance on mobile.
                    ModalProps={{ keepMounted: true }}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div className={classes.drawerHeader}>
                                <Grid container spacing={1}>
                                    <Grid container item xs={2} justifyContent="flex-start" alignContent="center">
                                        <IconButton onClick={handleToggleDrawer}>
                                            <ExpandLess />
                                        </IconButton>
                                    </Grid>
                                    <Grid container item xs={8} justifyContent="flex-start" alignContent="center">
                                        <Grid
                                            container
                                            spacing={3}
                                            // className={[classes.videoMenu, classes.flexGrow].join(' ')}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid container item xs={10} justifyContent="center" alignItems="center">
                                                <Slider value={volume} step={1} min={0} max={100} onChange={handleVolume} />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    // className={classes.paleBg}
                                                    value={volume}
                                                    onChange={handleTextVolume}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={2} justifyContent="flex-end" alignContent="center">
                                        <PlayerShadePanel />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.cameraPanel}>
                                <CirculatorPlayerPanel procedure={procedure} />
                            </div>
                        </Grid>
                    </Grid>
                </Drawer>
            </div>
        </>
    );
};
