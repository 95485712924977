import { keys } from 'client/hooks/keys';
import { ExtendedCognitoUser, getCurrentUserWithAttrs } from 'common/services/userServices';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export interface User {
    user?: ExtendedCognitoUser;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
}

export const useUser = (): User => {
    const result = useQuery(keys.user(), getCurrentUserWithAttrs, {
        onSettled: user => {
            Api.setAuthorizationHeaders(user);
        },
        cacheTime: Infinity,
    });

    return {
        user: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
};
