import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Response = api.ApiResponseStandard;

export interface ProcedureUpdateRequest {
    id: number;
    title: string;
    patientInitials: string;
    doctorId: number | null;
    deviceId: number;
    brandName: string;
    notes: string;
    companyName: string;
    surgeryStart?: string | null;
    surgeryEnd?: string | null;
    roomName?: string | null;
}

export const useUpdateProcedure = () => {
    const { path: updatePath } = config.procedure.update();
    const trans = useMutation((data: ProcedureUpdateRequest) => Api.post<Response>({ path: updatePath, body: data }));
    return {
        updateProcedure: trans.mutateAsync,
    };
};
