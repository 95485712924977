import { IconButton } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { DeleteIcon } from 'common/utils/Icons';

interface StreamDeleteButtonProps {
    s3Key: string;
    handleDelete: any;
    isLoading: boolean;
}

export const StreamDeleteButton: React.FC<StreamDeleteButtonProps> = ({ s3Key, handleDelete, isLoading }) => {
    if (isLoading) return <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />;
    return (
        <IconButton onClick={() => handleDelete(s3Key)}>
            <DeleteIcon color="error" />
        </IconButton>
    );
};
