import { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useMaster } from 'common/hooks/WebRTC';
import { MasterType } from 'common/hooks/WebRTC/master-controller';
import type { Procedure } from 'common/types/circulator';
import { Stream } from 'common/types/stream';
import noVideo from 'img/video-placeholder.jpg';

const useStyles = makeStyles({
    splash: {
        '&::before': {
            content: '""',
            height: '100%',
            width: '100%',
            position: 'fixed',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            backgroundColor: '#000',
            backgroundImage: 'url(./img/splash_2.jpg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.2,
            zIndex: -1,
        },
    },
    w100: {
        width: '100%',
    },
    videoDimensions: {
        width: '100%',
        height: '145px',
    },
    publisherTitle: {
        color: 'white',
    },
    videoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
});

export interface DynamicCameraPublisherProps {
    procedure: Procedure;
    stream: Stream;
}

export const DynamicCameraPublisher: React.FC<DynamicCameraPublisherProps> = props => {
    const classes = useStyles();
    const videoElementId = `local-view-${props.stream.channelId}`;
    const [isPublishingAudio, setIsPublishingAudio] = useState(false);
    const [isPublishingVideo, setIsPublishingVideo] = useState(false);
    const shouldPublishingAudio = props.stream.type.includes('AUDIO');
    const shouldPublishingVideo = props.stream.type.includes('VIDEO');
    const isHidden = props.stream.type === 'AUDIO_ONLY';

    const { controller } = useMaster({
        videoElementId,
        streamId: props.stream.channelId,
        publishUrl: props.procedure.publishUrl!,
        token: props.stream.publishToken!,
        iceServers: props.procedure.publishIceServers!,
        isPublishingVideo: shouldPublishingVideo,
        isPublishingAudio: shouldPublishingAudio,
        canPublishVideo: shouldPublishingVideo,
        masterType: MasterType.CIRCULATOR_DYNAMIC,
    });

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (!isPublishingAudio && shouldPublishingAudio && controller) {
            controller.addAudio();

            timerId = setTimeout(() => {
                if (controller) {
                    controller.addAudio();
                }
            }, 5000);

            setIsPublishingAudio(true);
        }
        return () => clearTimeout(timerId);
    }, [controller, isPublishingAudio, shouldPublishingAudio]);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (!isPublishingVideo && shouldPublishingVideo && controller) {
            controller.addVideo();

            timerId = setTimeout(() => {
                if (controller) {
                    controller.addVideo();
                }
            }, 5000);

            setIsPublishingVideo(true);
        }
        return () => clearTimeout(timerId);
    }, [controller, isPublishingVideo, shouldPublishingVideo]);

    return (
        <Box position="relative" bgcolor="black" className={isHidden ? classes.hidden : classes.videoWrapper}>
            <video id={videoElementId} autoPlay playsInline poster={noVideo} className={isHidden ? classes.hidden : classes.videoDimensions} muted />
        </Box>
    );
};
