import CommonUtils from 'common/utils/CommonUtils';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import AccessRequestArray from './AccessRequestArray';
import AttendeeArray from './AttendeeArray';
import BasicDomain from './BasicDomain';
import CameraArray from './CameraArray';
import Device from './Device';

export default class Procedure extends BasicDomain {
    get className() {
        return 'Procedure';
    }

    get myClass() {
        return Procedure;
    }

    static REQUIRED_FIELDS = ['doctorName', 'title'];

    static MAX_TIME = 90;

    constructor(props = {}) {
        super(props);

        this.id = props.id || null;
        this.doctorName = props.doctorName;
        this.patientInitials = props.patientInitials;
        this.title = props.title;
        this.deviceName = props.deviceName;
        this.companyName = props.companyName;
        this.brandName = props.brandName;
        this.referenceNumber = props.referenceNumber;
        this.notes = props.notes;
        this.isActive = props.isActive === true;
        this.device = isNotNullOrUndefined(props.device) ? new Device(props.device || {}) : null;
        this.deviceId = props.deviceId || null;
        this.userStream = props.userStream || null;
        this.isShadeDown = props.isShadeDown || false;
        this.isPremium = props.isPremium || true;
        this.connectionLost = props.connectionLost || false;
        this.doctorId = props.doctorId || null;
        this.createdAt = props.createdAt || null;
        this.isMobile = props.isMobile || false;

        if (props.startTime == null) {
            this.startTime = CommonUtils.formatDate(CommonUtils.dateLocal(new Date()), CommonUtils.FORMAT_HTML5);
        } else {
            this.startTime = CommonUtils.formatDate(CommonUtils.dateLocal(props.startTime || props.startTime), CommonUtils.FORMAT_HTML5);
        }
        if (props.featuredUser && props.featuredUser.profile) {
            this.featuredUser = props.featuredUser;
        }

        // Transient Values
        this.requests = new AccessRequestArray(props.requests);
        this.attendees = new AttendeeArray(props.attendees);
        this.cameras = new CameraArray(props.cameras);
        this.notify = props.notify === undefined ? true : props.notify;
    }

    isSame = target =>
        target != null &&
        target.id === this.id &&
        target.doctorName === this.doctorName &&
        target.patientInitials === this.patientInitials &&
        target.title === this.title &&
        target.deviceName === this.deviceName &&
        target.companyName === this.companyName &&
        target.brandName === this.brandName &&
        target.notes === this.notes &&
        target.isActive === this.isActive &&
        target.device.id === this.device.id &&
        target.startTime === this.startTime &&
        target.userStream === this.userStream &&
        target.isShadeDown === this.isShadeDown &&
        target.isPremium === this.isPremium &&
        target.connectionLost === this.connectionLost &&
        target.doctorId === this.doctorId &&
        target.isMobile === this.isMobile;

    isSavable = () => Procedure.REQUIRED_FIELDS.find(f => this[f] == null || this[f] === '') == null;

    isDateTimeValid = () => CommonUtils.isSameOrBefore(this.startTime, CommonUtils.dateAdd(CommonUtils.now(), Procedure.MAX_TIME, 'minutes'));

    getSaveToken = connectedDevices => {
        return {
            title: this.title,
            patientInitials: this.patientInitials,
            doctorName: this.doctorName,
            deviceName: this.deviceName,
            companyName: this.companyName,
            brandName: this.brandName,
            referenceNumber: this.referenceNumber,
            notes: this.notes,
            deviceId: this.device.id,
            doctorId: this.doctorId,
            isPremium: this.isPremium,
            notify: this.notify,
            devices: connectedDevices,
            isMobile: this.isMobile,
        };
    };

    getLabel = () => {
        if (this.id == null) {
            return 'New Procedure';
        }

        if (this.title == null || this.title === '') {
            return 'Procedure';
        }

        return this.title;
    };
}
