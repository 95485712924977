import { useMemo } from 'react';
import { useUser } from 'client/hooks/user/useUser';
import { getUserInAdminUsers } from 'common/services/userServices';

export function RequireAdmin({ children }: { children?: JSX.Element | JSX.Element[] }) {
    const { user } = useUser();
    const userAdmin = useMemo(() => getUserInAdminUsers(user), [user]);

    if (userAdmin) {
        return <>{children}</>;
    } else {
        return <></>;
    }
}
