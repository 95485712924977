import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { config } from 'mobile/hooks/config';
import { useMutation, useQueryClient } from 'react-query';

type CreateOrGetDeviceProps = {
    facilityId: number;
};

export const useCreateDevice = () => {
    const { path: startPath } = config.mobile.getDevice();
    const queryClient = useQueryClient();
    const { notify } = useNotifier();

    const create = useMutation((body: CreateOrGetDeviceProps) => Api.post({ path: startPath, body }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(config.mobile.all());
        },
        onError: (error: Error) => {
            console.log(error);
            notify(new Message({ title: error.message, type: 'error' }));
        },
    });

    return {
        createOrGetDevice: create.mutateAsync,
    };
};
