import { useState, FC } from 'react';
import { Link } from '@material-ui/core';
import { PlayCircleOutline } from '@mui/icons-material';
import { useGetPresignedUrl } from 'common/hooks/storage/useGetPresignedUrl';
import VideoPreviewModal, { VideoPreviewModalProps } from '../VideoPreviewModal';
interface VideoPreviewProps {
    s3Key: string;
    s3Filename: string;
    procedureId?: number;
    doctorName?: string;
    companyName?: string;
    brandName?: string;
    procedureTitle?: string;
    deviceName?: string;
    patientInitials?: string;
    exporting: boolean;
    jobTitle?: string;
    timeZone?: string;
    isAudioOnly?: boolean;
    isMobile?: boolean;
}
export const VideoPreview: FC<VideoPreviewProps> = ({
    s3Key,
    s3Filename,
    procedureId,
    doctorName,
    companyName,
    brandName,
    procedureTitle,
    deviceName,
    patientInitials,
    exporting,
    jobTitle,
    timeZone,
    isAudioOnly,
    isMobile,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [tempStartTime, setTempStartTime] = useState<string>('');
    const [tempEndTime, setTempEndTime] = useState<string>('');
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [captureTime, setCaptureTime] = useState(0);
    const [title, setTitle] = useState<string>(jobTitle || '');
    const presignedUrl = useGetPresignedUrl(s3Key);
    const handleOpenClose = () => {
        if (!isOpen === false) {
            setTempEndTime('');
            setTempStartTime('');
            setStartTime(0);
            setEndTime(0);
            setCaptureTime(0);
            setTitle('');
        }
        setIsOpen(!isOpen);
    };

    const ViewModalProps: VideoPreviewModalProps = {
        setCaptureTime: setCaptureTime,
        setTempStartTime: setTempStartTime,
        setTempEndTime: setTempEndTime,
        setStartTime: setStartTime,
        setEndTime: setEndTime,
        setIsOpen: setIsOpen,
        handleOpenClose: handleOpenClose,
        setTitle: setTitle,
        exporting: exporting,
        isOpen: isOpen,
        presignedUrl: presignedUrl,
        tempStartTime: tempStartTime,
        tempEndTime: tempEndTime,
        startTime: startTime,
        endTime: endTime,
        captureTime: captureTime,
        s3Key: s3Key,
        s3Filename: s3Filename,
        procedureId: procedureId,
        doctorName: doctorName,
        companyName: companyName,
        brandName: brandName,
        procedureTitle: procedureTitle,
        deviceName: deviceName,
        patientInitials: patientInitials,
        videoTitle: title,
        timeZone: timeZone,
        isAudioOnly: isAudioOnly,
        isMobile: isMobile,
    };
    return isOpen ? (
        <VideoPreviewModal {...ViewModalProps} />
    ) : (
        <Link
            component={'button'}
            onClick={handleOpenClose}
            style={{
                color: isMobile ? 'unset' : '',
            }}
        >
            {isMobile ? (
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                    {deviceName} <PlayCircleOutline sx={{ ml: 1 }} fontSize="small" />
                </div>
            ) : (
                s3Filename
            )}
        </Link>
    );
};

export default VideoPreview;
