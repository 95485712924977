import { useCallback, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
import { useGetPrimaryAttendee } from 'client/hooks/attendee/useGetPrimaryAttendee';
import { useGetProcedure } from 'client/hooks/procedure/useGetProcedure';
import { Player } from 'common/components/Player';
import { SpeakersIcon, SpeakersOffIcon } from 'common/utils/Icons';
import logo from 'img/logo-black.svg';
import noVideo from 'img/video-placeholder.jpg';
import { useLayouts } from '../hooks/styles/useLayouts';
import { useStyles } from '../hooks/styles/useStyles';

const styles: Parameters<typeof makeStyles>[0] = {
    splash: {
        '&::before': {
            content: '""',
            height: '100%',
            width: '100%',
            position: 'fixed',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            backgroundColor: '#000',
            backgroundImage: 'url(./img/splash_2.jpg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.2,
            zIndex: -1,
        },
    },
    w100: {
        width: '100%',
    },
    videoDimensions: {
        width: '200px',
        height: '95px',
        backgroundColor: 'white',
    },
    publisherTitle: {
        color: 'white',
    },
    shadeMain: {
        backgroundImage: `url(${logo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
    },
    logoSubhead: {
        color: 'black',
        position: 'relative',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        top: '75%',
        fontSize: 8,
        letterSpacing: theme.spacing(0.5),
        textTransform: 'uppercase',
    },
    shadeDown: {
        position: 'absolute',
        zIndex: 1111,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(12px)',
    },
};

const useCustomStyles = makeStyles(styles);

interface Props {
    procedureId: number;
}

export const PrimaryAttendeePlayer = ({ procedureId }: Props) => {
    const customClasses = useCustomStyles();
    const classes = useStyles();
    const layouts = useLayouts();
    const [muted, setMuted] = useState(true);
    const { primaryAttendee } = useGetPrimaryAttendee(procedureId);
    const { procedure } = useGetProcedure(procedureId);

    const src = procedure?.device.streams.find(x => x.isFeatured)?.src;
    const [isStreaming, setStreaming] = useState(false);
    const handleOnPlaying = useCallback(() => {
        setStreaming(true);
    }, [setStreaming]);
    const handleOnStopping = useCallback(() => {
        setStreaming(false);
    }, [setStreaming]);

    const handleToggleMute = () => {
        setMuted(e => !e);
    };

    return (
        <Box className={layouts.verticalContainer} bgcolor="black">
            <Box style={{ flex: '0 0 auto', paddingLeft: '1em' }} bgcolor={theme.palette.primary.dark}>
                <Typography variant="caption" className={customClasses.publisherTitle}>
                    {isStreaming ? 'Moderator' : 'Moderator - Offline'}
                </Typography>
            </Box>
            <div className={classes.videoFrameSmall}>
                {primaryAttendee ? (
                    <>
                        <Player
                            isActive={false}
                            isMainPlayer={false}
                            className={customClasses.videoDimensions}
                            src={src}
                            iceServers={JSON.stringify(procedure?.device.iceServers)}
                            poster={noVideo}
                            obscure={false}
                            muted={muted}
                            onPlaying={handleOnPlaying}
                            onStopping={handleOnStopping}
                        />

                        <Button onClick={handleToggleMute} className={[layouts.bottomLeft, classes.glassyButton].join(' ')}>
                            {muted ? <SpeakersOffIcon /> : <SpeakersIcon />}
                        </Button>
                    </>
                ) : (
                    <div className={`${customClasses.videoDimensions}`}>
                        <div className={` ${customClasses.shadeDown} ${customClasses.shadeMain}`}>
                            <Typography align="center" className={customClasses.logoSubhead}>
                                No Moderator Selected
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        </Box>
    );
};
