/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@mui/icons-material/Share';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { Alert, AppBar, Box, Drawer, FormControlLabel, Grid, IconButton, Switch, Toolbar, Typography } from '@mui/material';
import { useClipUpdate } from 'client/hooks/clips/useClipUpdate';
import { useGetExportList } from 'client/hooks/videoExport/useGetExportList';
import { IVideoExportJob } from 'common/types';
import { CloseIcon } from 'common/utils/Icons';
import { ClipShareModal } from './ClipShareModal';

const useStyles = makeStyles({
    list: {
        width: 'auto',
        height: '300px',
        position: 'relative',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    flexGrow: { flexGrow: 1 },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999,
        color: 'white',
        opacity: 1,
    },
});

interface IClipListModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    procedureId: number;
}

interface ClipGalleryProps {
    clips: IVideoExportJob[];
    onClickShare: (clip: IVideoExportJob) => void;
}

const ClipGallery: React.FC<ClipGalleryProps> = ({ clips, onClickShare }) => {
    const { updateClip } = useClipUpdate();
    const [list, setList] = useState<IVideoExportJob[]>([]);

    const handleUpdatePublic = async (clip: IVideoExportJob) => {
        await updateClip({ jobId: clip.jobId, isPublic: clip.isPublic ?? false, isShared: clip.isShared ?? false });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, clip: IVideoExportJob) => {
        const updatedList = list.map(item => {
            if (item.jobId === clip.jobId) {
                item.isPublic = event.target.checked;
                handleUpdatePublic(item);
            }
            return item;
        });
        setList(updatedList);
    };

    useEffect(() => {
        setList(clips ?? []);
    }, [clips]);

    return (
        <Box sx={{ flexGrow: 1, padding: 4 }}>
            <Grid container spacing={2}>
                {clips.map(clip => (
                    <Grid item key={clip.jobId} xs={6} sm={4} md={3} lg={2}>
                        <Box
                            sx={{
                                border: '1px solid gray',
                                padding: 2,
                                textAlign: 'center',
                                position: 'relative',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            <SmartDisplayIcon fontSize="large" sx={{ marginBottom: 1 }} />
                            <Box>{clip.title}</Box>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={clip.isPublic ?? false}
                                        onChange={e => handleChange(e, clip)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Is Public"
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <IconButton onClick={() => onClickShare(clip)}>
                                    <ShareIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export const ClipListModal: FC<IClipListModalProps> = ({ isOpen, setIsOpen, procedureId }) => {
    const classes = useStyles();
    const { data } = useGetExportList();
    const [clips, setClips] = useState<IVideoExportJob[]>([]);
    const [isShare, setIsShare] = useState(false);
    const [selectedClip, setSelectedClip] = useState<IVideoExportJob | undefined>();

    const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setIsOpen(false);
    };

    const handleClickShare = (clip: IVideoExportJob) => {
        setSelectedClip(clip);
        setIsShare(true);
    };

    const handleClickShareClose = () => {
        setSelectedClip(undefined);
        setIsShare(false);
    };

    const handlClickSend = () => {
        console.log('Send');
    };

    useEffect(() => {
        if (data) {
            const filteredClips = data.filter(clip => clip.procedureId === procedureId);
            setClips(filteredClips as IVideoExportJob[]);
        }
    }, []);

    return (
        <Drawer anchor="bottom" open={isOpen} onClose={toggleDrawer()}>
            <AppBar sx={{ position: 'absolute !important', backgroundColor: '#617c8b' }}>
                <Toolbar className={classes.header}>
                    <Typography variant="h6" component="div" className={classes.flexGrow}>
                        List of Exported Clips
                    </Typography>
                    <IconButton aria-label="close" onClick={() => setIsOpen(false)} className={classes.closeIcon}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Box className={classes.list}>
                {clips.length <= 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Alert variant="filled" severity="info">
                            No Clips Available for this Procedure
                        </Alert>
                    </Box>
                ) : (
                    <ClipGallery clips={clips} onClickShare={handleClickShare} />
                )}
            </Box>
            <ClipShareModal isShare={isShare} clip={selectedClip} onClose={handleClickShareClose} onSend={handlClickSend} />
        </Drawer>
    );
};
