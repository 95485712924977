import { config } from 'circulator/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Request = api.UpdateCampaignRequest;
type Response = api.UpdateCampaignResponse;

export function useUpdateCampaign() {
    const { path } = config.campaign.update();

    type Input = { campaignId: number; body: Request };
    const update = useMutation(({ campaignId, body }: Input) => Api.patch<Response>({ path: path(campaignId), body }));

    return {
        updateCampaign: update.mutateAsync,
    };
}
