export interface IAddress {
    readonly address1: string;
    readonly address2: string;
    readonly city: string;
    readonly state: string;
    readonly postalCode: string;
    readonly country: string;
}

export class Address implements IAddress {
    static fromJsonString(address: string): IAddress | undefined {
        if (address && address !== '') {
            return new Address(JSON.parse(address));
        }

        return undefined;
    }

    readonly address1: string;
    readonly address2: string;
    readonly city: string;
    readonly state: string;
    readonly postalCode: string;
    readonly country: string;

    constructor(props: IAddress) {
        this.address1 = props.address1;
        this.address2 = props.address2;
        this.city = props.city;
        this.state = props.state;
        this.postalCode = props.postalCode;
        this.country = props.country;
    }

    toJsonString(): string {
        return JSON.stringify(this);
    }
}
