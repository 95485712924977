import { FC } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { IProcedureVideoClip } from 'common/types';
import { formatVideoLengthTime } from 'common/utils/helper';

interface IClipGalleryProps {
    clips: IProcedureVideoClip[];
}

const getBaseURL = (clip: IProcedureVideoClip | undefined) => {
    if (process.env.NODE_ENV === 'development') return `http://localhost:3000/media/${clip?.jobId}`;
    const url = `${clip?.host ?? 'dev.v2.vsurgic.com'}/media/${clip?.jobId}`;
    return url.startsWith('http') ? url : `https://${url}`;
};

export const ClipGallery: FC<IClipGalleryProps> = ({ clips }) => {
    const onViewClip = (clip: IProcedureVideoClip) => {
        const path = getBaseURL(clip);
        console.log('env', process.env.NODE_ENV);
        window.open(path, '_blank');
    };
    return (
        <Box sx={{ flexGrow: 1, padding: 1, borderTop: '1px solid #ddd', paddingTop: '30px' }}>
            <Typography variant="body1" style={{ color: '#111', fontWeight: 'bold' }}>
                Latest Clips
            </Typography>
            <Box gap={1} display="grid" gridTemplateColumns="repeat(3, 1fr)" sx={{ marginTop: '15px' }}>
                {clips.map(clip => (
                    <Paper sx={{ display: 'flex', flexDirection: 'column', borderRadius: '7px' }}>
                        <Box
                            key={clip.clipId}
                            sx={{
                                position: 'relative',
                                backgroundColor: '#f5f5f5',
                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgb(26, 33, 35)), url(https://vsurgic.com/wp-content/uploads/2024/05/vsurgic-installation-min.png)`,
                                backgroundPosition: 'bottom',
                                backgroundSize: 'cover',
                                height: '130px',
                                display: 'flex',
                                borderTopLeftRadius: '7px',
                                borderTopRightRadius: '7px',
                            }}
                        >
                            <IconButton
                                onClick={() => onViewClip(clip)}
                                sx={{ justifyContent: 'center', alignItems: 'center', color: '#fff', width: '100%' }}
                            >
                                <Box sx={{ padding: '1px 4px', borderRadius: '50%', background: 'rgba(255, 255, 255, 0.25)' }}>
                                    <PlayArrowIcon fontSize="small" />
                                </Box>
                            </IconButton>
                        </Box>
                        <Box sx={{ padding: '10px 7px', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body1" style={{ color: '#111', fontWeight: 'bold', fontSize: '0.7rem' }}>
                                {clip.title}
                            </Typography>
                            <Typography variant="body1" style={{ color: '#888', fontSize: '0.7rem' }}>
                                {formatVideoLengthTime(clip?.videoLength)}
                            </Typography>
                        </Box>
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};
