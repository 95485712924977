import { makeStyles } from '@material-ui/core';

const classNames = makeStyles({
    iconSizeSmall: {
        transform: 'scale(0.7)',
    },
    videoFrameSmall: {
        cursor: 'pointer',
        position: 'relative',
        paddingBottom: 0,
        paddingTop: 0,
        width: '200px',
        height: '100px',
        flex: '0 0 auto',
    },
    /**
     * Glassy button style
     * button style designed for buttons with no text, only icons, with transparent backgrounds to hover over images and video.
     */
    glassyButton: {
        backgroundColor: 'transparent',
        padding: '0px',
        border: 'none',
        cursor: 'pointer',
        color: 'white',
        borderRadius: '50%',
        '&[disabled]': {
            cursor: 'default',
        },
        '& svg': {
            filter: 'grayscale(1) drop-shadow(1px 1px 1px rgb(0 0 0 / .8))',
        },
    },
});

export const useStyles = () => {
    const myNames = classNames();
    return {
        ...myNames,
        roundedButtonOptions: {
            color: 'primary',
            size: 'small',
            variant: 'contained',
        },
        glassyButtonOptions: {
            className: myNames.glassyButton,
        },
    };
};
