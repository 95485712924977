import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@mui/material';
import { useClipInfo } from 'client/hooks/clips/useClipInfo';
import { Player } from 'common/components/Player';
import { useGetPresignedUrl } from 'common/hooks/storage/useGetPresignedUrl';
import { useParams } from 'react-router-dom';
import CommentSection from './Comment';

const useStyle = makeStyles({
    mediaBox: {
        flex: '1 0 auto',
    },
    videoRow: {
        flex: '1 1 auto',
        width: '100%',
        minHeight: '50px',
    },
});

interface MediaContentProps {
    jobId: string;
}

const MediaContent: FC<MediaContentProps> = ({ jobId }) => {
    const css = useStyle();
    const { data } = useClipInfo(jobId);
    const [startTime] = useState(0);
    const [endTime] = useState(0);
    const [, setCaptureTime] = useState(0);
    const s3key = 'clips/' + data?.video.split('/').pop();
    const presignedUrl = useGetPresignedUrl(s3key);

    return (
        <div className={css.mediaBox}>
            {presignedUrl ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '75vh' }}>
                    <Box sx={{ padding: 2, height: '50vh', display: 'flex' }}>
                        <Player
                            id="videoPlayer"
                            src={presignedUrl}
                            loop={false}
                            controls
                            className={css.videoRow}
                            playerStartTime={startTime}
                            playerEndTime={endTime}
                            setCaptureTime={setCaptureTime}
                        />
                    </Box>
                    <Box sx={{ padding: 2, height: '25vh' }}>
                        <Paper sx={{ padding: 2 }}>
                            <CommentSection jobId={jobId} />
                        </Paper>
                    </Box>
                </Box>
            ) : (
                <Box padding={6} display="flex" justifyContent="center" alignItems="center" />
            )}
        </div>
    );
};

export const PageMedia = () => {
    const { id } = useParams<{ id: string }>();
    if (!id) return <Box>Invalid ID, No Clip to show</Box>;
    return (
        <Box display="flex" sx={{ height: '75vh' }}>
            <MediaContent jobId={id} />
        </Box>
    );
};
