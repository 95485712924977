import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const DraftIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} height="1em" viewBox="0 0 20 20">
        <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
        >
            <path d="M21.99 8c0-.72-.37-1.35-.94-1.7L12 1 2.95 6.3C2.38 6.65 2 7.28 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zM12 13 3.74 7.84 12 3l8.26 4.84L12 13z" />
        </svg>
    </SvgIcon>
);
