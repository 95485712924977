import React from 'react';
import { Skeleton } from '@material-ui/lab';
import Footer from 'mobile/components/UI/Footer';
import { Header } from 'mobile/components/UI/Header';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import { Background } from './Background';

interface IProps {
    children?: React.ReactNode;
    backButtonVisible?: boolean;
    backButtonOnclick?: () => void;
    backButtonHref?: string;
    footerVisible?: boolean;
    headerImage?: string;
}

export const BackgroundWrapper = ({ children, backButtonHref, backButtonOnclick, backButtonVisible, footerVisible, headerImage }: IProps) => {
    const layouts = useMobileLayouts();
    return (
        <Background>
            <main className={layouts.verticalContainer}>
                <Header
                    backButtonHref={backButtonHref}
                    backButtonOnclick={backButtonOnclick}
                    backButtonVisible={backButtonVisible}
                    headerImage={headerImage}
                    headerImageWidth={300}
                />
                <React.Suspense fallback={<Skeleton />}>{children}</React.Suspense>
                {footerVisible !== false && <Footer />}
            </main>
        </Background>
    );
};
