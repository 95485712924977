import { create } from 'zustand';

interface PreauthorizedUsersStoreState {
    acceptedRoles: string[];
    addAcceptedRole: (role: string) => void;
    removeAcceptedRole: (role: string) => void;
}

const usePreauthorizedUsersStore = create<PreauthorizedUsersStoreState>()((set, get) => ({
    acceptedRoles: [],
    addAcceptedRole: role => {
        set(state => ({
            acceptedRoles: state.acceptedRoles.includes(role) ? state.acceptedRoles : [...state.acceptedRoles, role],
        }));
    },
    removeAcceptedRole: role => {
        set(state => ({
            acceptedRoles: state.acceptedRoles.includes(role) ? state.acceptedRoles.filter(x => x !== role) : state.acceptedRoles,
        }));
    },
}));

export default usePreauthorizedUsersStore;
