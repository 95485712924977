/* eslint no-bitwise: ["error", { "allow": ["<<"] }] */
import moment from 'moment';

const DATE_FORMAT_SHORT = 'MMM Do';
const DATE_FORMAT_LONG = 'MMM Do, YYYY';
const PWD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
const EMAIL_REGEX = new RegExp(/\S+@\S+\.\S+/);
const PHONE_REGEX = new RegExp(/^\+\d\d{10}$/); // +15555555555
const PHONE_REGEX_SPACED = new RegExp(/^\+\d\s\d{3}\s\d{3}\s\d{4}$/); // +1 555 555 5555
const PHONE_REGEX_DASH = new RegExp(/^\d{3}-\d{3}-\d{4}$/); // 555-555-5555

export default class CommonUtils {
    static EMPTY_FUNCTION = () => {};

    static FORMAT_SHORT = 'short';

    static FORMAT_LONG = 'long';

    static FORMAT_HTML5 = 'html5';

    static generateKey = () => ((Math.random() * 0xffffff) << 0).toString(16);

    static isEmailValid = e => EMAIL_REGEX.test(e);

    static isPasswordValid = p => PWD_REGEX.test(p);

    static isPhoneValid = p => PHONE_REGEX.test(p) || PHONE_REGEX_SPACED.test(p) || PHONE_REGEX_DASH.test(p);

    static now() {
        return moment();
    }

    static formatDate(date, format) {
        switch (format) {
            case this.FORMAT_SHORT:
                return moment(date).format(DATE_FORMAT_SHORT);
            case this.FORMAT_LONG:
                return moment(date).format(DATE_FORMAT_LONG);
            case this.FORMAT_HTML5:
                return moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL);
            default:
                return moment(date).format();
        }
    }

    static dateDiff(start, end, unitOfTime = 'day') {
        return moment(end).startOf('day').diff(moment(start).startOf('day'), unitOfTime, true);
    }

    static calculateTimeSpentBasedOnDateRaw(start, end) {
        return start && end ? (new Date(end) - new Date(start)) / 1000 / 60 : null;
    }

    static calculateTimeSpentBasedOnDateFormatted(start, end) {
        const timeSpentRaw = this.calculateTimeSpentBasedOnDateRaw(start, end);
        return timeSpentRaw ? `${Math.round(timeSpentRaw * 10) / 10}m` : '-';
    }

    static dateAdd(date, amount, unit = 'day') {
        return moment(date).add(amount, unit);
    }

    static dateSubtract(date, amount, unit = 'day') {
        return moment(date).subtract(amount, unit);
    }

    static isSameOrAfter(a, b) {
        return moment(a).isSameOrAfter(moment(b));
    }

    static isSameOrBefore(a, b) {
        return moment(a).isSameOrBefore(moment(b));
    }

    static dateLocal(date) {
        return moment(date).local();
    }

    static getUnixTime() {
        return moment().unix();
    }

    static isMobileDevice = () => typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;

    static getInitials = name => `${name.split(' ')[1][0]}${name.split(' ')[2] ? name.split(' ')[2][0] : ''}`;

    static formatMilliseconds = value => {
        // convert milliseconds to hh:mm:ss.mmm format
        let hours = Math.floor(value / 3600000).toString();
        let minutes = Math.floor((value % 3600000) / 60000).toString();
        let seconds = Math.floor(((value % 360000) % 60000) / 1000).toString();
        if (hours.toString().length === 1) hours = '0' + hours;
        if (minutes.toString().length === 1) minutes = '0' + minutes;
        if (seconds.toString().length === 1) seconds = '0' + seconds;
        return `${hours} : ${minutes} : ${seconds}`;
    };

    // phoneNumber: 555-555-5555 -> +15555555555
    static convert2StandardPhoneNumberFormat = phoneNumber => {
        // Remove hyphens from the phone number
        const cleanedNumber = (phoneNumber ?? '').replace(/-/g, '').replace('+1', '');

        // Add the country code and area code
        const convertedNumber = '+1' + cleanedNumber;

        return convertedNumber;
    };

    // phoneNumber: +15555555555 -> 555-555-5555
    static convert2DashedPhoneNumberFormat = phoneNumber => {
        // Remove all non-digit characters from the phone number
        const cleaned = phoneNumber.replace(/\+1|\D/g, '');

        // Check if the cleaned phone number has 10 digits
        if (cleaned.length === 10) {
            // Format the phone number as xxx-xxx-xxxx
            return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }

        // If the phone number doesn't have 10 digits, return it as is
        return phoneNumber;
    };
}
