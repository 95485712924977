import { config } from 'client/hooks/config';
import { UserCognitoInfo } from 'common/types';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export interface UserListRespoonse {
    users: UserCognitoInfo[];
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
}
type Response = api.UserCognitoResponse;

export const useListUsers = (): UserListRespoonse => {
    const { key, path } = config.user.list();
    const result = useQuery(key, () => Api.get<Response>({ path }), {
        refetchOnWindowFocus: false,
        suspense: true,
    });

    const resUsers = result.data ?? [];

    // For future use if we want to filter users from group of ['Admin', 'Registered']
    //const lstUsers = resUsers.filter(user => user.groups.includes('Admin') || user.groups.includes('Registered'));

    return {
        users: resUsers,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
};
