import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';

const getData = (theme: { breakpoints: { values: { sm: number } } } | undefined) => ({
    orientation: window.screen.orientation,
    isLandscape: window.screen.orientation.type.startsWith('landscape'),
    isPortrait: window.screen.orientation.type.startsWith('portrait'),
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile:
        (theme?.breakpoints?.values?.sm && theme?.breakpoints?.values?.sm > window.innerWidth) ||
        (theme?.breakpoints?.values?.sm && theme?.breakpoints?.values?.sm > window.innerHeight),
});

export const useScreenInformation = (): {
    orientation: ScreenOrientation;
    isLandscape: boolean;
    isPortrait: boolean;
    width: number;
    height: number;
} => {
    const theme = useTheme() as { breakpoints: { values: { sm: number } } } | undefined;
    const [data, setData] = useState(getData(theme));
    useEffect(() => {
        const handler = () => {
            setData(getData(theme));
        };
        window.addEventListener('resize', handler);
        window.addEventListener('orientationchange', handler);
        window.screen.orientation.addEventListener ? window.screen.orientation.addEventListener('change', handler) : void 0;
        return () => {
            window.removeEventListener('resize', handler);
            window.removeEventListener('orientationchange', handler);
            window.screen.orientation.removeEventListener ? window.screen.orientation.removeEventListener('change', handler) : void 0;
        };
    }, [theme, setData]);
    return data;
};

export default useScreenInformation;
