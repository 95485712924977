import { useEffect } from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useGetActiveProcedure } from 'circulator/hooks/procedure/useGetActiveProcedure';
import { useCanUseCuts } from 'client/hooks/mobile/useCanUseCuts';
import logoWhiteMobile from 'img/white-logo-mobile.png';
import { BackgroundWrapper } from 'mobile/components/UI/BackgroundWrapper';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import { Link, useNavigate } from 'react-router-dom';
import CutsLogo from '../../img/cuts-logo.png';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
    },
    container2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '40px',
    },
    subtitle: {
        fontSize: '1.3rem',
    },
    text: {
        fontSize: '1.125rem',
        padding: '0 80px',
        textAlign: 'center',
        fontWeight: 300,
    },
    linkButton: {
        color: 'white',
        fontSize: '1.125rem',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        fontWeight: 200,
    },
    link: {
        color: 'white',
        fontSize: '1rem',
        textDecoration: 'none',
    },
    footer: {
        position: 'absolute',
        bottom: '0px',
    },
    marginTop: {
        marginTop: '20px',
    },
    textCenter: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '1.5rem',
    },
    gap: {
        gap: '20px',
    },
}));

export const MobileHome = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { procedure, isLoading: isProcedureLoading } = useGetActiveProcedure();
    const { isLoading: isCutsLoading, userDisplayName } = useCanUseCuts(true);
    const layouts = useMobileLayouts();
    const isLoading = isProcedureLoading || isCutsLoading;

    useEffect(() => {
        if (procedure) {
            navigate('/mobile/shot');
        }
    }, [procedure, navigate]);

    return (
        <BackgroundWrapper>
            {isLoading && <CircularProgress size={20} />}
            {!isLoading && (
                <div className={layouts.verticalContainer}>
                    <div className={layouts.padded}>
                        <Typography className={classes.subtitle}>
                            {userDisplayName && (
                                <div className={classes.textCenter}>
                                    Hello, <br /> {userDisplayName}
                                </div>
                            )}
                        </Typography>
                        <Typography className={`${classes.text} ${classes.marginTop}`}>Please choose from the following options:</Typography>
                    </div>
                    <div className={`${layouts.verticalContainer} ${classes.gap}`}>
                        <div className={layouts.padded}>
                            <Link className={classes.linkButton} to="/mobile/create-or-archives">
                                <img src={CutsLogo} alt="vsurgic cut" height={20} />
                            </Link>
                        </div>
                        <div className={layouts.padded}>
                            <Link className={classes.linkButton} to="/">
                                <img src={logoWhiteMobile} alt="vsurgic mobile" height={20} />
                                Mobile
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </BackgroundWrapper>
    );
};
