import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Response = api.ApiResponse;

export interface TemplatePayload {
    id: number;
    subject: string;
    body: string;
}

export const useUpdateTemplate = () => {
    const { path: updatePath } = config.templates.update();
    const updateMutation = useMutation((data: TemplatePayload) => Api.post<Response>({ path: updatePath, body: data }));
    return {
        updateTemplate: updateMutation.mutateAsync,
    };
};
