const fileCatSize = BigInt('1024');
/**
 * Converts a bigint to a string with the specified decimal places; inserts commas as well.
 * @param big
 * @param decimalPlaces
 * @returns
 */
const BigIntToFixedString = (big: bigint, decimalPlaces = 2): string => {
    let str = big.toString() || '';
    let beforeDecimal = str
        .split('.')[0]
        .split('')
        .reverse()
        .reduce((acc, val, idx) => [...acc, idx % 3 === 0 && idx !== 0 ? ',' : '', val], [''])
        .reverse()
        .join('');
    let afterDecimal = (str.split('.')[1] ?? '').slice(0, decimalPlaces).padEnd(decimalPlaces, '0');
    return `${beforeDecimal}${decimalPlaces > 0 ? '.' : ''}${afterDecimal}`;
};
/**
 * Converts a file size in bytes to a human readable string, with the appropriate unit. (kb, mb, gb, tb)
 * This will never throw.
 * @param size The size of the file in bytes; this can be a bigint, string, or number; if given null or undefined, it will return '0.00 B'
 * if the string cannot be parsed into bigint, or the number is NaN, it will return '0.00 B'
 * @param decimalPlaces The number of decimal places to show. Default is 2.
 */
export const fileSizeToString = (size: bigint | string | number | null | undefined, decimalPlaces = 2) => {
    let big: bigint;
    try {
        if (
            typeof size === 'number' &&
            (isNaN(size) || size === Infinity || size === Number.POSITIVE_INFINITY || size === Number.NEGATIVE_INFINITY)
        ) {
            return '0 B';
        }
        big = BigInt(!size ? '' : typeof size === 'number' ? size.toString() : size);
    } catch (e) {
        console.error(e);
        big = BigInt(0);
    }

    if (!big || !big.toString()) {
        return '0 B';
    }
    if (big < fileCatSize) {
        return BigIntToFixedString(big, decimalPlaces) + ' B';
    }
    big /= fileCatSize;
    if (big < fileCatSize) {
        return BigIntToFixedString(big, decimalPlaces) + ' KB';
    }
    big /= fileCatSize;
    if (big < fileCatSize) {
        return BigIntToFixedString(big, decimalPlaces) + ' MB';
    }
    big /= fileCatSize;
    if (big < fileCatSize) {
        return BigIntToFixedString(big, decimalPlaces) + ' GB';
    }
    big /= fileCatSize;
    return BigIntToFixedString(big, decimalPlaces) + ' TB';
};
export const formatMilliseconds = (ms?: number) => {
    if (!ms) {
        return '0:00:00';
    }
    const milliseconds = ms % 1000;
    let remainingMs = (ms - milliseconds) / 1000;
    const seconds = remainingMs % 60;
    remainingMs = (remainingMs - seconds) / 60;
    const minutes = remainingMs % 60;
    const hours = (remainingMs - minutes) / 60;

    return hours + ':' + `${minutes}`.padStart(2, '0') + ':' + `${seconds}`.padStart(2, '0');
};
