import { config } from 'client/hooks/config';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type ShareWithInput = {
    procedureId: number;
    shareTo?: string;
    sharedType?: string;
};

export const useMutateShare = () => {
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key, path } = config.sharing.share();

    const { mutateAsync, isLoading } = useMutation(key, async (body: ShareWithInput) => Api.post<any>({ path, body }), {
        onSuccess(data, variables, context) {
            notify(new Message({ title: 'Shared Successfully!', type: 'success' }));
            queryClient.invalidateQueries(config.sharing.all());
        },
        onError(error, variables, context) {
            notify(new Message({ title: 'An error occurred while trying to share.', type: 'error' }));
            queryClient.invalidateQueries(config.sharing.all());
        },
    });

    return { mutate: mutateAsync, isLoading };
};
