import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Footer } from 'client/components/UI/Footer';
import { Header } from 'client/components/UI/Header';
import { useUser } from 'client/hooks/user/useUser';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { getUserInAdminUsers, getUserInRegisteredUsers } from 'common/services/userServices';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import background from 'img/background.png';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        backgroundAttachment: 'fixed',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    content: {
        flex: '1 0 auto',
        zIndex: 1,
    },
});

export function UserClient({ children }: { children?: JSX.Element }) {
    const classes = useStyles();
    const { user, isLoading } = useUser();
    const userRegistered = useMemo(() => getUserInRegisteredUsers(user), [user]);
    const userAdmin = useMemo(() => getUserInAdminUsers(user), [user]);
    const allowHeader = isNotNullOrUndefined(user) && (userRegistered || userAdmin);

    if (isLoading) {
        return <ProcessingOverlay enabled />;
    }

    return (
        <main className={classes.container}>
            {allowHeader && <Header />}
            <div className={classes.content}>
                <React.Suspense fallback={<ProcessingOverlay enabled />}>{children ? children : <Outlet />}</React.Suspense>
            </div>
            <Footer />
        </main>
    );
}
