import { useEffect, useRef, useState } from 'react';

const key = 'c5d5lz08565047b4c87a9aal5mb3rt';

export function useConsent() {
    const [consent, setConsent] = useState(localStorage.getItem(key) === 'true' ? true : false);
    const interval = useRef<ReturnType<typeof setInterval>>();

    /*
        Interval is for detecting changes in localStorage caused by other tabs;
        We only care if they have now consented, we don't reverse the consent.
        Check local storage once per second while they have not consented; once they have we stop checking.
    */
    useEffect(() => {
        if (!consent && !interval.current) {
            interval.current = setInterval(() => {
                setConsent(localStorage.getItem(key) === 'true' ? true : false);
            }, 1000);
        } else if (consent && interval.current) {
            clearInterval(interval.current);
        }
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = undefined;
            }
        };
    }, [consent]);

    const updateConsent = (state: boolean): boolean => {
        setConsent(() => {
            localStorage.setItem(key, state.toString());
            return state;
        });
        return true;
    };

    return {
        Accepted: consent,
        ConsentUpdate: updateConsent,
    };
}
