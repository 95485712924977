import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';
import { config } from '../config';

type Response = api.GetFacilityByIdResponse;

export function useGetFacilityById(facilityId: number, enabled: boolean = true) {
    const { key, path } = config.facilities.detail(facilityId);

    const result = useQuery([key, facilityId], () => Api.get<Response>({ path }), {
        enabled: isNotNullOrUndefined(facilityId) && enabled,
    });

    return {
        facility: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
