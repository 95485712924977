import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export function useCanUserDeleteExport(jobId: string) {
    const { key, path } = config.videoexport.canDelete(jobId);

    const result = useQuery(key, async () => await Api.get({ path }), {});
    return {
        canDelete: result.data || false,
    };
}
