import React, { useMemo } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
import { useGetProcedure } from 'client/hooks/procedure/useGetProcedure';
import { Player } from 'common/components/Player';
import usePlayerControlsStore, { getStreamCurrentTime, isAudioActive } from 'common/hooks/usePlayerControlsStore';
import logo from 'img/logo-white.svg';
import { shallow } from 'zustand/shallow';

const styles = {
    feed: {
        height: '55vh',
    },
    logoSubhead: {
        color: 'white',
        letterSpacing: theme.spacing(0.5),
        textTransform: 'uppercase',
    },
    activeStreamTitle: {
        color: 'white',
    },
};
// @ts-ignore
const useStyles = makeStyles(styles);

interface Props {
    obscure: boolean;
    procedureId: number;
    enablePTZControls?: boolean;
}

export const MainPlayer: React.FC<Props> = ({ obscure, procedureId, enablePTZControls }) => {
    const classes = useStyles();

    const { procedure } = useGetProcedure(procedureId);
    const [activeStream, activeAudioStreamIds, streamCurrentTimes] = usePlayerControlsStore(
        state => [state.activeStream, state.activeAudioStreamIds, state.streamCurrentTimes],
        shallow
    );
    const audioActive = isAudioActive(activeAudioStreamIds, activeStream?.id);
    const startTime = useMemo(() => {
        return getStreamCurrentTime(streamCurrentTimes, activeStream?.id);
        // ignore changes to streamCurrentTimes
        // because we only want to get the start time
        // when we first start playing a specific stream
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStream]);

    return activeStream ? (
        <Box>
            <Player
                className={classes.feed}
                src={activeStream.src}
                iceServers={JSON.stringify(procedure?.device.iceServers)}
                obscure={obscure}
                isMainPlayer={true}
                muted={!audioActive}
                startTime={startTime}
                enablePTZControls={enablePTZControls && activeStream?.isPTZ}
            />
        </Box>
    ) : (
        <Box>
            <Box bgcolor="black" color="white">
                <Box className={classes.feed} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography className={classes.logoSubhead} gutterBottom>
                        No Active Stream
                    </Typography>
                    <img src={logo} alt="logo" />
                </Box>
            </Box>
        </Box>
    );
};
