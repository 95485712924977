import axios from 'axios';
import { generateHeader } from 'common/utils/APIUtils';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

export const getUserById = (userId: string) => {
    const headers = generateHeader();
    return axios.get(`/users/${userId}`, { headers });
};

export const useGetUserById = (userId: string) => {
    const useUserByIdData = useQuery(['user', userId, 'attendee', 'accessRequests'], () => getUserById(userId), {
        enabled: isNotNullOrUndefined(userId),
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    return {
        user: useUserByIdData.data?.data,
        isLoading: false,
        isFetching: false,
        isFetched: false,
    };
};
