/// @depreciated: use `./config` instead.

export const keys = {
    accessRequest: {
        all: () => ['accessRequest'],
        profile: profileId => [...keys.accessRequest.all(), profileId],
    },
    attendee: {
        all: () => ['attendee'],
        profile: profileId => [...keys.attendee.all(), profileId],
    },
    attendeeProcedure: {
        all: () => ['attendeeProcedure'],
        list: () => [...keys.attendeeProcedure.all(), 'list'],
        procedureAttendee: procedureId => [...keys.attendeeProcedure.all(), procedureId],
    },
    cameras: {
        all: () => ['cameras'],
        device: deviceId => [...keys.cameras.all(), deviceId],
    },
    devices: {
        all: () => ['devices'],
        list: () => [...keys.devices.all(), 'list'],
    },
    procedure: {
        all: () => ['procedure'],
        list: () => [...keys.procedure.all(), 'list'],
        detail: procedureId => [...keys.procedure.all(), 'detail', procedureId],
    },
    profile: () => ['profile'],
    procedureAttendee: () => ['procedureAttendee'],
    user: () => ['user'],
};
