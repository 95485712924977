import { FC, useState } from 'react';
import { Box, ButtonProps, IconButton, Typography, makeStyles } from '@material-ui/core';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { MasterController } from 'common/hooks/WebRTC/master-controller';

interface VideoToggleButtonProps extends ButtonProps {
    controller?: MasterController;
    isPlaying?: boolean;
}

const useStyles = makeStyles({
    container: {
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0%)',
        position: 'absolute',
        zIndex: 50,
    },
    button: {
        fontSize: '2rem !important',
        width: '65px !important',
        height: '65px !important',
        '& path': {
            stroke: 'white',
        },
    },
    buttonOn: {
        color: '#ff8f00',
    },
    buttonOff: {
        color: '#8fff00',
    },
    messageContainer: { bottom: 150, left: '50%', transform: 'translate(-50%, 0%)', position: 'absolute', zIndex: 50 },
});

export const VideoToggleButton: FC<VideoToggleButtonProps> = ({ controller, isPlaying: nIsPlaying = true }) => {
    const classes = useStyles();
    const [isVideoOn, setVideoOn] = useState(true);
    const [isToggling, setToggling] = useState(false);

    const handleVideoToggle = async () => {
        try {
            if (controller) {
                setToggling(true);
                if (isVideoOn) {
                    await controller.removeVideo();
                } else {
                    await controller.addVideo();
                }
                setVideoOn(!isVideoOn);
            }
        } catch (err) {
            console.error('Error while toggling video: ', err);
        } finally {
            setToggling(false);
        }
    };

    return (
        <>
            <Box className={classes.container} onClick={handleVideoToggle}>
                {isVideoOn ? (
                    <Box>
                        <IconButton color="primary" disabled={isToggling}>
                            <VideocamIcon className={`${classes.button} ${classes.buttonOn}`} fontSize="large" />
                        </IconButton>
                    </Box>
                ) : (
                    <Box>
                        <IconButton color="primary" disabled={isToggling}>
                            <VideocamOffIcon className={`${classes.button} ${classes.buttonOff}`} />
                        </IconButton>
                    </Box>
                )}
            </Box>
            {!isVideoOn && (
                <Box className={classes.messageContainer}>
                    <Typography>Camera paused, audio still active. Select camera to resume.</Typography>
                </Box>
            )}
        </>
    );
};
