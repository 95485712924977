import { FC } from 'react';
import { Box, ButtonProps, IconButton, makeStyles } from '@material-ui/core';
import AttendeeToggleIcon from '@mui/icons-material/SpatialAudioOff';
import { MobileStylesConstants, RGBA } from 'mobile/hooks/useMobileStyles';

export const attendeeJoinAnimation = { name: 'attendeeJoinAnimation' };

const useStyles = (options: { hasAttendeesColor: string }) =>
    makeStyles({
        container: {
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 50,
        },
        button: {
            fontSize: '2rem !important',
            width: '55px !important',
            height: '55px !important',
            color: options.hasAttendeesColor,
            animation: `attendeeJoinAnimation 5s 1`,
            boxShadowColor: options.hasAttendeesColor,
        },
    })();

interface IToggleButtonProps extends ButtonProps {
    toggleDrawer: (open?: boolean) => void;
    hasAttendees: boolean;
}

export const AttendeeToggleButton: FC<IToggleButtonProps> = ({ toggleDrawer, hasAttendees }) => {
    const classes = useStyles({ hasAttendeesColor: hasAttendees ? MobileStylesConstants.buttonColor : 'white' });

    return (
        <Box className={classes.container}>
            <Box>
                <style>
                    @keyframes attendeeJoinAnimation{' '}
                    {`{
                        0% {
                            box-shadow: 0px 0px 10px 10px ${hasAttendees ? MobileStylesConstants.buttonColor : 'rgba(255, 255, 255, 1)'};
                        }
                        100% {
                            box-shadow: 0px 0px 0px 0px ${hasAttendees ? new RGBA(MobileStylesConstants.buttonColor, 0) : new RGBA('white', 0)};
                        }
                    }`}
                </style>
                <IconButton color="primary" onClick={() => toggleDrawer()}>
                    <AttendeeToggleIcon className={classes.button} />
                </IconButton>
            </Box>
        </Box>
    );
};
