export function getBool(value?: string, defaultValue = false) {
    if (value === undefined || value === null) {
        return defaultValue;
    }

    if (value.toLowerCase() === 'true') {
        return true;
    }

    return false;
}

export function putBool(value?: boolean, defaultValue = false) {
    if (value === undefined || value === null) {
        return defaultValue;
    }

    return value.toString();
}

export function getString(value?: string, defaultValue = '') {
    if (value === undefined || value === null) {
        return defaultValue;
    }

    return value;
}

export function putString(value?: string, defaultValue = '') {
    if (value === undefined || value === null) {
        return defaultValue;
    }

    return value;
}
