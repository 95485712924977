import { useEffect, useState } from 'react';
import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { useListProcedures } from 'client/hooks/procedure/useListProcedures';
import { CutsJoinVideoPlayer } from 'mobile/components/procedure/CutsJoinVideoPlayer';
import { Background } from 'mobile/components/UI/Background';
import { Header } from 'mobile/components/UI/Header';
import useJoinStore from 'mobile/hooks/useJoinStore';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import { useScreenInformation } from 'mobile/hooks/useScreenInformation';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import CutsLogo from '../../img/cuts-logo.png';
import { TimeElapsed } from '../components/TimeElapsed';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    timeElapsedLabel: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '1rem',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '1rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        maxWidth: '100vw',
        color: '#ff8f00',
        background: 'white',
        width: '100%',
    },
    videoPlayerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100vw',
        flex: 1,
        flexDirection: 'column',
    },
    horizontalTimeElapsed: {
        margin: '0px',
        padding: '10px',
        height: '100%',
        flex: '1 0 auto',
        '& p': {
            '&:first-child': {
                flex: '1 1 auto',
            },
            flex: '1 0 auto',
        },
    },
    marginTop: {
        marginTop: '50px',
    },
    marginTopSpacing: {
        margnTop: '10px',
    },
    alignProgress: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            margin: '0 5px',
        },
    },
    feedHidden: {
        maxHeight: '40vh',
        maxWidth: '100%',
        display: 'none',
    },
    hidden: {
        display: 'none',
    },
    attendeeRow: {
        display: 'flex !important',
        flex: '1 !important',
        justifyContent: 'space-between !important',
    },
}));

export const JoinPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { procedures, isLoading } = useListProcedures();
    const [procedureId, publishStreamId, publishUrl, publishToken, publishIceServers] = useJoinStore(
        state => [state.procedureId, state.publishStreamId, state.publishUrl, state.publishToken, state.publishIceServers],
        shallow
    );
    const [startTime, setStartTime] = useState<string | null>(null);

    const screenInformation = useScreenInformation();
    const layouts = useMobileLayouts();

    useEffect(() => {
        if (!publishStreamId) {
            navigate('/mobile/active-procedure-list');
        }
    }, [navigate, publishStreamId]);

    useEffect(() => {
        if (!isLoading) {
            const procedure = procedures.find(x => x.id === procedureId);

            if (!procedure || !procedure.isActive) {
                navigate('/mobile/active-procedure-list');
            } else {
                setStartTime(procedure.startTime);
            }
        }
    }, [isLoading, navigate, procedureId, procedures]);

    const handleBackButton = () => {
        navigate('/mobile/active-procedure-list');
    };

    return (
        <Background>
            <>
                <Header backButtonVisible={true} backButtonOnclick={handleBackButton} headerImage={CutsLogo} headerImageWidth={300}>
                    {!isLoading && screenInformation.isLandscape ? (
                        <Grid container item className={`${classes.timeElapsedLabel} ${classes.horizontalTimeElapsed}`}>
                            <Typography>Time Elapsed:</Typography>
                            {startTime ? <TimeElapsed startTime={startTime} /> : <></>}
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Header>
                {isLoading && (
                    <Typography component="div" variant="body1" className={classes.alignProgress}>
                        <CircularProgress size={20} />
                    </Typography>
                )}
                {!isLoading && (
                    <Grid container className={layouts.verticalContainer}>
                        {screenInformation.isPortrait ? (
                            <Grid item className={classes.timeElapsedLabel}>
                                <Typography>Time Elapsed:</Typography>
                                {startTime ? <TimeElapsed startTime={startTime} /> : <></>}
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid item className={layouts.verticalContainer}>
                            <CutsJoinVideoPlayer
                                publishStreamId={publishStreamId}
                                publishUrl={publishUrl}
                                publishToken={publishToken}
                                publishIceServers={publishIceServers}
                            />
                        </Grid>
                    </Grid>
                )}
            </>
        </Background>
    );
};
