import CommonUtils from 'common/utils/CommonUtils';
import BasicDomain from './BasicDomain';

export default class Credentials extends BasicDomain {
    get myClass() {
        return Credentials;
    }

    constructor(props = {}) {
        super(props);
        this.id = props.id || props.objectId || null;
        this.emailVerified = false;
        this.username = props.username || '';
        this.oldPassword = props.oldPassword || '';
        this.password = props.password || '';
        this.profile = props.profile || '';
        this.fname = props.fname || '';
        this.lname = props.lname || '';
        this.acceptedPP = props.acceptedPP || new Date();
        this.acceptedTC = props.acceptedTC || new Date();
        this.createdAt = props.createdAt || undefined;
        this.updatedAt = props.updatedAt || undefined;
        this.recoveryCode = props.recoveryCode || undefined;
        this.emailConfirmCode = props.emailConfirmCode || undefined;
    }

    isPasswordValid = () => CommonUtils.isPasswordValid(this.password);

    isLoginValid = () => CommonUtils.isEmailValid(this.username);

    isSame = target => target != null && target.id === this.id && target.username === this.username && target.password === this.password;

    isSavable = () =>
        this.password != null &&
        this.password.trim() !== '' &&
        this.isPasswordValid() &&
        this.username != null &&
        this.username.trim() !== '' &&
        this.isLoginValid();

    isLoginSavable = () =>
        this.password != null && this.password.trim() !== '' && this.username != null && this.username.trim() !== '' && this.isLoginValid();

    getSaveToken = () => ({
        email: this.username.trim().toLowerCase(),
        username: this.username.trim().toLowerCase(),
        password: this.password.trim(),
        fname: this.fname,
        lname: this.lname,
        acceptedPP: this.acceptedPP,
        acceptedTC: this.acceptedTC,
        profile: this.profile,
    });

    isNew = () => this.id == null;

    getLabel = () => (this.isNew() ? 'New Credentials' : this.username);

    equals = obj => obj instanceof Credentials && this.id === obj.id;

    toString = () => `${this.id}:{${this.getLabel()}}`;
}
