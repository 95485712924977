import React, { Suspense } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { useListOrdersFromPaymentId } from 'client/hooks/orders/useListOrdersFromPaymentId';
import { useGetPaymentLiveInfo } from 'client/hooks/payment/useGetPaymentLiveInfo';
import { useGetUserById } from 'client/hooks/user/useGetUserById';
import Panel from 'common/components/UI/Panel';
import { Order, Payment, User } from 'common/types';
import { CheckCircleIcon, ChevronLeftIcon, XCircleIcon } from 'common/utils/Icons';
import login from 'img/login/shutterstock_1799586823@2x_f.png';
import logo from 'img/logo-branded_white.svg';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

// @ts-ignore
const styles = theme => ({
    root: {
        backgroundImage: `url(${login})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'stretch',
        backgroundSize: 'cover',
        height: '100vh',
        paddingTop: '5rem',
        zIndex: -1000,
    },
    cardContent: {
        textAlign: 'center',
        paddingTop: '2.5rem',
        paddingBottom: '2.5rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        maxHeight: '80vh',
        overflowY: 'auto',
    },
    cardTitle: {
        color: 'black',
    },
    caption: {
        lineHeight: '2',
        marginTop: '.5rem',
    },
    orderDetailsHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    orderDetails: {},
    orderDetailsItem: {
        marginBottom: '1rem',
    },
    orderDetailsItemTitle: {
        fontWeight: 'bold',
        color: 'black',
    },
    orderDetailsItemValue: {
        color: 'black',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    hiddenXS: {
        [theme.breakpoints.down('lg')]: {
            left: '12%',
            top: '40%',
            position: 'absolute',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: '0%',
        },
        alignItems: 'center',
        height: '200px',
        marginTop: '1rem',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

// @ts-ignore
export const useStyles = makeStyles(styles);
const Success = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { reset } = useQueryErrorResetBoundary();
    let { sessionId } = useParams();
    return (
        <div className={classes.root}>
            <Grid alignItems="center" container justify="center" style={{ height: '100%' }}>
                <Grid item xs={12} md={4} lg={4} xl={4} />
                <Grid item xs={12} md={4} lg={4} xl={4} alignContent="center">
                    <Card elevation={0}>
                        <CardContent className={classes.cardContent}>
                            <ErrorBoundary
                                onReset={reset}
                                // eslint-disable-next-line react/no-unstable-nested-components
                                fallbackRender={({ resetErrorBoundary, error }) => {
                                    return error.message === 'Payment failed' ? (
                                        <>
                                            <Box display="flex" mb={4} flexDirection="column" alignItems="center" justifyContent="center">
                                                <div>
                                                    <XCircleIcon style={{ height: '40px', width: '40px', fill: 'red' }} />
                                                    <Typography variant="h5">Payment Failed</Typography>
                                                    <Typography variant="caption" className={classes.caption} paragraph>
                                                        We couldn't process your payment, please try again or email us at <br />
                                                        <a href="mailto:orders@vsurgic.com" className={classes.link}>
                                                            {' '}
                                                            orders@vsurgic.com
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </Box>
                                            <Box mt={5} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                                <img src={logo} alt="logo branded" width="250px" />
                                                <div className="no-print">
                                                    <Button type="button" variant="contained" color="primary" onClick={() => navigate('/')}>
                                                        <ChevronLeftIcon />
                                                        &nbsp;{' '}
                                                        <Typography
                                                            variant="body2"
                                                            style={{ textTransform: 'capitalize' }}
                                                            onClick={() => navigate('/')}
                                                        >
                                                            Back to VSURGIC
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </Box>
                                        </>
                                    ) : (
                                        <Panel>
                                            <p>Oops! Something went wrong...</p>
                                            <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                                                Try again
                                            </Button>
                                        </Panel>
                                    );
                                }}
                            >
                                <Suspense fallback={<PaymentProcessingContent />}>
                                    <SuccessContent sessionId={sessionId} />
                                </Suspense>
                            </ErrorBoundary>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} lg={4} xl={4} />
            </Grid>
        </div>
    );
};

const SuccessContent = ({ sessionId }: { sessionId: string | undefined }) => {
    const { payment } = useGetPaymentLiveInfo({ paymentIntent: typeof sessionId === 'string' ? sessionId : null });
    const { orders } = useListOrdersFromPaymentId({ paymentId: payment?.id });
    const { user } = useGetUserById(orders[0]!.userId);
    return payment && payment.paymentStatus === 'PAID' ? (
        <PaymentSuccessContent user={user} order={orders[0]} payment={payment} />
    ) : (
        <PaymentProcessingContent />
    );
};

const PaymentProcessingContent = () => {
    const classes = useStyles();
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h5" className={classes.cardTitle}>
                Payment Processing
            </Typography>
            <Typography variant="body2">Please wait</Typography>
            <Box mt={5}>
                <CircularProgress color="primary" />
            </Box>
        </Box>
    );
};

interface PaymentSuccessfulContentProps {
    user: User;
    order: Order | null;
    payment?: Payment | null;
}

const ReceiptItem = ({ title, value }: { title: string; value?: string | number }) => {
    const classes = useStyles();
    return (
        <Box className={classes.orderDetailsItem} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="body2" align="left" className={classes.orderDetailsItemTitle}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body2" align="left" className={classes.orderDetailsItemValue}>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const PaymentSuccessContent = ({ user, order, payment }: PaymentSuccessfulContentProps) => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <div>
                    <CheckCircleIcon style={{ height: '40px', width: '40px', fill: 'limegreen' }} />
                    <Typography variant="h5" className={classes.cardTitle}>
                        Payment Successful
                    </Typography>
                    <Typography variant="caption" className={classes.caption} paragraph>
                        Your order has been placed. We'll send you an email with your email details.{' '}
                        <span className="no-print">
                            If you would like to print the receipt,{' '}
                            <span className={classes.link} onClick={() => window.print()}>
                                Click Here
                            </span>
                            .
                        </span>
                    </Typography>
                </div>
            </Box>
            <Card>
                <CardHeader title="Order Details" className={classes.orderDetailsHeader} titleTypographyProps={{ variant: 'body1', noWrap: true }} />
                <CardContent style={{ padding: '2.4rem' }}>
                    <ReceiptItem title="Name: " value={`${user.firstName} ${user.lastName}`} />
                    <ReceiptItem title="Email: " value={user.email} />
                    <ReceiptItem title="Purchase: " value={order?.license?.licenseType?.name} />
                    <ReceiptItem title="Order Total: " value={`$ ${(payment?.total || 0) / 100}`} />
                    <ReceiptItem title="Payment Status: " value={payment?.paymentStatus} />
                    <ReceiptItem title="Facility: " value={order?.license?.facility?.name} />
                    <ReceiptItem title="License #: " value={order?.license?.id} />
                    <ReceiptItem title="License Details: " value={order?.license?.licenseDetails} />
                </CardContent>
                <CardActions style={{ display: 'block', padding: '1.5rem', width: '100%', borderTop: '1px solid lightgray' }}>
                    <Box flexDirection="column" alignItems="center" justifyContent="center">
                        <Typography variant="body2" align="center">
                            We appreciate your business! If you have any questions, please email <br />
                            <a href="mailto:orders@vsurgic.com" className={classes.link}>
                                {' '}
                                orders@vsurgic.com
                            </a>
                        </Typography>
                    </Box>
                </CardActions>
            </Card>
            <Grid container>
                <Grid className={classes.hiddenXS} spacing={4}>
                    <Grid item xs={12} style={{ marginBottom: '2em' }}>
                        <img src={logo} alt="logo branded" style={{ color: 'white' }} width="250px" />
                    </Grid>
                    <Grid item xs={12} className="no-print">
                        <Button type="button" variant="contained" color="primary" onClick={() => navigate('/')}>
                            <ChevronLeftIcon />
                            &nbsp;{' '}
                            <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                                Back to VSURGIC
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Success;
