import React from 'react';
import { Button } from '@material-ui/core';
import { DeviceNotFoundPanel } from 'circulator/components/Device/DeviceNotFoundPanel';
import { useGetPrimaryAttendee } from 'circulator/hooks/attendee/useGetPrimaryAttendee';
import { useDeviceLogin } from 'circulator/hooks/device/useDeviceLogin';
import { useGetDevice } from 'circulator/hooks/device/useGetDevice';
import { useUser } from 'circulator/hooks/user/useUser';
import Panel from 'common/components/UI/Panel';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { Splash } from 'common/components/UI/Splash';
import { getUserInDeviceGroup } from 'common/services/userServices';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { useParams } from 'react-router-dom';
import { BlankPlayerPlaceholder } from 'tv/components/BlankPlayerPlaceholder';
import { TVPlayer } from 'tv/components/TVPlayer';
import { useGetProcedureByRoomNumber } from 'tv/hooks/useGetProcedureByRoomNumber';

export const TVPage = () => {
    const { reset } = useQueryErrorResetBoundary();
    return (
        <ErrorBoundary
            onReset={reset}
            // eslint-disable-next-line react/no-unstable-nested-components
            fallbackRender={({ resetErrorBoundary }) => (
                <Panel>
                    <p>Oops! Something went wrong...</p>
                    <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                        Try again
                    </Button>
                </Panel>
            )}
        >
            <React.Suspense fallback={<BlankPlayerPlaceholder message="" />}>
                <TV />
            </React.Suspense>
        </ErrorBoundary>
    );
};

export const TV = () => {
    const { user, isLoading: userLoading } = useUser();
    const { uuid, deviceLogin, isLoading: deviceLoginLoading } = useDeviceLogin();
    const { reset } = useQueryErrorResetBoundary();
    const userInDeviceGroup = getUserInDeviceGroup(user);
    const { device, isLoading: deviceLoading } = useGetDevice({ enabled: userInDeviceGroup });
    const { roomNumber } = useParams<{ roomNumber: string }>();
    const ready = userLoading || deviceLoginLoading || deviceLoading;
    const { procedure, isLoading: procedureLoading } = useGetProcedureByRoomNumber({
        enabled: ready,
        body: { roomNumber: Number(roomNumber) },
    });
    const { primaryAttendee } = useGetPrimaryAttendee(procedure?.id);

    if (ready) return <Splash showLoader />;
    if (!userInDeviceGroup || !device?.isActive) return <DeviceNotFoundPanel deviceUuid={uuid} onRetry={deviceLogin} />;
    if (procedureLoading) return <ProcessingOverlay enabled />;

    return (
        <React.Suspense fallback={<BlankPlayerPlaceholder message="" />}>
            <ErrorBoundary
                onReset={reset}
                // eslint-disable-next-line react/no-unstable-nested-components
                fallbackRender={({ resetErrorBoundary }) => (
                    <Panel>
                        <p>Oops! Something went wrong...</p>
                        <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                            Try again
                        </Button>
                    </Panel>
                )}
            >
                <React.Suspense fallback={<BlankPlayerPlaceholder message="Loading..." />}>
                    {!primaryAttendee ? (
                        <BlankPlayerPlaceholder message={procedure ? `No moderator currently` : `No procedure started`} />
                    ) : (
                        <>
                            {procedure && isNotNullOrUndefined(procedure.id) && (
                                <TVPlayer procedure={procedure} primaryAttendeeId={primaryAttendee.userId} />
                            )}
                        </>
                    )}
                </React.Suspense>
            </ErrorBoundary>
        </React.Suspense>
    );
};
