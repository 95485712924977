import { useState, useEffect } from 'react';
import { IceServer } from 'common/types';
import { MasterController, MasterType } from './master-controller';

export interface MasterConfig {
    videoElementId: string;
    streamId: string;
    publishUrl: string;
    token: string;
    iceServers: IceServer[];
    isPublishingVideo?: boolean;
    isPublishingAudio?: boolean;
    canPublishVideo?: boolean;
    audioDevice?: { id?: string; type: string };
    videoDevice?: { id?: string; type: string };
    masterType: MasterType;
}

export const useMaster = (config: MasterConfig) => {
    const [controller, setController] = useState<MasterController>();

    useEffect(() => {
        const controller = new MasterController({
            localViewId: config.videoElementId,
            streamId: config.streamId,
            publishUrl: config.publishUrl,
            token: config.token,
            iceServers: config.iceServers,
            isPublishingAudio: config.isPublishingAudio,
            isPublishingVideo: config.isPublishingVideo,
            canPublishVideo: config.canPublishVideo,
            audioDevice: config.audioDevice,
            videoDevice: config.videoDevice,
            masterType: config.masterType,
        });

        setController(controller);

        controller.start();
        // disabled because we don't care about changes to isPublishing* or canPublishVideo here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config.videoElementId, config.streamId, config.publishUrl, config.token, config.iceServers]);

    useEffect(() => {
        if (controller) {
            if (!config.isPublishingVideo) {
                controller.video = false;
            } else controller.video = true;
            if (!config.isPublishingAudio) {
                controller.audio = false;
            } else controller.audio = true;
        }
    }, [config.isPublishingVideo, config.isPublishingAudio, controller]);

    useEffect(() => {
        console.debug('*** useMaster dispose: Setup');
        return () => {
            console.debug('*** useMaster dispose: Stopping', controller);
            controller?.stop();
        };
    }, [controller]);

    return { controller };
};
