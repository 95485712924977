import { useMemo } from 'react';
import { useUser } from 'client/hooks/user/useUser';
import { getUserInRegisteredUsers, getUserInAdminUsers } from 'common/services/userServices';
import { useLocation, Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { ProcessingOverlay } from '../UI/ProcessingOverlay';

export function RequireAuth({ indexPath = '/login', admin = false, children }: { indexPath?: string; admin?: boolean; children?: JSX.Element }) {
    const location = useLocation();
    const { user, isLoading } = useUser();
    const userRegistered = useMemo(() => getUserInRegisteredUsers(user), [user]);
    const userAdmin = useMemo(() => getUserInAdminUsers(user), [user]);

    if (isLoading) {
        return <ProcessingOverlay enabled />;
    } else if ((admin && !userAdmin) || !(userAdmin || userRegistered)) {
        return <Navigate to={indexPath} state={{ from: location }} replace />;
    }
    if (children) return children;

    return <Outlet />;
}
