/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useGetAttendeeByProcedureId } from 'client/hooks/attendee/useGetAttendeeByProcedureID';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';
import { isNullOrUndefined } from 'common/utils/ObjectUtils';
import Panel from '../UI/Panel';
import AProcedureAttendeesPanelInfo from './AProcedureAttendeesPanelInfo';

interface IAProcedureAttendeesPanelProps {
    procedureId: number;
    enableAPI: boolean;
}

const AProcedureAttendeesPanel = ({ procedureId, enableAPI }: IAProcedureAttendeesPanelProps) => {
    const [searchTerm, setSearchTerm] = React.useState(null);
    const { attendees } = useGetAttendeeByProcedureId(procedureId, enableAPI);
    if (isNullOrUndefined(attendees)) return <></>;
    const handleChange = (event: ChangeEvent<any>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>
            <Panel elevation={0}>
                <EmptyStateHandler data={attendees} keyword="attendees">
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={4}>
                            <Typography align="left" gutterBottom>
                                Access requests: ({attendees?.length} attendees)
                            </Typography>
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <TextField
                                id="searchTerm"
                                name="searchTerm"
                                label="Attendee Search"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                value={searchTerm}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    {attendees &&
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        attendees.map((attendee: any) => (
                            <div key={attendee.userId}>
                                <AProcedureAttendeesPanelInfo attendee={attendee} />
                            </div>
                        ))}
                </EmptyStateHandler>
            </Panel>
        </div>
    );
};

export default AProcedureAttendeesPanel;
