/* eslint-disable no-console */
import axios from 'axios';
import { generateHeader } from 'common/utils/APIUtils';
import Config from 'Config';

const baseUrl = `${Config.apiUrl}/classes/Device`;

export async function getDeviceByUUID(uuid) {
    const headers = generateHeader();
    const params = {
        where: {
            UUID: uuid,
        },
        include: 'facility',
    };
    return await axios.get(`${baseUrl}`, { headers, params });
}

export const listAllDevices = async () => {
    const headers = generateHeader();
    return axios.get('/devices', { headers });
};
