import { config } from 'client/hooks/config';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

interface ICommentInfo {
    jobId: string;
    userId: string;
    comment: string;
}

export function useClipCommentAdd() {
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key, path } = config.clips.commentCreate();
    const { mutateAsync, isLoading } = useMutation(
        key,
        async ({ jobId, userId, comment }: ICommentInfo) => {
            const results = Api.post<Response>({ path, body: { jobId, userId, comment } });
            return results;
        },
        {
            onSuccess: () => {
                notify(new Message({ title: 'Success', type: 'success' }));
                queryClient.invalidateQueries(key);
                queryClient.invalidateQueries(config.clips.commentCreate().key);
            },
            onError: () => {
                notify(new Message({ title: 'Failed', type: 'error' }));
                queryClient.invalidateQueries(key);
            },
        }
    );
    return { addComment: mutateAsync, isLoading };
}
