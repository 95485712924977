import { FC, useState } from 'react';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { ClipListModal } from './ClipListModal';

interface IClipListProps {
    procedureId: number;
    count?: number;
}

export const ClipListButton: FC<IClipListProps> = ({ procedureId, count }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    };

    return isOpen ? (
        <ClipListModal procedureId={procedureId} isOpen={isOpen} setIsOpen={setIsOpen} />
    ) : (
        <VideoFileIcon color={count && count > 0 ? 'info' : 'disabled'} onClick={() => openModal()} />
    );
};
