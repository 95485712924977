const scssConvertedToCSS = `
.grid-6 {
    position: relative;
    border: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    height: fit-content;
    padding: 0px;
}

.grid-6, .grid-6 .subgrid:not(.header,.data) {
    grid-template-rows: min-content;
    grid-auto-rows: 1fr;
}

.grid-6 .full-width {
    grid-column: 1 / -1;
    width: 100%;
}

.grid-6 .subgrid {
    display: grid;
    grid-template-columns: subgrid;
}

.grid-6 .subgrid.data{
    overflow-y: auto;
}

.grid-6 > :not(.subgrid),
.grid-6 .subgrid > :not(.subgrid) {
    border-top: white thin solid;
}

.grid-6 > :not(.subgrid),
.grid-6 .subgrid > :not(.subgrid) {
    border-left: white thin solid;
}

.grid-6 .subgrid > :not(.subgrid):nth-child(6n),
.grid-6 > :not(.subgrid):nth-child(6n) {
    border-right: white thin solid;
}

.grid-6 .subgrid > :not(.subgrid):nth-last-child(-n+7),
.grid-6 > :not(.subgrid):nth-last-child(-n+7) {
    border-bottom: white thin solid;
}

.grid-6 .subgrid.header > :not(.subgrid),
.grid-6 .subgrid.data > :not(.subgrid)  {
    margin: 0px;
    border-spacing: 0px;
    padding: 5px;
    display: flex;
    color: white;
    align-items: center;
    word-break: break-word;
    scroll-snap-align: 'start';
}

.grid-6 .subgrid.header > :not(.subgrid),
.grid-6 .subgrid.footer > :not(.subgrid) {
    text-align: center;
    font-weight: bold;
}

.grid-6 .subgrid.detail {
    transition: height 0.15s ease-in-out, max-height 0.15s ease-in-out;
    overflow-y: hidden;
}
.grid-6 .subgrid.detail.detail-closing {
    
    max-height: 0px;
}
.grid-6 .subgrid.detail.detail-closed {
    
    max-height: 0px;
}
.grid-6 .subgrid.detail.detail-opening {
    height: max-content;
    max-height: 100vh;
}
.grid-6 .subgrid.detail.detail-open {
    height: max-content;
    max-height: 100vh;
}
`;

const styleElement = document.createElement('style');
styleElement.innerHTML = scssConvertedToCSS;
styleElement.setAttribute('type', 'text/css');
document.head.appendChild(styleElement);

export const styles = {
    grid6: 'grid-6',
    fullWidthDataItem: 'full-width',
    //subgrid: 'full-width subgrid',
    detail: 'full-width subgrid detail',
    detailContent: 'full-width subgrid detail-content',
    header: 'full-width subgrid header',
    footer: 'full-width subgrid footer',
    data: 'full-width subgrid data',
    detailClosing: 'detail-closing',
    detailClosed: 'detail-closed',
    detailOpening: 'detail-opening',
    detailOpen: 'detail-open',
} as const;
