import { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogContentText, Typography, makeStyles } from '@material-ui/core';
import { useUpsertUserGroups } from 'client/hooks/user/useUpsertUserGroups';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';

const styles = {
    btnCancel: {
        border: '1px solid #2b333f',
        color: '#2b333f',
        '&:active': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:hover': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:focus': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:disabled': {
            backgroundColor: '#eee !important',
            color: '#ccc !important',
        },
    },
    btnCOnfirm: {
        marginLeft: '5px',
    },
};

const useStyles = makeStyles(styles);

interface IGroupAssignmentDialog {
    open: boolean;
    userId: string;
    userGroups: string[];
    update: (data: IUserGroupData) => void;
    close: () => void;
}

export interface IUserGroupData {
    userId: string;
    groups: string[];
}

export const cognitoGroups = ['Registered', 'Admin', 'Device'];

export const GroupAssignmentDialog = ({ open, userId, userGroups, update, close }: IGroupAssignmentDialog) => {
    const classes = useStyles();
    const { notify } = useNotifier();
    const [groups] = useState<string[]>(cognitoGroups);
    const [acceptedGroups, setAcceptedGroups] = useState<string[]>([]);
    const { addUserToGroup } = useUpsertUserGroups();
    const [isProgress, setProgress] = useState(false);

    useEffect(() => {
        const rolesList = [...userGroups];
        setAcceptedGroups(rolesList);
        setProgress(false);
    }, [userGroups, groups]);

    const handleAddAcceptedGroup = (newGroup: string) => {
        const grp = [...acceptedGroups, newGroup];
        setAcceptedGroups(grp);
    };
    const handleRemoveAcceptedGroup = (group: string) => {
        const grp = acceptedGroups.filter(r => r !== group);
        setAcceptedGroups(grp);
    };

    const handleGroupChange = (newGroup: string) => {
        if (acceptedGroups.includes(newGroup)) {
            handleRemoveAcceptedGroup(newGroup);
        } else {
            handleAddAcceptedGroup(newGroup);
        }
    };

    const handelConfirm = async () => {
        setProgress(true);
        const data: IUserGroupData = {
            userId: userId,
            groups: acceptedGroups,
        };
        try {
            const res = await addUserToGroup(data);
            if (res.success === true) {
                update(data);
            }
        } catch (err) {
            notify(new Message({ title: 'Something went wrong in your request. Please see the logs for more information', type: 'error' }));
            setProgress(false);
        }
    };
    return (
        <>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText color="primary">
                        <Box p={2} style={{ width: '25vw' }}>
                            <Typography variant="h6">Groups Assigment</Typography>
                            <Typography>Please select groups to assign. Click confirm to save changes.</Typography>
                            <Box p={1}></Box>
                            {groups.map((group: string) => (
                                <Box key={group} display="flex" alignItems="center">
                                    <Checkbox
                                        id={`${group}-checkbox`}
                                        name={`${group}-checkbox`}
                                        color="primary"
                                        required={false}
                                        checked={acceptedGroups.includes(group)}
                                        onChange={() => handleGroupChange(group)}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <Typography variant="body1">{group}</Typography>
                                </Box>
                            ))}
                            <Box p={1}></Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, justifyContent: 'flex-start' }}>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    color="secondary"
                                    onClick={close}
                                    disabled={isProgress}
                                    className={classes.btnCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    autoFocus
                                    variant="contained"
                                    size="medium"
                                    onClick={handelConfirm}
                                    disabled={isProgress}
                                    className={classes.btnCOnfirm}
                                >
                                    Confirm
                                    <Box sx={{ ml: 1, mr: 1 }}>{isProgress ? <CircularProgress size={'0.8rem'} color="inherit" /> : <></>}</Box>
                                </Button>
                            </Box>
                            <Box p={2}></Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};
