import React from 'react';
import { List } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import type { Attendee } from 'common/types/circulator';
import NoDataWrapper from '../../../common/components/NoDataWrapper/NoDataWrapper';
import { ProcedureSubscriberItem } from './ProcedureSubscriberItem';

interface Props {
    procedureId: number;
    attendees: Attendee[];
    enablePublishActions: boolean;
}

export const ProcedureSubscribersPanel = ({ procedureId, attendees, enablePublishActions }: Props) => {
    return (
        <NoDataWrapper data={attendees}>
            <List style={{ height: 'auto' }}>
                {attendees.map(attendee => (
                    <React.Suspense key={attendee.id} fallback={<Skeleton variant="rect" height={80} />}>
                        <ProcedureSubscriberItem procedureId={procedureId} attendee={attendee} enablePublishActions={enablePublishActions} />
                    </React.Suspense>
                ))}
            </List>
        </NoDataWrapper>
    );
};
