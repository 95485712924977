/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useListAllDevices } from 'client/hooks/device/useListAllDevices';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import ByRoomPanel from './ByRoomPanel';

const RoomAnalytics = () => {
    const [includeUnknown, setIncludeUnknown] = useState(false);
    const [deviceSearchTerm, setDeviceSearchTerm] = useState('');
    const { devices, isLoading } = useListAllDevices();
    const deviceFacilities = useMemo(
        () =>
            !isLoading && isNotNullOrUndefined(devices) && devices.length > 0
                ? [
                      ...new Set(
                          devices.filter((device: any) => isNotNullOrUndefined(device.facilityId)).map((device: any) => device.facilityId.toString())
                      ),
                  ]
                : [],
        [isLoading, devices]
    );

    const handleOnAutofillChange = useCallback((event: any, value: any) => {
        if (value) {
            setDeviceSearchTerm(value);
        } else {
            setDeviceSearchTerm(event.target.value);
        }
    }, []);

    const handleOnChange = useCallback((event: any) => {
        setDeviceSearchTerm(event.target.value);
    }, []);
    const handleCheckboxChange = useCallback((event: any) => {
        setIncludeUnknown(event.target.checked);
    }, []);
    const deviceItems = useMemo(
        () =>
            !isLoading && isNotNullOrUndefined(devices) && devices.length > 0 ? (
                devices
                    .filter((device: any) => (includeUnknown ? true : device.isActive === true))
                    .filter((device: any) =>
                        isNotNullOrUndefined(deviceSearchTerm) && deviceSearchTerm.length > 0
                            ? device.roomName?.toLowerCase().includes(deviceSearchTerm.toLowerCase()) ||
                              device.name?.toLowerCase().includes(deviceSearchTerm.toLowerCase()) ||
                              device.tv?.toLowerCase().includes(deviceSearchTerm.toLowerCase())
                            : true
                    )
                    .map((device: any) => <ByRoomPanel key={device.id} device={device} />)
            ) : (
                <ProcessingOverlay enabled caption="Loading..." />
            ),
        [isLoading, devices, includeUnknown, deviceSearchTerm]
    );

    const autocompleteComponent = useMemo(
        () => (
            <Autocomplete
                options={deviceFacilities}
                getOptionLabel={(option: any) => option}
                freeSolo
                onKeyDown={(e: any) => handleOnAutofillChange(e, e.target.value)}
                onClick={(e: any) => handleOnAutofillChange(e, e.target.value)}
                onChange={(e: any) => handleOnAutofillChange(e, e.target.value)}
                renderInput={params => (
                    <TextField
                        {...params}
                        id="deviceSearchTerm"
                        name="deviceSearchTerm"
                        value={deviceSearchTerm}
                        label="Facility Name"
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        autoComplete="Facility Name"
                        onChange={handleOnChange}
                        helperText="Please select a Facility"
                    />
                )}
            />
        ),
        [deviceFacilities, handleOnAutofillChange, deviceSearchTerm, handleOnChange]
    );

    return (
        <div>
            <Grid container>
                <Grid item xs={4}>
                    {autocompleteComponent}
                </Grid>
                <Grid item xs={4}>
                    <Box padding={1}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={includeUnknown} onChange={handleCheckboxChange} />}
                                label="Include Inactive / Unknown Devices"
                            />
                        </FormGroup>
                    </Box>
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <EmptyStateHandler data={deviceItems}>{deviceItems}</EmptyStateHandler>
        </div>
    );
};

export default RoomAnalytics;
