import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogContentText, makeStyles, Typography } from '@material-ui/core';
import usePreauthorizedUsersStore from 'circulator/hooks/usePreauthorizedUsersStore';
import { useListAllUserRoles } from 'common/hooks/role/useListUserRoles';
import type { Attendee } from 'common/types/circulator';
import { shallow } from 'zustand/shallow';

interface Props {
    attendees: Attendee[];
}

const useStyles = makeStyles(theme => ({
    btn: {
        float: 'right',
        marginRight: theme.spacing(2),
    },
    hrule: {
        borderTop: '1px solid rgb(0, 0, 0, 0.12)',
    },
}));

export const SpecialAccessButton: React.FC<Props> = ({ attendees }) => {
    const classes = useStyles();
    const [accessDialogOpen, setAccessDialogOpen] = useState(true);
    const { userRoles } = useListAllUserRoles();

    const { acceptedRoles, addAcceptedRole, removeAcceptedRole } = usePreauthorizedUsersStore(
        state => ({
            acceptedRoles: state.acceptedRoles,
            addAcceptedRole: state.addAcceptedRole,
            removeAcceptedRole: state.removeAcceptedRole,
        }),
        shallow
    );

    useEffect(() => {
        for (const role of userRoles) {
            addAcceptedRole(role);
        }
    }, [addAcceptedRole, userRoles]);

    const handleOpenAccessDialog = async () => {
        console.log('handleOpenAccessDialog', accessDialogOpen);
        if (accessDialogOpen) {
            setAccessDialogOpen(false);
        } else {
            setAccessDialogOpen(true);
        }
    };
    const handleAddAcceptedRole = (newRole: string) => {
        addAcceptedRole(newRole);
    };
    const handleRemoveAcceptedRole = (role: string) => {
        removeAcceptedRole(role);
    };
    const handleAddRole = (newRole: string) => {
        if (acceptedRoles.includes(newRole)) {
            handleRemoveAcceptedRole(newRole);
        } else {
            handleAddAcceptedRole(newRole);
        }
    };
    const handleConfirm = async () => {
        setAccessDialogOpen(false);
    };

    return (
        <>
            <Button variant="outlined" size="small" color="primary" className={classes.btn} onClick={handleOpenAccessDialog}>
                Preauthorized Users
            </Button>
            <Dialog open={accessDialogOpen} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText color="primary">
                        <Box p={2} style={{ width: '25vw' }}>
                            <Typography variant="h6">Preauthorized Users</Typography>
                            <Typography>
                                Preauthorized user types are listed below. These types of users will be automatically admitted to the procedure.
                                Change settings and/or select continue.
                            </Typography>
                            <br />
                            {userRoles
                                .filter((x: string) => x !== 'PATIENT')
                                .map((role: string) => (
                                    <Box key={role} display="flex" alignItems="center" className={classes.hrule}>
                                        <Checkbox
                                            id={`${role}-checkbox`}
                                            name={`${role}-checkbox`}
                                            color="primary"
                                            required={false}
                                            checked={acceptedRoles.includes(role)}
                                            onChange={() => handleAddRole(role)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <Typography variant="body1">{role}</Typography>
                                    </Box>
                                ))}
                            <Box p={1}></Box>
                            <Button autoFocus variant="contained" size="medium" className={classes.btn} onClick={handleConfirm}>
                                Continue
                            </Button>
                            <Box p={3}></Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};
