import React, { useState } from 'react';
import { Box, Button, Card, CircularProgress, FormControl, Grid, InputLabel, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Panel from 'client/components/UI/Panel';
import { useListFacilities } from 'client/hooks/facilities/useListFacilities';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';
import PurchaseOptionsDialog from 'common/components/UI/PurchaseOptionsDialog';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import { Facility, FacilityUsers } from 'common/types';
import placeholder from 'img/company-placeholder.png';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { Link } from 'react-router-dom';
import { useStyles } from './facility.styles';
import { DoctorItemProps, FacilityContentProps, FacilityProps } from './facility.types';

const DoctorItem = ({ facilityDoctors }: DoctorItemProps) => {
    const classes = useStyles();
    const doctorImage = useGetProtectedObject(facilityDoctors?.doctor?.photoUrl);
    return (
        <Box className={classes.boxDoctorsInfo}>
            <Box className={classes.boxDoctorAvatar}>
                {doctorImage ? (
                    <img src={doctorImage} alt={facilityDoctors.doctor.name} className={classes.boxDoctorAvatarImg} />
                ) : (
                    <CircularProgress className={classes.loader} />
                )}
            </Box>
            <strong className={classes.boxDoctorAvatarName}>{facilityDoctors.doctor.name}</strong>
        </Box>
    );
};

export const FacilityContent = ({ facility, surgeryTypes }: FacilityContentProps) => {
    const classes = useStyles();
    const facilityPhoto = useGetProtectedObject(facility?.photoUrl);
    const facilityAvatar = facility?.photoUrl ? facilityPhoto : placeholder;
    return (
        <Card className={classes.facilityContentCard}>
            <Box className={classes.imgAvatar}>
                {facilityAvatar ? (
                    <Link to={`/facilities/${facility.id}`} style={{ height: '100%' }}>
                        <img src={facilityAvatar} alt={facility?.name} />
                    </Link>
                ) : (
                    <CircularProgress className={classes.loader} />
                )}
            </Box>
            <Box sx={{ flex: 'auto', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" component="h5" className={classes.darkText}>
                    {facility.name} ({facility.idNumber})
                </Typography>
                <Typography variant="caption">
                    {facility.streetAddress ?? ''}&nbsp;{facility.city},&nbsp;{facility.state}&nbsp;
                    {facility.zip}
                </Typography>
                <Typography variant="caption" className={classes.textSecondary}>
                    {facility.phone ?? ''}
                </Typography>
                <Box display="flex" flexDirection="row" mb={2} sx={{ marginBottom: 'auto' }}>
                    {facility.websiteUrl && (
                        <a href={facility.websiteUrl} target="_blank" rel="noopener noreferrer external" className={classes.websiteLink}>
                            {facility.websiteUrl}
                        </a>
                    )}
                </Box>
                <Box sx={{ position: 'relative', marginTop: '10px' }}>
                    <PurchaseOptionsDialog facility={facility} isPrimary={false} />
                </Box>
            </Box>
            <Box>
                <Box className={classes.boxDoctorsHeader}>
                    <strong>Facility Doctors</strong>{' '}
                    <Link to={`/facilities/${facility.id}`} color="info" className={classes.blueLink}>
                        View All
                    </Link>
                </Box>
                <EmptyStateHandler data={facility.FacilityUsers} keyword="doctors">
                    <div className={classes.boxDoctorsItem}>
                        {facility.FacilityUsers.map((doctor: FacilityUsers, index: number) => (
                            <DoctorItem facilityDoctors={doctor} />
                        ))}
                    </div>
                </EmptyStateHandler>
            </Box>
        </Card>
    );
};

export const Facilities = ({ name, city, state, country }: FacilityProps) => {
    const { facilities, surgeryTypes } = useListFacilities({ name, city, state, country });
    return (
        <Grid container spacing={2}>
            {facilities.map((facility: Facility, index: number) => (
                <React.Fragment key={index}>
                    {facility.isListed && (
                        <Grid item xs={12} md={12} lg={6} key={facility.id}>
                            <FacilityContent facility={facility} surgeryTypes={surgeryTypes} />
                        </Grid>
                    )}
                </React.Fragment>
            ))}
        </Grid>
    );
};

const FacilitiesPage = () => {
    const classes = useStyles();
    const { reset } = useQueryErrorResetBoundary();
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [filterState, setFilterState] = useState<string | undefined>(undefined);
    const [filterCountry, SetFilterCountry] = useState<string | undefined>(undefined);
    const [filterCity, SetFilterCity] = useState<string | undefined>(undefined);
    const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName((event.target as HTMLInputElement).value as any);
    };
    const handleFilterState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState((event.target as HTMLInputElement).value as any);
    };
    const handleFilterCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetFilterCountry((event.target as HTMLInputElement).value as any);
    };
    const handleFilterCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        SetFilterCity((event.target as HTMLInputElement).value as any);
    };
    return (
        <ErrorBoundary
            onReset={reset}
            // eslint-disable-next-line react/no-unstable-nested-components
            fallbackRender={({ resetErrorBoundary }) => (
                <Panel>
                    <p>Oops! Something went wrong...</p>
                    <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                        Try again
                    </Button>
                </Panel>
            )}
        >
            <div className={classes.root}>
                <Box display="flex" flexDirection="row" py={2}>
                    <Box>
                        <TextField
                            id="input-name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            size="small"
                            margin="none"
                            fullWidth
                            value={filterName}
                            onChange={handleFilterName}
                            className={classes.outlinedWhite}
                        />
                    </Box>
                    <Box ml={2}>
                        <FormControl variant="outlined" size="small" className={classes.outlinedWhite}>
                            <InputLabel htmlFor="outlined-adornment-amount">State</InputLabel>
                            <OutlinedInput labelWidth={75} id="outlined-adornment-amount" value={filterState} onChange={handleFilterState} />
                        </FormControl>
                    </Box>
                    <Box ml={2}>
                        <FormControl variant="outlined" size="small" className={classes.outlinedWhite}>
                            <InputLabel htmlFor="outlined-adornment-amount">City</InputLabel>
                            <OutlinedInput labelWidth={75} id="outlined-adornment-amount" value={filterCity} onChange={handleFilterCity} />
                        </FormControl>
                    </Box>
                    <Box ml={2}>
                        <FormControl variant="outlined" size="small" className={classes.outlinedWhite}>
                            <InputLabel htmlFor="outlined-adornment-amount">Country</InputLabel>
                            <OutlinedInput labelWidth={75} id="outlined-adornment-amount" value={filterCountry} onChange={handleFilterCountry} />
                        </FormControl>
                    </Box>
                </Box>
                <React.Suspense fallback={<Skeleton />}>
                    <Facilities name={filterName} city={filterCity} country={filterCountry} state={filterState} />
                </React.Suspense>
            </div>
        </ErrorBoundary>
    );
};

export default FacilitiesPage;
