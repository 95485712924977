import React from 'react';
import type * as storage from '@aws-amplify/storage';
import * as amplify from 'aws-amplify';

interface Options {
    level?: storage.StorageAccessLevel;
    identityId?: string;
}

export function useGetObject(key: string | null | undefined, options: Options = {}) {
    const [object, setObject] = React.useState<string>();
    const { level = 'protected', identityId } = options;
    const opt = React.useMemo(() => ({ level, identityId }), [level, identityId]);

    React.useEffect(() => {
        async function load() {
            if (key) {
                const obj = await amplify.Storage.get(key, opt);
                setObject(obj);
            } else {
                setObject(undefined);
            }
        }
        load();
    }, [key, opt]);

    return object;
}
