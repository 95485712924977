import { useEffect, useState } from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import * as amplify from 'aws-amplify';
import { WebConfig } from 'web-config';

export function useGetPresignedUrl(s3Key?: string) {
    const [presignedUrl, setPresignedUrl] = useState('');
    const config = useConfig<WebConfig>();
    const region = config.additionalConfig?.storage.AWSS3.region;
    const bucket = config.additionalConfig?.storage.AWSS3.streamArchiveBucket;
    useEffect(() => {
        async function load() {
            if (s3Key) {
                setPresignedUrl(
                    await amplify.Storage.get(s3Key, {
                        level: 'public',
                        region: region,
                        bucket: bucket,
                        download: false,
                        contentType: 'video/mp4', // Replace with the appropriate content type for your video file
                        customPrefix: {
                            public: '',
                        },
                        contentDisposition: `attachment; filename="video.mp4"`,
                    })
                );
            } else {
                setPresignedUrl('');
            }
        }
        load();
    }, [s3Key, region, bucket]);

    return presignedUrl;
}
