// Create React Functional Compenont and Props

import { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useMutateProcedure } from 'circulator/hooks/procedure/useMutateProcedure';
import Procedure from 'common/models/Procedure';
import { useCreateDevice } from 'mobile/hooks/useCreateDevice';
import { useNavigate } from 'react-router-dom';
import useMobileStyles from '../hooks/useMobileStyles';

export interface StartMobileProcedureButtonProps {
    isPublic: boolean;
    title: string;
    patientInitials: string;
    facilityId: number;
    drName: string;
    notify: boolean;
}

export const StartMobileProcedureButton: React.FC<StartMobileProcedureButtonProps> = props => {
    const [isLoading, setLoading] = useState(false);
    const { createOrGetDevice } = useCreateDevice();
    const procedureMutation = useMutateProcedure();
    const navigate = useNavigate();
    const { button } = useMobileStyles();

    const onStartSession = async () => {
        try {
            setLoading(true);
            const device = await createOrGetDevice({
                facilityId: props.facilityId,
            });
            const startObject = {
                doctorName: props.drName,
                title: props.title,
                device: device,
                deviceId: device.id,
                deviceName: 'NA',
                notify: props.notify,
                isMobile: true,
                patientInitials: props.patientInitials,
            };
            let procObj = new Procedure(startObject);

            await procedureMutation.start.mutateAsync(procObj);
            navigate('/mobile/shot');
        } catch (err) {
            console.error('Error while starting a new mobile session: ', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            className={button}
            disabled={!props.title || !props.patientInitials || !props.facilityId || isLoading}
            onClick={onStartSession}
            variant="contained"
        >
            Start Session {isLoading && <CircularProgress size={20} />}
        </Button>
    );
};
