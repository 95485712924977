import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { StreamDetails } from 'common/types/procedure';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

type useGetProcedureStreamsParameters = {
    procedureId?: number;
    enabled?: boolean;
};
type Response = api.GetProcedureStreamsResponse;

export const useGetProcedureStreams = ({
    procedureId,
    enabled = false,
}: useGetProcedureStreamsParameters): {
    streams?: StreamDetails[];
    isLoading: boolean;
} => {
    const { key, path } = config.procedure.vods(procedureId);
    const { data, isLoading } = useQuery(key, () => Api.get<Response>({ path }), {
        enabled: isNotNullOrUndefined(procedureId) && enabled,
        retry: false,
    });
    return { streams: data || [], isLoading };
};
