import React, { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Grow,
    makeStyles,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useCreateOrder } from 'client/hooks/orders/useCreateOrder';
import { useListFacilityLicenses } from 'common/hooks/license/useListFacilityLicenses';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import { Facility } from 'common/types';
import { ChevronRightIcon, CloseIcon, FacilityIcon } from 'common/utils/Icons';
import ClockAllTime from 'img/clock-alltime.png';
import ClockCombined from 'img/clock-combined.png';
import ClockMonth from 'img/clock-month.png';
import ClockYear from 'img/clock-year.png';
import splash from 'img/home/splash.jpg';
// @ts-ignore
const styles = theme => ({
    root: {
        color: theme.palette.secondary.contrastText,
    },
    dialogTitle: {
        color: theme.palette.secondary.contrastText,
        textAlign: 'center',
        position: 'relative',
    },
    dialogContentGrid: {
        maxWidth: '85%',
        margin: 'auto',
        position: 'absolute',
        top: '225px',
        left: '100px',
    },
    dialogContent: {
        padding: 0,
        minHeight: '650px',
    },
    darkText: {
        color: theme.palette.secondary.contrastText,
    },
    currency: {
        verticalAlign: 'super',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    buttonWarning: {
        background: '#FF5722',
        color: '#fff',
        borderRadius: '15px',
        fontSize: '12px',
        padding: '5px 15px',
        '&:hover, &:active': {
            background: '#ed3f08',
        },
        '&:disabled': {
            background: '#f56d43 !important',
            color: '#333 !important',
        },
    },
    buttonPrimary: {
        borderRadius: '0px',
    },
});

// @ts-ignore
export const useStyles = makeStyles(styles);

interface PurchaseOptionsDialogProps {
    facility: Facility;
    isPrimary: boolean;
    purchaseText?: string;
}

const PurchaseOptionsDialog = ({ facility, isPrimary, purchaseText }: PurchaseOptionsDialogProps) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { licenses } = useListFacilityLicenses(facility.id);
    const facilityPhoto = useGetProtectedObject(facility?.photoUrl);

    const handleCancel = () => {
        setDialogOpen(false);
    };

    const { createOrder, isOrderLoading } = useCreateOrder();

    const handleCreateOrder = useCallback(
        async (licenseId: number) => {
            if (licenseId !== null || licenseId !== undefined) {
                try {
                    await createOrder({ licenseId });
                    setDialogOpen(false);
                } catch (error) {
                    throw error;
                }
            }
        },
        [createOrder, setDialogOpen]
    );

    const hasLicenses = licenses && licenses.length > 0;

    if (facility.isPublicAccess) {
        return null;
    }

    return (
        <CreateOrderRedirectWrapper isLoading={isOrderLoading}>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={dialogOpen}
                onClose={handleCancel}
                TransitionComponent={Grow}
                PaperComponent={Paper}
                className={classes.root}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="baseline" width={1} justifyContent="center">
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <FacilityIcon color="primary" />
                            </Box>
                            <Box ml={1}>
                                <Typography variant="h5" className={classes.darkText}>
                                    {' '}
                                    Buy Access
                                </Typography>
                            </Box>
                        </Box>
                        <Button type="button" variant="text" color="primary" onClick={() => handleCancel()}>
                            <CloseIcon />
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box textAlign="center" zIndex={30} position="absolute" width="100%" top="125px" height="fit-content">
                        <Typography variant="h4" color="textSecondary">
                            {facility.name} ({facility.idNumber})
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {facility.city},&nbsp;{facility.state}
                        </Typography>
                    </Box>
                    <Box
                        position="relative"
                        height="250px"
                        style={{
                            backgroundImage: `url(${facilityPhoto ?? splash})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            filter: 'blur(4px)',
                        }}
                    ></Box>
                    <Grid container spacing={2} className={classes.dialogContentGrid}>
                        {licenses.map((license: any, index: number) => {
                            let licenseLogo = '';
                            switch (license.licenseType.name.toLowerCase()) {
                                case '1 month':
                                    licenseLogo = ClockMonth;
                                    break;
                                case '1 year':
                                    licenseLogo = ClockYear;
                                    break;
                                case 'all-time':
                                    licenseLogo = ClockAllTime;
                                    break;
                                default:
                                    licenseLogo = ClockCombined;
                                    break;
                            }

                            return (
                                <Grid item xs={12} md={4} lg={4} xl={4} key={index}>
                                    <Card>
                                        <CardContent style={{ minHeight: '200px' }}>
                                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb={3}>
                                                <img src={licenseLogo} alt={license.id} width="100px" height="100px" />
                                            </Box>
                                            <Typography align="center" variant="h5" gutterBottom className={classes.darkText}>
                                                {license.licenseType.name}
                                            </Typography>
                                            <Typography align="center" variant="body2" gutterBottom className={classes.darkText}>
                                                {license.licenseDetails}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Box
                                                px={3}
                                                py={2}
                                                width={1}
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Box display="flex" width={1} flexDirection="column">
                                                    <Divider />
                                                    <Box width={1} py={2} alignItems="center">
                                                        <Typography variant="h2" align="center" className={classes.darkText}>
                                                            <span className={classes.currency}>$</span>
                                                            <span>{license.cost / 100}</span>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box mt={1} width={1}>
                                                    <Button
                                                        fullWidth
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleCreateOrder(license.id)}
                                                    >
                                                        Purchase&nbsp;
                                                        <ChevronRightIcon />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                        <Grid item xs={12}>
                            <Typography>
                                I have a promotional code. If you have a promotional code, close this window and select Redeem Code at the bottom of
                                the page.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Tooltip title={hasLicenses ? 'Purchase Access Now' : 'License Not Available'} placement="top-start">
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        className={isPrimary ? classes.buttonWarning : classes.buttonWarning}
                        disabled={!hasLicenses}
                        onClick={() => setDialogOpen(true)}
                    >
                        {purchaseText && purchaseText?.length > 0 ? purchaseText : 'Purchase Now'}
                    </Button>
                </div>
            </Tooltip>
        </CreateOrderRedirectWrapper>
    );
};

interface CreateOrderRedirectWrapperProps {
    children: React.ReactNode;
    isLoading: boolean;
}

const CreateOrderRedirectWrapper: React.FC<CreateOrderRedirectWrapperProps> = ({ isLoading, children }) => {
    return (
        <>
            <Dialog fullWidth maxWidth="md" open={isLoading} TransitionComponent={Grow} PaperComponent={Paper}>
                <DialogContent>
                    <Box minHeight={4} textAlign="center" display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Redirecting
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Please wait
                        </Typography>
                        <CircularProgress size={20} />
                    </Box>
                </DialogContent>
            </Dialog>
            {children}
        </>
    );
};

export default PurchaseOptionsDialog;
