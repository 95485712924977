import { RedeemPromotionRequest } from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

export const useMutatePromotion = () => {
    const redeemPromotionMutation = useMutation((promotionRequest: RedeemPromotionRequest) =>
        Api.post({ path: '/promotion/redeem', body: promotionRequest })
    );

    return {
        redeemPromotion: redeemPromotionMutation,
    };
};
