/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { useMutateSurvey } from 'client/hooks/survey/useSurvey';
import { CloseIcon } from 'common/utils/Icons';

const styles = {
    content: {
        flexGrow: 1,
        width: 'auto',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
        zIndex: 20000,
    },
    surveyPaper: {
        maxWidth: '30%',
        position: 'absolute',
        opacity: '100%',
        zIndex: 20000,
        bottom: theme.spacing(2),
        left: 0,
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    p2: {
        padding: theme.spacing(2),
    },
    pl2: {
        paddingLeft: theme.spacing(2),
    },
    pb2: {
        paddingBottom: theme.spacing(2),
    },
    mp0: {
        margin: 0,
        padding: 0,
    },
    gridContainer: {
        paddingRight: theme.spacing(2),
        maxWidth: '100%',
    },
    surveyDescriptorText: {
        fontSize: '10px',
    },
};

// @ts-ignore
const useStyles = makeStyles(styles);

export interface QuestionFormProps {
    surveyQuestion: any;
    procedureId?: number;
    onClose: () => void;
    onSubmit: () => void;
}

const ratings = [1, 2, 3, 4, 5];

export const QuestionForm: React.FC<QuestionFormProps> = ({ procedureId, surveyQuestion, onClose, onSubmit }) => {
    const classes = useStyles();

    const { saveSurveyAnswer } = useMutateSurvey();
    const [surveyResponse, setSurveyResponse] = useState(5);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSurveyResponse(parseInt(event.target.value));
    };

    const handleYesNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        const yesNoValue = value >= 1 ? 1 : 0;
        setSurveyResponse(yesNoValue);
    };

    const handleConfirm = () => {
        const newAnswer = {
            surveyQuestionId: surveyQuestion?.id,
            surveyResponse: surveyResponse,
            procedureId: procedureId,
        };
        saveSurveyAnswer(newAnswer).then(() => {
            onClose();
            onSubmit();
        });
    };
    return (
        <>
            <Paper className={classes.surveyPaper}>
                <Toolbar className={classes.mp0}>
                    <Grid spacing={1} container justifyContent="flex-end" alignContent="center" alignItems="center">
                        <Grid item xs={10}>
                            <Typography>Your feedback is appreciated!</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" onClick={() => onClose()} className={classes.p2}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
                <FormControl className={classes.p2}>
                    <Typography variant="h5" style={{ marginBottom: '2em' }}>
                        {surveyQuestion.question || 'How would you rate this service?'}
                    </Typography>
                    <Grid container spacing={0} className={classes.gridContainer} alignContent="center" alignItems="center">
                        {(surveyQuestion.answerFormat === 'rating-likely' || surveyQuestion.answerFormat === 'rating') && (
                            <Grid item xs={1} className={classes.pb2}>
                                <Typography className={classes.surveyDescriptorText} align="center">
                                    {surveyQuestion.answerFormat === 'rating-likely' ? `Not Likely` : ''}
                                    {surveyQuestion.answerFormat === 'rating' ? `Poor` : ''}
                                </Typography>
                            </Grid>
                        )}
                        {(surveyQuestion.answerFormat === 'rating-likely' || surveyQuestion.answerFormat === 'rating') && (
                            <>
                                {ratings.map(rating => (
                                    <Grid item xs={2} className={classes.pb2}>
                                        <FormControlLabel
                                            value={rating}
                                            control={
                                                <Radio
                                                    checked={Number(surveyResponse) === rating}
                                                    onChange={handleChange}
                                                    color="primary"
                                                    value={rating}
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                            }
                                            label={rating}
                                            labelPlacement="top"
                                        />
                                    </Grid>
                                ))}
                            </>
                        )}
                        {surveyQuestion.answerFormat === 'yes-no' && (
                            <Grid item xs={12} className={classes.pb2}>
                                <Grid container spacing={3} justifyContent="center" alignContent="center" alignItems="center">
                                    <Grid item className={classes.pb2}>
                                        <FormControlLabel
                                            value={0}
                                            control={
                                                <Radio
                                                    checked={Number(surveyResponse) === 0}
                                                    onChange={handleYesNoChange}
                                                    color="primary"
                                                    value={0}
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                            }
                                            label="No"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                    <Grid item className={classes.pb2}>
                                        <FormControlLabel
                                            value={1}
                                            control={
                                                <Radio
                                                    checked={Number(surveyResponse) === 1}
                                                    onChange={handleYesNoChange}
                                                    color="primary"
                                                    value={1}
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                            }
                                            label="Yes"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {(surveyQuestion.answerFormat === 'rating-likely' || surveyQuestion.answerFormat === 'rating') && (
                            <Grid item xs={1} className={classes.pb2}>
                                <Typography className={classes.surveyDescriptorText} align="center">
                                    {surveyQuestion.answerFormat === 'rating-likely' ? `Very Likely` : ''}
                                    {surveyQuestion.answerFormat === 'rating' ? `Excellent` : ''}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        Submit
                    </Button>
                </FormControl>
            </Paper>
        </>
    );
};
