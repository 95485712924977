import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
import logo from 'img/logo-black.svg';
import useOrientation from '../../common/hooks/useOrientation';

// @ts-ignore
const useStyles = (props: { isLandscape: boolean }) =>
    makeStyles({
        shadeMain: {
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'fit',
            height: '100vh',
            maxHeight: '100vh',
            width: '100vw',
        },
        logoSubhead: {
            color: 'black',
            position: 'relative',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            top: props.isLandscape ? '40%' : '30%',
            letterSpacing: theme.spacing(0.5),
            textTransform: 'uppercase',
        },
    });

interface BlankPlayerPlaceholderProps {
    message: string | 'Loading...';
}

export const BlankPlayerPlaceholder: React.FunctionComponent<BlankPlayerPlaceholderProps> = ({ message }) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape })();
    return (
        <div className={`${classes.shadeMain}`}>
            <Typography align="center" className={classes.logoSubhead}>
                {message}
            </Typography>
        </div>
    );
};
