export enum StreamType {
    AUDIO_ONLY = 'AUDIO_ONLY',
    VIDEO_ONLY = 'VIDEO_ONLY',
    AUDIO_VIDEO = 'AUDIO_VIDEO',
}

export interface Stream {
    id: number;
    name: string;
    type: StreamType;
    src: string;
    lowResSrc: string | undefined;
    location: string | null;
    channelId: string;
    lowResChannelId: string | undefined;
    isFeatured: boolean;
    isCirculator: boolean;
    procedureId: number | undefined;
    publishToken: string | undefined;
    isPTZ?: boolean;
    order: number;
}
