import { useState } from 'react';
import { Button, CircularProgress, Dialog, Grid, Grow, Toolbar, Typography } from '@material-ui/core';
import { ProcedureForm } from 'circulator/components/Procedure/ProcedureForm';
import PropTypes from 'prop-types';

export const ProcedureDialog = ({ procedure, open, error, onCancel, onStart }) => {
    const [formProcedure, setFormProcedure] = useState(procedure);
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = updatedProcedure => {
        setFormProcedure(updatedProcedure);
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleStart = () => {
        setIsSaving(true);
        onStart(formProcedure);
    };

    return (
        <Dialog fullWidth maxWidth="xl" open={open} TransitionComponent={Grow}>
            <Toolbar style={{ marginTop: '1em' }}>
                <Grid container>
                    <Grid container item xs={8} justifyContent="flex-start" alignContent="center">
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {formProcedure.getLabel()}
                        </Typography>
                    </Grid>
                    <Grid container item xs={4} justifyContent="flex-end" alignContent="center" spacing={1}>
                        <Grid container item xs={6} justifyContent="flex-end" alignContent="center">
                            <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={handleCancel} disabled={isSaving}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid container item xs={6} justifyContent="flex-start" alignContent="center">
                            <Button
                                style={{ width: '100%' }}
                                variant="contained"
                                color="primary"
                                disabled={!formProcedure.isSavable() || !formProcedure.isDateTimeValid() || isSaving}
                                onClick={handleStart}
                            >
                                Start
                                {isSaving && (
                                    <>
                                        &nbsp;&nbsp;
                                        <CircularProgress size={15} thickness={8} color="primary" />
                                    </>
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <div style={{ padding: '1em' }}>
                <ProcedureForm procedure={formProcedure} onChange={handleChange} error={error} />
            </div>
        </Dialog>
    );
};

ProcedureDialog.propTypes = {
    procedure: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
};
