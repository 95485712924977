import { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogContentText, Typography, makeStyles } from '@material-ui/core';
import { useListRoles } from 'client/hooks/user/userListRoles';
import { useUpsertUserRoles } from 'client/hooks/user/useUpsertUserRoles';

const styles = {
    btnCancel: {
        border: '1px solid #2b333f',
        color: '#2b333f',
        '&:active': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:hover': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:focus': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:disabled': {
            backgroundColor: '#eee !important',
            color: '#ccc !important',
        },
    },
    btnCOnfirm: {
        marginLeft: '5px',
    },
};

const useStyles = makeStyles(styles);

interface IRoleAssignmentDialog {
    open: boolean;
    userId: string;
    userRoles: string[];
    update: (data: IUserRolesData) => void;
    close: () => void;
}

export interface IUserRolesData {
    userId: string;
    roles: string[];
}

export const RoleAssignmentDialog = ({ open, userId, userRoles, update, close }: IRoleAssignmentDialog) => {
    const classes = useStyles();
    const { roles } = useListRoles();
    const [acceptedRoles, setAcceptedRoles] = useState<string[]>([]);
    const { updateUserRoles } = useUpsertUserRoles();
    const [isProgress, setProgress] = useState(false);

    useEffect(() => {
        const rolesList = [...userRoles];
        setAcceptedRoles(rolesList);
        setProgress(false);
    }, [userRoles]);

    const handleAddAcceptedRole = (newRole: string) => {
        const roles = [...acceptedRoles, newRole];
        setAcceptedRoles(roles);
    };
    const handleRemoveAcceptedRole = (role: string) => {
        const roles = acceptedRoles.filter(r => r !== role);
        setAcceptedRoles(roles);
    };

    const handleRoleChange = (newRole: string) => {
        if (acceptedRoles.includes(newRole)) {
            handleRemoveAcceptedRole(newRole);
        } else {
            handleAddAcceptedRole(newRole);
        }
    };

    const handelConfirm = async () => {
        setProgress(true);
        const data: IUserRolesData = {
            userId: userId,
            roles: acceptedRoles,
        };
        const res = await updateUserRoles(data);
        if (res.success === true) {
            update(data);
        }
    };

    return (
        <>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText color="primary">
                        <Box p={2} style={{ width: '25vw' }}>
                            <Typography variant="h6">Roles Assignment</Typography>
                            <Typography>Please select roles to assign. Click confirm to save changes.</Typography>
                            <Box p={1}></Box>
                            {roles
                                .filter((x: string) => x !== 'PATIENT')
                                .map((role: string) => (
                                    <Box key={role} display="flex" alignItems="center">
                                        <Checkbox
                                            id={`${role}-checkbox`}
                                            name={`${role}-checkbox`}
                                            color="primary"
                                            required={false}
                                            checked={acceptedRoles.includes(role)}
                                            onChange={() => handleRoleChange(role)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <Typography variant="body1">{role}</Typography>
                                    </Box>
                                ))}
                            <Box p={1}></Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, justifyContent: 'flex-start' }}>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    color="secondary"
                                    onClick={close}
                                    disabled={isProgress}
                                    className={classes.btnCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    autoFocus
                                    variant="contained"
                                    size="medium"
                                    onClick={handelConfirm}
                                    disabled={isProgress}
                                    className={classes.btnCOnfirm}
                                >
                                    Confirm
                                    <Box sx={{ ml: 1, mr: 1 }}>{isProgress ? <CircularProgress size={'0.8rem'} color="inherit" /> : <></>}</Box>
                                </Button>
                            </Box>
                            <Box p={2}></Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};
