import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Response = api.UserRolesResponse;

export interface UserCutsUpdateRequest {
    userId: string;
    canUseCuts: boolean;
}

export const useUpsertUserCuts = () => {
    const { path: updatePath } = config.user.updateCuts();
    const updateCuts = useMutation((data: UserCutsUpdateRequest) => Api.post<Response>({ path: updatePath, body: data }));
    return {
        updateCuts: updateCuts.mutateAsync,
    };
};
