export const ToDateShort = (date: Date) => {
    const year = date.toLocaleString('default', { year: 'numeric' });
    const month = date.toLocaleString('default', {
        month: '2-digit',
    });
    const day = date.toLocaleString('default', { day: '2-digit' });
    return [year, month, day].join('-');
};

export const ToTimeShort = (date: Date) => {
    const hour = date.toLocaleString('default', { hour: '2-digit', hour12: false });
    const m = date.toLocaleString('default', {
        minute: 'numeric',
    });
    const s = date.toLocaleString('default', { second: 'numeric' });
    const minute = Number(m) < 0 ? '00' : ('0' + m).slice(-2);
    const second = Number(s) < 0 ? '00' : ('0' + s).slice(-2);
    return [hour, minute, second].join(':');
};

export const GetTimeSpent = (start?: string | null, end?: string | null) =>
    start && end ? new Date(end).getTime() - new Date(start).getTime() : null;

export const GetTime = (date?: string) => (date ? new Date(date).getTime() : null);

export const GetTimeFromDateString = (date?: string | null) => {
    if (date) {
        const newDate = new Date(date);
        const newTime = ToTimeShort(newDate);
        return newTime;
    }
    return null;
};
