import React from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import { Link } from '@mui/material';
import * as amplify from 'aws-amplify';
import { WebConfig } from 'web-config';

const S3DownloadLink = ({ s3Key, s3FileName, showFilename = true }: { s3Key: string; s3FileName?: string; showFilename?: boolean }) => {
    const config = useConfig<WebConfig>();
    const handleDownload = async () => {
        try {
            // Get the download URL using Amplify's Storage.get method
            const signedURL = await amplify.Storage.get(s3Key, {
                level: 'public',
                region: config.additionalConfig?.storage.AWSS3.region,
                bucket: config.additionalConfig?.storage.AWSS3.streamArchiveBucket,
                download: false,
                contentType: 'video/mp4', // Replace with the appropriate content type for your video file
                customPrefix: {
                    public: '',
                },
                contentDisposition: `attachment; filename="${s3FileName || 'video.mp4'}"`,
            });

            // Trigger the download
            const link = document.createElement('a');
            link.href = signedURL;
            link.setAttribute('download', s3FileName || 'download');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error while downloading the file:', error);
        }
    };

    return <Link component="button" onClick={handleDownload}>{`Download ${showFilename ? s3FileName : ''}`}</Link>;
};

export default S3DownloadLink;
