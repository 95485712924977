import { makeStyles } from '@material-ui/core';
import ChevronDown from 'img/chevron-down.png';
import ChevronUp from 'img/chevron-up.png';

// @ts-ignore
const styles = theme => ({
    root: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    toolbar: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: '1em',
        marginBottom: '1em',
        marginLeft: 0,
        marginRight: 0,
        background: theme.palette.primary.light,
    },
    imageList: {
        width: 300,
        height: 150,
    },
    outlinedWhite: {
        '& .MuiFormLabel-root': {
            color: 'white !important',
        },
        '& > .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white !important',
            },
            color: 'white !important',
        },
    },
    cover: {
        width: 250,
        height: '100%',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    darkText: {
        color: theme.palette.secondary.contrastText,
    },
    blueLink: {
        color: theme.palette.info.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textDecoration: 'none',
    },
    chipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    facilityContentCard: {
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        padding: '15px',
        borderRadius: '15px',
    },
    imageOverlay: {
        background: 'linear-gradient(0deg, rgba(29,29,29,0.9) 0%, rgba(6,6,6,0.02) 81%)',
    },
    imgAvatar: {
        width: '200px',
        height: '150px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#eee',
        '& img': {
            width: '100%',
            height: '150px',
        },
    },
    boxDoctors: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
    },
    boxDoctorsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        marginBottom: '5px',
    },
    boxDoctorsItem: {
        display: 'block',
        width: '220px',
        height: '125px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#eee',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            border: '2px solid #eee',
            minHeight: '10px',
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#333',
            },
        },
        '&::-webkit-scrollbar-button': {
            background: 'no-repeat #eee',
            backgroundSize: '4px',
            backgroundPosition: 'center',
            '&:vertical:decrement': {
                backgroundImage: `url(${ChevronUp})`,
            },
            '&:vertical:increment': {
                backgroundImage: `url(${ChevronDown})`,
            },
        },
    },
    boxDoctorsInfo: {
        width: '45%',
        height: '80px',
        display: 'inline-flex',
        flexDirection: 'column',
        margin: '5px',
        marginBottom: '20px',
    },
    boxDoctorAvatar: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '50%',
    },
    boxDoctorAvatarImg: {
        width: '90px',
        height: '90px',
        borderRadius: '50%',
    },
    boxDoctorAvatarName: {
        fontSize: '9px',
        textAlign: 'center',
        padding: '5px 0',
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
    },
    textSecondary: {
        color: '#ff5722',
        fontSize: '11px',
        marginTop: '7px',
    },
    websiteLink: {
        color: '#ff5722',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '11px',
    },
});

// @ts-ignore
export const useStyles = makeStyles(styles);
