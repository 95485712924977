import { config } from 'circulator/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

type Response = api.GetProcedureByRoomNumberResponse;
type Request = api.GetProcedureByRoomNumberRequest;
interface UseGetProcedureByRoomNumberProps {
    body: Request;
    enabled: boolean;
}
export function useGetProcedureByRoomNumber(props: UseGetProcedureByRoomNumberProps) {
    const { roomNumber } = props.body;
    const { key, path } = config.tv.room(roomNumber);

    const result = useQuery(key, () => Api.get<Response>({ path }), {
        enabled: isNotNullOrUndefined(roomNumber) && props.enabled,
        retry: true,
        refetchOnReconnect: true,
        retryDelay: 10000,
        refetchInterval: 30000,
    });

    return {
        procedure: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
