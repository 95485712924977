import { useState } from 'react';
import { StarRate, StarBorder } from '@mui/icons-material';
import { useAddFavoriteMutation, useRemoveFavoriteMutation } from 'client/hooks/videoExport/useFavoriteMutation';

interface ExportFavoriteIconProps {
    procedureId: number;
    isFavorite: boolean;
    favoritesId: number;
    jobId: string;
}
export const ExportFavoriteIcon: React.FC<ExportFavoriteIconProps> = ({ procedureId, isFavorite, favoritesId, jobId }) => {
    const [isFavoriteData, setIsFavoriteData] = useState<boolean>(isFavorite);
    const { mutateAsync: addFavorite } = useAddFavoriteMutation();
    const { mutateAsync: removeFavorite } = useRemoveFavoriteMutation(procedureId);
    const handleFavorite = async (procedureId: number, isFavorite: boolean, favoritesId?: number, jobId?: string) => {
        if (isFavorite && favoritesId) {
            setIsFavoriteData(false);
            await removeFavorite(favoritesId);
        } else {
            const data = { procedureId, jobId };
            setIsFavoriteData(true);
            await addFavorite(data);
        }
    };

    return isFavoriteData ? (
        <span style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', color: '#A2D45E' }}>
            <StarRate onClick={() => handleFavorite(procedureId, isFavorite, favoritesId, jobId)} />
        </span>
    ) : (
        <span style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
            <StarBorder onClick={() => handleFavorite(procedureId, isFavorite, favoritesId, jobId)} />
        </span>
    );
};
