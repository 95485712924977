import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const AboutIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 32 32">
        <g transform="translate(-70.000000, -613.000000)">
            <path d="M86,613 C77.1473778,613 70,620.147378 70,629 C70,637.852622 77.1473778,645 86,645 C94.8526222,645 102,637.852622 102,629 C102,620.147378 94.8526222,613 86,613 Z M87.8486044,636.325156 L84.1515378,636.325156 L84.1515378,627.222222 L87.8141156,627.222222 L87.8141156,636.325156 L87.8486044,636.325156 Z M86,625.693333 C84.8972089,625.693333 83.9736178,624.804444 83.9736178,623.666951 C83.9736178,622.56416 84.8625067,621.640569 86,621.640569 C87.1027911,621.640569 88.0263822,622.529458 88.0263822,623.666951 C88.0263822,624.804444 87.1027556,625.693333 86,625.693333 Z" />
        </g>
    </SvgIcon>
);
