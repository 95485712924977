import { useEffect, useMemo, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useGetAttendee } from 'client/hooks/attendee/useGetAttendee';
import { useMaster } from 'common/hooks/WebRTC';
import { MasterType } from 'common/hooks/WebRTC/master-controller';
import Attendee from 'common/models/Attendee';
import { VideoCamIcon, VideoCamOffIcon, MicIcon, MicOffIcon } from 'common/utils/Icons';
import noVideo from 'img/video-placeholder.jpg';
import theme from '../../App/theme';
import { useLayouts } from '../hooks/styles/useLayouts';
import { useStyles } from '../hooks/styles/useStyles';

const useCustomStyles = makeStyles({
    splash: {
        '&::before': {
            content: '""',
            height: '100%',
            width: '100%',
            position: 'fixed',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            backgroundColor: '#000',
            backgroundImage: 'url(./img/splash_2.jpg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.2,
            zIndex: -1,
        },
    },
    w100: {
        width: '100%',
    },
    videoDimensions: {
        width: '200px',
        height: '100px',
    },
    publisherTitle: {
        color: 'white',
    },
});

export interface PrimaryAttendeePublisherProps {
    procedureId: number;
}

export const PrimaryAttendeePublisher: React.FC<PrimaryAttendeePublisherProps> = props => {
    const customClasses = useCustomStyles();
    const layouts = useLayouts();
    const classes = useStyles();
    const videoElementId = 'local-view';

    const [isPublishingVideo, setIsPublishingVideo] = useState(false);
    const [isPublishingAudio, setIsPublishingAudio] = useState(false);

    // const { feature, unfeature } = useMutateUser();
    // const { user } = useUser();

    const { attendee } = useGetAttendee(props.procedureId);
    const outputAccess = useMemo(() => (attendee?.publishAccess ? Attendee.ACCESS_FULL : Attendee.ACCESS_NONE), [attendee]);

    const { controller } = useMaster({
        videoElementId,
        streamId: attendee!.publishStreamId!,
        publishUrl: attendee!.publishUrl!,
        token: attendee!.publishToken!,
        iceServers: attendee!.publishIceServers!,
        isPublishingVideo,
        isPublishingAudio,
        canPublishVideo: true,
        masterType: MasterType.FEATURED,
    });

    useEffect(() => {
        if (isPublishingVideo && controller) {
            controller.addVideo();
        } else if (!isPublishingVideo && controller) {
            controller.removeVideo();
        }
        // disable here to prevent unwanted re-renders. Controller changes a lot, so we don't want to re-run this effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPublishingVideo]);

    useEffect(() => {
        if (isPublishingAudio && controller) {
            controller.addAudio();
        } else if (!isPublishingAudio && controller) {
            controller.removeAudio();
        }
        // disable here to prevent unwanted re-renders. Controller changes a lot, so we don't want to re-run this effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPublishingAudio]);

    const toggleVideo = () => {
        setIsPublishingVideo(!isPublishingVideo);
    };
    const toggleAudio = () => {
        setIsPublishingAudio(!isPublishingAudio);
    };

    return (
        <Box className={layouts.verticalContainer} bgcolor="black">
            <Box style={{ flex: '0 0 auto', paddingLeft: '1em' }} bgcolor={theme.palette.primary.dark}>
                <Typography variant="caption" className={customClasses.publisherTitle}>
                    Moderator Publisher
                </Typography>
            </Box>
            <div className={classes.videoFrameSmall}>
                <video id={videoElementId} autoPlay playsInline poster={noVideo} className={customClasses.videoDimensions} muted />

                <Button
                    className={[layouts.bottomLeft, classes.glassyButton].join(' ')}
                    disabled={outputAccess !== Attendee.ACCESS_FULL}
                    onClick={toggleVideo}
                >
                    {isPublishingVideo ? <VideoCamIcon /> : <VideoCamOffIcon />}
                </Button>

                <Button
                    className={[layouts.bottomRight, classes.glassyButton].join(' ')}
                    disabled={outputAccess !== Attendee.ACCESS_FULL}
                    onClick={toggleAudio}
                >
                    {isPublishingAudio ? <MicIcon /> : <MicOffIcon />}
                </Button>
            </div>
        </Box>
    );
};
