import { makeStyles } from '@material-ui/core';

export const useLayouts = makeStyles({
    verticalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > *': {
            flex: '1 1 auto',
        },
    },
    hidden: {
        display: 'none',
    },
    bottomLeft: { position: 'absolute', bottom: 'max(3%, 5px)', left: 'max(3%, 5px)', zIndex: 1112 },
    bottomRight: { position: 'absolute', bottom: 'max(3%, 5px)', right: 'max(3%, 5px)', zIndex: 1112 },
});
