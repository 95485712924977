import React from 'react';
import { Divider, FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useGetFacilityById } from 'client/hooks/facilities/useGetFacilityById';
import Procedure from 'common/models/Procedure';
import * as PartnerServices from 'common/services/partnerServices';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

export const ProcedureForm = props => {
    const { procedure, onChange, error } = props;
    const [procedureOptions, setProcedureOptions] = React.useState([]);
    const [companyOptions, setCompanyOptions] = React.useState([]);
    const [brandOptions, setBrandOptions] = React.useState([]);
    const [deviceOptions, setDeviceOptions] = React.useState([]);
    const { facility, isLoading } = useGetFacilityById(procedure.device.facility.id);
    const doctors = facility?.FacilityUsers?.map(x => x.doctor) ?? [];

    const handleOnAutofillChange = key => (event, newValue) => {
        let data = {};
        if (event.key === undefined) {
            switch (key) {
                case 'doctorName':
                    const clonedProcedure = procedure.clone();
                    clonedProcedure.value('doctorName', newValue.name);
                    clonedProcedure.value('doctorId', newValue.id);
                    onChange(clonedProcedure);
                    break;
                case 'deviceName':
                    data = newValue.value;
                    onChange(procedure.clone().value(key, data));
                    break;
                case 'brandName':
                    data = newValue.value;
                    onChange(procedure.clone().value(key, data));
                    break;
                case 'companyName':
                    data = newValue.value;
                    onChange(procedure.clone().value(key, data));
                    break;
                case 'title':
                    data = newValue.value;
                    onChange(procedure.clone().value(key, data));
                    break;
                default:
                    break;
            }
        } else {
            onChange(procedure.clone().value(key, event.target.value));
        }
    };

    const handleOnChange = key => event => {
        onChange(procedure.clone().value(key, event.target.value));
    };

    const handleCheckboxChange = key => event => {
        onChange(procedure.clone().value(key, event.target.checked));
    };

    const { iLoading: isLoadingOptions } = useQuery(['partners'], PartnerServices.getAllPartnersService, {
        enabled: true,
        onSuccess: success => {
            const allOptions = success.data || [];
            setProcedureOptions(allOptions.filter(option => option.category === 'PROCEDURE'));
            setCompanyOptions(allOptions.filter(option => option.category === 'COMPANY'));
            setBrandOptions(allOptions.filter(option => option.category === 'BRAND'));
            setDeviceOptions(allOptions.filter(option => option.category === 'DEVICE'));

            setProcedureOptions(procedureOptions => [...procedureOptions, { id: 0, value: 'Unknown' }]);
        },
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Autocomplete
                    key={error}
                    options={doctors}
                    loading={isLoading}
                    getOptionLabel={option => option.name}
                    onKeyDown={handleOnAutofillChange('doctorName')}
                    onClick={handleOnAutofillChange('doctorName')}
                    onChange={handleOnAutofillChange('doctorName')}
                    renderInput={params => (
                        <TextField
                            {...params}
                            id="doctorName"
                            name="doctorName"
                            value={procedure.doctorName}
                            label="Doctor Name"
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            autoComplete="Doctor Name"
                            onChange={handleOnChange('doctorName')}
                            helperText="Please select a Doctor"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    key={error}
                    options={procedureOptions}
                    loading={isLoadingOptions}
                    getOptionLabel={option => option.value}
                    freeSolo
                    onKeyDown={handleOnAutofillChange('title')}
                    onClick={handleOnAutofillChange('title')}
                    onChange={handleOnAutofillChange('title')}
                    renderInput={params => (
                        <TextField
                            {...params}
                            id="title"
                            name="title"
                            value={procedure.title}
                            label="Surgery Title"
                            required
                            variant="outlined"
                            margin="none"
                            fullWidth
                            autoComplete="Surgery Title"
                            onChange={handleOnChange('title')}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    key={error}
                    id="patientInitials"
                    name="patientInitials"
                    value={procedure.patientInitials}
                    label="Patient Initials"
                    variant="outlined"
                    margin="none"
                    fullWidth
                    autoComplete="Patient Initials"
                    onChange={handleOnChange('patientInitials')}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    key={error}
                    id="referenceNumber"
                    name="referenceNumber"
                    value={procedure.referenceNumber}
                    label="Reference Number"
                    variant="outlined"
                    margin="none"
                    fullWidth
                    autoComplete="Reference Number"
                    onChange={handleOnChange('referenceNumber')}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    key={error}
                    id="notes"
                    name="notes"
                    value={procedure.notes}
                    label="Notes/Comments"
                    variant="outlined"
                    margin="none"
                    fullWidth
                    multiline
                    minRows={3}
                    autoComplete="Notes or Comments"
                    onChange={handleOnChange('notes')}
                />
            </Grid>
            <Divider />
            <Grid item xs={4}>
                <Autocomplete
                    key={error}
                    options={companyOptions}
                    loading={isLoadingOptions}
                    getOptionLabel={option => option.value}
                    freeSolo
                    onKeyDown={handleOnAutofillChange('companyName')}
                    onClick={handleOnAutofillChange('companyName')}
                    onChange={handleOnAutofillChange('companyName')}
                    renderInput={params => (
                        <TextField
                            {...params}
                            id="companyName"
                            name="companyName"
                            value={procedure.companyName}
                            label="Company Name"
                            variant="outlined"
                            margin="none"
                            fullWidth
                            autoComplete="Company"
                            onChange={handleOnChange('companyName')}
                            helperText="Please select a Company"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    key={error}
                    options={brandOptions}
                    loading={isLoadingOptions}
                    getOptionLabel={option => option.value}
                    freeSolo
                    onKeyDown={handleOnAutofillChange('brandName')}
                    onClick={handleOnAutofillChange('brandName')}
                    onChange={handleOnAutofillChange('brandName')}
                    renderInput={params => (
                        <TextField
                            {...params}
                            id="brandName"
                            name="brandName"
                            value={procedure.brandName}
                            label="Brand Name"
                            variant="outlined"
                            margin="none"
                            fullWidth
                            autoComplete="Brand Name"
                            onChange={handleOnChange('brandName')}
                            helperText="Please select a Brand"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    key={error}
                    options={deviceOptions}
                    loading={isLoadingOptions}
                    getOptionLabel={option => option.value}
                    freeSolo
                    disableCloseOnSelect
                    onKeyDown={handleOnAutofillChange('deviceName')}
                    onClick={handleOnAutofillChange('deviceName')}
                    onChange={handleOnAutofillChange('deviceName')}
                    renderInput={params => (
                        <TextField
                            {...params}
                            id="deviceName"
                            name="deviceName"
                            value={procedure.deviceName}
                            label="Device Name"
                            variant="outlined"
                            margin="none"
                            fullWidth
                            autoComplete="Device Name"
                            onChange={handleOnChange('deviceName')}
                            helperText="Please select a Device"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={8}>
                <FormControlLabel
                    control={
                        <Grid component="label" container alignItems="center" spacing={0} style={{ paddingLeft: '30px' }}>
                            <Grid item>Off</Grid>
                            <Grid item>
                                <Switch
                                    checked={procedure.notify}
                                    onChange={handleCheckboxChange('notify')}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Grid>
                            <Grid item>On</Grid>
                        </Grid>
                    }
                    label="Notify&nbsp;users"
                    labelPlacement="start"
                />
            </Grid>
        </Grid>
    );
};

ProcedureForm.propTypes = {
    procedure: PropTypes.instanceOf(Procedure).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ProcedureForm;
