/* eslint-disable react/jsx-fragments */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import PropTypes from 'prop-types';

const styles = {
    splash: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#000',
        backgroundImage: 'url(./img/splash.jpg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
};

const useStyles = makeStyles(styles);
export const Splash = ({ showLoader }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.splash} />
            <ProcessingOverlay caption="Validating..." enabled={showLoader} />
        </>
    );
};

Splash.propTypes = {
    showLoader: PropTypes.bool,
};

Splash.defaultProps = {
    showLoader: false,
};
