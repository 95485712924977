import { config } from 'client/hooks/config';
import { ExportStreams } from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = Array<ExportStreams>;
export function useGetExportList(enabled = true): { data: Response | undefined; isLoading: boolean } {
    const { key, path } = config.videoexport.jobList();
    const { data, isLoading } = useQuery(key, () => Api.get<Response>({ path }), {
        refetchInterval: 4000,
        enabled,
    });

    return {
        data: data,
        isLoading: isLoading,
    };
}
