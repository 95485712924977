import * as amplify from 'aws-amplify';
import Profile from 'common/models/Profile';

export const updateProfile = async (profile: Profile) => {
    const user = await amplify.Auth.currentAuthenticatedUser();
    return amplify.Auth.updateUserAttributes(user, profile.toCognitoAttributes());
};

export const getProfile = async () => {
    const user = await amplify.Auth.currentAuthenticatedUser();

    return user.attributes;
};
