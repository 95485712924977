import { Button, Link, makeStyles, Box } from '@material-ui/core';
import { PromotionForm } from 'client/components/PromotionForm';
import logo from 'img/logo-color.svg';

export function Footer() {
    const classes = useStyles();

    return (
        <footer>
            {/* Used to draw outside the box. */}
            <div className={classes.outside}></div>
            <div className={classes.content}>
                <img src={logo} alt="logo" width={300} />
                <Box m="auto">
                    <PromotionForm />
                </Box>
                <div className={classes.nav}>
                    <Button component={Link} href="https://vsurgic.com/">
                        Corporate
                    </Button>
                </div>
            </div>
        </footer>
    );
}

const useStyles = makeStyles(theme => ({
    outside: {
        background: 'transparent',
        borderBottomLeftRadius: 80,
        boxShadow: '-50px 50px white',
        height: 160,
        marginTop: -160,
        width: 160,
        zIndex: 0,
    },
    content: {
        display: 'grid',
        alignContent: 'center',
        alignItems: 'center',
        background: 'white',
        borderBottomLeftRadius: 50,
        color: theme.palette.primary.dark,
        gap: theme.spacing(2),
        gridTemplateColumns: 'repeat(3, 1fr)',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 6),

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            justifyItems: 'center',
        },
    },
    nav: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
    },
}));
