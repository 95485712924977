import { useState } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { MasterController } from 'common/hooks/WebRTC/master-controller';
import { MicIcon, MicOffIcon } from 'common/utils/Icons';

const useStyles = makeStyles({
    muteButtonContainer: {
        top: 0,
        right: 0,
        position: 'absolute',
        zIndex: 50,
    },
    muteButton: {
        fontSize: '2rem !important',
        color: 'white !important',
        width: '50px !important',
        height: '50px !important',
    },
});

type MuteButtonProps = {
    controller?: MasterController;
    isMuted?: boolean;
    className?: string;
    variant?: 'contained' | 'outlined';
    color?: 'primary' | 'secondary';
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    fullWidth?: boolean;
};

export const MuteButton: React.FC<MuteButtonProps> = props => {
    const classes = useStyles();
    const [isMuted, setIsMuted] = useState(props.isMuted || false);
    const [isToggling, setToggling] = useState(false);

    const handleMute = async () => {
        const { controller } = props;
        try {
            setToggling(true);
            if (controller) {
                if (isMuted) {
                    await controller.addAudio();
                } else {
                    await controller.removeAudio();
                }
                setIsMuted(!isMuted);
            }
        } catch (err) {
            console.error('Error while toggling audio: ', err);
        } finally {
            setToggling(false);
        }
    };
    return (
        <Box className={classes.muteButtonContainer} onClick={handleMute}>
            {!isMuted ? (
                <Box>
                    <Button disabled={isToggling}>
                        <MicIcon className={classes.muteButton} />
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Button disabled={isToggling}>
                        <MicOffIcon className={classes.muteButton} />
                    </Button>
                </Box>
            )}
        </Box>
    );
};
