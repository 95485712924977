import { Box, Link, Typography } from '@material-ui/core';
import Panel from 'common/components/UI/Panel';
import logo from 'img/logo-color.svg';

export const AboutPage = () => {
    return (
        <Panel elevation={0}>
            <Typography variant="h5">About</Typography>
            <Box py={4}>
                <Link href="https://www.vsurgic.com">
                    <img src={logo} alt="VSurgic" width={300} />
                </Link>
            </Box>
        </Panel>
    );
};
