import React from 'react';
import { Avatar, Box, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
import { useProfile } from 'client/hooks/profile/useProfile';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import placeholder from 'img/profile-placeholder.png';

const styles = {
    profileImg: {
        textAlign: 'center',
        '& button': {
            transition: 'none',
            '&:hover': {
                borderRadius: '50%',
                backgroundColor: theme.palette.secondary.light,
            },
        },
    },
    highlightImage: {
        maxHeight: '60px',
        maxWidth: '60px',
        width: 'auto',
        height: '100%',
        margin: '0 auto',
    },
    logo: {
        maxWidth: '60px',
        maxHeight: '50px',
        marginLeft: '3rem',
        position: 'absolute',
        marginTop: '64px',
    },
    h100: {
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        padding: '10px !important',
        columnGap: 10,
    },
    boxInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
    },
};
// @ts-ignore
const useStyles = makeStyles(styles);

export const ProfileInfo = () => {
    const classes = useStyles();

    const { profile } = useProfile();
    const profilePicture = useGetProtectedObject(profile.picture.url);
    const companyLogo = useGetProtectedObject(profile.companyLogo.url);

    return (
        <Card elevation={0} style={{ height: '132px' }}>
            <CardContent className={classes.h100}>
                <Avatar alt={profile.getLabel()} src={profilePicture ?? placeholder} className={classes.highlightImage} />
                {companyLogo && <img src={companyLogo} className={classes.logo} alt={profile.company ?? 'Company Name'} />}
                <Box className={classes.boxInfo}>
                    <Typography variant="body1">{profile.getLabel()}</Typography>
                    <Typography variant="caption" style={{ fontWeight: 300 }}>
                        {profile.email}
                    </Typography>
                    <Typography variant="caption" style={{ fontWeight: 300 }}>
                        {profile.phone}
                    </Typography>
                    <Typography variant="caption" style={{ fontWeight: 300 }}>
                        {profile.company} {profile.position && `(${profile.position})`}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};
