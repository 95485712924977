import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';
import { Facility, FacilityUsers } from '../../../common/types';
import { Doctor } from '../../../common/types/doctor';

type Response = api.SearchFacilitiesResponse;
type Request = api.SearchFacilitiesRequest;

export function useListFacilities(body: Request) {
    const { key, path } = config.facilities.list();

    const result = useQuery([key, body], () => Api.post<Response>({ path, body }), {});
    const doctors: Doctor[] = result.data
        ? result.data.flatMap((facility: Facility) => facility.FacilityUsers.map((facilityDoctor: FacilityUsers) => facilityDoctor.doctor))
        : [];

    const uniqueSurgeryTypes = doctors.reduce((acc: Set<string>, doctor: Doctor) => {
        for (const surgeryType of doctor.surgeryTypes) {
            acc.add(surgeryType);
        }
        return acc;
    }, new Set<string>());

    return {
        facilities: result.data ?? [],
        doctors,
        surgeryTypes: Array.from(uniqueSurgeryTypes),
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
