import { FC, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useClipComment } from 'client/hooks/clips/useClipComment';
import { useClipCommentAdd } from 'client/hooks/clips/useClipCommentAdd';
import { useUser } from 'client/hooks/user/useUser';
import { IVideoClipComment } from 'common/types';

interface CommentSectionProps {
    jobId: string;
}

const CommentSection: FC<CommentSectionProps> = ({ jobId }) => {
    const { user } = useUser();
    const { data: commentsData, refetch } = useClipComment(jobId ?? '');
    const { addComment } = useClipCommentAdd();
    const [comments, setComments] = useState<IVideoClipComment[]>([]);
    const [newComment, setNewComment] = useState('');

    const handleAddComment = async () => {
        const comment = {
            jobId,
            userId: user?.attributes.sub ?? '',
            comment: newComment,
        };

        await addComment(comment);

        setComments([comment, ...comments]);
        setNewComment('');

        refetch();
    };

    return (
        <Box>
            <TextField
                label="Add a comment"
                value={newComment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewComment(event.target.value);
                }}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
            />
            <Button variant="contained" color="primary" onClick={handleAddComment} style={{ marginTop: 10 }}>
                Add Comment
            </Button>
            <Box mt={3} sx={{ border: '1px solid #ddd', padding: 2, borderRadius: 1 }}>
                {commentsData && commentsData.length > 0 ? (
                    <Box>
                        <Typography variant="h6">Comments</Typography>
                        {commentsData.map((comment, index) => (
                            <Box key={index} p={1} borderBottom="1px solid #ddd">
                                <Typography variant="body2" color="textSecondary">
                                    <strong>User:</strong> {comment.userInfo?.firstName} {comment.userInfo?.lastName}
                                </Typography>
                                <Typography variant="body1">{comment.comment}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    <strong>Time:</strong> {new Date(comment.createdAt ?? '').toLocaleString()}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box>No comments</Box>
                )}
            </Box>
        </Box>
    );
};

export default CommentSection;
