import { useEffect, useState } from 'react';
import { CircularProgress, FormControl, FormControlLabel, Grid, Select, Switch, Typography } from '@material-ui/core';
import { TextField } from '@mui/material';
import { useGetActiveProcedure } from 'circulator/hooks/procedure/useGetActiveProcedure';
import { useCanUseCuts } from 'client/hooks/mobile/useCanUseCuts';
import { StartMobileProcedureButton } from 'mobile/components/StartMobileProcedureButton';
import { BackgroundWrapper } from 'mobile/components/UI/BackgroundWrapper';
import { useListFacilitiesForCuts } from 'mobile/hooks/facility/useListFacilitiesForCuts';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import useMobileClasses from 'mobile/hooks/useMobileStyles';
import { useNavigate } from 'react-router-dom';
import CutsLogo from '../../img/cuts-logo.png';

export const StartShotsPage = () => {
    const mobileClasses = useMobileClasses();
    const layouts = useMobileLayouts();
    const navigate = useNavigate();
    const { procedure, isLoading: isProcedureLoading } = useGetActiveProcedure();
    const { isLoading: isCutsLoading, userDisplayName } = useCanUseCuts(true);
    const { facilities, isLoading: isFacilitiesLoading } = useListFacilitiesForCuts();
    const [sessionType, setSessionType] = useState('Public');
    const [facilityId, setFacilityId] = useState(0);
    const [patientInitials, setPatientInitials] = useState('NA');
    const [title, setTitle] = useState('');
    const [notify, setNotify] = useState(true);
    const isLoading = isCutsLoading || isFacilitiesLoading || isProcedureLoading;

    useEffect(() => {
        if (procedure) {
            navigate('/mobile/shot');
        }
    }, [procedure, navigate]);

    useEffect(() => {
        //when facilities are loaded, set the first facility as the default
        if (facilities && facilities.length > 0 && facilityId === 0) {
            setFacilityId(facilities[0].id);
        }
    }, [facilities, isFacilitiesLoading, facilityId]);

    const onSessionTypeSwitch = () => {
        setSessionType(sessionType === 'Private' ? 'Public' : 'Private');
    };

    return (
        <BackgroundWrapper headerImage={CutsLogo} backButtonVisible={true} backButtonHref="/mobile/create-or-archives">
            {isLoading && <CircularProgress size={20} />}
            {!isLoading && (
                <div className={layouts.verticalContainer}>
                    <Typography className={mobileClasses.text}>Adjust the options below or select Start Session.</Typography>
                    <div className={`${layouts.dynamicContainer} ${layouts.padded}`}>
                        <div className={`${mobileClasses.dropdown} ${layouts.verticalContainer} ${layouts.padded}`}>
                            <div>
                                <Typography className={mobileClasses.text} style={{ textAlign: 'center' }}>
                                    Facility
                                </Typography>
                            </div>
                            <FormControl fullWidth variant="filled">
                                <Select
                                    native
                                    className={`${mobileClasses.dropdown}`}
                                    onInput={ev => setFacilityId(() => Number((ev.target as HTMLSelectElement).value))}
                                >
                                    {facilities?.map(facility => (
                                        <option key={facility.id} value={facility.id}>
                                            {facility.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={`${mobileClasses.dropdown} ${layouts.verticalContainer} ${layouts.padded}`}>
                            <div>
                                <Typography className={mobileClasses.text} style={{ textAlign: 'center' }}>
                                    Description
                                </Typography>
                            </div>
                            <FormControl fullWidth>
                                <TextField className={mobileClasses.input} variant="filled" onChange={e => setTitle(e.target.value)} />
                            </FormControl>
                        </div>
                    </div>
                    <div className={`${layouts.dynamicContainer} ${layouts.padded}`}>
                        <div className={`${layouts.verticalContainer} ${mobileClasses.dropdown}`}>
                            <div>
                                <Typography className={mobileClasses.text} style={{ textAlign: 'center' }}>
                                    ID
                                </Typography>
                            </div>
                            <TextField variant="outlined" value={patientInitials} onChange={e => setPatientInitials(e.target.value)} />
                            {false && (
                                <FormControlLabel
                                    control={<Switch color="secondary" onChange={onSessionTypeSwitch} checked={sessionType === 'Public'} />}
                                    label={sessionType}
                                    labelPlacement="top"
                                />
                            )}
                        </div>
                        <div className={layouts.verticalContainer}>
                            <FormControlLabel
                                control={
                                    <Grid component="label" container spacing={0}>
                                        <Grid item>Off</Grid>
                                        <Grid item>
                                            <Switch
                                                checked={notify}
                                                onChange={() => setNotify(!notify)}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Grid>
                                        <Grid item>On</Grid>
                                    </Grid>
                                }
                                label="Notify&nbsp;users"
                                labelPlacement="top"
                            />
                        </div>
                    </div>
                    <div className={`${layouts.dynamicContainer} ${layouts.padded}`}>
                        <div className={layouts.verticalContainer}>
                            <Typography className={mobileClasses.text} style={{ textAlign: 'center' }}>
                                By choosing Start Session, I confirm that I have received the required permission from all necessary parties to make
                                this recording.
                            </Typography>
                        </div>
                        <div className={layouts.verticalContainer}>
                            <StartMobileProcedureButton
                                isPublic={sessionType === 'Public'}
                                facilityId={facilityId}
                                patientInitials={patientInitials}
                                title={title}
                                drName={userDisplayName}
                                notify={notify}
                            />
                        </div>
                    </div>
                </div>
            )}
        </BackgroundWrapper>
    );
};
