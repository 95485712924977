import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export function useGetFavoriteInfo(procedureId: number) {
    const { key, path } = config.videoexport.getFavoriteInfo(procedureId);
    const result = useQuery<{ favoriteId?: number | null; favoriteType?: string | null }>(key, async () => await Api.get({ path }), {
        refetchOnWindowFocus: false,
    });
    return {
        data: result.data,
        isLoading: result.isLoading,
    };
}
