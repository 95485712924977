import AccessRequest from './AccessRequest';
import BasicArray from './BasicArray';

export default class AccessRequestArray extends BasicArray {
    get myClass() {
        return AccessRequestArray;
    }

    get myItemClass() {
        return AccessRequest;
    }

    constructor(items = []) {
        super(items);
    }

    comparator = (a, b) => {
        if (a && b) {
            const nameA = `${a.requester.fname}${a.requester.lname}`;
            const nameB = `${b.requester.fname}${b.requester.lname}`;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
        }
        return 0;
    };

    filterByStatus = status => new AccessRequestArray(this.filter(e => e.status === status));
}
