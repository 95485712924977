/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
import { useListDeviceProcedures } from 'client/hooks/device/useListDeviceProcedures';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';
import { ExpandMore } from 'common/utils/Icons';
import ADeviceProcedurePanel from './ADeviceProcedurePanel';
import DeviceFacility from './DeviceFacility';

interface IByRoomPanelProps {
    device: any;
}
const styles: any = {
    box: {
        border: '1px',
    },
    boxHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    boxAccepted: {
        backgroundColor: 'green',
    },
    boxAlarm: {
        backgroundColor: 'red',
    },
    boxInactive: {
        backgroundColor: theme.palette.grey[500],
    },
    boxHeaderText: {
        color: theme.palette.primary.contrastText,
    },
    w100: {
        width: '100%',
        display: 'block',
    },
    pcontainer: {
        maxHeight: '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollX: 'hidden',
    },
};

const useStyles = makeStyles(styles);

const ByRoomPanel = ({ device }: IByRoomPanelProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const { procedures } = useListDeviceProcedures(device.id, expanded);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary /*onClick={() => setExpanded(!expanded)}*/ expandIcon={<ExpandMore />} id="procedureHeader">
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography align="left" noWrap>
                                    {device.username}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography align="center" noWrap>
                                    {device.tv && `(${device.tv})`} {device.roomName} - {device.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography align="right" noWrap>
                                    {device.isActive ? 'Active' : 'Inactive'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.w100}>
                        <DeviceFacility classes={classes} device={device} enableAPI={expanded} />
                        <EmptyStateHandler data={procedures} keyword="procedures">
                            <ADeviceProcedurePanel procedures={procedures} enableAPI={expanded} />
                        </EmptyStateHandler>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default ByRoomPanel;
