import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useGetDeviceFacility } from 'client/hooks/device/useGetDeviceFacility';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';

export default function DeviceFacility({ classes, device, enableAPI }: any) {
    const { facility } = useGetDeviceFacility(device.facilityId, enableAPI);

    return (
        <EmptyStateHandler data={facility} keyword="facility">
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Facility
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {facility?.name} {`(${facility?.idNumber})`}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Facility Location
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {facility?.city}, {facility?.state}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Facility Phone
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {facility?.phone}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        <Box className={classes.boxHeader}>
                            <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                Facility Timezone
                            </Typography>
                        </Box>
                        <Typography align="center" variant="body1" noWrap>
                            {facility?.timeZone}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </EmptyStateHandler>
    );
}
