import { config } from 'client/hooks/config';
import { CreateWatermarkAndTextOverlay } from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
type Request = CreateWatermarkAndTextOverlay;
type Response = any;

const createOverlayFn = async (body: Request) => {
    const { path } = config.videoexport.createOverlay();
    const data = await Api.post<Response>({
        path,
        body,
    });
    return data;
};
export const useCreateVideoExportJob = () => {
    const queryClient = useQueryClient();
    const { key } = config.videoexport.jobList();

    const { mutateAsync, isLoading } = useMutation(createOverlayFn, {
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: key });
        },
    });
    return {
        createOverlay: mutateAsync,
        isLoading: isLoading,
    };
};
