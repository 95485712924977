import { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import { FavoriteBorder, FavoriteSharp, StarHalf, StarRate } from '@mui/icons-material';
import { useAddFavoriteMutation, useRemoveFavoriteMutation } from 'client/hooks/videoExport/useFavoriteMutation';
import { useGetFavoriteInfo } from 'client/hooks/videoExport/useGetFavoriteInfo';

interface ProcedureFavoriteIconProps {
    procedureId: number;
}
export const ProcedureFavoriteIcon: FC<ProcedureFavoriteIconProps> = ({ procedureId }) => {
    const { data, isLoading } = useGetFavoriteInfo(procedureId);
    const { mutateAsync: addFavorite } = useAddFavoriteMutation();
    const { mutateAsync: removeFavorite } = useRemoveFavoriteMutation(procedureId);
    const handleFavorite = async (procedureId: number, remove: boolean) => {
        if (remove && data?.favoriteId) {
            await removeFavorite(data.favoriteId);
        } else {
            const data = { procedureId, jobId: undefined };
            await addFavorite(data);
        }
    };
    if (!data) {
        return (
            <span style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '50px' }}>
                <FavoriteBorder onClick={() => handleFavorite(procedureId, false)} />
            </span>
        );
    }
    if (isLoading) return <CircularProgress />;
    switch (data?.favoriteType) {
        case 'procedure':
            return (
                <span style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', color: '#A2D45E', width: '50px' }}>
                    <FavoriteSharp onClick={() => handleFavorite(procedureId, true)} />
                </span>
            );
        case 'job':
            return (
                <span style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', color: '#A2D45E', width: '50px' }}>
                    <StarHalf onClick={() => handleFavorite(procedureId, false)} />
                </span>
            );
        case 'both':
            return (
                <span style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', color: '#A2D45E', width: '50px' }}>
                    <StarRate onClick={() => handleFavorite(procedureId, true)} />
                </span>
            );
        default:
            return (
                <span style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '50px' }}>
                    <FavoriteBorder onClick={() => handleFavorite(procedureId, false)} />
                </span>
            );
    }
};
