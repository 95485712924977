import React from 'react';
import { List } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import type { Attendee } from 'common/types/circulator';
import NoDataWrapper from '../../../common/components/NoDataWrapper/NoDataWrapper';
import { ProcedureRequestItem } from './ProcedureRequestItem';

interface Props {
    procedureId: number;
    attendees: Attendee[];
}

export const ProcedureRequestsPanel = ({ procedureId, attendees }: Props) => {
    return (
        <NoDataWrapper data={attendees}>
            <List>
                {attendees.map(attendee => (
                    <React.Suspense key={attendee.id} fallback={<Skeleton variant="rect" height={80} />}>
                        <ProcedureRequestItem key={attendee.id} procedureId={procedureId} attendee={attendee} />
                    </React.Suspense>
                ))}
            </List>
        </NoDataWrapper>
    );
};
