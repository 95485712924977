import { keys } from 'client/hooks/keys';
import {
    confirmResetUserPassword,
    confirmSignUp,
    createUser,
    logInUser,
    logOutUser,
    resetUserPassword,
    changePassword,
} from 'common/services/userServices';
import { useMutation, useQueryClient } from 'react-query';

export const useMutateUser = () => {
    const queryClient = useQueryClient();

    const reset = async () => {
        await queryClient.invalidateQueries(keys.user());
    };

    const createUserMutation = useMutation(createUser, {
        onSuccess: reset,
        onError: reset,
        onMutate: reset,
    });

    const confirmSignUpMutation = useMutation(confirmSignUp, {
        onSuccess: reset,
        onError: reset,
        onMutate: reset,
    });

    const logInUserMutation = useMutation(logInUser, {
        onSuccess: reset,
        onError: reset,
        onMutate: reset,
    });

    const logOutUserMutation = useMutation(logOutUser, {
        onSuccess: reset,
        onError: reset,
        onMutate: reset,
    });

    const resetUserPwMutation = useMutation(resetUserPassword, {
        onSuccess: reset,
        onError: reset,
        onMutate: reset,
    });

    const confirmResetUserPwMutation = useMutation(confirmResetUserPassword, {
        onSuccess: reset,
        onError: reset,
        onMutate: reset,
    });

    const changePasswordMutation = useMutation(changePassword);

    return {
        createUser: createUserMutation,
        confirmSignUp: confirmSignUpMutation,
        login: logInUserMutation,
        logout: logOutUserMutation,
        resetPw: resetUserPwMutation,
        confirmResetPw: confirmResetUserPwMutation,
        changePw: changePasswordMutation,
    };
};
