import { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHubspot, type ContactInfo } from 'client/hooks/hubspot/useHubspot';
import { useMutateUser } from 'client/hooks/user/useMutateUser';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { getCurrentUserWithAttrs, getUserInRegisteredUsers } from 'common/services/userServices';
import login from 'img/login/background.png';
import logoWhite from 'img/white-logo.png';
import { useNavigate } from 'react-router-dom';
import { ForVerifyPanel } from './ForVerifyPanel';
import { LoginPanel, LoginCredentials } from './LoginPanel';
import { RecoverPanel, ResetCredentials, ResetConfirmCredentials } from './RecoverPanel';
import { RegistrationPanel, RegisterCredentials, RegisterConfirmCredentials } from './RegistrationPanel';

export type Mode = 'login' | 'register' | 'register-confirm' | 'reset' | 'reset-confirm' | 'for-verify';

export const Layout = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [mode, setMode] = useState<Mode>('login');
    const [isConfirmAccount, setIsConfirmAccount] = useState(false);
    const [isLoggingIn, setisLoggingIn] = useState(false);
    const { logout } = useMutateUser();

    const { createUser, confirmSignUp, confirmResetPw, login, resetPw } = useMutateUser();

    const { notify } = useNotifier();
    const { submitContact } = useHubspot();

    const handleChangeMode = (newMode: Mode) => {
        setMode(newMode);
    };

    const handleLogin = async ({ username, password }: LoginCredentials) => {
        setisLoggingIn(true);
        login
            .mutateAsync({ username, password })
            .then(() => {
                getCurrentUserWithAttrs().then(user => {
                    const isRegistered = getUserInRegisteredUsers(user);
                    if (user && !isRegistered) {
                        setisLoggingIn(false);
                        logout.mutateAsync();
                        setMode('for-verify');
                        return;
                    }
                });
            })
            .catch(() => {
                setisLoggingIn(false);
                notify(new Message({ title: 'Login Failed: Invalid username or password', type: 'error' }));
            });
    };

    const handleRegister = async (credentials: RegisterCredentials) => {
        try {
            await createUser.mutateAsync(credentials);
            const contact: ContactInfo = {
                username: credentials.username,
                firstname: credentials.firstName,
                lastname: credentials.lastName,
            };
            await submitContact(contact);
            handleChangeMode('register-confirm');
        } catch (error) {
            notify(new Message({ title: 'Registration Failed', type: 'error' }));
        }
    };

    const handleRegisterConfirm = async (credentials: RegisterConfirmCredentials) => {
        setIsConfirmAccount(true);
        const result = await confirmSignUp.mutateAsync(credentials);
        if (result.success === false) {
            notify(new Message({ title: result.message, type: 'error' }));
            setIsConfirmAccount(false);
            return;
        }
        navigate('/message/thank-you');
    };

    const handleReset = async (credentials: ResetCredentials) => {
        try {
            await resetPw.mutateAsync(credentials);
            setMode('reset-confirm');
        } catch (error) {
            notify(new Message({ title: 'Unable to Reset Password', type: 'error' }));
        }
    };

    const handleResetConfirm = async (credentials: ResetConfirmCredentials) => {
        try {
            await confirmResetPw.mutateAsync(credentials);
            notify(new Message({ title: 'Password Reset Successfully' }));
            setMode('login');
        } catch (error) {
            notify(new Message({ title: 'Unable to Confirm Password Reset', type: 'error' }));
        }
    };

    return (
        <main className={classes.container}>
            {/* Sections inverted to achieve correct tab sequence. */}
            <section className={classes.panel}>
                {mode === 'login' && <LoginPanel onLogin={handleLogin} onChangeMode={handleChangeMode} isLoggingIn={isLoggingIn} />}
                {(mode === 'register' || mode === 'register-confirm') && (
                    <RegistrationPanel
                        mode={mode}
                        onRegister={handleRegister}
                        onConfirm={handleRegisterConfirm}
                        onChangeMode={handleChangeMode}
                        isConfirmRegistration={isConfirmAccount}
                    />
                )}
                {(mode === 'reset' || mode === 'reset-confirm') && (
                    <RecoverPanel mode={mode} onReset={handleReset} onConfirm={handleResetConfirm} onChangeMode={handleChangeMode} />
                )}
                {mode === 'for-verify' && <ForVerifyPanel onChangeMode={handleChangeMode} />}
            </section>
            <section className={classes.visual}>
                <div className={classes.content}>
                    <img className={classes.logo} src={logoWhite} alt="vsurgic logo" width={300} />
                    <Typography className={classes.links} variant="caption" color="textSecondary">
                        &copy; Copyright VSurgic - All Rights Reserved
                        <div>
                            <a
                                href="https://vsurgic.com/privacy-policy/"
                                target="_blank"
                                rel="noreferrer"
                                title="Privacy Policy"
                                style={{ color: '#fff', textDecoration: 'none' }}
                            >
                                Privacy Policy
                            </a>
                            &nbsp;-&nbsp;
                            <a
                                href="https://vsurgic.com/terms-of-use/"
                                target="_blank"
                                rel="noreferrer"
                                title="Terms & Conditions"
                                style={{ color: '#fff', textDecoration: 'none' }}
                            >
                                Terms & Conditions
                            </a>
                        </div>
                    </Typography>
                </div>
            </section>
        </main>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        minHeight: '100vh',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    visual: {
        backgroundImage: `url(${login})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        order: -1,
        [theme.breakpoints.up('md')]: {
            marginRight: -100,
            paddingRight: 100,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        padding: theme.spacing(4),
    },
    logo: {
        margin: 'auto',
    },
    links: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
    },
    panel: {
        backgroundColor: theme.palette.secondary.light,
        borderBottomLeftRadius: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(4),
    },
}));
