import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { ListItemButton } from '@mui/material';
import { useListTemplates } from 'client/hooks/templates/useListTemplates';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';
import Panel from 'common/components/UI/Panel';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { Template } from 'common/types/template';
import { DraftIcon } from 'common/utils/Icons';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import TemplateEditor, { MergeTag } from './TemplateEditor';

const styles = (theme: Theme) => ({
    container: {
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(2),
    },
    grid: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    listbox: {
        padding: theme.spacing(0),
    },
    listHeader: {
        padding: theme.spacing(2),
    },
});
const useStyles = makeStyles(styles);

const TemplatesPage = () => {
    const classes = useStyles();
    const { reset } = useQueryErrorResetBoundary();
    return (
        <div className={classes.container}>
            <Box width={1} py={2} px={3}>
                <ErrorBoundary
                    onReset={reset}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    fallbackRender={({ resetErrorBoundary }) => (
                        <Panel>
                            <p>Oops! Something went wrong...</p>
                            <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                                Try again
                            </Button>
                        </Panel>
                    )}
                >
                    <React.Suspense fallback={<ProcessingOverlay enabled />}>
                        <TemplatesList />
                    </React.Suspense>
                </ErrorBoundary>
            </Box>
        </div>
    );
};

const TemplatesList = () => {
    const classes = useStyles();
    const { templates, refetch } = useListTemplates();
    const [selectedTemplate, setSelectedTemplate] = useState<Template>();
    const [mergeTags, setMergeTags] = useState<MergeTag[]>([
        {
            value: 'firstName',
            title: 'firstName',
        },
    ]);

    const onTemplateItemClick = async (data: Template) => {
        refetch();
        if (data.fields) {
            const tags = data.fields.map(tag => {
                return {
                    value: tag,
                    title: tag,
                };
            });
            setMergeTags(tags);
        }
        setSelectedTemplate(data);
    };

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <EmptyStateHandler data={templates} keyword="templates">
            <Grid container>
                <Grid xs={12} sm={12} md={4}>
                    <Box className={classes.grid}>
                        <Paper>
                            <Typography variant="h6" className={classes.listHeader}>
                                Templates
                            </Typography>
                            <List className={classes.listbox}>
                                <Divider />
                                {templates.map((row: Template) => (
                                    <>
                                        <ListItem key={row.id} className={classes.listbox}>
                                            <ListItemButton onClick={() => onTemplateItemClick(row)}>
                                                <ListItemIcon>
                                                    <DraftIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={row.name} />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                    </>
                                ))}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} sm={12} md={8}>
                    <Box className={classes.grid}>
                        <Paper>
                            <TemplateEditor
                                id={selectedTemplate?.id}
                                name={selectedTemplate?.name}
                                subject={selectedTemplate?.subject}
                                body={selectedTemplate?.body}
                                tags={mergeTags}
                                original={selectedTemplate?.html ?? '<p>Email body</p>'}
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </EmptyStateHandler>
    );
};

export default TemplatesPage;
