import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const AccountIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 32 32">
        <g transform="translate(-70.000000, -709.000000)">
            <path d="M86,709 C81.75616,709 77.6864,710.68624 74.6864,713.6864 C71.6864,716.68656 70,720.75648 70,725 C70,729.24352 71.68624,733.3136 74.6864,736.3136 C77.68656,739.3136 81.75648,741 86,741 C90.24352,741 94.3136,739.31376 97.3136,736.3136 C100.3136,733.31344 102,729.24352 102,725 C102,720.75648 100.31376,716.6864 97.3136,713.6864 C94.31344,710.6864 90.24352,709 86,709 Z M86,716.33376 C87.237504,716.33376 88.424992,716.825024 89.29984,717.7 C90.174688,718.574976 90.66608,719.762496 90.66608,720.99984 C90.66608,722.237184 90.174816,723.424832 89.29984,724.29968 C88.424864,725.174528 87.237344,725.66592 86,725.66592 C84.762656,725.66592 83.575008,725.174656 82.70016,724.29968 C81.825312,723.424704 81.33392,722.237184 81.33392,720.99984 C81.33392,719.762496 81.825184,718.574848 82.70016,717.7 C83.575136,716.825152 84.762656,716.33376 86,716.33376 Z M93.4,732.65376 C91.411264,734.572512 88.75616,735.643776 85.9936,735.643776 C83.229856,735.643776 80.57472,734.572512 78.58624,732.65376 C78.3799904,732.438758 78.33624,732.115008 78.4799904,731.85376 C79.5999904,729.8 82.5337504,728.33376 85.9999904,728.33376 C89.4662304,728.33376 92.3999904,729.8 93.5199904,731.85376 C93.6599904,732.11751 93.6112416,732.442496 93.3999904,732.65376 L93.4,732.65376 Z" />
        </g>
    </SvgIcon>
);
