import React, { useMemo } from 'react';
import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import theme from 'App/theme';
import { useGetDevice } from 'circulator/hooks/device/useGetDevice';
import Clock from 'common/components/Clock/index';
import UiTooltip from 'common/components/UI/UiTooltip';
import { ClockIcon, FacilityIcon } from 'common/utils/Icons';
import RecordIcon from 'img/record-icon.svg';

const styles = {
    toolbar: {
        flexGrow: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    titleBox: {
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        display: 'inline',
        verticalAlign: 'middle',
    },
    icon: {
        verticalAlign: 'middle',
        margin: theme.spacing(1.5),
    },
    iconButton: {},
    clockBox: {
        paddingLeft: theme.spacing(),
        whiteSpace: 'nowrap',
    },
    drawer: {
        width: 180,
    },
    drawerPaper: {
        width: 180,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(),
        paddingTop: theme.spacing(2),
        justifyContent: 'flex-end',
    },
};

const useStyles = makeStyles(styles);

const Header = ({ hasProcedure = false }) => {
    const classes = useStyles();
    const { device } = useGetDevice();
    const deviceLabel = useMemo(() => {
        if (device === null || device === undefined) return null;
        const { tv: idNumber, facility, roomName } = device;
        const { name, city, state, phone } = facility ?? {};

        const strIdNumber = !!idNumber && `(${idNumber})`;
        const strAddress = [city, state].filter(e => !!e).join(', ');
        const strDescription = [name, strAddress, roomName, phone].filter(e => !!e).join(' - ');
        return [strIdNumber, strDescription].filter(e => !!e).join(' ');
    }, [device]);
    const title = device ? deviceLabel : 'Device Title';
    return (
        <>
            <AppBar position="static" color="primary">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.titleBox}>
                        {device && (
                            <UiTooltip title="Facility">
                                <span>
                                    <FacilityIcon className={classes.icon} />
                                </span>
                            </UiTooltip>
                        )}
                        <Typography variant="h6" className={classes.text}>
                            {title}
                        </Typography>
                    </div>
                    {device && (
                        <>
                            {hasProcedure && (
                                <div
                                    style={{
                                        border: '1px solid red',
                                        background: 'red',
                                        width: '75px',
                                        borderRadius: '20px',
                                        padding: '4px 10px',
                                        cursor: 'pointer',
                                        height: '30px',
                                    }}
                                >
                                    <span>REC</span>
                                    <div style={{ float: 'right' }}>
                                        <img
                                            src={RecordIcon}
                                            alt="Recording"
                                            width="20px"
                                            height="20px"
                                            style={{
                                                filter: 'invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%)',
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={classes.clockBox}>
                                <UiTooltip title="Current Facility Time">
                                    <span>
                                        <ClockIcon className={classes.icon} />
                                    </span>
                                </UiTooltip>
                                <Typography variant="body1" className={classes.text}>
                                    <Clock timezone={device.facility?.timeZone} />
                                </Typography>
                            </div>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
