import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type userCanView = {
    canView: boolean;
    isLoading: boolean;
    isFetching: boolean;
};
export function useCanUserViewExport() {
    const { key, path } = config.videoexport.canView();

    const result = useQuery(key, async () => await Api.get<userCanView>({ path }), {});
    return {
        canView: result.data || false,
    };
}
