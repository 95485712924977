import { Modal, Box, AppBar, Toolbar, Typography, IconButton, Container, List, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@mui/material/ListItem';
import theme from 'App/theme';
import { useGetUsersSharedWith } from 'client/hooks/videoExport/useGetUsersSharedWith';
import { useRemoveShare } from 'client/hooks/videoExport/useRemoveShare';
import { CloseIcon, DeleteIcon } from 'common/utils/Icons';
import AutoCompleteBox from './AutoCompleteBox';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    modalContainer: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        maxHeight: 470,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: '24',
        padding: 4,
    },

    flexGrow: { flexGrow: 1 },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999,
        color: 'white',
        opacity: 1,
    },
    container: {
        marginTop: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    alreadySharedWith: {
        marginTop: '5%',
    },
    sharedUsersList: {
        border: '1px solid inset',
        paddingLeft: '5px',
        paddingTop: '5px',
        boxShadow: '1px 1px 5px #555 inset',
        maxHeight: '160px',
        overflow: 'auto',
    },
});

interface ShareModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    procedureId: number;
}

export const ShareModal = ({ isOpen, setIsOpen, procedureId }: ShareModalProps) => {
    const classes = useStyles();
    const { users: sharedUsers, isLoading } = useGetUsersSharedWith(procedureId);
    const { removeShare } = useRemoveShare(procedureId);

    function createListItems() {
        if (isLoading) {
            return <div>Loading...</div>;
        }
        return sharedUsers.map((user: any) => {
            return (
                <ListItem
                    secondaryAction={
                        <IconButton
                            onClick={e => {
                                removeShare(user.id);
                            }}
                            edge="end"
                            aria-label="delete"
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                >
                    <ListItemText primary={user.name} />
                </ListItem>
            );
        });
    }
    return (
        <Modal open={isOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className={classes.modalContainer}>
                <AppBar>
                    <Toolbar className={classes.header}>
                        <Typography variant="h6" component="div" className={classes.flexGrow}>
                            Share Procedure
                        </Typography>
                        <IconButton aria-label="close" onClick={() => setIsOpen(false)} className={classes.closeIcon}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Container className={classes.container}>
                    {sharedUsers && (
                        <>
                            <Typography variant="h6" component="div">
                                Share To
                            </Typography>
                            <AutoCompleteBox procedureId={procedureId} />
                            {sharedUsers.length > 0 && (
                                <>
                                    <Typography className={classes.alreadySharedWith} variant="h6" component="div">
                                        Already Shared With
                                    </Typography>
                                    <List className={classes.sharedUsersList}>{createListItems()}</List>
                                </>
                            )}
                        </>
                    )}
                </Container>
            </Box>
        </Modal>
    );
};
