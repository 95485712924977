/* eslint-disable max-len */
import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
import logo from 'img/apple-touch-icon.png';
import PropTypes from 'prop-types';

const styles = {
    root: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    btn: {
        verticalAlign: 'baseline',
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: 'none',
    },
    documentWrapper: {
        position: 'relative',
        margin: 0,
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    deviceId: {
        fontFamily: 'Roboto-Mon',
        textTransform: 'uppercase',
        background: '#eeeeee',
        padding: theme.spacing(1),
        letterSpacing: theme.spacing(1),
    },
};

const useStyles = makeStyles(styles);
export const DeviceNotFoundPanel = ({ deviceUuid, onRetry }) => {
    const classes = useStyles();
    return (
        <div className={classes.documentWrapper}>
            <Typography component="div" className={classes.root}>
                <a href="https://www.vsurgic.com" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="VSurgic" />
                </a>
            </Typography>
            <Typography paragraph variant="h1">
                Welcome to VSURGIC
            </Typography>
            <Typography paragraph>Please have your facility manager or VSURGIC representative register this device and retry.</Typography>
            <Typography paragraph>Please provide the following device ID:</Typography>
            <Typography paragraph className={classes.deviceId}>
                {deviceUuid}
            </Typography>
            <Typography paragraph>
                <Button variant="contained" onClick={onRetry}>
                    Retry
                </Button>
            </Typography>
            <Typography paragraph>We apologize for any inconvenience.</Typography>
        </div>
    );
};

DeviceNotFoundPanel.propTypes = { deviceUuid: PropTypes.string.isRequired, onRetry: PropTypes.func.isRequired };
