import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const HomeIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 29 30">
        <g transform="translate(-70.000000, -228.000000)">
            <polygon points="70 258 70 238.469164 84.4966002 228 98.9983001 238.469164 99 258"></polygon>
        </g>
    </SvgIcon>
);
