import { config } from 'client/hooks/config';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useQueryClient } from 'react-query';
import { useMutation } from 'react-query';

export const useRemoveShare = (procedureId: number) => {
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key: removeShareKey, path } = config.sharing.removeShare(procedureId);
    const { key: availableUsersKey } = config.sharing.listOfAvailableToShare(procedureId);
    const { key: usersSharedWithKey } = config.sharing.listUsersSharedWith(procedureId);
    const { mutateAsync } = useMutation(
        removeShareKey,
        async (shareId: number) => {
            const deleteSharePath = path(shareId);
            const results = Api.delete({ path: deleteSharePath });
            return results;
        },
        {
            onSuccess: () => {
                notify(new Message({ title: 'Share removed successfully', type: 'success' }));
                queryClient.invalidateQueries({ queryKey: removeShareKey });
                queryClient.invalidateQueries({ queryKey: availableUsersKey });
                queryClient.invalidateQueries({ queryKey: usersSharedWithKey });
            },
            onError: () => {
                notify(new Message({ title: 'Unable to remove share', type: 'error' }));
                queryClient.invalidateQueries({ queryKey: removeShareKey });
                queryClient.invalidateQueries({ queryKey: availableUsersKey });
                queryClient.invalidateQueries({ queryKey: usersSharedWithKey });
            },
        }
    );
    return { removeShare: mutateAsync };
};
