import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import { getChatList } from 'client/hooks/chats/useChatList';
import { ExtendedCognitoUser } from 'common/services/userServices';
import { ProcedureDetail } from 'common/types';
import { IUserMessage } from 'common/types/user-message';
import { WebConfig } from 'web-config';
import ChatCard from './ChatCard';

interface ChatProps {
    procedure: ProcedureDetail;
    user?: ExtendedCognitoUser;
    isRoom: boolean;
}
const initialState: IUserMessage[] = [];

export const Chat: React.FC<ChatProps> = (props: ChatProps) => {
    const { procedure, user, isRoom } = props;

    const config = useConfig<WebConfig>();
    const webSocketApiEndpoint = config.additionalConfig?.webSocketApiEndpoint || null;
    const ws = useRef<WebSocket | null>(null);
    const [wsConnected, setWsConnected] = useState(false);
    const [messageList, setMessageList] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);

    const [currentName] = useState(`${user?.attributes?.given_name} ${user?.attributes?.family_name}`);
    const [currentAlias, setCurrentAlias] = useState(`${user?.attributes?.given_name} ${user?.attributes?.family_name}`);

    const displayNameGenerator = () => {
        const deviceName = procedure.device.tv || procedure.deviceName || procedure.doctorName || procedure.title;
        const author = `${user?.attributes?.given_name} ${user?.attributes?.family_name}`;
        const chatAlias = isRoom ? author : deviceName;
        setCurrentAlias(chatAlias);
    };

    const fetchChatList = async () => {
        setIsLoading(true);
        const chatsList = await getChatList(procedure.id);
        const sortedMessage = chatsList.sort((x, y) => new Date(y.stamp).getTime() - new Date(x.stamp).getTime());
        let messages: IUserMessage[] = sortedMessage.map(cm => {
            const msg = cm.message.split(':>') ?? [];
            return {
                author: msg[0],
                type: 'text',
                data: {
                    text: msg[1],
                },
                currentUser: currentAlias === msg[0],
            };
        });
        setIsLoading(false);
        setMessageList(messages);
    };

    const setupWebsocket = useCallback(
        (procedureId: number, token: string, sub: string, email: string, webSocketApiEndpoint: string) => {
            console.log('setupWebsocket');

            if (ws.current) {
                ws.current.close();
            }

            ws.current = new WebSocket(`${webSocketApiEndpoint}?token=${token}&userId=${sub}&procedureId=${procedureId}`);

            setWsConnected(true);

            ws.current.onopen = event => {
                console.log('ws open', event);
            };

            ws.current.onerror = event => {
                console.log('ws error', event);
            };

            ws.current.onclose = closeEvent => {
                console.log('ws close', closeEvent);
                setWsConnected(false);
            };

            ws.current.onmessage = messageEvent => {
                console.log('WS: Incoming');
                const { type, data: messageData } = JSON.parse(messageEvent.data);
                const { author, data } = JSON.parse(messageData);
                const isMe = currentName === author;
                const msg = (data.text as string).split(':>');
                const authorAlias = (author as string).toLowerCase().includes('device')
                    ? procedure.device.tv || procedure.deviceName || procedure.doctorName || procedure.title
                    : author;
                //@ts-ignore
                setMessageList(prev => {
                    if (typeof messageData === 'string') {
                        if (!isMe) {
                            return [
                                {
                                    author: authorAlias,
                                    currentUser: false,
                                    type,
                                    data: { text: msg[1] ?? '' },
                                },
                                ...prev,
                            ];
                        } else {
                            return [...prev];
                        }
                    } else {
                        return [
                            {
                                author: authorAlias,
                                currentUser: isMe,
                                type,
                                data: { text: msg[1] ?? '' },
                            },
                            ...prev,
                        ];
                    }
                });
            };
        },
        [currentName, procedure]
    );

    const onMessageWasSent = (message: any) => {
        const newMessage = { ...message, author: currentName };
        ws.current &&
            ws.current.send(
                JSON.stringify({
                    action: 'message',
                    data: JSON.stringify(newMessage),
                })
            );
        const msg = (newMessage.data.text as string).split(':>');
        setMessageList(prev => [
            {
                author: currentAlias,
                type: 'text',
                data: { text: msg[1] ?? '' },
                currentUser: true,
            },
            ...prev,
        ]);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const token = user?.getSignInUserSession()?.getIdToken().getJwtToken();
            const sub = user?.attributes.sub;
            if (!wsConnected && token && sub && currentName && webSocketApiEndpoint) {
                setupWebsocket(procedure.id, token, sub, currentName, webSocketApiEndpoint);
            }
        }, 1000);

        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedure.id, setupWebsocket, webSocketApiEndpoint, wsConnected, currentName]);

    useEffect(() => {
        displayNameGenerator();
        fetchChatList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentName]);

    return <ChatCard messageList={messageList} onMessageWasSent={onMessageWasSent} isRoom={isRoom} isLoading={isLoading} alias={currentAlias} />;
};
