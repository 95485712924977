import { useCallback, useMemo, useState } from 'react';
import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery, useMutation } from 'react-query';

export interface AnswerSurveyRequest {
    surveyResponse: number;
    surveyQuestionId: number;
}

export interface SurveyQuestion {
    id: number;
    question: string;
    answerFormat: string;
}

export interface useSurveyProps {
    procedureId?: number;
}

export const useSurvey = (props: useSurveyProps) => {
    const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
    const { key, path: getQuestionsPath } = config.survey.list(props?.procedureId);
    const { isLoading, refetch } = useQuery<SurveyQuestion[]>(key, () => Api.get({ path: getQuestionsPath }), {
        enabled: isNotNullOrUndefined(props?.procedureId),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data: SurveyQuestion[]) => {
            setQuestions(data);
        },
    });

    const questionsAvailable = useMemo(() => (questions || []).length > 0, [questions]);
    const getNextQuestion = useCallback(() => {
        if (questionsAvailable) {
            const arr = questions;
            if (arr.length > 0) {
                const nextQuestion = arr.shift();
                if (nextQuestion) {
                    arr.push(nextQuestion);
                    setQuestions(arr);
                }
            }
        }
        return null;
    }, [questions, questionsAvailable]);

    return {
        question: questions.at(0),
        getNextQuestion,
        refetch,
        isLoading,
        questionsAvailable,
    };
};

export const useMutateSurvey = () => {
    const { path: saveAnswerPath } = config.survey.saveAnswer();
    const surveyMutation = useMutation((data: AnswerSurveyRequest) => Api.post({ path: `${saveAnswerPath}${data.surveyQuestionId}`, body: data }));
    const saveSurveyAnswer = (data: AnswerSurveyRequest) => surveyMutation.mutateAsync(data);
    return { saveSurveyAnswer };
};
