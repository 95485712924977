import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export const useListAllUserRoles = () => {
    const result = useQuery(['user', 'roles'], () => Api.get({ path: '/roles' }), {
        enabled: true,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    const userRoles = result.data || [];
    return {
        userRoles,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
};
