import { Campaign } from 'circulator/components/Campaign';
import Header from '../components/UI/Header';
import LandscapeMode from './LandscapeMode';
import { NoProcedureFoundPanel } from './NoProcedureFound';
import NoProcedureBanner from './NoProcedureFound/NoProcedureBanner';
import PortraitMode from './PortraitMode';
import { useGetCirculatorHomePage } from './useGetCirculatorHomePage';

export const CirculatorHome = () => {
    const { isLandscape, procedure, ...rest } = useGetCirculatorHomePage();

    const hasProcedure: boolean = procedure?.id;

    return (
        <>
            {!hasProcedure && (
                <>
                    <NoProcedureBanner />
                    <Header hasProcedure={hasProcedure} />
                    <NoProcedureFoundPanel />
                    <Campaign />
                </>
            )}
            {hasProcedure && (isLandscape ? <LandscapeMode {...rest} procedure={procedure} /> : <PortraitMode {...rest} procedure={procedure} />)}
        </>
    );
};
