import Profile from 'common/models/Profile';
import { useUser } from '../user/useUser';

export const useProfile = () => {
    const { user, isLoading } = useUser();

    const profile = user ? Profile.fromCognitoAttributes(user?.attributes) : new Profile();

    return {
        isLoading,
        user,
        profile,
    };
};
