import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import PasswordInput from 'client/components/UI/PasswordInput';
import { useMutateUser } from 'client/hooks/user/useMutateUser';
import { useUser } from 'client/hooks/user/useUser';
import { MessageBox } from 'common/components/UI/MessageBox';
import { useNotifier } from 'common/hooks/useNotifier';
import Credentials from 'common/models/Credentials';
import Message from 'common/models/Message';
import { GoIcon } from 'common/utils/Icons';

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(2),
    },
    msg: {
        marginBottom: theme.spacing(3),
    },
    submit: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        transition: 'color .01s',
    },
    requirements: {
        minHeight: '20px',
        padding: '19px',
        marginBottom: '20px',
        backgroundColor: '#f5f5f5',
        border: '1px solid #e3e3e3',
        borderRadius: '4px',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
    },
}));

const PasswordReset: React.FunctionComponent = () => {
    const classes = useStyles();
    const { user } = useUser();
    const { changePw } = useMutateUser();

    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [credentials, setCredentials] = useState(new Credentials({ username: user?.attributes.email }));
    const [focus, setFocus] = useState<string | null>(null);
    const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
    const { notify } = useNotifier();

    const getErrorMessage = () => {
        let message: string = '';
        if (!credentials.password.match(/[A-Z]/)) {
            message += !!message ? ', one uppercase letter' : 'Password does not contain at least one uppercase letter';
        }
        if (!credentials.password.match(/[a-z]/)) {
            message += !!message ? ', one lowercase letter' : 'Password does not contain at least one lowercase letter';
        }
        if (!credentials.password.match(/[0-9]/)) {
            message += !!message ? ', one number' : 'Password does not contain at least one number';
        }
        if (!credentials.password.match(/[!@#$%^&*]/)) {
            message += !!message
                ? ', one special character (!, @, #, $, %, ^, &, or *)'
                : 'Password does not contain at least one special character (!, @, #, $, %, ^, &, or *)';
        }
        if (credentials.password.length < 8) {
            message += !!message ? ' and is not 8 characters long' : 'Password is not at least 8 characters long';
        }
        return message + '.';
    };

    const setBlur = (blur: string | null) => () => {
        if (blur === focus) {
            setFocus(null);
        }
    };

    const handleChange = (name: string) => (event: { target: { value: string } }) => {
        if (name === 'confirmPassword') {
            setConfirmPassword(event.target.value);
        } else {
            setCredentials(credentials.clone().value(name, event.target.value));
        }
    };

    const handleChangePassword = () => {
        if (user && credentials.isSavable()) {
            setIsSubmitting(true);
            const { oldPassword, password } = credentials;
            changePw.mutate(
                { user, oldPassword, password },
                {
                    onSuccess: () => {
                        setConfirmPassword(null);
                        setCredentials(new Credentials({ username: user?.attributes.email }));
                        setOpen(false);
                        setIsSubmitting(false);
                        notify(new Message({ title: 'Password changed successfully' }));
                    },
                    onError: error => {
                        const message = error instanceof Error ? error.message : 'An error occurred while trying to change password.';
                        notify(new Message({ title: message }));
                        setIsSubmitting(false);
                    },
                }
            );
        }
    };

    const isPwdConfirmed = () => credentials.password != null && credentials.password === confirmPassword;
    const btnDisabled = !credentials.isSavable() || !isPwdConfirmed() || isSubmitting;
    return (
        <>
            <Typography variant="h5" align="center" className={classes.header}>
                Reset Password
            </Typography>
            <Button
                variant="contained"
                aria-label="Save"
                color="primary"
                fullWidth
                onClick={() => {
                    setConfirmPassword(null);
                    setCredentials(new Credentials({ username: user?.attributes.email }));
                    setOpen(true);
                }}
            >
                Reset Password
            </Button>
            <Dialog fullScreen={false} open={open}>
                <DialogContent>
                    <Typography className={classes.requirements} paragraph>
                        Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character{' '}
                        <code>(!, @, #, $, %, ^, &, or *)</code>
                    </Typography>
                    <PasswordInput
                        id="old-password"
                        name="old-password"
                        label="Current Password"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="password"
                        onChange={handleChange('oldPassword')}
                        onFocus={() => setFocus('oldPassword')}
                        onBlur={() => setBlur('oldPassword')}
                    />
                    <PasswordInput
                        id="new-password"
                        name="new-password"
                        label="New Password"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="password"
                        onChange={handleChange('password')}
                        onFocus={() => setFocus('password')}
                        onBlur={() => setBlur('password')}
                    />
                    <MessageBox
                        message={
                            new Message({
                                title: 'Invalid Password',
                                details: getErrorMessage(),
                                type: 'error',
                            })
                        }
                        className={classes.msg}
                        enabled={!credentials.isPasswordValid() && credentials.password.trim().length > 0 && focus !== 'password'}
                    />
                    <PasswordInput
                        id="confirm-password"
                        name="confirm-password"
                        label="New Password (confirm)"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="confirm-password"
                        onChange={handleChange('confirmPassword')}
                        onFocus={() => setFocus('confirmPassword')}
                        onBlur={setBlur('confirmPassword')}
                    />
                    <MessageBox
                        message={
                            new Message({
                                title: 'Password Does Not Match',
                                details: 'The password provided does not match the confirmed password provided.',
                                type: 'error',
                            })
                        }
                        className={classes.msg}
                        enabled={
                            credentials.password &&
                            confirmPassword &&
                            !isPwdConfirmed() &&
                            focus !== 'oldPassword' &&
                            focus !== 'password' &&
                            focus !== 'confirmPassword'
                        }
                    />
                    <Button
                        variant="contained"
                        aria-label="Reset Password"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={btnDisabled}
                        onClick={handleChangePassword}
                        endIcon={<GoIcon />}
                    >
                        Reset Password
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setConfirmPassword(null);
                            setCredentials(new Credentials({ username: user?.attributes.email }));
                            setOpen(false);
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PasswordReset;
