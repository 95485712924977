import { IceServer } from 'common/types';
import { JoinProcedureResponse } from 'common/types/api';
import { create } from 'zustand';

interface JoinStoreState {
    procedureId: number;
    publishStreamId: string;
    publishUrl: string;
    publishToken: string;
    publishIceServers: IceServer[];

    setJoinDetails: (procedureId: number, details: JoinProcedureResponse) => void;
}

const useJoinStore = create<JoinStoreState>()((set, get) => ({
    procedureId: 0,
    publishStreamId: '',
    publishUrl: '',
    publishToken: '',
    publishIceServers: [],

    setJoinDetails: (procedureId, details) => {
        set({
            procedureId: procedureId,
            publishStreamId: details.publishStreamId,
            publishUrl: details.publishUrl,
            publishToken: details.publishToken,
            publishIceServers: details.publishIceServers,
        });
    },
}));

export default useJoinStore;
