import BasicDomain from './BasicDomain';
import Procedure from './Procedure';
import Profile from './Profile';

export default class AccessRequest extends BasicDomain {
    static STATUS_PENDING = 'PENDING';

    static STATUS_ACCEPTED = 'ACCEPTED';

    static STATUS_REJECTED = 'REJECTED';

    static STATUS_CANCELED = 'CANCELED';

    static STATUS_UNKNOWN = 'UNKNOWN';

    get className() {
        return 'AccessRequest';
    }

    get myClass() {
        return AccessRequest;
    }

    constructor(props = {}) {
        super(props);

        this.id = props.id || props.objectId || null;
        this.requester = new Profile(props.requester || {});
        this.procedure = new Procedure(props.procedure || {});
        this.status = props.status || AccessRequest.STATUS_UNKNOWN;
        this.createdAt = props.createdAt || null;
        this.updatedAt = props.updatedAt || null;
    }

    getSaveToken = () => ({
        requester: this.requester.pointer,
        procedure: this.procedure.pointer,
        status: this.status,
    });

    isSame = target =>
        target != null &&
        this.id === target.id &&
        this.requester.id === target.requester.id &&
        this.status === target.status &&
        this.procedure === target.procedure;

    isPending = () => this.status === AccessRequest.STATUS_PENDING;

    isAccepted = () => this.status === AccessRequest.STATUS_ACCEPTED;

    isRejected = () => this.status === AccessRequest.STATUS_REJECTED;

    isCanceled = () => this.status === AccessRequest.STATUS_CANCELED;

    isUnknown = () => this.status === AccessRequest.STATUS_UNKNOWN;
}
