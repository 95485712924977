export enum AttendeeStatus {
    REQUESTED = 'REQUESTED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED',
    ACCEPTED = 'ACCEPTED',
    JOINED = 'JOINED',
}

export interface IceServer {
    urls: string;
    username?: string;
    credential?: string;
}

export interface Attendee {
    id: number;
    connectionLost: boolean;
    status: AttendeeStatus;
    viewAccess: boolean;
    publishAccess: boolean;
    userId: string;
    joinTimes: Date[];
    leaveTimes: Date[];
    publishStreamId?: string;
    publishUrl?: string;
    publishToken?: string;
    publishIceServers?: IceServer[];
}
