import { useCallback } from 'react';
import { useCanUseCuts } from 'client/hooks/mobile/useCanUseCuts';
import { useUser } from 'client/hooks/user/useUser';
import { getUserInRegisteredUsers } from 'common/services/userServices';
import { isMobileOnly } from 'react-device-detect';
import { Navigate, useLocation } from 'react-router-dom';
import { Layout } from './Layout';

export const LoginPage = () => {
    const { user, isLoading: isLoadingUser } = useUser();
    const isRegistered = getUserInRegisteredUsers(user);
    const { canUseCuts, isLoading: isLoadingAssociatedWithFacility } = useCanUseCuts(isRegistered && isMobileOnly);
    const location = useLocation();
    let previous: null | string = location.state?.from?.pathname as null | string;
    console.log(`Beginning Rendering LoginPage with previous: ${previous}`);
    const callback = useCallback(() => {
        let target = '/';
        /*
         * Regex here allows mobile users to be redirected to the whichever mobile page they were on
         * but makes sure they stay in the mobile zone.
         */
        if (canUseCuts && isMobileOnly && !(previous ?? '').match(/\\mobile\b/i)) {
            target = '/mobile';
        } else if (previous && previous !== '/login') {
            target = previous;
        }
        return { to: target, replace: true };
    }, [canUseCuts, previous]);
    if (user && !isLoadingAssociatedWithFacility && !isLoadingUser && isRegistered) {
        const results = callback();
        console.log(`User is registered and logged in, redirecting to ${results.to}`);
        return <Navigate {...results} />;
    }
    console.log('Rendering LoginPage with no redirect');
    return <Layout />;
};
