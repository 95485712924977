import React from 'react';
import { DeviceNotFoundPanel } from 'circulator/components/Device/DeviceNotFoundPanel';
import { useDeviceLogin } from 'circulator/hooks/device/useDeviceLogin';
import { useGetDevice } from 'circulator/hooks/device/useGetDevice';
import { useUser } from 'circulator/hooks/user/useUser';
import { CirculatorHome } from 'circulator/pages/CirculatorHome';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { Splash } from 'common/components/UI/Splash';
import { getUserInDeviceGroup } from 'common/services/userServices';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import ErrorBoundaryFallback from '../common/components/ErrorBoundary/ErrorBoundaryFallback';

export const CirculatorPage = () => {
    const { reset } = useQueryErrorResetBoundary();
    return (
        <ErrorBoundary onReset={reset} fallbackRender={({ resetErrorBoundary }) => <ErrorBoundaryFallback resetErrorBoundary={resetErrorBoundary} />}>
            <React.Suspense fallback={<ProcessingOverlay enabled />}>
                <Circulator />
            </React.Suspense>
        </ErrorBoundary>
    );
};

export const Circulator = () => {
    const { reset } = useQueryErrorResetBoundary();
    const { user, isLoading: userLoading } = useUser();
    const userInDeviceGroup = getUserInDeviceGroup(user);
    const { uuid, deviceLogin, isLoading: deviceLoginLoading } = useDeviceLogin();
    const { device, isLoading: deviceLoading } = useGetDevice({ enabled: userInDeviceGroup });

    if (userLoading || deviceLoginLoading || deviceLoading) return <Splash showLoader />;

    if (!userInDeviceGroup || !device?.isActive) return <DeviceNotFoundPanel deviceUuid={uuid} onRetry={deviceLogin} />;

    return (
        <React.Suspense fallback={<ProcessingOverlay enabled />}>
            <ErrorBoundary
                onReset={reset}
                fallbackRender={({ resetErrorBoundary }) => <ErrorBoundaryFallback resetErrorBoundary={resetErrorBoundary} />}
            >
                <React.Suspense fallback={<ProcessingOverlay enabled />}>{user && <CirculatorHome />}</React.Suspense>
            </ErrorBoundary>
        </React.Suspense>
    );
};
