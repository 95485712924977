import { useState } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import Add from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Remove from '@mui/icons-material/Remove';
import { useMutateCameraPosition } from 'client/hooks/camera/useMutateCameraPosition';
import { shallow } from 'zustand/shallow';
import usePlayerControlsStore from '../../hooks/usePlayerControlsStore';
import PercentageSlider from './PercentageSlider';

const styles = {
    btn: {
        padding: '1px',
        minWidth: '50px',
        cursor: 'pointer',
    },
};
// @ts-ignore
const useStyles = makeStyles(styles);

type Direction = 'up' | 'down' | 'left' | 'right' | 'zoomin' | 'zoomout' | 'default';

interface CameraControlsState {
    direction: Direction;
    streamId: number | undefined;
    speed: number;
}

const CameraControls = () => {
    const classes = useStyles();
    const [activeStream] = usePlayerControlsStore(state => [state.activeStream, state.activeAudioStreamIds], shallow);
    const { changePosition, gotoHome } = useMutateCameraPosition();
    const [speedValue, setSpeedValue] = useState<number>(20);

    const changeDirection = (direction: Direction) => {
        const requestBody: CameraControlsState = { direction, streamId: activeStream?.id, speed: speedValue };
        changePosition.mutate(requestBody);
    };

    return (
        <Box position="absolute" top={40} right={10} display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center" zIndex={50}>
            <Box>
                <Button className={classes.btn} type="button" color="primary" size="small" variant="contained" onClick={() => changeDirection('up')}>
                    <KeyboardArrowUpIcon />
                </Button>
            </Box>
            <Box mt={2}>
                <Button
                    className={classes.btn}
                    type="button"
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => changeDirection('zoomin')}
                >
                    <Add />
                </Button>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box>
                    <Button
                        className={classes.btn}
                        type="button"
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => changeDirection('left')}
                    >
                        <KeyboardArrowLeftIcon />
                    </Button>
                </Box>
                <Box px={1} py={1} display="flex" flexDirection="column" gridRowGap={4}>
                    <Box py={1}>
                        <Button
                            className={classes.btn}
                            type="button"
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => gotoHome.mutate(activeStream?.id)}
                        >
                            <HomeIcon />
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Button
                        className={classes.btn}
                        type="button"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => changeDirection('right')}
                    >
                        <KeyboardArrowRightIcon />
                    </Button>
                </Box>
            </Box>
            <Box mb={2}>
                <Button
                    className={classes.btn}
                    type="button"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => changeDirection('zoomout')}
                >
                    <Remove />
                </Button>
            </Box>
            <Box>
                <Button
                    className={classes.btn}
                    type="button"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => changeDirection('down')}
                >
                    <KeyboardArrowDownIcon />
                </Button>
            </Box>
            <Box style={{ width: '210px' }}>
                <PercentageSlider value={speedValue} setValue={setSpeedValue} min={5} />
            </Box>
        </Box>
    );
};

export default CameraControls;
