import BasicDomain from './BasicDomain';

export default class File extends BasicDomain {
    get myClass() {
        return File;
    }

    constructor(props = {}) {
        super(props);
        this.name = props.name || '';
        this.url = props.url || null;

        if (props.fileType) {
            this.fileType = props.fileType;
        }

        if (props.bytes) {
            this.bytes = props.bytes;
        }
    }

    isEmpty = () => this.url == null || this.url === '' || this.name == null || this.name === '';

    getAPIToken = () => ({ __type: 'File', url: this.url, name: this.name });
}
