import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Response = api.UserRolesResponse;

export interface UserGroupsUpdateRequest {
    userId: string;
    groups: string[];
}

export const useUpsertUserGroups = () => {
    const { path: updatePath } = config.user.updateGroups();
    const updateGroup = useMutation((data: UserGroupsUpdateRequest) => Api.post<Response>({ path: updatePath, body: data }));
    return {
        addUserToGroup: updateGroup.mutateAsync,
    };
};
