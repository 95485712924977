import React from 'react';
import { makeStyles } from '@material-ui/core';
import type { Attendee } from 'common/types/circulator';
import { DoctorRoleIcon, FacilityRoleIcon, EventRoleIcon, GuestRoleIcon, PartnerRoleIcon, TechnicianRoleIcon } from 'common/utils/Icons/roles';

const styles = {
    roleList: {
        listStyleType: 'none',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, .5fr)',
        width: '33%',
        gap: '6px',
        padding: '5%',
        marginLeft: '10px',
    },
    tooltipContainer: {
        position: 'relative' as 'relative',
        display: 'inline-block',
        '&:hover': {
            '& $tooltipText': {
                visibility: 'visible' as 'visible',
                opacity: 1,
            },
        },
    },
    tooltipText: {
        visibility: 'hidden' as 'hidden',
        width: '120px',
        backgroundColor: 'black',
        color: '#fff',
        textAlign: 'center' as 'center',
        borderRadius: '6px',
        padding: '5px 0',
        position: 'absolute' as 'absolute',
        zIndex: 1,
        bottom: '125%',
        left: '50%',
        marginLeft: '-60px',
        opacity: 0,
        transition: 'opacity 0.3s',
    },
};

const useStyles = makeStyles(styles);

interface RoleListItemProps {
    attendee: Attendee;
}

export default function RoleListItem({ attendee }: RoleListItemProps) {
    const classes = useStyles();

    const roleIcons: {
        [key: string]: JSX.Element;
    } = {
        DOCTOR: <DoctorRoleIcon height="inherit" width="inherit" />,
        FACILITY: <FacilityRoleIcon />,
        EVENT: <EventRoleIcon />,
        GUEST: <GuestRoleIcon />,
        PARTNER: <PartnerRoleIcon />,
        TECHNICIAN: <TechnicianRoleIcon />,
    };

    return (
        <ul className={classes.roleList}>
            {attendee.roles.map(role => (
                <li key={role}>
                    <div className={classes.tooltipContainer}>
                        {roleIcons[role]}
                        <span className={classes.tooltipText}>{role}</span>
                    </div>
                </li>
            ))}
        </ul>
    );
}
