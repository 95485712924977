import { Facility } from 'common/types';
import { Api } from 'common/utils/api';
import { config } from 'mobile/hooks/config';
import { useQuery } from 'react-query';

export function useListFacilitiesForCuts() {
    const { key, path } = config.facilities.listForCuts();
    const result = useQuery(
        key,
        () => {
            return Api.get<Facility[]>({ path });
        },
        {
            onError: error => {
                console.log('ERROR: ', error);
            },
            onSuccess: data => {
                console.log('SUCCESS: ', data);
            },
        }
    );

    return {
        facilities: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
}
