import { useCanUserViewExport } from 'client/hooks/videoExport/useCanUserViewExport';

export function RequireExportView({ children }) {
    const { canView } = useCanUserViewExport();
    if (canView) {
        return children;
    } else {
        return null;
    }
}
