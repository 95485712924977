import { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

class Clock extends Component {
    static propTypes = {
        timezone: PropTypes.string,
    };

    static defaultProps = {
        timezone: 'Etc/UTC',
    };

    constructor(props) {
        super(props);

        const { timezone } = props;
        this.timeFormat = 'H:mm';

        this.state = {
            time: moment().tz(timezone).format(this.timeFormat),
        };
    }

    componentDidMount() {
        this.clockInterval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.clockInterval);
    }

    tick() {
        const { timezone } = this.props;

        this.setState({
            time: moment().tz(timezone).format(this.timeFormat),
        });
    }

    render() {
        const { time } = this.state;

        return time;
    }
}

export default Clock;
