import React, { useEffect, useRef } from 'react';
import { Box, Card, CardActions, CardContent, CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core';
import { SendRounded } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import theme from 'App/theme';
import { useUser } from 'client/hooks/user/useUser';
import { IUserMessage } from 'common/types/user-message';
import useOrientation from '../../hooks/useOrientation';

const useStyles = (props: { isLandscape: boolean; isRoom: boolean }) => {
    const showDeviceLandscapeMode = props.isLandscape && !props.isRoom;

    return makeStyles({
        root: {
            position: 'relative',
            width: '100%',
            height: '100%',
            borderTopLeftRadius: showDeviceLandscapeMode ? 0 : 10,
            borderTopRightRadius: showDeviceLandscapeMode ? 0 : 10,
            backgroundColor: '#e5ecef',
        },
        rootRoom: {
            position: 'relative',
            width: '100%',
            height: '100%',
            borderTopLeftRadius: showDeviceLandscapeMode ? 0 : 10,
            borderTopRightRadius: showDeviceLandscapeMode ? 0 : 10,
            borderBottomLeftRadius: showDeviceLandscapeMode ? 0 : 35,
            backgroundColor: '#e5ecef',
        },
        darkText: {
            color: '#000',
        },
        chatHeader: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: 15,
        },
        chatHeaderTitle: {
            fontSize: '1rem',
        },
        chatHeaderTitleMessage: {
            marginTop: 4,
        },
        chatContent: {
            maxHeight: 'calc(90% - 90px)',
            height: 'calc(90% - 90px)',
            overflowY: 'auto',
        },
        chatContentAuthor: {
            padding: 0,
            margin: 0,
        },
        chatContentMessageLeft: {
            backgroundColor: '#fff',
            padding: '10px 7px;',
            borderRadius: 7,
            fontWeight: 300,
        },
        chatContentMessageRight: {
            backgroundColor: '#abc8d6',
            padding: '10px 7px;',
            borderRadius: 7,
            fontWeight: 300,
        },
        chatActionInput: {
            borderRadius: 30,
            padding: 5,
            backgroundColor: '#fff',
            border: '2px solid #99a9b3',
            width: '100%',
            '&:hover': {
                borderColor: '#37505f',
            },
        },
        chatActionInputField: {
            color: '#617c8b !important',
            padding: 3,
        },
        chatActionInputButton: {
            backgroundColor: '#617c8b',
            padding: 8,
            '&:hover': {
                background: '#405865',
            },
        },
        chatActionInputButtonIcon: {
            color: '#fff !important',
            rotate: '-30deg',
            padding: 0,
            margin: '0 0px 0 3px !important',
        },
    });
};

interface ChatCardProps {
    messageList: IUserMessage[];
    isRoom: boolean;
    isLoading?: boolean;
    alias: string;

    onMessageWasSent(message: IUserMessage): void;
}

const ChatCard: React.FC<ChatCardProps> = ({ messageList, onMessageWasSent, isRoom, isLoading, alias }) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape, isRoom })();
    return (
        <Card className={isRoom ? classes.rootRoom : classes.root} elevation={0}>
            <ChatHeader messages={messageList} isRoom={isRoom} />
            <ChatContent messages={messageList} isLoading={isLoading} isRoom={isRoom} />
            <ChatMessageBox onMessageWasSent={onMessageWasSent} alias={alias} isRoom={isRoom} />
        </Card>
    );
};

interface ChatMessageBoxProps {
    alias: string;
    isRoom: boolean;
    onMessageWasSent(message: IUserMessage): void;
}

const ChatMessageBox = ({ onMessageWasSent, alias, isRoom }: ChatMessageBoxProps) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape, isRoom })();
    const [newMessage, setNewMessage] = React.useState<string>('');
    const user = useUser();
    const username = user?.user?.attributes.email;

    const submitNewMessage = () => {
        if (!newMessage) return;
        if (newMessage.length <= 0) return;
        const body: IUserMessage = {
            data: {
                text: `${alias}:> ${newMessage}`,
            },
            type: 'text',
            author: username || 'Unknown',
            currentUser: true,
        };
        onMessageWasSent(body);
        setNewMessage('');
    };

    return (
        <CardActions>
            <Box className={classes.chatActionInput} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <InputBase
                    className={classes.chatActionInputField}
                    maxRows={1}
                    placeholder="Type here..."
                    size="small"
                    fullWidth
                    onChange={e => setNewMessage(e.target.value)}
                    onKeyDown={ev => {
                        if (ev.key === 'Enter') {
                            // Do code here
                            submitNewMessage();
                        }
                    }}
                    value={newMessage}
                />
                <IconButton size="small" color="secondary" onClick={() => submitNewMessage()} className={classes.chatActionInputButton}>
                    <SendRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} className={classes.chatActionInputButtonIcon} />
                </IconButton>
            </Box>
        </CardActions>
    );
};

interface ChatContentProps {
    messages: IUserMessage[];
    isRoom: boolean;
    isLoading?: boolean;
}

const ChatContent = ({ messages, isLoading, isRoom }: ChatContentProps) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape, isRoom })();
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        // @ts-ignore
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <CardContent className={classes.chatContent}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        flexDirection: 'column',
                    }}
                    style={{ gap: '15px' }}
                >
                    <CircularProgress />
                    <Typography variant="body2">Loading Chat History</Typography>
                </Box>
            ) : (
                <>
                    <div ref={messagesEndRef} />
                    {messages.map((message: IUserMessage, index: number) => (
                        <Box key={index} display="inline-flex" width={1} justifyContent={message.currentUser ? 'flex-end' : 'flex-start'}>
                            <Box
                                display="flex"
                                flexDirection={message.currentUser ? 'column-reverse' : 'column'}
                                py={1}
                                textAlign={message.currentUser ? 'right' : 'left'}
                            >
                                <Box order={message.currentUser ? 1 : 0} className={classes.chatContentAuthor}>
                                    <Typography variant="caption">{message.author}</Typography>
                                </Box>
                                <Box
                                    px={1}
                                    py={1}
                                    sx={{ flexDirection: message.currentUser ? 'row-reverse' : 'row' }}
                                    className={message.currentUser ? classes.chatContentMessageRight : classes.chatContentMessageLeft}
                                >
                                    <Typography
                                        variant="caption"
                                        style={{ wordBreak: 'break-word', display: 'inline-block', whiteSpace: 'pre-line' }}
                                    >
                                        {message.data.text}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </>
            )}
        </CardContent>
    );
};

const ChatHeader = ({ messages, isRoom }: ChatContentProps) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape, isRoom })();
    return (
        <>
            <Box className={classes.chatHeader}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="caption" className={classes.chatHeaderTitle}>
                        Live Chat
                    </Typography>
                    <Typography variant="caption" className={classes.chatHeaderTitleMessage}>
                        ({messages.length} Messages)
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default ChatCard;
