/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon {...props}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M 22.842 4.411 C 22.842 3.829 22.615 3.282 22.203 2.871 C 21.354 2.021 19.971 2.021 19.122 2.871 L 10.267 11.725 L 13.349 14.806 L 22.203 5.952 C 22.615 5.541 22.842 4.993 22.842 4.411 Z" />
        <path
            d="M 9.352 12.643 L 11.147 12.643 L 11.147 17 L 9.352 17 Z"
            transform="matrix(0.707107, -0.707107, 0.707107, 0.707107, -7.478374, 11.588608)"
        />
        <path d="M 7.506 15.189 L 0.858 21.837 L 4.605 21.685 C 5.387 21.654 6.121 21.332 6.674 20.779 L 9.885 17.568 Z" />
    </SvgIcon>
);
