import { useEffect } from 'react';
import { useUser } from 'client/hooks/user/useUser';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from './Layout';

export const MessagePage = () => {
    const { user } = useUser();

    const location = useLocation();
    const navigate = useNavigate();
    const previous = location.state?.from?.pathname || '/home';

    useEffect(() => {
        if (user) {
            // Navigate to previous location when authenticated.
            navigate(previous, { replace: true });
        }
    }, [navigate, previous, user]);

    return <Layout />;
};
