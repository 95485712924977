/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore
import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery, useQueryClient } from 'react-query';

type Response = api.ListOrdersResponse;
type Request = api.ListOrderFromPaymentIDRequest;

export function useListOrdersFromPaymentId(body: Request) {
    const { key, path } = config.orders.listFromPaymentId(body.paymentId);
    const queryClient = useQueryClient();

    const result = useQuery([key, body], () => Api.get<Response>({ path, body }), {
        onSuccess: data => {
            if (data) {
                data.forEach((element: any) => {
                    queryClient.setQueryData(['order', element.id], element);
                });
            }
        },
    });

    return {
        orders: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
