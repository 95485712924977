/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import theme from 'App/theme';
import { useGetAttendeeByProcedureId } from 'client/hooks/attendee/useGetAttendeeByProcedureID';
import CommonUtils from 'common/utils/CommonUtils';
import { VideoCamIcon, VideoCamOffIcon } from 'common/utils/Icons';
import { isNullOrUndefined } from 'common/utils/ObjectUtils';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import Panel from '../UI/Panel';

interface IAttendeeCountPanelProps {
    procedureId: number;
    enableAPI: boolean;
}

const useAttendeeCount = (procedureId: number, enableAPI: boolean = false) => {
    const { attendees, isLoading } = useGetAttendeeByProcedureId(procedureId, enableAPI);
    const numberOfAttendees = useMemo(() => attendees.length, [attendees]);
    return { attendees, numberOfAttendees, isLoading };
};

const AttendeeCountPanel = ({ procedureId, enableAPI }: IAttendeeCountPanelProps) => {
    const { attendees, numberOfAttendees, isLoading } = useAttendeeCount(procedureId, enableAPI);
    if (!attendees || isLoading) return <Skeleton />;
    return <Typography align="center">{numberOfAttendees}</Typography>;
};

const styles: any = {
    box: {
        border: '1px',
    },
    boxHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    boxAccepted: {
        backgroundColor: 'green',
    },
    boxAlarm: {
        backgroundColor: 'red',
    },
    boxInactive: {
        backgroundColor: theme.palette.grey[500],
    },
    boxHeaderText: {
        color: theme.palette.primary.contrastText,
    },
    w100: {
        width: '100%',
        display: 'block',
    },
    pcontainer: {
        maxHeight: '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollX: 'hidden',
    },
};
const useStyles = makeStyles(styles);

interface ADeviceProcedurePanelProps {
    procedures: Array<any>;
    enableAPI: boolean;
}

const ADeviceProcedurePanel = ({ procedures, enableAPI }: ADeviceProcedurePanelProps) => {
    const classes = useStyles();
    const { reset } = useQueryErrorResetBoundary();
    if (isNullOrUndefined(procedures)) return <></>;

    return (
        <Panel elevation={0}>
            <Grid container>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <Typography align="center" variant="h5">
                        Procedures
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography align="right">({`${procedures.length} procedures`})</Typography>
                </Grid>
            </Grid>
            {procedures && procedures.length > 0 && (
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    ID
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Doctor
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Title
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Patient
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Date
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Length
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Company
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Brand
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Device
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Attendance
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className={classes.box}>
                            <Box className={classes.boxHeader}>
                                <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                    Active
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            )}
            <div className={classes.pcontainer}>
                {procedures &&
                    procedures.length > 0 &&
                    procedures.map(procedure => (
                        <Grid container spacing={1} key={procedure.id}>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Box>
                                        <Typography align="center" variant="body2" gutterBottom noWrap>
                                            {procedure.id}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Box>
                                        <Typography align="center" variant="body2" gutterBottom noWrap>
                                            {procedure.doctorName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Box>
                                        <Typography align="center" variant="body2" gutterBottom noWrap>
                                            {procedure.surgeryTitle}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Box>
                                        <Typography align="center" variant="body2" gutterBottom noWrap>
                                            {`${procedure.patientInitials}`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box className={classes.box}>
                                    <Typography align="center" variant="body2" noWrap>
                                        {new Date(procedure.startTime).toLocaleString('en-US', { timeZone: procedure?.timeZone })}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Typography align="center" variant="body2" noWrap>
                                        {procedure.endTime
                                            ? `${CommonUtils.calculateTimeSpentBasedOnDateFormatted(procedure.startTime, procedure.endTime)}`
                                            : '-'}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Typography align="center" variant="body1" noWrap>
                                        {procedure.companyName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Typography align="center" variant="body1" noWrap>
                                        {procedure.brandName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Typography align="center" variant="body1" noWrap>
                                        {procedure.deviceName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <ErrorBoundary
                                        onReset={reset}
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        fallbackRender={({ resetErrorBoundary }) => (
                                            <div>
                                                <p>Oops! Something went wrong...</p>
                                                <Button onClick={() => resetErrorBoundary()}>Try again</Button>
                                            </div>
                                        )}
                                    >
                                        <React.Suspense fallback={<Skeleton />}>
                                            <AttendeeCountPanel procedureId={procedure.id} enableAPI={enableAPI} />
                                        </React.Suspense>
                                    </ErrorBoundary>
                                </Box>
                            </Grid>
                            <Grid item xs={1}>
                                <Box className={classes.box}>
                                    <Typography align="center" variant="h6">
                                        {procedure.isActive === true ? <VideoCamIcon /> : <VideoCamOffIcon />}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
            </div>
        </Panel>
    );
};

export default ADeviceProcedurePanel;
