import { config } from 'circulator/hooks/config';
import { useDeviceLogin } from 'circulator/hooks/device/useDeviceLogin';
import { getCurrentUserWithAttrs } from 'common/services/userServices';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export const useUser = () => {
    const { deviceLogin } = useDeviceLogin();
    const key = config.user.me();

    const result = useQuery(key, getCurrentUserWithAttrs, {
        onSuccess: async user => {
            // Login device if user is undefined.
            if (!user) {
                await deviceLogin();
            }
            Api.setAuthorizationHeaders(user);
        },
        onError: () => {
            // Clear header on error.
            Api.setAuthorizationHeaders(undefined);
        },
    });

    return {
        user: result.data,
        isLoading: result.isLoading,
    };
};
