import React from 'react';

export function useReadAsDataUrl(blob: Blob | null | undefined) {
    const [data, setData] = React.useState<string>();

    React.useEffect(() => {
        if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setData(reader.result);
                } else {
                    setData(undefined);
                }
            };
        } else {
            setData(undefined);
        }
    }, [blob]);

    return data;
}
