import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        textAlign: 'center',
        margin: theme.spacing(2),
        height: '100%',
    },
});

function Panel({ children, elevation = 2, classes, style }) {
    return (
        <Paper className={classes.root} style={style} elevation={elevation} variant={Boolean(elevation) ? 'elevation' : 'outlined'}>
            {children}
        </Paper>
    );
}

Panel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    elevation: PropTypes.number,
    classes: PropTypes.object.isRequired,
    style: PropTypes.object,
};

Panel.defaultProps = {
    elevation: 3,
};

export default withStyles(styles)(Panel);
