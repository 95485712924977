/* eslint-disable @typescript-eslint/no-explicit-any */
import { listFaciltyLicensesService } from 'common/services/licenseServices';
import { useQuery } from 'react-query';

export const useListFacilityLicenses = (facilityId: number) => {
    const result = useQuery(['device', 'facility', facilityId, 'licenses'], listFaciltyLicensesService(facilityId), {
        enabled: facilityId !== 0,
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    const licenses = result.data ? result.data.data : [];
    return {
        licenses,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
};
