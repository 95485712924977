import React from 'react';
import { loadConfig, asyncWithConfigProvider } from '@kikoda/generated-config-hooks';
import { Amplify } from 'aws-amplify';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { App } from './App';
import { WebConfig } from './web-config';

const render = async (host = '') => {
    const config = await loadConfig<WebConfig>(host);
    const ConfigProvider = await asyncWithConfigProvider<WebConfig>({ host, config });

    /**
     * If no host is provided during axios calls, axios will used the `baseUrl` default.
     * This will allow us to piecemeal out the current API to new methods implemented in
     * AWS.
     */
    axios.defaults.baseURL = config.additionalConfig?.apiEndpoint;

    if (config.additionalConfig?.auth) {
        Amplify.configure(config.additionalConfig.auth);
        Amplify.configure(config.additionalConfig.storage);
    }

    /**
     * @here
     * With `ConfigProvider`, we can access the config in any component using the `@kikoda/generated-config-hooks.useConfig()` hook.
     */

    ReactDOM.render(
        <React.StrictMode>
            <ConfigProvider>
                <App />
            </ConfigProvider>
        </React.StrictMode>,
        document.getElementById('app')
    );
};

// Async blocking render
render();
