import React, { useCallback, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
import { useGetUser } from 'circulator/hooks/user/useGetUser';
import { Player } from 'common/components/Player';
import { Procedure } from 'common/types';
import logo from 'img/logo-black.svg';

const styles = {
    root: {
        position: 'relative',
        height: '100vh',
        maxHeight: '100vh',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    feedOffset: {
        bottom: theme.spacing(4),
    },
    drawerOffset: {
        bottom: theme.spacing(1),
    },
    flexGrow: {
        flexGrow: 1,
    },
    videoControls: {
        position: 'absolute',
        zIndex: 2222,
        width: '100%',
    },
    videoMenu: {
        borderRadius: theme.shape.borderRadius,
    },
    paleBg: {
        background: 'rgba(255,255,255)',
        height: '100%',
    },
    gridContainer: {
        gridAutoColumns: '1fr',
        gridAutoFlow: 'column',
    },
    panel: {
        padding: theme.spacing(2),
    },
    heading: {
        marginBottom: theme.spacing(1),
    },
    shadeDown: {
        position: 'absolute',
        zIndex: 1111,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    shadeUp: {
        display: 'none',
    },
    shadeMain: {
        backgroundImage: `url(${logo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
    },
    logoSubhead: {
        color: 'black',
        position: 'relative',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        top: '15%',
        letterSpacing: theme.spacing(0.5),
        textTransform: 'uppercase',
    },
    userOverlay: {
        position: 'absolute',
        zIndex: 1111,
        bottom: 0,
        border: '1px',
        backgroundColor: '#efefef',
        width: '100%',
    },
    userSubhead: {
        color: 'black',
        position: 'relative',
        top: '85%',
        letterSpacing: theme.spacing(0.5),
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(2),
    },
    userSubSubhead: {
        color: 'black',
        position: 'relative',
        top: '85%',
        width: '100%',
        letterSpacing: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        minWidth: '75vw',
        maxWidth: '100%',
    },
    companyLogo: {
        maxHeight: 64,
        maxWidth: 128,
        paddingTop: theme.spacing(1),
        margin: 0,
    },
    tv: {
        marginTop: theme.spacing(2),
        height: '95vh',
        width: '100%',
        zIndex: 100,
    },
    tvHidden: {
        marginTop: theme.spacing(2),
        height: '95vh',
        width: '100%',
        zIndex: 100,
        display: 'none',
    },
};

// @ts-ignore
const useStyles = makeStyles(styles);
export interface FeaturedUserPlayerProps {
    procedure: Procedure;
    primaryAttendeeId: string;
}

export const TVPlayer: React.FC<FeaturedUserPlayerProps> = props => {
    const classes = useStyles();
    const { user: primaryAttendeeUser } = useGetUser(props.primaryAttendeeId);
    const [isStreaming, setStreaming] = useState(false);
    const handleOnPlaying = useCallback(() => {
        setStreaming(true);
    }, [setStreaming]);
    const handleOnStopping = useCallback(() => {
        setStreaming(false);
    }, [setStreaming]);

    const src = props.procedure.device.streams.find(x => x.isFeatured)?.src;

    return (
        <div className={classes.tv}>
            {isStreaming && primaryAttendeeUser ? (
                <div className={`${classes.userOverlay}`}>
                    <Grid container alignContent="center" alignItems="center" justifyContent="flex-start">
                        <Grid item xs={3}>
                            <img
                                src={primaryAttendeeUser.companyLogo ?? ''}
                                alt={primaryAttendeeUser.companyName ?? ''}
                                className={classes.companyLogo}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography align="left" variant="h5" className={classes.userSubSubhead}>
                                {primaryAttendeeUser.firstName} {primaryAttendeeUser.lastName}
                                {primaryAttendeeUser.position ? ` - ${primaryAttendeeUser.position}` : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div className={`${classes.shadeDown} ${classes.shadeMain}`}>
                    <Typography align="center" variant="h5" className={classes.userSubSubhead}>
                        Waiting for moderator to begin streaming...
                    </Typography>
                </div>
            )}

            <Player
                isActive={false}
                isMainPlayer={false}
                width="100%"
                height="100%"
                src={src}
                iceServers={JSON.stringify(props.procedure.device.iceServers)}
                className={isStreaming ? classes.tv : classes.tvHidden}
                obscure={false}
                muted={false}
                onPlaying={handleOnPlaying}
                onStopping={handleOnStopping}
                controls={false}
            />
        </div>
    );
};
