import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

export function useGetUsersSharedWith(procedureId: number) {
    const { key, path } = config.sharing.listUsersSharedWith(procedureId);
    const result = useQuery(key, () => Api.get({ path }), {
        enabled: isNotNullOrUndefined(procedureId),
    });
    return {
        users: result.data ?? null,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
