import BasicDomain from './BasicDomain';

export default class Device extends BasicDomain {
    get className() {
        return 'Device';
    }

    get myClass() {
        return Device;
    }

    constructor(props = {}) {
        super(props);

        this.id = props.id || null;
        this.uuid = props.uuid || props.UUID || null;
        this.name = props.name || '';
        this.roomName = props.roomName || '';
        this.facility = props.facility || {
            city: null,
            idNumber: null,
            name: null,
            phone: null,
            state: null,
        };
        this.tv = props.tv || null;
        this.createdAt = props.createdAt || null;
        this.updatedAt = props.updatedAt || null;
    }

    getLabel = () => {
        const { facility, roomName } = this;
        const { idNumber, name, city, state, phone } = facility;

        const strIdNumber = !!idNumber && `(${idNumber})`;
        const strAddress = [city, state].filter(e => !!e).join(', ');
        const strDescription = [name, strAddress, roomName, phone].filter(e => !!e).join(' - ');
        return [strIdNumber, strDescription].filter(e => !!e).join(' ');
    };
}
