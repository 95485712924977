import axios from 'axios';
// @ts-ignore
import { generateHeader } from 'common/utils/APIUtils';
import Config from 'Config';

const baseUrl = `${Config.apiUrl}/classes/Procedure`;

export const startProcedure = async procedure => axios.post('/procedures', procedure.getSaveToken(procedure.connectedDevices));
export const stopProcedure = async procedureId => axios.delete(`/procedures/${procedureId}`);

export const updateProdecure = async actionObject => {
    const headers = generateHeader();
    return axios.put(`${baseUrl}/${actionObject.id}`, actionObject.getSaveToken(), { headers });
};

export const getDeviceProcedure = () => axios.get(`/procedures/me/active`);

export const obscureProcedure = async procedureId => {
    const headers = generateHeader();
    return axios.post(`procedures/obscure`, { id: procedureId }, { headers });
};

export const unobscureProdecure = async procedureId => {
    const headers = generateHeader();
    return axios.post(`procedures/unobscure`, { id: procedureId }, { headers });
};
