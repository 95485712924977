import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../config';

export const useDeleteProcedureStream = (procedureId?: number) => {
    const queryClient = useQueryClient();
    const { key: invalidationKey } = config.procedure.vods(procedureId);
    const { notify } = useNotifier();
    const { key, path } = config.streams.deleteStream();
    const { mutateAsync, isLoading } = useMutation(
        key,
        async (s3Key: string) => {
            const body = { s3Key };
            const results = await Api.post({ path, body });
            return results;
        },
        {
            onSuccess: data => {
                queryClient.invalidateQueries({ queryKey: invalidationKey });
                notify(new Message({ title: 'Stream removed successfully', type: 'success' }));
            },
            onError: error => {
                notify(new Message({ title: `Unable to remove stream\n${error}`, type: 'error' }));
            },
        }
    );
    return { mutateAsync, isLoading };
};
