import { makeStyles } from '@material-ui/core';
import { useMaster } from 'common/hooks/WebRTC';
import { MasterType } from 'common/hooks/WebRTC/master-controller';
import { IceServer } from 'common/types';
import noVideo from 'img/video-placeholder.jpg';
import { MuteButton } from '../MuteButton';
import { VideoToggleButton } from '../VideoToggleButton';

export interface CutsJoinVideoPlayerProps {
    publishStreamId: string;
    publishUrl: string;
    publishToken: string;
    publishIceServers: IceServer[];
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flex: 1,
        maxHeight: '100%',
        maxWidth: '100%',
        background: 'black',
        position: 'relative',
        width: '100%',
    },
    video: {
        maxWidth: '100%',
        maxHeight: '100%',
        background: 'black',
        flex: '1 1 auto',
    },
}));

const videoElementId = 'cuts-local-video-output';

export const CutsJoinVideoPlayer: React.FC<CutsJoinVideoPlayerProps> = props => {
    const classes = useStyles();

    const { controller } = useMaster({
        videoElementId,
        streamId: props.publishStreamId,
        publishUrl: props.publishUrl,
        token: props.publishToken,
        iceServers: props.publishIceServers,
        isPublishingAudio: true,
        isPublishingVideo: true,
        canPublishVideo: true,
        masterType: MasterType.CUTS_MOBILE_JOIN,
    });

    return (
        <div className={classes.container}>
            <video className={classes.video} id={videoElementId} autoPlay playsInline poster={noVideo} muted />
            <VideoToggleButton controller={controller} />
            <MuteButton controller={controller} />
        </div>
    );
};
