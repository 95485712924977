import React, { useMemo } from 'react';
import { TablePagination } from '@mui/material';
import { useSearchProcedures } from 'client/hooks/procedure/useSearchProcedures';
import ByProcedurePanel from './ByProcedurePanel';

const ProcedureAnalytics = (): JSX.Element => {
    const { procedures } = useSearchProcedures({});
    const results = useMemo(
        () =>
            procedures
                .sort((a, b) => (a.id > b.id ? -1 : 1))
                ?.map(procedure => ({
                    id: procedure.id,
                    procedure: procedure,
                })) || [],
        [procedures]
    );

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Get the options to display on the current page
    const indexOfLastOption = (page + 1) * rowsPerPage;
    const indexOfFirstOption = indexOfLastOption - rowsPerPage;
    const currentOptions = results.slice(indexOfFirstOption, indexOfLastOption);

    return (
        <div>
            {currentOptions.map((procedure, index) => (
                <ByProcedurePanel key={procedure.id} procedure={procedure?.procedure} />
            ))}
            <TablePagination
                component="div"
                count={results.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default ProcedureAnalytics;
