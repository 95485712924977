import React from 'react';
import { Button, Modal } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Header from 'circulator/components/UI/Header';
import { useUpdateCampaign } from 'circulator/hooks/campaign/useUpdateCampaign';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import type { Campaign } from 'common/types';

interface Props {
    campaigns: Campaign[];
    isIdle?: boolean;
    debug?: boolean;
}

export const CampaignOverlay: React.FC<Props> = ({ campaigns, isIdle = false, debug = false }) => {
    const { updateCampaign } = useUpdateCampaign();

    const [autoplay, setAutoplay] = React.useState(false);
    const [filePlaying, setFilePlaying] = React.useState(-1);
    const [fileType, setFileType] = React.useState('jpg');
    const [seconds, setSeconds] = React.useState(0);
    const [maxSeconds, setMaxSeconds] = React.useState(5);
    const [open, setOpen] = React.useState(false);
    const [url, setURL] = React.useState('');

    const src = useGetProtectedObject(url);

    const reset = () => {
        setFilePlaying(-1);
        setMaxSeconds(5);
        setSeconds(0);
        setOpen(false);
    };

    React.useEffect(() => {
        let interval: any = null;
        setAutoplay(isIdle);
        if (isIdle && !open) {
            setOpen(true);
        }
        if (open && campaigns && campaigns.length > 0) {
            interval = setInterval(() => {
                setSeconds(seconds + 1);
            }, 1000);
            if (seconds === maxSeconds && !autoplay) {
                reset();
            }
            if (filePlaying < 0 && campaigns && campaigns.length > 0) {
                const newIndex = 0;
                setFilePlaying(newIndex);
                const itemData = campaigns[newIndex];

                updateCampaign({ campaignId: itemData.id, body: { incrementView: true } });
                setURL(itemData.file);
                setSeconds(0);

                if (itemData.lengthInSeconds > 0) {
                    setMaxSeconds(itemData.lengthInSeconds);
                } else {
                    setMaxSeconds(5);
                }
                const index = String(itemData.file).split('.');
                const parsedFileType = String(itemData.file).split('.')[index.length - 1];
                setFileType(parsedFileType);
                setOpen(true);
            } else if (filePlaying >= 0 && seconds === maxSeconds && autoplay) {
                const itemData = campaigns[filePlaying];
                updateCampaign({ campaignId: itemData.id, body: { incrementVtr: true } });

                const newIndex = filePlaying >= campaigns.length - 1 ? -1 : filePlaying;
                const nextItemData = campaigns[newIndex + 1];
                setURL(nextItemData.file);
                setSeconds(0);

                if (nextItemData.lengthInSeconds > 0) {
                    setMaxSeconds(nextItemData.lengthInSeconds);
                } else {
                    setMaxSeconds(5);
                }
                const index = String(nextItemData.file).split('.');
                const parsedFileType = String(nextItemData.file).split('.')[index.length - 1];
                setFileType(parsedFileType);
                setOpen(true);
                setFilePlaying(newIndex + 1);

                updateCampaign({ campaignId: nextItemData.id, body: { incrementView: true } });
            }
        } else if (!open && seconds !== 0) {
            clearInterval(interval);
            setFilePlaying(-1);
        }
        return () => clearInterval(interval);
    }, [open, seconds, isIdle, autoplay, campaigns, maxSeconds, filePlaying, updateCampaign]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = (selectedFileIndex: number) => async () => {
        const itemData = campaigns[selectedFileIndex];
        setURL(itemData.file);

        if (itemData.lengthInSeconds > 0) {
            setSeconds(0);
            setMaxSeconds(itemData.lengthInSeconds);
        }
        const index = String(itemData.file).split('.');
        const parsedFileType = String(itemData.file).split('.')[index.length - 1];
        setFileType(parsedFileType);
        setOpen(true);
        setAutoplay(true);
        setFilePlaying(selectedFileIndex);
    };

    let localFileIndex = 0;
    return (
        <Modal open={open || debug} onClose={handleClose}>
            <>
                <Header />

                {open && (fileType === 'jpg' || fileType === 'png') && (
                    <Paper
                        style={{
                            position: 'relative',
                            top: 0,
                            left: 0,
                            height: '100vh',
                            width: '100vw',
                            backgroundSize: 'cover',
                            backgroundImage: `url('${src}')`,
                            backgroundPosition: 'center',
                            zIndex: 9999,
                        }}
                        onClick={handleClose}
                    />
                )}
                {open && fileType === 'mp4' && (
                    <video
                        src={src}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100vh',
                            width: '100vw',
                            objectFit: 'cover',
                            zIndex: 9999,
                        }}
                        onClick={handleClose}
                        autoPlay
                        muted
                        loop
                    />
                )}
                {debug && (
                    <TableContainer style={!debug ? { display: 'none' } : {}} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right" />
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Facility ID</TableCell>
                                    <TableCell align="right">Device ID</TableCell>
                                    <TableCell align="right">File Name</TableCell>
                                    {debug && <TableCell align="right">Views</TableCell>}
                                    {debug && <TableCell align="right">Max Views</TableCell>}
                                    {debug && <TableCell align="right">View Through Rate</TableCell>}
                                    {debug && <TableCell align="right">Play Time (seconds)</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {campaigns.map(campaign => {
                                    const tableRows = (
                                        <TableRow key={campaign.id}>
                                            <TableCell align="right">
                                                <Button variant="contained" color="secondary" onClick={handleToggle(localFileIndex)}>
                                                    Preview
                                                </Button>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {campaign.description}
                                            </TableCell>
                                            <TableCell align="right">{campaign.facilityId}</TableCell>
                                            <TableCell align="right">{campaign.deviceId}</TableCell>
                                            <TableCell align="right">{campaign.file}</TableCell>
                                            {debug && <TableCell align="right">{campaign.views}</TableCell>}
                                            {debug && <TableCell align="right">{campaign.maxViews}</TableCell>}
                                            {debug && <TableCell align="right">{campaign.vtr}</TableCell>}
                                            {debug && <TableCell align="right">{Number(campaign.vtr) * Number(campaign.lengthInSeconds)}</TableCell>}
                                        </TableRow>
                                    );
                                    localFileIndex++;
                                    return tableRows;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </>
        </Modal>
    );
};
