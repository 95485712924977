import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type Response = api.SearchDoctorsResponse;
type Request = api.AssignDoctorAssistantRequest;

export const useAssignAssistant = () => {
    const { path } = config.doctors.assignAssistant();
    const { path: removePath } = config.doctors.removeAssistant();
    const queryClient = useQueryClient();
    const trans = useMutation(
        (body: Request) => {
            if (body.assistantId !== '') return Api.post<Response>({ path, body });
            else return Api.post<Response>({ path: removePath, body });
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries({ queryKey: [config.doctors.get().key] });
            },
        }
    );

    return {
        assignAssistant: trans.mutateAsync,
    };
};
