import { useState } from 'react';
import { Modal, Button, Box, AppBar, Toolbar, Typography, IconButton, Container, makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { CloseIcon } from 'common/utils/Icons';

const useStyles = makeStyles({
    outlinedWhite: {
        '& .MuiFormLabel-root': {
            color: 'white !important',
        },
        '& > .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white !important',
            },
            color: 'white !important',
        },
    },
    button: {
        width: '10px',
        height: '30px',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    modalContainer: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        height: 'auto',
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: '24',
        padding: 4,
    },
    flexGrow: { flexGrow: 1 },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999,
        color: 'white',
        opacity: 1,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
        },
    },
});

interface DeleteModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    nameOfObjectToBeDeleted: string;
    deleteMutateFn: any;
    jobId: string;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, setIsOpen, nameOfObjectToBeDeleted, deleteMutateFn, jobId }) => {
    const classes = useStyles();
    const { notify } = useNotifier();
    const [isWaiting, setIsWaiting] = useState(false);

    const handleYes = async () => {
        setIsWaiting(true);
        try {
            await deleteMutateFn(jobId);
            notify(new Message({ title: 'Successfully Deleted' }));
            setIsWaiting(false);
            setIsOpen(false);
        } catch (error) {
            notify(new Message({ title: 'An error occurred, please try again.' }));
            setIsWaiting(false);
        }
    };

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className={classes.modalContainer}>
                <AppBar>
                    <Toolbar className={classes.header}>
                        <Typography variant="h6" component="div" className={classes.flexGrow}>
                            Delete {nameOfObjectToBeDeleted}?
                        </Typography>
                        <IconButton aria-label="close" onClick={() => setIsOpen(false)} className={classes.closeIcon}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Container className={classes.container}>
                    <Typography variant="body1" component="div">
                        Are you sure you want to delete {nameOfObjectToBeDeleted}?<br />
                        <strong>This action cannot be undone.</strong>
                    </Typography>
                    <Container className={classes.buttonContainer}>
                        <Button disabled={isWaiting} variant="contained" color="primary" onClick={() => handleYes()}>
                            Yes
                        </Button>
                        <Button disabled={isWaiting} variant="contained" color="primary" onClick={() => setIsOpen(false)}>
                            No
                        </Button>
                    </Container>
                </Container>
            </Box>
        </Modal>
    );
};
