import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import theme from 'App/theme';
// import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import usePlayerControlsStore from 'common/hooks/usePlayerControlsStore';
import logo from 'img/logo-black.svg';
import type { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
// was @antmedia/videojs-webrtc-plugin
import 'videojs-webrtc-plugin';
import useOrientation from '../../hooks/useOrientation';
import CameraControls from '../CameraControls/CameraControls';

// @ts-ignore
const useStyles = (props: { isLandscape: boolean }) =>
    makeStyles({
        root: {
            overflow: 'hidden',
            position: 'relative',
        },
        videoScale: {
            '& .vjs-big-play-centered': {
                height: 'auto',
                '& > video': {
                    height: '100%',
                },
            },
        },
        h100: {
            height: '100%',
        },
        obscure: {
            filter: 'blur(12px)',
        },
        shadeDown: {
            position: 'absolute',
            zIndex: 1111,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(12px)',
        },
        shadeMain: {
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60%',
        },
        userOverlay: {
            position: 'absolute',
            zIndex: 1111,
            bottom: 0,
            border: '1px',
            backgroundColor: '#efefef',
            width: '100%',
        },
        userSubSubhead: {
            color: 'black',
            position: 'relative',
            top: '20%',
            width: '100%',
            letterSpacing: theme.spacing(0.5),
            fontSize: '0.8rem',
            maxWidth: '100%',
        },
    });

interface Props extends Omit<VideoJsPlayerOptions, 'sources' | 'height' | 'width'> {
    className?: string;
    obscure?: boolean;
    isMainPlayer?: boolean;
    isActive?: boolean;
    iceServers?: string;
    volume?: number;
    height?: number | string;
    width?: number | string;
    streamId?: number;
    startTime?: number;
    playerStartTime?: number;
    playerEndTime?: number;
    enablePTZControls?: boolean;
    onPlaying?: () => void;
    onStopping?: () => void;
    setCaptureTime?: (captureTime: number) => void;
}

// function isLive(player: VideoJsPlayer) {
//     const duration = player.duration();

//     // if we are in "live" mode, duration will be infinity
//     return !Number.isFinite(duration);
// }

// function seekToLatest(player: VideoJsPlayer) {
//     if (isLive(player)) {
//         const timeRanges = player.seekable();

//         if (timeRanges.length > 0) {
//             const end = timeRanges.end(0);
//             const current = player.currentTime();

//             if (end > current + 0.1) {
//                 player.currentTime(end);
//             }
//         }
//     }
// }

// function switchToLive(player: VideoJsPlayer, src: string) {
//     if (!isLive(player)) {
//         player.src([{ src, type: 'application/x-mpegURL' }]);
//     }
// }

function isStatic(src?: string) {
    if (!src) {
        return false;
    }

    return !src.includes('webrtc');
}

function retrySrc(player: VideoJsPlayer, src: string, iceServers?: string) {
    console.debug('*** Player retrying src');

    player.src({
        src,
        // this property is added twice due to a bug in the current ant media videojs plugin
        // @ts-ignore
        iceServers: iceServers,
        iceservers: iceServers,
    });
}

export const Player: React.FC<Props> = ({
    src,
    className = '',
    obscure,
    height,
    width,
    isMainPlayer,
    isActive,
    iceServers,
    streamId,
    startTime,
    playerStartTime,
    playerEndTime,
    enablePTZControls,
    onPlaying,
    onStopping,
    setCaptureTime,
    ...options
}) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape })();
    const videoRef = useRef<HTMLDivElement>(null);
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const [ready, setReady] = useState(false);
    const [playing, setPlaying] = useState(false);
    const isSrcStatic = isStatic(src);
    const setStreamCurrentTime = usePlayerControlsStore(state => state.setStreamCurrentTime);

    const updateCurrentTime = useCallback(
        (player: VideoJsPlayer, streamId: number) => {
            setStreamCurrentTime(streamId, player.currentTime());
        },
        [setStreamCurrentTime]
    );

    const getPlayer = useCallback((): VideoJsPlayer => {
        if (!playerRef.current) {
            const initialOptions: VideoJsPlayerOptions = {
                autoplay: 'muted',
                bigPlayButton: false,
                controls: false,
                fill: true,
                responsive: true,
                loop: true,
                // html5: {
                //     vhs: {
                //         experimentalLLHLS: true,
                //         experimentalBufferBasedABR: true,
                //     },
                // },
            };

            // Initialize the player.
            const videoElement = document.createElement('video-js');
            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current?.appendChild(videoElement);

            function readyCallback(this: videojs.Player): void {
                console.debug('Player onStopping');
                setPlaying(false);
                if (onStopping) {
                    // fire this during initialization since we aren't playing anything yet
                    onStopping();
                }

                this.on('ant-error', function (event, errors) {
                    console.error('Player ant-error', event, errors);
                });

                setReady(true);
            }

            playerRef.current = videojs(videoElement, initialOptions, readyCallback);
        }
        return playerRef.current;
    }, [playerRef, onStopping /*, seekToLatest, switchToLive*/]);

    // Call player initialization on initial render.
    useEffect(() => {
        getPlayer();
    }, [getPlayer]);

    // Bind event handlers.
    useEffect(() => {
        const player = getPlayer();

        player.on('playing', () => {
            console.debug('Player onPlaying');
            setPlaying(true);
            if (onPlaying) {
                onPlaying();
            }
        });

        if (!isSrcStatic) {
            const events = ['abort', 'ended', 'error', 'pause', 'waiting', 'suspend'];

            player.on(events, (a, b) => {
                console.debug('Player onStopping');
                setPlaying(false);
                if (onStopping) {
                    onStopping();
                }
            });
        }
    }, [getPlayer, onPlaying, onStopping, isSrcStatic]);

    // const staticSrc = useGetProtectedObject(src);
    // Start at start time if provided
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (ready && playing && isSrcStatic && startTime) {
            const player = getPlayer();

            timeout = setTimeout(() => player.currentTime(startTime + 1), 1000);
        }

        return () => {
            // clean up timeout
            clearInterval(timeout);
        };
    }, [getPlayer, ready, playing, isSrcStatic, startTime]);

    const checkEndTime = (player: VideoJsPlayer, playerEndTime: number, playerStartTime: number) => {
        if (player.currentTime() >= playerEndTime) {
            player.currentTime(playerStartTime || 0);
            player.play();
        }
    };
    useEffect(() => {
        const player = getPlayer();

        if (ready && isSrcStatic && playing && playerStartTime) {
            player.currentTime(playerStartTime);
        }
        player.off('timeupdate', checkEndTime);
        if (ready && isSrcStatic && playing && playerEndTime) {
            player.on('timeupdate', checkEndTime.bind(null, player, playerEndTime || player.duration(), playerStartTime || 0));
        }
        if (ready && playing && isSrcStatic && setCaptureTime) {
            player.on('timeupdate', () => setCaptureTime(player.currentTime()));
        }
    }, [getPlayer, ready, isSrcStatic, playing, playerStartTime, playerEndTime, setCaptureTime]);
    // Attach, or update, intervals for retrying src
    useEffect(() => {
        const intervals: NodeJS.Timeout[] = [];

        if (ready && src && !playing) {
            const player = getPlayer();

            intervals.push(setInterval(() => retrySrc(player, src, iceServers), 5000));
        }

        if (ready && src && playing && streamId && isSrcStatic) {
            const player = getPlayer();

            intervals.push(setInterval(() => updateCurrentTime(player, streamId), 1000));
        }

        return () => {
            // clean up intervals
            for (const interval of intervals) {
                clearInterval(interval);
            }
        };
    }, [getPlayer, ready, src, iceServers, playing, streamId, isSrcStatic, updateCurrentTime]);

    // Play video when player is ready or on src change.
    useEffect(() => {
        if (ready && src) {
            const player = getPlayer();

            // set new internvals
            // intervals.push(setInterval(() => seekToLatest(player), 1000));
            // intervals.push(setInterval(() => switchToLive(player, src), 3000));

            // start playing
            // player.src([{ src, type: 'application/x-mpegURL' }]);
            // if (isStatic(src) && staticSrc) {
            //     player.src({ src: staticSrc });
            // } else {
            player.src({
                src,
                // this property is added twice due to a bug in the current ant media videojs plugin
                // @ts-ignore
                iceServers: iceServers,
                iceservers: iceServers,
            });
            // }
        }
    }, [getPlayer, ready, src, /* staticSrc,*/ iceServers]);

    // Update options when changed.
    useEffect(() => {
        if (ready) {
            const player = getPlayer();

            if (options.controls !== undefined) {
                player.controls(options.controls);
            }

            if (options.muted !== undefined) {
                player.muted(options.muted);
            }

            if (options.poster !== undefined) {
                player.poster(options.poster);
            }

            if (options.volume !== undefined) {
                player.volume(options.volume / 100);
            }

            if (options.loop !== undefined) {
                player.loop(options.loop);
            }
        }
    }, [getPlayer, ready, options]);

    // Dispose the player when the component unmounts.
    useEffect(() => {
        console.debug('*** Player dispose: Setup');
        return () => {
            console.debug('*** Player dispose: Running');
            const player = playerRef.current;
            console.debug('*** Player dispose: player & isDisposed', player, player?.isDisposed());
            if (player && !player.isDisposed()) {
                console.debug('*** Player dispose: Disposing');
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const cameraControlsElement = useMemo(() => {
        if (enablePTZControls && isMainPlayer) {
            return <CameraControls />;
        } else {
            return null;
        }
    }, [enablePTZControls, isMainPlayer]);

    const obscureClassName = obscure ? classes.obscure : '';

    return (
        <div data-vjs-player className={`${classes.root} ${className} ${isLandscape && classes.videoScale}`} style={{ height, width }}>
            {isMainPlayer === false && isActive && (
                <div className={`${classes.shadeDown} ${classes.shadeMain}`}>
                    <Typography align="center" variant="h5" className={classes.userSubSubhead}>
                        Now playing...
                    </Typography>
                </div>
            )}
            <div className={`${classes.h100} ${obscureClassName}`} ref={videoRef} />
            {isMainPlayer && cameraControlsElement}
        </div>
    );
};
