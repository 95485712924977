import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress/CircularProgress';
import Select from '@material-ui/core/Select';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useGetProcedureListForExport } from 'client/hooks/videoExport/useGetProcedureListForExport';
import type { Facility } from 'common/types';
import { DatePicker } from 'mobile/components/DatePicker';
import { useListFacilitiesForCuts } from 'mobile/hooks/facility/useListFacilitiesForCuts';
import { useSearchParams } from 'react-router-dom';
import type { Procedure } from '../../../../../layers/database/nodejs/database';
import CutsLogo from '../../../img/cuts-logo.png';
import { BackgroundWrapper } from '../../components/UI/BackgroundWrapper';
import { useMobileLayouts } from '../../hooks/useMobileLayouts';
import { ArchivesDetailPanel } from './ArchivesDetailPanel';
import { ArchivesShareModal } from './ArchivesShareModal';
import { styles } from './ArchivesStyles';

const useGetMyFacilities = () => {
    const { facilities, isLoading } = useListFacilitiesForCuts();
    const data = facilities?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    return {
        facilities: data,
        isLoading,
    };
};

const formatTime = (date: Date | ConstructorParameters<typeof Date>[0]) => {
    const d = new Date(date);
    return `${d.getHours() % 12 === 0 ? 12 : d.getHours() % 12}:${d.getMinutes().toString().padStart(2, '0')}${d.getHours() > 11 ? ' PM' : ' AM'}`;
};

const useGetProceduresByFacilityAndDate = (
    facilityId: number,
    date: Date
): {
    data: Procedure[];
    isLoading: boolean;
    isFetching: boolean;
} => {
    //we want to get the current day, but toss out any time information, but keep the timezone.
    const startDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(0, 0, 0, 0);
    return useGetProcedureListForExport('', startDate, endDate, facilityId);
};

const useCustomStyles = makeStyles({
    formInput: {
        color: 'rgb(125,140,146)',
        width: '100%',
        height: '3em',
        backgroundColor: 'white',
        boxShadow: 'none',
        border: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
        padding: '1em',
        textAlign: 'center',
        '&:before, &:after': {
            borderBottom: 'none',
            border: 'none',
        },
        '& *, & *:focus, & *:before': {
            backgroundColor: 'white',
            border: 'none',
        },
        '& *:focus': {
            backgroundColor: 'white',
        },
    },
});

const SlidingState = {
    Open: 'open',
    Closed: 'closed',
    Opening: 'opening',
    Closing: 'closing',
} as const;

//eslint-disable-next-line @typescript-eslint/no-redeclare
type SlidingState = typeof SlidingState[keyof typeof SlidingState];

const toggleSlidingState = (state: SlidingState, setSlidingState: React.Dispatch<React.SetStateAction<SlidingState>>) => {
    const newState = state === SlidingState.Open || state === SlidingState.Opening ? SlidingState.Closed : SlidingState.Open;
    setSlidingState(oldState => {
        if (oldState !== 'open' && newState === SlidingState.Open) {
            return 'opening';
        } else if (oldState !== 'closed' && newState === SlidingState.Closed) {
            return 'closed';
        }
        return oldState;
    });
};

const useSlideStateTransition = (
    elementRef: React.MutableRefObject<HTMLDivElement>,
    state: SlidingState,
    setState: React.Dispatch<React.SetStateAction<SlidingState>>
) => {
    useEffect(() => {
        const handler = () => {
            setState(state === SlidingState.Opening ? SlidingState.Open : SlidingState.Closed);
        };
        let element: HTMLDivElement;
        if (elementRef.current) {
            element = elementRef.current;
            element.addEventListener('transitionend', handler);
            return () => element.removeEventListener('transitionend', handler);
        }
    }, [state, setState, elementRef]);
};

const ArchivesTableRow = ({ procedure }: { procedure: Procedure }) => {
    const detailPanelRef = useRef<HTMLDivElement>(document.createElement('div'));
    const [slidingState, setSlidingState] = useState<SlidingState>('closed');
    const onClick = useCallback(() => {
        toggleSlidingState(slidingState, setSlidingState);
    }, [slidingState, setSlidingState]);
    useSlideStateTransition(detailPanelRef, slidingState, setSlidingState);
    return (
        <>
            <div onClick={onClick} style={{ backgroundColor: '#8F8F8F' }}>
                {slidingState === 'closed' ? <ExpandMore /> : <ExpandLess />}
            </div>
            <div style={{ backgroundColor: '#8F8F8F' }}>
                <ArchivesShareModal procedureId={procedure.id}></ArchivesShareModal>
            </div>
            <div onClick={onClick} style={{ backgroundColor: '#8F8F8F' }}>
                {procedure.id}
            </div>
            <div onClick={onClick} style={{ backgroundColor: '#8F8F8F' }}>
                {procedure.title}
            </div>
            <div onClick={onClick} style={{ backgroundColor: '#8F8F8F' }}>
                {formatTime(procedure.startTime)}
            </div>
            <div onClick={onClick} style={{ backgroundColor: '#8F8F8F' }}>
                {procedure.patientInitials}
            </div>
            <ArchivesDetailPanel procedure={procedure} ref={detailPanelRef} state={slidingState}></ArchivesDetailPanel>
        </>
    );
};

const ArchivesTable = ({ procedures, isLoading }: { procedures: Procedure[]; isLoading: boolean }) => {
    return (
        <div style={{ flex: '1 1 auto', overflowY: 'hidden' }}>
            <div
                className={styles.grid6}
                style={{
                    width: '100%',
                    maxHeight: '100%',
                    overflowY: 'hidden',
                }}
            >
                <div className={styles.header}>
                    <div></div>
                    <div>Share</div>
                    <div>Num</div>
                    <div>Title</div>
                    <div>Time / Size</div>
                    <div>ID</div>
                </div>
                <div className={styles.data}>
                    {isLoading ? (
                        <div className={styles.fullWidthDataItem}>
                            <Spinner></Spinner>
                        </div>
                    ) : procedures.length === 0 ? (
                        <div className={styles.fullWidthDataItem}>No procedures found</div>
                    ) : (
                        procedures.map(procedure => <ArchivesTableRow procedure={procedure} key={procedure.id} />)
                    )}
                </div>
            </div>
        </div>
    );
};

const isValidDate = (date: Date): date is Date => {
    const d = new Date(date);
    return !isNaN(d.valueOf());
};

const JustTheDate = (date?: Date | string | number | null): Date => {
    const d = date ? new Date(date) : new Date();
    if (date && !isValidDate(d)) {
        return JustTheDate();
    }
    d.setHours(0, 0, 0, 0);
    return d;
};

const createNewSearchParams = (facilityId: number | null | undefined, archiveDate: Date | null | undefined) => {
    let newParams: { facility?: string; date?: string } = {};
    if (facilityId && facilityId !== 0) {
        Object.assign(newParams, { facility: facilityId.toString() });
    }
    if (archiveDate && isValidDate(archiveDate) && archiveDate <= new Date()) {
        Object.assign(newParams, { date: archiveDate.toISOString() });
    }
    return newParams;
};

export const ArchivesPage = () => {
    const [urlParams, setUrlParams] = useSearchParams();
    const [archiveDate, setArchiveDateState] = useState(JustTheDate(urlParams.get('date')));
    const [facilityId, setFacilityId] = useState(() =>
        urlParams.get('facility') && !isNaN(parseInt(urlParams.get('facility') ?? '')) ? parseInt(urlParams.get('facility') as string) : 0
    );
    const { facilities, isLoading: isLoadingFacilities } = useGetMyFacilities();

    const setArchiveDate = useCallback(
        (date: Date) => {
            setUrlParams(createNewSearchParams(facilityId, date));
            setArchiveDateState(date);
        },
        [setArchiveDateState, setUrlParams, facilityId]
    );
    const setFacility = useCallback(
        (id: number) => {
            setUrlParams(createNewSearchParams(id, archiveDate));
            setFacilityId(id);
        },
        [setUrlParams, archiveDate, setFacilityId]
    );
    const facility = useMemo(() => facilities.find(fac => fac.id === facilityId) ?? facilities[0], [facilities, facilityId]);
    useEffect(() => {
        if (!facility && facilities?.length && !isLoadingFacilities) {
            setFacility((facilities.at(0) as Facility).id);
        }
    }, [facility, facilities, isLoadingFacilities, setFacility]);
    const layouts = useMobileLayouts();
    const customStyles = useCustomStyles();
    const procedures = useGetProceduresByFacilityAndDate(facility?.id || 0, archiveDate);
    const selectRef = useRef<HTMLSelectElement>(null);

    return (
        <BackgroundWrapper backButtonVisible={true} backButtonHref="/mobile" headerImage={CutsLogo}>
            <div className={layouts.verticalContainer} style={{ justifyContent: 'flex-start' }}>
                <Typography variant="h6" style={{ textAlign: 'center', width: '100%', backgroundColor: 'transparent' }}>
                    Facility
                </Typography>
                <div
                    className={layouts.verticalContainer}
                    style={{
                        borderLeft: '1px solid black',
                        borderTop: '1px solid black',
                        borderRight: '1px solid black',
                        height: 'auto',
                        flex: '0 0 auto',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                >
                    {!isLoadingFacilities && (
                        <Select
                            fullWidth
                            native
                            ref={selectRef}
                            // style={{ borderRight: '1px solid black' }}
                            // className={customStyles.formInput}
                            value={facility?.id ?? ''}
                            // MenuProps={{
                            //     PaperProps: { style: { width: selectRef.current?.clientWidth, borderRadius: '0px', color: 'black' } },
                            //     anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                            //     getContentAnchorEl: null,
                            //     marginThreshold: 0,
                            //     transformOrigin: { vertical: 0, horizontal: 16 },
                            // }}
                            // onInput={event => {
                            //     const value = (event.target as HTMLSelectElement).value;
                            //     setFacility(Number(value));
                            // }}
                            onChange={event => {
                                const value = event.target.value;
                                setFacility(Number(value));
                                // setFacility((facilities.find(k => k.id === e.target.value) ?? (facilities.at(0) as Facility)).id);
                            }}
                        >
                            {facilities?.map(facility => (
                                <option style={{ width: '100%', paddingTop: '.25em', paddingBottom: '.25em' }} key={facility.id} value={facility.id}>
                                    {facility.name}
                                </option>
                            )) ?? null}
                        </Select>
                    )}
                </div>
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    Date
                </Typography>
                <div
                    className={layouts.verticalContainer}
                    style={{
                        borderLeft: '1px solid black',
                        borderTop: '1px solid black',
                        borderRight: '1px solid black',
                        height: 'auto',
                        flex: '0 0 auto',
                        width: '100%',
                        backgroundColor: 'white',
                        marginBottom: '.25em',
                    }}
                >
                    <DatePicker
                        selected={archiveDate}
                        className={customStyles.formInput}
                        maxDate={new Date()}
                        onChange={(date: Date | null) => {
                            setArchiveDate(date ?? new Date());
                        }}
                    ></DatePicker>
                </div>
                <ArchivesTable procedures={procedures.data ?? []} isLoading={procedures.isLoading}></ArchivesTable>
            </div>
        </BackgroundWrapper>
    );
};
