import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    link: {
        color: 'white',
        fontSize: '1rem',
        textDecoration: 'none',
    },
    footer: {
        padding: '1rem',
        minHeight: '3rem',
    },
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <Grid container alignContent="center" justifyContent="center">
            <Grid container item xs={12} justifyContent="center" className={classes.footer}>
                <Link className={classes.link} to="#">
                    Help&nbsp;/
                </Link>
                <Link className={classes.link} to="#">
                    &nbsp;FAQ
                </Link>
            </Grid>
        </Grid>
    );
}
