import React from 'react';
import { Button, Box, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, makeStyles, Typography } from '@material-ui/core';
import { useConsent } from 'client/hooks/consent/useConsent';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export function RequireConsent({ children }: { children?: JSX.Element }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const { Accepted, ConsentUpdate } = useConsent();

    const handleClose = () => {
        setOpen(false);
        ConsentUpdate(true);
    };

    if (Accepted === true) {
        return <>{children ? children : <Outlet />}</>;
    }

    return (
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle>Terms of use</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" color="primary">
                    <Typography variant="caption" display="block" gutterBottom>
                        Although the below requirements are covered in the VSURGIC website Terms of Use and the VSURGIC User Agreement, I agree to the
                        following Conditions of Use:
                    </Typography>
                    <Box sx={{ m: 2 }}></Box>
                    <Typography variant="caption" display="block" gutterBottom>
                        I agree:
                    </Typography>

                    <ul>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                To not to share my login to VSURGIC with anyone.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                To only access my VSURGIC account on a secure network.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                To only access VSURGIC procedures in a private, non-public, location.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                That my contact information (photo, logo, company name, phone number, credentials, email address, etc.) are accurate
                                as of today.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                That I am familiar with the requirements of the Health Insurance Portability and Accountability Act (HIPAA) and will
                                comply with those requirements.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                That I will not photograph, screenshot (screencapture), or record any audio or video of my VSURGIC session.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                That I am not employed by, or acting on behalf of, a competitor of VSURGIC.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="caption" display="block" gutterBottom>
                                That my VSURGIC session is watermarked in several ways and that any unauthorized recording or capture of VSURGIC
                                information (audio, video or other) may be traced back to my account and I may be personally liable for the
                                unauthorized use of this information.
                            </Typography>
                        </li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.flexCenter}>
                <Button variant="text" size="small" onClick={handleClose} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
