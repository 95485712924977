import { useState, useEffect } from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import login from 'img/login/background.png';
import logoWhite from 'img/white-logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { RegistrationThanksPanel } from './RegistrationThanks';
import { WelcomePanel } from './Welcome';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        minHeight: '100vh',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    visual: {
        backgroundImage: `url(${login})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        order: -1,
        [theme.breakpoints.up('md')]: {
            marginRight: -100,
            paddingRight: 100,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        padding: theme.spacing(4),
    },
    logo: {
        margin: 'auto',
    },
    links: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
    },
    panel: {
        backgroundColor: theme.palette.secondary.light,
        borderBottomLeftRadius: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(4),
    },
}));

export type Mode = 'thank-you' | 'welcome' | 'for-verify';

export const Layout = () => {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const [mode, setMode] = useState<Mode>('welcome');

    useEffect(() => {
        switch (id) {
            case 'thank-you':
                setMode('thank-you');
                break;
            case 'for-verify':
                setMode('for-verify');
                break;
            default:
                break;
        }
    }, [id]);

    return (
        <main className={classes.container}>
            <section className={classes.panel}>
                {mode === 'welcome' && <WelcomePanel />}
                {mode === 'thank-you' && <RegistrationThanksPanel />}
            </section>
            <section className={classes.visual}>
                <div className={classes.content}>
                    <img className={classes.logo} src={logoWhite} alt="vsurgic logo" width={300} />
                    <Typography className={classes.links} variant="caption" color="textSecondary">
                        &copy; Copyright VSurgic - All Rights Reserved
                        <div>
                            <Link component={RouterLink} to="/" color="textSecondary">
                                Privacy Policy
                            </Link>
                            &nbsp;-&nbsp;
                            <Link component={RouterLink} to="/" color="textSecondary">
                                Terms & Conditions
                            </Link>
                        </div>
                    </Typography>
                </div>
            </section>
        </main>
    );
};
