import React from 'react';
import { Button } from '@material-ui/core';
import { ProcedureList } from 'client/components/ProcedureList';
import { useListProcedures } from 'client/hooks/procedure/useListProcedures';
import Panel from 'common/components/UI/Panel';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { AttendeeStatus } from 'common/types';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { DisplayRoom } from './DisplayRoom';

const { REJECTED, JOINED } = AttendeeStatus;

export const UserHomePage = () => {
    const { reset } = useQueryErrorResetBoundary();
    return (
        <React.Suspense fallback={<ProcessingOverlay enabled />}>
            <ErrorBoundary
                onReset={reset}
                // eslint-disable-next-line react/no-unstable-nested-components
                fallbackRender={({ resetErrorBoundary }) => (
                    <Panel>
                        <p>Oops! Something went wrong...</p>
                        <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                            Try again
                        </Button>
                    </Panel>
                )}
            >
                <React.Suspense fallback={<ProcessingOverlay enabled />}>
                    <UserHome />
                </React.Suspense>
            </ErrorBoundary>
        </React.Suspense>
    );
};

export const UserHome = () => {
    const { procedures: allProcedures } = useListProcedures();

    const { reset } = useQueryErrorResetBoundary();
    const procedures = allProcedures.filter(procedure => procedure.attendee?.status !== REJECTED);
    const joinedProcedure = procedures.find(procedure => procedure.attendee?.status === JOINED);

    return joinedProcedure ? (
        <React.Suspense fallback={<ProcessingOverlay enabled />}>
            <ErrorBoundary
                onReset={reset}
                // eslint-disable-next-line react/no-unstable-nested-components
                fallbackRender={({ resetErrorBoundary }) => (
                    <Panel>
                        <p>Oops! Something went wrong...</p>
                        <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                            Try again
                        </Button>
                    </Panel>
                )}
            >
                <React.Suspense fallback={<ProcessingOverlay enabled />}>
                    <DisplayRoom procedureId={joinedProcedure.id} />
                </React.Suspense>
            </ErrorBoundary>
        </React.Suspense>
    ) : (
        <ProcedureList procedures={procedures} />
    );
};
