import { Fragment } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { Player } from 'common/components/Player';
import usePlayerControlsStore, { isAudioActive } from 'common/hooks/usePlayerControlsStore';
import { type ProcedureDetail, type Stream } from 'common/types';
import { SpeakersIcon, SpeakersOffIcon } from 'common/utils/Icons';
import { shallow } from 'zustand/shallow';
import { useLayouts } from '../../hooks/styles/useLayouts';
import { useStyles } from '../../hooks/styles/useStyles';

const useCustomStyles = makeStyles({
    videoTitle: {
        color: 'white',
        flex: '0 0 auto',
    },
    button: {
        width: 200,
        maxWidth: 200,
        color: 'black',
        padding: '0px',
        paddingLeft: '1px',
        border: 'none',
        '& .MuiButton-label': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textAlign: 'left',
            display: 'inline-block',
            fontSize: 12,
        },
        '& .MuiButton-startIcon': {
            display: 'inline-block',
            transform: 'translateY(5px)',
            marginRight: '0px',
        },
    },
});

interface Props {
    streams: Stream[];
    iceServers: ProcedureDetail['device']['iceServers'];
}

export function AudioOnlyToggleList({ streams, iceServers }: Props) {
    const customClasses = useCustomStyles();
    const classes = useStyles();
    const layouts = useLayouts();
    const [activeAudioStreamIds, toggleAudioActive] = usePlayerControlsStore(state => [state.activeAudioStreamIds, state.toggleAudioActive], shallow);

    return (
        <div className={layouts.verticalContainer}>
            <Box bgcolor={theme.palette.primary.dark} width={'100%'} paddingLeft={'1em'}>
                <Typography variant="caption" className={customClasses.videoTitle}>
                    Audio
                </Typography>
            </Box>
            <div className={classes.videoFrameSmall} style={{ cursor: 'auto', background: 'white', overflowY: 'auto', overflowX: 'clip' }}>
                {streams.map(stream => {
                    const isManuallyMuted = !isAudioActive(activeAudioStreamIds, stream.id);

                    return (
                        <Fragment key={stream.id}>
                            <Player
                                className={layouts.hidden}
                                isActive={false}
                                src={stream.src}
                                width={'100%'}
                                height={'100%'}
                                iceServers={JSON.stringify(iceServers)}
                                obscure={false}
                                isMainPlayer={false}
                                muted={isManuallyMuted}
                                streamId={stream.id}
                            />
                            <Button
                                size="small"
                                className={customClasses.button}
                                onClick={() => toggleAudioActive(stream.id)}
                                startIcon={isManuallyMuted ? <SpeakersOffIcon /> : <SpeakersIcon />}
                            >
                                {stream.name}
                            </Button>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
}
