import { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogContentText, Typography, makeStyles } from '@material-ui/core';
import { useUpsertUserFacilities } from 'client/hooks/user/useUpsertUserFacilities';
import { FacilityInfo } from 'common/types';

const styles = {
    btnCancel: {
        border: '1px solid #2b333f',
        color: '#2b333f',
        '&:active': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:hover': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:focus': {
            backgroundColor: '1px solid #2b333f',
            boxShadow: 'none',
        },
        '&:disabled': {
            backgroundColor: '#eee !important',
            color: '#ccc !important',
        },
    },
    btnCOnfirm: {
        marginLeft: '5px',
    },
};

const useStyles = makeStyles(styles);

interface IFacilityAssignmentDialog {
    open: boolean;
    userId: string;
    userFacilities: number[];
    update: (data: IUserFacilitiesData) => void;
    close: () => void;
    facilityList: FacilityInfo[];
}

export interface IUserFacilitiesData {
    userId: string;
    facilities: number[];
}

export const FacilityAssignmentDialog = ({ open, userId, userFacilities, update, close, facilityList }: IFacilityAssignmentDialog) => {
    const classes = useStyles();
    const [acceptedFacilities, setAcceptedFacilities] = useState<number[]>([]);
    const { updateUserFacilities } = useUpsertUserFacilities();
    const [isProgress, setProgress] = useState(false);

    useEffect(() => {
        const facilitysList = [...userFacilities];
        setAcceptedFacilities(facilitysList);
        setProgress(false);
    }, [userFacilities]);

    const handleAddAcceptedFacility = (newFacility: number) => {
        const arr = [...acceptedFacilities, newFacility];
        setAcceptedFacilities(arr);
    };
    const handleRemoveAcceptedFacility = (facility: number) => {
        const arr = acceptedFacilities.filter(r => r !== facility);
        setAcceptedFacilities(arr);
    };

    const handleFacilityChange = (newFacility: number) => {
        if (acceptedFacilities.includes(newFacility)) {
            handleRemoveAcceptedFacility(newFacility);
        } else {
            handleAddAcceptedFacility(newFacility);
        }
    };

    const handelConfirm = async () => {
        setProgress(true);
        const data: IUserFacilitiesData = {
            userId: userId,
            facilities: acceptedFacilities,
        };
        const res = await updateUserFacilities(data);
        if (res.success === true) {
            update(data);
        }
    };

    return (
        <>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText color="primary">
                        <Box p={2} style={{ width: '25vw' }}>
                            <Typography variant="h6">Facilities Assigment</Typography>
                            <Typography>Please select facilitys to assign. Click confirm to save changes.</Typography>
                            <Box p={1}></Box>
                            {facilityList.map((row: FacilityInfo) => (
                                <Box key={row.id} display="flex" alignItems="center">
                                    <Checkbox
                                        id={`${row.id}-checkbox`}
                                        name={`${row.id}-checkbox`}
                                        color="primary"
                                        required={false}
                                        checked={acceptedFacilities.includes(row.id)}
                                        onChange={() => handleFacilityChange(row.id)}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <Typography variant="body1">{row.name}</Typography>
                                </Box>
                            ))}
                            <Box p={1}></Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1, justifyContent: 'flex-start' }}>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    color="secondary"
                                    onClick={close}
                                    disabled={isProgress}
                                    className={classes.btnCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    autoFocus
                                    variant="contained"
                                    size="medium"
                                    onClick={handelConfirm}
                                    disabled={isProgress}
                                    className={classes.btnCOnfirm}
                                >
                                    Confirm
                                    <Box sx={{ ml: 1, mr: 1 }}>{isProgress ? <CircularProgress size={'0.8rem'} color="inherit" /> : <></>}</Box>
                                </Button>
                            </Box>
                            <Box p={2}></Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};
