import { Button, makeStyles } from '@material-ui/core';
import { MessageBox } from 'common/components/UI/MessageBox';
import Message from 'common/models/Message';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    msg: {
        padding: theme.spacing(2),
        textIndent: 30,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const RegistrationThanksPanel = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const goto = (path: string) => {
        navigate(path);
    };
    return (
        <>
            <MessageBox
                message={
                    new Message({
                        title: 'Account Verification Successful',
                        details: 'Thank you for creating your VSURGIC Live account.',
                        type: 'success',
                    })
                }
            />
            <p className={classes.msg}>
                Please wait while verify your information. In the meantime, you will not be able to login to your account. We are committed to having
                a community of medical professionals. We may reach out to verify your info via email or phone. You will receive an email when your
                account is ready. Please check your spam and junk folders for the email.
            </p>
            <div className={classes.actions}>
                <Button size="small" onClick={() => goto('/home')}>
                    Back to Sign In Page
                </Button>
            </div>
        </>
    );
};
