import { config } from 'client/hooks/config';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { IVideoExportJob } from 'common/types';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type Response = IVideoExportJob;

type useClipUpdateProps = {
    jobId: string;
    isPublic: boolean;
    isShared: boolean;
};

export function useClipUpdate() {
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key, path } = config.clips.update();
    const { mutateAsync, isLoading } = useMutation(
        key,
        async ({ jobId, isPublic, isShared }: useClipUpdateProps) => {
            const results = Api.post<Response>({ path, body: { jobId, isPublic, isShared } });
            return results;
        },
        {
            onSuccess: () => {
                notify(new Message({ title: 'Updated successfully', type: 'success' }));
                queryClient.invalidateQueries(key);
                queryClient.invalidateQueries(config.clips.update().key);
            },
            onError: () => {
                notify(new Message({ title: 'Failed to update', type: 'error' }));
                queryClient.invalidateQueries(key);
            },
        }
    );
    return { updateClip: mutateAsync, isLoading };
}
