import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LogoutIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 32 32">
        <g transform="translate(-70.000000, -805.000000)">
            <path d="M102,811.048997 L102,830.949755 C102.007459,832.922326 100.56988,834.602344 98.6199532,834.899953 L85.6192697,836.949999 C85.4142606,836.982499 85.206736,836.998749 84.9992371,837 C83.9379333,837 82.9203758,836.578739 82.1703524,835.828701 C81.420329,835.078662 80.9990268,834.06116 80.9990268,832.99988 L80.9990268,823.99961 L91.9996051,823.99961 C92.5521462,823.99961 92.9996577,823.552108 92.9996577,822.99958 C92.9996577,822.447051 92.5521462,821.99955 91.9996051,821.99955 L73.412388,821.99955 L77.7050937,826.292159 L77.7063449,826.292159 C77.8951037,826.479665 78.0026101,826.734668 78.0026101,827.000916 C78.0038582,827.267174 77.8988543,827.523428 77.7100955,827.710953 C77.5225849,827.899708 77.2663282,828.004713 77.0000422,828.003461 C76.7337786,828.003461 76.4787668,827.895957 76.2912689,827.707203 L70.2909521,821.707023 C70.2134472,821.603269 70.1409442,821.494517 70.0759424,821.383269 C69.9746859,821.137012 69.9746859,820.862005 70.0759424,820.615758 C70.1409442,820.504503 70.2134504,820.395751 70.2909521,820.292004 L76.2912689,814.291824 C76.6838016,813.908069 77.3113226,813.910565 77.700079,814.298074 C78.0875874,814.686822 78.0901155,815.31436 77.7063289,815.706852 L73.4123432,819.999461 L80.998982,819.999461 L80.998982,808.999131 C80.998982,807.83036 81.5102729,806.719062 82.3990556,805.95904 C83.2878384,805.199017 84.4641562,804.866508 85.6190649,805.048996 L98.6197484,807.099042 C100.569867,807.396552 102.007459,809.076605 102,811.04924 L102,811.048997 Z" />
        </g>
    </SvgIcon>
);
