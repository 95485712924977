import { useCallback, useState } from 'react';

export const usePromotionDialog = () => {
    const [promotionDialogOpen, setPromotionDialog] = useState(false);

    const openPromotionDialog = useCallback(() => {
        setPromotionDialog(true);
    }, []);

    const closePromotionDialog = useCallback(() => {
        setPromotionDialog(false);
    }, []);

    return {
        promotionDialogOpen,
        openPromotionDialog,
        closePromotionDialog,
    };
};
