import { config } from 'client/hooks/config';
import { IProcedureVideoClip } from 'common/types';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = IProcedureVideoClip;

export function useClipInfo(jobId: string) {
    const { key, path } = config.clips.info(jobId);
    const result = useQuery([key], () => Api.get<Response>({ path }));
    return {
        data: result?.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
