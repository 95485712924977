import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import * as types from 'common/types';

const styles = {
    notesDialog: {
        minWidth: '600px',
    },
};
const useStyles = makeStyles(styles);

interface Props {
    procedure: types.Procedure;
    enabled: boolean;
    onClose: () => void;
}

export const ProcedureNotesDialog = ({ procedure, enabled, onClose }: Props) => {
    const classes = useStyles();
    return (
        <Dialog open={enabled} onClose={onClose}>
            <DialogTitle>{procedure.title}</DialogTitle>
            <DialogContent className={classes.notesDialog}>{procedure.notes}</DialogContent>
            <DialogActions>
                <Button variant="text" color="primary" onClick={onClose}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};
