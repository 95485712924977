import React, { useMemo, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import theme from 'App/theme';
import { useProcedureDelete } from 'client/hooks/procedure/useProcedureDelete';
import CommonUtils from 'common/utils/CommonUtils';
import { ExpandMore } from 'common/utils/Icons';
import AProcedureAttendeesPanel from './AProcedureAttendeesPanel';
import { ProcedureDeleteButton } from './ProcedureDeleteButton';
import ProcedureStreamsPanel from './ProcedureStreamsPanel';

interface IByProcedureProps {
    procedure: any;
}

const styles = {
    box: {
        border: '1px',
    },
    boxHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    boxAccepted: {
        backgroundColor: 'green',
    },
    boxAlarm: {
        backgroundColor: 'red',
    },
    boxHeaderText: {
        color: theme.palette.primary.contrastText,
    },
    w100: {
        width: '100%',
        display: 'block',
    },
};

const useStyles = makeStyles(styles);

const ByProcedurePanel = ({ procedure }: IByProcedureProps) => {
    const classes = useStyles();
    const { mutateAsync: procedureDelete, isLoading: isDeleteLoading } = useProcedureDelete(procedure.id);
    const myRef = useRef<null | HTMLDivElement>(null);

    const executeScroll = useMemo(() => () => myRef?.current?.scrollIntoView(), [myRef]);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    if (!procedure) return <Skeleton />;
    return (
        <Accordion className={classes.w100} expanded={expanded}>
            <AccordionSummary
                ref={myRef}
                onClick={() => {
                    setExpanded(!expanded);
                    executeScroll();
                }}
                expandIcon={<ExpandMore />}
                id="procedureHeader"
            >
                <Grid container>
                    <Grid item xs={4}>
                        <Typography align="left" noWrap>
                            ProcedureId: {procedure.id}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography align="center" noWrap>
                            {procedure.device?.tv && `(${procedure.device?.tv})`} {procedure.device?.roomName} - {procedure.device?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="right" noWrap>
                            {new Date(procedure.startTime).toLocaleString('en-US', { timeZone: procedure?.timeZone })} (
                            {CommonUtils.calculateTimeSpentBasedOnDateFormatted(procedure.startTime, procedure.endTime)})
                        </Typography>
                    </Grid>
                    <Grid onClick={e => e.stopPropagation()} item xs={1}>
                        <Typography align="right" noWrap>
                            <ProcedureDeleteButton isLoading={isDeleteLoading} handleDelete={procedureDelete} />
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.w100}>
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Box className={classes.box}>
                                <Box className={classes.boxHeader}>
                                    <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                        Doctor
                                    </Typography>
                                </Box>
                                <Typography align="center" variant="body1" noWrap>
                                    {procedure.doctorName}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className={classes.box}>
                                <Box className={classes.boxHeader}>
                                    <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                        Surgery Title
                                    </Typography>
                                </Box>
                                <Typography align="center" variant="body1" noWrap>
                                    {procedure.title}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box className={classes.box}>
                                <Box className={classes.boxHeader}>
                                    <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                        Patient Initials
                                    </Typography>
                                </Box>
                                <Typography align="center" variant="body1" noWrap>
                                    {procedure.patientInitials}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box className={classes.box}>
                                <Box className={classes.boxHeader}>
                                    <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                        Reference Number
                                    </Typography>
                                </Box>
                                <Typography align="center" variant="body1" noWrap>
                                    {procedure.referenceNumber}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className={classes.box}>
                                <Box className={classes.boxHeader}>
                                    <Typography align="center" variant="h6" className={classes.boxHeaderText} gutterBottom>
                                        Company Name
                                    </Typography>
                                </Box>
                                <Typography align="center" variant="body1" noWrap>
                                    {procedure.companyName || 'No Company Selected'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className={classes.box}>
                                <Box className={classes.boxHeader}>
                                    <Typography align="center" variant="h5" className={classes.boxHeaderText} gutterBottom>
                                        Brand Name
                                    </Typography>
                                </Box>
                                <Typography align="center" variant="body1" noWrap>
                                    {procedure.brandName || 'No Brand Selected'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className={classes.box}>
                                <Box className={classes.boxHeader}>
                                    <Typography align="center" variant="h5" className={classes.boxHeaderText} gutterBottom>
                                        Device Name
                                    </Typography>
                                </Box>
                                <Typography align="center" variant="body1" noWrap>
                                    {procedure.deviceName || 'No Device Selected'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <AProcedureAttendeesPanel procedureId={procedure?.id} enableAPI={expanded} />
                    <ProcedureStreamsPanel procedureId={procedure?.id} enabledAPI={expanded} />
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default ByProcedurePanel;
