import React from 'react';
import { Avatar, Box, Card, CardContent, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { PlayCircle } from '@mui/icons-material';
import { useGetUser } from 'circulator/hooks/user/useGetUser';
import { Attendee } from 'common/types';
import profilePlaceholder from 'img/profile-placeholder-colors.png';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxHeight: '55vh',
        padding: 10,
        borderRadius: 0,
    },
    boxHeader: {
        display: 'flex',
        flexDirection: 'column',
    },
    boxContent: {
        maxHeight: 'calc(95%)',
        height: 'calc(95%)',
        overflowY: 'auto',
    },
    boxAvatar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    avatar: {
        width: 25,
        height: 25,
        marginRight: 10,
    },
    expander: {
        float: 'right',
        position: 'absolute',
        right: 5,
        top: 5,
        padding: 0,
    },
});

interface ChatProps {
    participants: Attendee[];
    handleHiding: () => void;
}

export const ChatMembers: React.FC<ChatProps> = ({ participants, handleHiding }: ChatProps) => {
    const classes = useStyles();
    return (
        <>
            <Card className={classes.root}>
                <Box className={classes.boxHeader}>
                    <Typography variant="caption" style={{ fontWeight: 500, fontSize: '1rem' }}>
                        Total Participants
                    </Typography>
                    <Typography variant="caption" style={{ fontWeight: 300, fontSize: '0.7rem' }}>
                        ({participants.length} participants)
                    </Typography>
                    <IconButton className={classes.expander} title="Hide Participants" onClick={handleHiding}>
                        <PlayCircle sx={{ fontSize: 30 }} style={{ color: '#4a6473' }} />
                    </IconButton>
                </Box>
                <CardContent className={classes.boxContent}>
                    {participants.map(attendee => (
                        <React.Suspense key={attendee.id} fallback={<Skeleton variant="rect" height={80} />}>
                            <ChatMembersItem userId={attendee.userId} />
                        </React.Suspense>
                    ))}
                </CardContent>
            </Card>
        </>
    );
};

export type ChatPropsItem = {
    userId: string;
};

export const ChatMembersItem = ({ userId }: ChatPropsItem) => {
    const classes = useStyles();
    const { user } = useGetUser(userId);
    return (
        <Box className={classes.boxAvatar}>
            <Avatar alt="Avatar" src={profilePlaceholder} className={classes.avatar} />
            <Typography variant="caption">
                {user?.firstName} {user?.lastName}
            </Typography>
        </Box>
    );
};
