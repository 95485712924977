import React from 'react';
import { Avatar, Button, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import { ProcedureDetail } from 'common/types';
import { BusinessIcon, CloseIcon, DeviceIcon, DoctorIcon, FacilityIcon, PatientIcon, ProcedureIcon } from 'common/utils/Icons';
import StopWatchIcon from 'img/duration-icon.svg';
import { TimeElapsed } from 'mobile/components/TimeElapsed';

interface Props {
    procedure: ProcedureDetail | undefined;
    onLeaveRoom: () => void;
}

export const ProcedureSummary = ({ procedure, onLeaveRoom }: Props) => {
    const classes = useStyles();

    const { doctor, device, title, patientInitials, companyName, deviceName, brandName } = procedure ?? {};
    const { facility } = device ?? {};
    const doctorPhoto = useGetProtectedObject(doctor?.photoUrl);
    const facilityPhoto = useGetProtectedObject(facility?.photoUrl);

    return (
        <Grid container className={classes.root}>
            <div className={classes.item}>
                <Avatar variant="circular" src={doctorPhoto ?? ''}>
                    <DoctorIcon />
                </Avatar>
                <div>
                    <Typography variant="body2">{doctor?.name}</Typography>
                    <Typography variant="body2">{doctor?.specialty}</Typography>
                </div>
            </div>
            <div className={classes.item}>
                <Avatar variant="circular" src={facilityPhoto ?? ''}>
                    <FacilityIcon />
                </Avatar>
                <div>
                    <Typography variant="body2">{`(${device?.tv}) ${facility?.name}`}</Typography>
                    <Typography variant="body2">{facility?.streetAddress}</Typography>
                    <Typography variant="body2">{[facility?.city, facility?.state, facility?.zip].join(' ')}</Typography>
                    <Typography variant="body2">{facility?.phone}</Typography>
                </div>
            </div>
            <div className={classes.collection}>
                {[
                    { title: 'Procedure', icon: <ProcedureIcon />, value: title },
                    { title: 'Patient', icon: <PatientIcon />, value: patientInitials },
                    { title: 'Company', icon: <BusinessIcon />, value: companyName },
                    { title: 'Device', icon: <DeviceIcon />, value: deviceName },
                    { title: 'Brand', icon: <ProcedureIcon />, value: brandName && `${brandName} ™` },
                ]
                    .filter(({ value }) => value)
                    .map(({ title, icon, value }) => (
                        <div className={classes.item} key={title}>
                            <Tooltip title={title} arrow>
                                {icon}
                            </Tooltip>
                            <Typography variant="body2">{value}</Typography>
                        </div>
                    ))}
            </div>
            <div className={classes.item}>
                <Typography variant="body2">{`${new Date().toLocaleTimeString('en-us', {
                    timeZone: facility?.timeZone || 'America/New_York',
                    hour: '2-digit',
                    minute: '2-digit',
                })}`}</Typography>
            </div>
            <div className={classes.elapsedTime}>
                <img src={StopWatchIcon} alt="" className={classes.durationIcon} />
                <TimeElapsed startTime={procedure?.startTime ?? ''} timeZone={facility?.timeZone}></TimeElapsed>
            </div>
            <Button className={classes.leave} variant="contained" onClick={onLeaveRoom} color="primary" startIcon={<CloseIcon />}>
                Leave Room
            </Button>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        background: '#4a6473',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1, 4),
        padding: theme.spacing(2),
    },
    item: {
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing(2),
    },
    collection: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        flex: '1 1 0',
    },
    leave: {
        margin: 'auto',
    },
    elapsedTime: {
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing(2),
        border: '1px solid white',
        padding: '3px 8px 3px 7px',
        width: 'auto',
        borderRadius: '25px',
    },
    durationIcon: {
        filter: 'invert(98%) sepia(66%) saturate(0%) hue-rotate(10deg) brightness(101%) contrast(100%)',
        width: '18px',
    },
}));
