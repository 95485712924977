import { CircularProgress, IconButton } from '@material-ui/core';
import { DeleteIcon } from 'common/utils/Icons';

interface ProcedureDeleteButtonProps {
    handleDelete: any;
    isLoading: boolean;
}

export const ProcedureDeleteButton: React.FC<ProcedureDeleteButtonProps> = ({ isLoading, handleDelete }) => {
    if (isLoading) return <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />;
    return (
        <IconButton onClick={() => handleDelete()}>
            <DeleteIcon color="error" />
        </IconButton>
    );
};
