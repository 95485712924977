import { config } from 'circulator/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

type Response = api.ListAttendeesResponse;

export function useListAttendees(procedureId: number) {
    const { key, path } = config.attendee.list(procedureId);

    const result = useQuery(key, () => Api.get<Response>({ path }), {
        enabled: isNotNullOrUndefined(procedureId),
        refetchInterval: 4000,
    });

    return {
        attendees: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
}
