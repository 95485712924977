/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { generateHeader } from 'common/utils/APIUtils';
import CommonUtils from 'common/utils/CommonUtils';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

export const getAllAttendees = (procedureId: number) => {
    const headers = generateHeader();
    return axios.get(`/procedures/${procedureId}/attendees`, { headers });
};

const formatTimeSpent = (joinTimes: Array<string>, leaveTimes: Array<string>, procedureEndTime?: string) => {
    let timeSpent = 0;
    let usedProcedureEndTime = false;

    // console.log('joinTimes', joinTimes, 'leaveTimes', leaveTimes, 'procedureEndTime', procedureEndTime);

    joinTimes.forEach((time: string, index: number) => {
        // console.log('timeSpent', timeSpent);
        if (leaveTimes[index]) {
            timeSpent += CommonUtils.calculateTimeSpentBasedOnDateRaw(time, leaveTimes[index]) ?? 0;
            // console.log('new timeSpent (leave)', timeSpent);
        } else if (!usedProcedureEndTime && procedureEndTime) {
            usedProcedureEndTime = true;
            timeSpent += CommonUtils.calculateTimeSpentBasedOnDateRaw(time, procedureEndTime) ?? 0;
            // console.log('new timeSpent (proc)', timeSpent);
        }
    });

    return Math.round(timeSpent * 10) / 10;
};

export const useGetAttendeeByProcedureId = (procedureId: number, enabledAPI = false) => {
    const useListAllAttendeesForProcedure = useQuery(['attendee', procedureId, 'timeSpent', enabledAPI], () => getAllAttendees(procedureId), {
        enabled: isNotNullOrUndefined(procedureId) && enabledAPI,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        select: (data: AxiosResponse<any>) =>
            data.data.reduce((accumulator: any, currentAttendee: any) => {
                if (currentAttendee.joinTimes.length) {
                    accumulator.push({
                        ...currentAttendee,
                        timeSpent: formatTimeSpent(currentAttendee.joinTimes, currentAttendee.leaveTimes, currentAttendee.procedureEndTime),
                    });
                } else {
                    accumulator.push({ ...currentAttendee, timeSpent: null });
                }
                return accumulator;
            }, []),
    });
    return {
        attendees: useListAllAttendeesForProcedure.data ?? [],
        isLoading: false,
        isFetching: false,
        isFetched: false,
    };
};
