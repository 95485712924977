import { useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useCanUseCuts } from 'client/hooks/mobile/useCanUseCuts';
import { useUser } from 'client/hooks/user/useUser';
import { getUserInRegisteredUsers } from 'common/services/userServices';
import { BackgroundWrapper } from 'mobile/components/UI/BackgroundWrapper';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

/**
 * Wrapper component to require that the user can use cuts; redirects them back to the root otherwise.
 * @returns
 */
export const RequireCanUseCuts = (props: { children?: JSX.Element }) => {
    const location = useLocation();
    const { user, isLoading: isUserLoading } = useUser();
    const userRegistered = useMemo(() => getUserInRegisteredUsers(user), [user]);
    const { isLoading: isAssociatedWithFacilityLoading, canUseCuts } = useCanUseCuts(userRegistered);

    if (isAssociatedWithFacilityLoading || isUserLoading) {
        return (
            <BackgroundWrapper>
                <CircularProgress size={20} />
            </BackgroundWrapper>
        );
    }
    if (user && userRegistered && canUseCuts) {
        return <>{props.children ? <>{props.children}</> : <Outlet />}</>;
    } else {
        return <Navigate to={'/home'} state={{ from: location }} replace />;
    }
};
