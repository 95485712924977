/* eslint no-bitwise: ["error", { "allow": ["<<"] }] */
import BasicDomain from './BasicDomain';

export default class Message extends BasicDomain {
    get myClass() {
        return Message;
    }

    constructor(props = {}) {
        super(props);
        this.id = props.id || props.objectId || `${((Math.random() * 0xffffff) << 0).toString(16)}`;
        this.title = props.title || '';
        this.details = props.details || '';
        this.time = props.time || new Date();
        this.active = props.active == null || props.active === true;
        this.type = props.type || 'message';
        this.source = props.source || {};
    }

    getSecondsOld = () => (new Date() - this.time) / 1000;

    getLabel = () => (this.details != null && this.details !== '' ? `${this.title}: ${this.details}` : this.title);

    equals = obj => obj instanceof Message && this.id === obj.id;

    toString = () => this.getLabel();
}
