import { useMemo } from 'react';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useDeleteProcedureStream } from 'client/hooks/procedure/useDeleteProcedureStream';
import { useGetProcedureStreams } from 'client/hooks/procedure/useGetProcedureStreams';
import { useUser } from 'client/hooks/user/useUser';
import DataGrid from 'common/components/DataGrid/DataGrid';
import { getUserInAdminUsers } from 'common/services/userServices';
import AmplifyDownloadButton from 'common/utils/S3DownloadLink/S3DownloadLink';
import VideoPreview from '../VideoExport/VideoPreview';
import { StreamDeleteButton } from './StreamDeleteButton';

type procedureStreamsPanelProps = {
    procedureId?: number;
    enabledAPI?: boolean;
};
const BytesToMBConverter = ({ bytes }: { bytes: number }): JSX.Element => {
    const bytesToMB = (bytes: number) => {
        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2);
    };

    return (
        <div>
            <p>{`${bytesToMB(bytes)} MB`}</p>
        </div>
    );
};

const ProcedureStreamsPanel: React.FC<procedureStreamsPanelProps> = ({ procedureId, enabledAPI }) => {
    const { user } = useUser();
    const { mutateAsync: deleteStream, isLoading: isDeleteLoading } = useDeleteProcedureStream(procedureId);
    const { streams, isLoading } = useGetProcedureStreams({ procedureId: procedureId, enabled: enabledAPI });
    const userAdmin = useMemo(() => getUserInAdminUsers(user), [user]);

    const columns: GridColDef[] = [
        {
            field: 'previewKey',
            headerName: 'View/Download',
            flex: 0.5,
            renderCell: c => {
                return (
                    <>
                        <VideoPreview s3Key={c.value.s3Key} s3Filename={'View'} exporting={false} />
                        <span style={{ marginRight: '.5rem', marginLeft: '.5rem' }}> | </span>
                        <AmplifyDownloadButton s3Key={c.value.s3Key} showFilename={false} s3FileName={c.value.s3FileName} />
                    </>
                );
            },
        },
        {
            field: 'fileKey',
            headerName: 'File Name',
            flex: 1,
            renderCell: c => c.value.s3FileName || 'No File Name',
        },
        { field: 'streamName', headerName: 'Stream Name', flex: 0.33 },
        { field: 's3Size', headerName: 'File Size', flex: 0.4, renderCell: c => <BytesToMBConverter bytes={c.value} /> },
        { field: 'streamLocation', headerName: 'Source', flex: 0.75 },
        { field: 'streamOrder', headerName: 'Order', flex: 0.25 },
        {
            field: 'featuredStream',
            headerName: 'Moderator',
            flex: 0.75,
            renderCell: c => <>{c.value.streamIsFeatured ? `${c.value.user}` : 'No'}</>,
        },
        {
            field: 'canDelete',
            headerName: '',
            flex: 0.25,
            renderCell: c => {
                return c.value ? <StreamDeleteButton isLoading={isDeleteLoading} handleDelete={deleteStream} s3Key={c.value.s3Key} /> : null;
            },
        },
    ];

    const rows: GridRowsProp = useMemo(() => {
        if (!streams) return [];
        return streams.map((value, index) => ({
            id: index,
            ...value,
            fileKey: {
                s3FileName: value.s3FileName,
                s3Key: value.s3Key,
            },
            previewKey: {
                s3FileName: value.s3FileName,
                s3Key: value.s3Key,
            },
            featuredStream: { streamIsFeatured: value.streamIsFeatured, user: value.streamUserId },
            canDelete: { userAdmin: userAdmin, s3Key: value.s3Key },
        }));
    }, [streams, userAdmin]);
    return (
        <>
            <DataGrid
                loading={isLoading}
                actions={[]}
                rows={rows}
                columns={columns}
                hideFooterSelectedRowCount={true}
                sx={{ bgcolor: 'background.paper' }}
            />
        </>
    );
};

export default ProcedureStreamsPanel;
