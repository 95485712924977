import { Authenticator } from '@aws-amplify/ui-react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CampaignAnalyticsPage } from 'circulator/pages/CampaignAnalytics/CampaignAnalytics';
import { RequireAgreement } from 'client/components/Agreement/RequireAgreement';
import { RequireConsent } from 'client/components/Consent/RequireConsent';
import { AboutPage } from 'client/pages/AboutPage';
import AnalyticsPage from 'client/pages/Analytics/Analytics';
import DoctorsPage from 'client/pages/Doctors';
import DoctorsInfoPage from 'client/pages/Doctors/DoctorInfo';
import FacilitiesPage from 'client/pages/Facilities';
import FacilityDetailsPage from 'client/pages/Facilities/FacilityDetailsPage';
import { LoginPage } from 'client/pages/LoginPage';
import { PageMedia } from 'client/pages/Media';
import { MessagePage } from 'client/pages/Messages';
import PurchaseHistory from 'client/pages/Purchase/History/PurchaseHistory';
import Success from 'client/pages/Success/Success';
import TemplatesPage from 'client/pages/Templates';
import { UserAccount } from 'client/pages/UserAccount';
import { UserHome } from 'client/pages/UserHome/Home';
import { UserManagementPage } from 'client/pages/UserManagement';
import VideoExport from 'client/pages/VideoExport/VideoExportByProcedureList';
import { RequireAuth } from 'common/components/Auth/RequireAuth';
import { RequireCanUseCuts } from 'common/components/Auth/RequireCanUseCuts';
import { RequireExportView } from 'common/components/Auth/RequireExportView';
import { ActiveProcedureList } from 'mobile/pages/ActiveProcedureList';
import { ArchivesPage } from 'mobile/pages/Archives/ArchivesPage';
import { CreateOrArchive } from 'mobile/pages/CreateOrArchive';
import { JoinPage } from 'mobile/pages/JoinPage';
import { MobileHome } from 'mobile/pages/MobileHome';
import { ShotPage } from 'mobile/pages/ShotPage';
import { StartShotsPage } from 'mobile/pages/StartShots';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { CirculatorPage } from './Circulator';
import { UserClient } from './Client';
import theme from './theme';
import { TVPage } from './TV';

const queryClient = new QueryClient();

const AppRoutes = (
    <>
        <Routes>
            {/*root route gives easy modification in the future, like a new wrapper for prefetching.*/}
            <Route path="/" element={<Outlet />}>
                <Route path="circulator" element={<CirculatorPage />} />
                <Route path="messages" element={<MessagePage />} />
                <Route path="mobile" element={<RequireCanUseCuts />}>
                    <Route index element={<MobileHome />} />
                    <Route path="shot" element={<ShotPage />} />
                    <Route path="start-shot" element={<StartShotsPage />} />
                    <Route path="create-or-archives" element={<CreateOrArchive />} />
                    <Route path="archives" element={<ArchivesPage />} />
                    <Route path="active-procedure-list" element={<ActiveProcedureList />} />
                    <Route path="join" element={<JoinPage />} />
                </Route>
                <Route path="*" element={<Navigate to={'/'} />} />
                <Route path="">
                    <Route path="login" element={<LoginPage />} />
                    <Route
                        path=""
                        element={
                            <RequireAuth>
                                <UserClient />
                            </RequireAuth>
                        }
                    >
                        <Route path="campaign" element={<CampaignAnalyticsPage />} />
                        <Route path="tv/:roomNumber" element={<TVPage />} />
                        <Route path="success/:sessionId" element={<Success />} />
                        <Route path="">
                            <Route element={<RequireAgreement />}>
                                <Route element={<RequireConsent />}>
                                    <Route path="" element={<UserHome />} index />
                                    <Route path="account" element={<UserAccount />} />
                                    <Route path="about" element={<AboutPage />} />
                                    <Route path="user-management" element={<UserManagementPage />} />
                                    <Route
                                        path="video-export"
                                        element={
                                            <RequireExportView>
                                                <VideoExport />
                                            </RequireExportView>
                                        }
                                    />
                                    <Route
                                        path="analytics"
                                        element={
                                            <RequireAuth admin={true} indexPath="/">
                                                <AnalyticsPage />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route path="doctors" element={<DoctorsPage />} />
                                    <Route path="doctors/:id" element={<DoctorsInfoPage />} />
                                    <Route path="facilities" element={<FacilitiesPage />} />
                                    <Route path="facilities/:id" element={<FacilityDetailsPage />} />
                                    <Route path="purchase/history" element={<PurchaseHistory />} />
                                    <Route path="templates" element={<TemplatesPage />} />
                                    <Route path="media/:id" element={<PageMedia />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    </>
);

export const App = () => (
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Authenticator.Provider>
            <SnackbarProvider maxSnack={3}>
                <QueryClientProvider client={queryClient}>
                    <Router>{AppRoutes}</Router>
                    <ReactQueryDevtools position="bottom-right" />
                </QueryClientProvider>
            </SnackbarProvider>
        </Authenticator.Provider>
    </MuiThemeProvider>
);
