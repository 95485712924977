import { config } from 'circulator/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery, UseQueryOptions } from 'react-query';

type Options = Pick<UseQueryOptions, 'enabled'>;

export const useGetDevice = (options: Options = {}) => {
    const { key, path } = config.device.detail();

    const result = useQuery(key, () => Api.get({ path }), {
        ...options,
    });

    return {
        device: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
};
