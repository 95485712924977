import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Response = api.SignPdfResponse;

export interface SignPdfRequest {
    name: string;
    ip: string;
}

export const useSignAgreementPdf = () => {
    const { path: signPdfPath } = config.agreement.signPDF();
    const generatePdf = useMutation((data: SignPdfRequest) => Api.post<Response>({ path: signPdfPath, body: data }), {
        onSuccess: res => {
            console.log(res);
            if (res.success === true) {
                window.location.reload();
            }
        },
    });
    return {
        generatePdf: generatePdf.mutateAsync,
    };
};
