import { makeStyles } from '@material-ui/core';

declare global {
    interface Animation {
        animationName: string;
    }
}

function escapeRegex(str: string): string {
    return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

/**
 * Gets all elements starting with root element and working through the decendents where a computed value matches. If no value is given, it instead checks if there is an inline style for that property.
 * You can change the operator (default is CONTAINS); only CONTAINS is supported at the moment but this could be extended if needed.
 * @param element
 * @param property
 * @param value
 * @param operator
 * @returns
 */
export const getElementsByComputedProperty = (
    element: HTMLElement,
    property: keyof CSSStyleDeclaration,
    value?: string,
    operator: 'CONTAINS' = 'CONTAINS'
): HTMLElement[] => {
    const returnItems: HTMLElement[] = [];
    const styleDeclaration = window.getComputedStyle(element);
    const propertyValue = styleDeclaration.getPropertyValue(property.toString());
    if (value) {
        switch (operator) {
            case 'CONTAINS':
                if (propertyValue.match(new RegExp(`(?:^|\\s)${escapeRegex(value)}(?:$|\\s)`, 'ig'))) {
                    returnItems.push(element);
                }
                break;
        }
        if (propertyValue === value) {
            returnItems.push(element);
        }
    } else if (element.style[property] !== undefined) {
        returnItems.push(element);
    }
    if (element.children.length) {
        const children = Array.from(element.children).filter(child => child.nodeType === Node.ELEMENT_NODE) as HTMLElement[];
        for (let i = 0; i < children.length; i++) {
            returnItems.push(...getElementsByComputedProperty(children[i], property, value, operator));
        }
    }
    return returnItems;
};

export const useResetAnimation = (animationName: string) => {
    const { resetAnimation } = makeStyles({
        resetAnimation: {
            animation: 'none',
        },
    })();
    return () => {
        //Restart in progress animation
        document.getAnimations().forEach(animation => {
            if (animation.animationName === animationName) {
                animation.cancel();
                animation.play();
            }
        });

        //Start animations that have finished
        //@ts-expect-error -- it does not like 'animation-name' as a property but it is valid.
        getElementsByComputedProperty(document.body, 'animation-name', animationName).forEach(element => {
            requestAnimationFrame(() => {
                element.classList.add(resetAnimation);
                requestAnimationFrame(() => element.classList.remove(resetAnimation));
            });
        });
    };
};
