import axios, { AxiosRequestConfig } from 'axios';
import { ExtendedCognitoUser } from 'common/services/userServices';
import videojs from 'video.js';

interface IRequest {
    config?: AxiosRequestConfig;
    body?: any;
    path: string;
}

export class Api {
    public static clearAuthorizationHeaders() {
        // clear for axios calls
        delete axios.defaults.headers.common['Authorization'];

        // clear for video player calls
        videojs.Vhs.xhr.beforeRequest = options => {
            return options;
        };
    }

    public static setAuthorizationHeaders(user?: ExtendedCognitoUser) {
        if (user) {
            const session = user.getSignInUserSession();

            if (session) {
                const token = session.getIdToken().getJwtToken();

                // set for axios calls
                axios.defaults.headers.common.Authorization = token;

                // set for video player calls
                videojs.Vhs.xhr.beforeRequest = options => {
                    options.headers = {
                        Authorization: token,
                    };

                    return options;
                };
            } else {
                this.clearAuthorizationHeaders();
            }
        } else {
            this.clearAuthorizationHeaders();
        }
    }

    public static async delete<T = any>({ path, config }: IRequest): Promise<T> {
        const response = await axios.delete(path, config);
        return response.data;
    }

    public static async get<TData = any>({ path, config }: IRequest): Promise<TData> {
        const response = await axios.get(path, config);
        return response.data;
    }

    public static async patch<T = any>({ path, body, config }: IRequest): Promise<T> {
        const response = await axios.patch(path, body, config);
        return response.data;
    }

    public static async post<T = any>({ path, body, config }: IRequest): Promise<T> {
        const response = await axios.post(path, body, config);
        return response.data;
    }

    public static async put<T = any>({ path, body, config }: IRequest): Promise<T> {
        const response = await axios.put(path, body, config);
        return response.data;
    }
}
