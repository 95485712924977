import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import logo from 'img/logo-black.svg';

const useStyles = makeStyles(theme => ({
    shadeMain: {
        backgroundImage: `url(${logo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        zIndex: 1111,
        height: '30vh',
    },
    logoSubhead: {
        color: 'black',
        position: 'relative',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        top: '15%',
        letterSpacing: theme.spacing(0.5),
        textTransform: 'uppercase',
    },
}));
const NoProcedureBanner = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.shadeMain}`}>
            <Typography align="center" className={classes.logoSubhead}>
                No procedure started
            </Typography>
        </div>
    );
};

export default NoProcedureBanner;
