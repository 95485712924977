import React from 'react';
import { Collapse, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'App/theme';
import classnames from 'classnames';
import Message from 'common/models/Message';
import { ErrorIcon, InfoIcon, SuccessIcon, WarnIcon } from 'common/utils/Icons';
import PropTypes from 'prop-types';

const styles = {
    root: {},
    messageText: {
        display: 'inline-block',
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: 20,
        verticalAlign: 'top',
        marginBottom: 8,
    },
    icon: {
        float: 'left',
        marginRight: '0.25em',
    },
    error: {
        margin: 0,
        padding: '1em',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        textAlign: 'center',
        display: 'block',
    },
    warning: {
        margin: 0,
        padding: '1em',
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        textAlign: 'center',
        display: 'block',
    },
    info: {
        margin: 0,
        padding: '1em',
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.contrastText,
        textAlign: 'center',
        display: 'block',
    },
    success: {
        margin: 0,
        padding: '1em',
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        textAlign: 'center',
        display: 'block',
    },
};
const useStyles = makeStyles(styles);
export const MessageBox = props => {
    const classes = useStyles();
    const { message, icon, className, enabled } = props;
    const { title, type, details } = message;

    let myIcon = null;
    let myClass = null;

    switch (type) {
        case 'warning':
        case 'warn': {
            myIcon = <WarnIcon className={classes.icon} data-html2canvas-ignore />;
            myClass = classes.warning;
            break;
        }
        case 'message':
        case 'info': {
            myIcon = <InfoIcon className={classes.icon} data-html2canvas-ignore />;
            myClass = classes.info;
            break;
        }
        case 'success':
        case 'suc': {
            myIcon = <SuccessIcon className={classes.icon} data-html2canvas-ignore />;
            myClass = classes.success;
            break;
        }
        default: {
            myIcon = <ErrorIcon className={classes.icon} data-html2canvas-ignore />;
            myClass = classes.error;
        }
    }

    return (
        <Collapse in={enabled} unmountOnExit>
            <Paper className={classnames([className, myClass])}>
                <div className={classes.messageText}>
                    {icon ? React.cloneElement(icon, { className: classes.icon }) : myIcon}
                    {title}
                </div>
                {details === undefined || details === '' ? '' : <div>{details}</div>}
            </Paper>
        </Collapse>
    );
};

MessageBox.propTypes = {
    message: PropTypes.instanceOf(Message).isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    enabled: PropTypes.bool,
    className: PropTypes.string,
};
MessageBox.defaultProps = {
    icon: undefined,
    enabled: true,
    className: null,
};
