import React from 'react';
import { Avatar, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import type { User } from 'common/types';
import placeholder from 'img/profile-placeholder.png';
import useOrientation from '../../../common/hooks/useOrientation';

const useStyles = (props: { isLandscape: boolean }) =>
    makeStyles({
        companyLogo: {
            maxHeight: 64,
            maxWidth: 128,
            paddingLeft: theme.spacing(2),
        },
        infoBox: {
            display: 'flex',
            flexDirection: props.isLandscape ? 'row' : 'column',
            alignItems: props.isLandscape ? 'center' : 'start',
            minWidth: '100px',
            width: '25%',
        },
        infoText: {
            paddingLeft: theme.spacing(2),
        },
        profilePic: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            '& > svg': {
                width: '1.5em',
                height: '1.5em',
            },
        },
        grayedOut: {
            filter: 'opacity(0.5) grayscale(0.5)',
        },
        blackColor: {
            color: '#000000',
        },
    });

interface Props {
    user: User | undefined;
    grayedOut?: boolean;
}

export const UserListItem = ({ user, grayedOut }: Props) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape })();
    const profilePicture = useGetProtectedObject(user?.profilePicture);
    const companyLogo = useGetProtectedObject(user?.companyLogo);

    if (!user) return <></>;
    return (
        <>
            <ListItemAvatar>
                <Avatar
                    className={classes.profilePic + ' ' + (grayedOut && classes.grayedOut)}
                    alt="Profile Picture"
                    src={profilePicture ?? placeholder}
                />
            </ListItemAvatar>
            <div className={classes.infoBox + ' ' + (grayedOut && classes.grayedOut)}>
                <ListItemText
                    className={classes.infoText}
                    classes={{ secondary: classes.blackColor }}
                    primary={`${user.firstName} ${user.lastName}`}
                    secondary={
                        <>
                            {user.position} - {user.companyName} - {user.phoneNumber}
                            <br />
                            {user.email}
                        </>
                    }
                />
                {companyLogo && <img className={classes.companyLogo} src={companyLogo} alt={user.companyName ?? 'Company Name'} />}
            </div>
        </>
    );
};
