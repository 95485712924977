import { useCallback, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Attendee } from 'common/types/attendee';
import { CheckIcon, DeleteIcon } from 'common/utils/Icons';
import { useUpdateAttendee } from 'mobile/hooks/attendee/useUpdateAttendee';
import { useGetUser } from '../hooks/user/useGetUser';

export interface IProcedureAttendeeProps {
    userId: string;
    procedureId: number;
    attendee: Attendee;
}

const useStyles = makeStyles(() => ({
    attendeeButton: {
        width: 'max-content',
    },
}));

export const AttendeeDetail = ({ userId, procedureId, attendee }: IProcedureAttendeeProps) => {
    const styles = useStyles();
    const { user } = useGetUser(userId);
    const [isProcessing, setProcessing] = useState(false);
    const { updateAttendee } = useUpdateAttendee(procedureId, attendee.id);

    const featureRequest = useCallback(async () => {
        try {
            setProcessing(true);
            await updateAttendee({ publishAccess: !attendee.publishAccess });
        } catch (err) {
            console.error(err);
        } finally {
            setProcessing(false);
        }
    }, [updateAttendee, attendee.publishAccess]);
    const drName = user?.firstName + ' ' + user?.lastName;
    return (
        <>
            <div>{drName}</div>
            <div>
                <Button
                    size="small"
                    variant="contained"
                    startIcon={attendee.publishAccess ? <DeleteIcon /> : <CheckIcon />}
                    disabled={isProcessing}
                    onClick={featureRequest}
                    className={styles.attendeeButton}
                >
                    {attendee.publishAccess ? 'Unselect Moderator' : 'Select Moderator'}
                    {isProcessing && (
                        <>
                            &nbsp;&nbsp;
                            <div>
                                <CircularProgress size={20} thickness={10} color="primary" />
                            </div>
                        </>
                    )}
                </Button>
            </div>
        </>
    );
};
