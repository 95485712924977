/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

interface IEmptyStateHandlerProps {
    children: React.ReactNode;
    data: Array<any> | Record<string, unknown> | undefined | null;
    keyword?: string;
}

const EmptyStateBox = ({ keyword }: Pick<IEmptyStateHandlerProps, 'keyword'>) => {
    const message = `No ${keyword ?? ''} data currently`;
    return (
        <Box p={3}>
            <p>{message}</p>
        </Box>
    );
};

const EmptyStateHandler: React.FC<IEmptyStateHandlerProps> = ({ children, data, keyword }) => {
    useEffect(() => {}, [data]);

    if (data && Array.isArray(data) && !data.length) return <EmptyStateBox keyword={keyword} />;
    if (data && typeof data === 'object' && !Object.keys(data).length) return <EmptyStateBox keyword={keyword} />;
    if (!data) return <EmptyStateBox />;
    return <>{children}</>;
};

export default EmptyStateHandler;
