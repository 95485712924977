import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../config';

export const useProcedureDelete = (procedureId: number) => {
    const queryClient = useQueryClient();
    const { notify } = useNotifier();
    const { key, path } = config.procedure.deleteProcedure();
    const invalidationKey = config.procedure.searches();
    const { mutateAsync, isLoading } = useMutation(
        key,
        async () => {
            console.log('Deleting procedure', procedureId);
            const deletePath = path(procedureId);
            const results = await Api.delete({ path: deletePath });
            return results;
        },
        {
            onSuccess: data => {
                queryClient.invalidateQueries({ queryKey: invalidationKey });
                notify(new Message({ title: 'Procedure removed successfully', type: 'success' }));
            },
            onError: () => {
                notify(new Message({ title: 'Unable to remove procedure', type: 'error' }));
            },
        }
    );
    return { mutateAsync, isLoading };
};
