import { config } from 'circulator/hooks/config';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

export const useStopProcedure = (procedureId: number) => {
    const { path } = config.procedure.stop(procedureId);
    const queryClient = useQueryClient();

    const stop = useMutation(() => Api.delete({ path }), {
        onSuccess: async () => {
            await queryClient.resetQueries(config.procedure.all());
        },
    });

    return {
        stopProcedure: stop.mutateAsync,
    };
};
