import { startProcedure, stopProcedure, obscureProcedure, unobscureProdecure, updateProdecure } from 'common/services/procedureServices';
import { useMutation, useQueryClient } from 'react-query';

export const useMutateProcedure = () => {
    const queryClient = useQueryClient();
    const resetProcedure = () => {
        queryClient.refetchQueries(['procedure']);
    };

    const startProcedureMutation = useMutation(startProcedure, {
        onSettled: resetProcedure,
    });

    const stopProcedureMutation = useMutation(stopProcedure, {
        onSettled: resetProcedure,
    });

    const updateProcedureMutation = useMutation(updateProdecure, {
        onSettled: resetProcedure,
    });

    const obscureProcedureMutation = useMutation(obscureProcedure, {
        onSettled: resetProcedure,
    });

    const unobscureProcedureMutation = useMutation(unobscureProdecure, {
        onSettled: resetProcedure,
    });

    return {
        start: startProcedureMutation,
        stop: stopProcedureMutation,
        update: updateProcedureMutation,
        isUpdating: !updateProcedureMutation.isIdle,
        isErrorWhenStarting: startProcedureMutation.isError,
        obscure: obscureProcedureMutation,
        unobscure: unobscureProcedureMutation,
    };
};
