import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type Request = api.UpsertAttendeeRequest;
type Response = api.UpsertAttendeeResponse;

export function useUpsertAttendee() {
    const { path } = config.attendee.upsert();
    const queryClient = useQueryClient();

    type Input = { procedureId: number; body: Request };
    const upsert = useMutation(({ procedureId, body }: Input) => Api.patch<Response>({ path: path(procedureId), body }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(config.procedure.all());
        },
    });

    return {
        upsertAttendee: upsert.mutateAsync,
    };
}
