import { Button, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
}));

export const WelcomePanel = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const goto = (path: string) => {
        navigate(path);
    };
    return (
        <>
            <div className={classes.box}>
                <Typography variant="h5">
                    Welcome to <span>VSURGIC</span>
                </Typography>
            </div>
            <div className={classes.box}>
                <Button size="small" onClick={() => goto('/home')}>
                    Back to Hompage
                </Button>
            </div>
        </>
    );
};
