import { useQuery } from 'react-query';

export function useGetPublicNetwork() {
    const result = useQuery('get', async () => {
        const reponse = await (await fetch('https://api.ipify.org/?format=json')).json();
        return reponse;
    });
    const res = result.data || [];
    return {
        network: res,
        isLoading: result.isLoading,
    };
}
