import React from 'react';
import { Typography } from '@material-ui/core';
import { PrivacyPolicyDialog } from 'client/components/PrivacyPolicyDialog';
import TCDialog from 'client/components/TCDialog';

const Policies = () => (
    <>
        <Typography variant="h5">Policies</Typography>
        <Typography variant="body1">
            <PrivacyPolicyDialog />
        </Typography>
        <Typography variant="body1">
            <TCDialog />
        </Typography>
    </>
);

export default Policies;
