import { makeStyles } from '@material-ui/core';
import backgroundImage from 'img/background.png';

type UpToFourOptions = readonly [string | string, (string | number)?, (string | number)?, (string | number)?];

const fromFourCoords = (prefix: 'margin' | 'padding' | 'border', coords: UpToFourOptions) => {
    return {
        [prefix]: coords.join(' '), // shorthand override
        [prefix + 'Top']: coords[0], // individual overrides
        [prefix + 'Right']: coords[1],
        [prefix + 'Bottom']: coords[2],
        [prefix + 'Left']: coords[3],
    } as const;
};

const fromFourCornersCoords = (prefix: 'border', coords: UpToFourOptions, suffix?: 'Radius') => {
    return {
        [prefix + 'TopLeft' + (suffix ?? '')]: coords[0],
        [prefix + 'TopRight' + (suffix ?? '')]: coords[1],
        [prefix + 'BottomRight' + (suffix ?? '')]: coords[2],
        [prefix + 'BottomLeft' + (suffix ?? '')]: coords[3],
    } as const;
};

export const MobileStylesConstants = {
    buttonColor: 'rgba(255,153,0,1)',
    buttonColor_disabled: 'rgba(0,0,0,1)',
    buttonFontWeight: 300,
    buttonBackgroundColor: 'white',
    buttonBackgroundColor_disabled: 'rgba(124,124,124,1)',
    buttonFontFamily: 'Arial',
    buttonFontSize: '1.3rem',
    inputBackgroundColor: 'white',
    fieldBorderRadius: ['0px', '0px', '0px', '0px'],
    inputWidth: '100%',
    inputPadding: ['16px', '14px', '16px', '14px'],
    textFieldBackgroundColor: 'white',
    textFieldWidth: '100%',
    textFieldColor: 'black',
    textColor: 'white',
} as const;

const MobileClasses = makeStyles(theme => ({
    button: {
        background: MobileStylesConstants.buttonBackgroundColor,
        backgroundColor: MobileStylesConstants.buttonBackgroundColor,
        color: MobileStylesConstants.buttonColor,
        borderRadius: 'calc(max(100vw, 100vh))',
        '& span.MuiButton-label': {
            color: MobileStylesConstants.buttonColor,
            fontWeight: MobileStylesConstants.buttonFontWeight,
            fontSize: MobileStylesConstants.buttonFontSize,
            fontFamily: MobileStylesConstants.buttonFontFamily,
        },
        '&.Mui-disabled': {
            background: MobileStylesConstants.buttonBackgroundColor_disabled,
            backgroundColor: MobileStylesConstants.buttonBackgroundColor_disabled,
            '& span.MuiButton-label': { color: MobileStylesConstants.buttonColor_disabled },
        },
    },
    dropdown: {
        textAlign: 'center',
        '& .MuiFilledInput-input ': {
            padding: MobileStylesConstants.inputPadding.join(' '),
            ...fromFourCoords('padding', MobileStylesConstants.inputPadding),
        },
        '& .MuiFilledInput-root': {
            ...fromFourCornersCoords('border', MobileStylesConstants.fieldBorderRadius, 'Radius'),
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
        },
        '& .MuiInputBase-formControl': {
            background: 'white',
        },
        '& .MuiInputLabel-root': {
            color: 'black',
        },
        '& .MuiSelect-root': {
            color: 'black',
        },
    },
    input: {
        backgroundColor: MobileStylesConstants.textFieldBackgroundColor,
        width: MobileStylesConstants.textFieldWidth,
        padding: MobileStylesConstants.inputPadding.join(' '),
        ...fromFourCoords('padding', MobileStylesConstants.inputPadding),
        alignText: 'center',
    },
    marginTop: {
        marginTop: '50px',
    },
    marginTopSpacing: {
        marginTop: '15px',
    },
    paddingX: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    text: {
        fontSize: '1rem',
    },
    notify: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '20px',
    },
    background: {
        color: MobileStylesConstants.textColor,
        '&:after': {
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            backgroundAttachment: 'fixed',
            content: '""',
            backgroundSize: 'cover',
            top: '0px',
            bottom: '100vh',
            left: '0px',
            right: '100vw',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            zIndex: '-1',
            filter: 'brightness(.4)',
        },
    },
}));

const CSS_COLOR_NAMES = {
    aliceblue: '#F0F8FF',
    antiquewhite: '#FAEBD7',
    aqua: '#00FFFF',
    aquamarine: '#7FFFD4',
    azure: '#F0FFFF',
    beige: '#F5F5DC',
    bisque: '#FFE4C4',
    black: '#000000',
    blanchedalmond: '#FFEBCD',
    blue: '#0000FF',
    blueviolet: '#8A2BE2',
    brown: '#A52A2A',
    burlywood: '#DEB887',
    cadetblue: '#5F9EA0',
    chartreuse: '#7FFF00',
    chocolate: '#D2691E',
    coral: '#FF7F50',
    cornflowerblue: '#6495ED',
    cornsilk: '#FFF8DC',
    crimson: '#DC143C',
    cyan: '#00FFFF',
    darkblue: '#00008B',
    darkcyan: '#008B8B',
    darkgoldenrod: '#B8860B',
    darkgray: '#A9A9A9',
    darkgrey: '#A9A9A9',
    darkgreen: '#006400',
    darkkhaki: '#BDB76B',
    darkmagenta: '#8B008B',
    darkolivegreen: '#556B2F',
    darkorange: '#FF8C00',
    darkorchid: '#9932CC',
    darkred: '#8B0000',
    darksalmon: '#E9967A',
    darkseagreen: '#8FBC8F',
    darkslateblue: '#483D8B',
    darkslategray: '#2F4F4F',
    darkslategrey: '#2F4F4F',
    darkturquoise: '#00CED1',
    darkviolet: '#9400D3',
    deeppink: '#FF1493',
    deepskyblue: '#00BFFF',
    dimgray: '#696969',
    dimgrey: '#696969',
    dodgerblue: '#1E90FF',
    firebrick: '#B22222',
    floralwhite: '#FFFAF0',
    forestgreen: '#228B22',
    fuchsia: '#FF00FF',
    gainsboro: '#DCDCDC',
    ghostwhite: '#F8F8FF',
    gold: '#FFD700',
    goldenrod: '#DAA520',
    gray: '#808080',
    grey: '#808080',
    green: '#008000',
    greenyellow: '#ADFF2F',
    honeydew: '#F0FFF0',
    hotpink: '#FF69B4',
    indianred: '#CD5C5C',
    indigo: '#4B0082',
    ivory: '#FFFFF0',
    khaki: '#F0E68C',
    lavender: '#E6E6FA',
    lavenderblush: '#FFF0F5',
    lawngreen: '#7CFC00',
    lemonchiffon: '#FFFACD',
    lightblue: '#ADD8E6',
    lightcoral: '#F08080',
    lightcyan: '#E0FFFF',
    lightgoldenrodyellow: '#FAFAD2',
    lightgray: '#D3D3D3',
    lightgrey: '#D3D3D3',
    lightgreen: '#90EE90',
    lightpink: '#FFB6C1',
    lightsalmon: '#FFA07A',
    lightseagreen: '#20B2AA',
    lightskyblue: '#87CEFA',
    lightslategray: '#778899',
    lightslategrey: '#778899',
    lightsteelblue: '#B0C4DE',
    lightyellow: '#FFFFE0',
    lime: '#00FF00',
    limegreen: '#32CD32',
    linen: '#FAF0E6',
    magenta: '#FF00FF',
    maroon: '#800000',
    mediumaquamarine: '#66CDAA',
    mediumblue: '#0000CD',
    mediumorchid: '#BA55D3',
    mediumpurple: '#9370DB',
    mediumseagreen: '#3CB371',
    mediumslateblue: '#7B68EE',
    mediumspringgreen: '#00FA9A',
    mediumturquoise: '#48D1CC',
    mediumvioletred: '#C71585',
    midnightblue: '#191970',
    mintcream: '#F5FFFA',
    mistyrose: '#FFE4E1',
    moccasin: '#FFE4B5',
    navajowhite: '#FFDEAD',
    navy: '#000080',
    oldlace: '#FDF5E6',
    olive: '#808000',
    olivedrab: '#6B8E23',
    orange: '#FFA500',
    orangered: '#FF4500',
    orchid: '#DA70D6',
    palegoldenrod: '#EEE8AA',
    palegreen: '#98FB98',
    paleturquoise: '#AFEEEE',
    palevioletred: '#DB7093',
    papayawhip: '#FFEFD5',
    peachpuff: '#FFDAB9',
    peru: '#CD853F',
    pink: '#FFC0CB',
    plum: '#DDA0DD',
    powderblue: '#B0E0E6',
    purple: '#800080',
    rebeccapurple: '#663399',
    red: '#FF0000',
    rosybrown: '#BC8F8F',
    royalblue: '#4169E1',
    saddlebrown: '#8B4513',
    salmon: '#FA8072',
    sandybrown: '#F4A460',
    seagreen: '#2E8B57',
    seashell: '#FFF5EE',
    sienna: '#A0522D',
    silver: '#C0C0C0',
    skyblue: '#87CEEB',
    slateblue: '#6A5ACD',
    slategray: '#708090',
    slategrey: '#708090',
    snow: '#FFFAFA',
    springgreen: '#00FF7F',
    steelblue: '#4682B4',
    tan: '#D2B48C',
    teal: '#008080',
    thistle: '#D8BFD8',
    tomato: '#FF6347',
    turquoise: '#40E0D0',
    violet: '#EE82EE',
    wheat: '#F5DEB3',
    white: '#FFFFFF',
    whitesmoke: '#F5F5F5',
    yellow: '#FFFF00',
    yellowgreen: '#9ACD32',
} as const;

const rgbaRegExp = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*([\d]?(?:\.[\d]{1,10})?)\s*)?\)$/gi;
const hexRegExp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/gi;
const hslRegExp =
    /^\s*hsl\(\s*((?:[\d]{0,3}(?:\.[\d]{1,10}))|(?:[\d]{1,3})|none)(|deg|turn)\s*[\s,]\s*((?:[\d]{0,3}(?:\.[\d]{1,10}))|(?:[\d]{1,3}))(%|)\s*[\s,]\s*((?:[\d]{0,3}(?:\.[\d]{1,10}))|(?:[\d]{1,3}))(%|)\s*(?:\/\s*((?:[\d]{0,3}(?:\.[\d]{1,10}))|(?:[\d]{1,3}))(|%))?\)$/gi;

export class RGBA {
    /**
     * Converts a Hue to the value of one of the components of RGB.
     * Internal Use Only.
     * @param p
     * @param q
     * @param t
     * @returns
     */
    private static hueToRgb = function (p: number, q: number, t: number): number {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    };

    /**
     * Converts colors from different formats to RGBA format
     * Suppported Formats include: RGB(A), HEX, HSL(A), CONSTANTCOLOR
     * Unsupported Formats include: LCH, OKLCH, P3-COLOR
     *
     * Math for converting from HSL to RGBA comes from https://en.wikipedia.org/wiki/HSL_color_space
     * Inspiration from https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
     * @param color
     * @returns
     */
    static convertUnknownStringFormatColorToRGBAStringFormatColor = function (color: string): string {
        if (color.match(rgbaRegExp)) {
            return color;
        }
        if (color.match(hexRegExp)) {
            return color.replace(hexRegExp, (m, r, g, b) => {
                return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, 1)`;
            });
        }
        const constantColor = CSS_COLOR_NAMES[color.toLowerCase() as keyof typeof CSS_COLOR_NAMES];
        if (constantColor) {
            return RGBA.convertUnknownStringFormatColorToRGBAStringFormatColor(constantColor);
        }
        if (color.match(hslRegExp)) {
            return color.replace(
                hslRegExp,
                (
                    m: string,
                    h: string,
                    hUnit: string,
                    s: string,
                    sUnit: string,
                    l: string,
                    lUnit: string,
                    a: string | undefined,
                    aUnit: string | undefined
                ) => {
                    const hueUnit = hUnit.trim().toLowerCase(); // turn, deg, grad, rad, none
                    let hue: number;
                    switch (hueUnit) {
                        case 'turn':
                            hue = (parseFloat(h) * 360) % 360;
                            break;
                        case 'grad':
                            hue = parseFloat(h) * 0.9;
                            break;
                        case 'rad':
                            hue = parseFloat(h) * 57.29577951308232; // (180 / Math.PI) // Precalculated value
                            break;
                        case 'deg':
                        default:
                            hue = parseFloat(h) % 360;
                    }
                    const saturation = sUnit === '%' ? parseFloat(s) / 100 : parseFloat(s);
                    const lightness = lUnit === '%' ? parseFloat(l) / 100 : parseFloat(l);
                    const alpha = aUnit === (a && aUnit) ? ('%' ? parseFloat(a as string) / 100 : parseFloat(a as string)) : 1; // % or number

                    if (saturation === 0) {
                        return `rgba(${lightness * 255}, ${lightness * 255}, ${lightness * 255}, ${alpha})`;
                    }
                    //black - note that this is HSL, not HSB so we can do this.
                    if (lightness === 0) {
                        return `rgba(0, 0, 0, ${alpha})`;
                    }
                    ///white - note that this is HSL, not HSB so we can do this.
                    if (lightness === 1) {
                        return `rgba(255, 255, 255, ${alpha})`;
                    }
                    const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
                    const p = 2 * lightness - q;
                    const r = RGBA.hueToRgb(p, q, hue + 1 / 3);
                    const g = RGBA.hueToRgb(p, q, hue);
                    const b = RGBA.hueToRgb(p, q, hue - 1 / 3);
                    return `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${alpha})`;
                }
            );
        }
        if (color.match(/$\s*lch\(/)) {
            throw new Error(`Invalid color: ${color}, cannot convert to rgba - lch format is not currently supported by RGBA class.`);
        }
        if (color.match(/$\s*oklch\(/)) {
            throw new Error(`Invalid color: ${color}, cannot convert to rgba - oklch format is not currently supported by RGBA class.`);
        }
        if (color.match(/$\s*color\(/)) {
            throw new Error(`Invalid color: ${color}, cannot convert to rgba - p3-color format is not currently supported by RGBA class.`);
        }
        throw new Error(`Invalid color: ${color}, cannot convert to rgba`);
    };

    r: number;
    g: number;
    b: number;
    a: number;
    constructor(r: string);
    constructor(r: string, g: number);
    constructor(r: number, g: number, b: number);
    constructor(r: number, g: number, b: number, a: number);
    constructor(r: number | string, g?: typeof r extends string ? never : number, b?: typeof r extends string ? never : number, a: number = 1) {
        if (typeof r !== 'number') {
            const rgba = RGBA.convertUnknownStringFormatColorToRGBAStringFormatColor(r);
            const match = rgba.match(rgbaRegExp);
            this.r = match ? Number(match[1]) : 0;
            this.g = match ? Number(match[2]) : 0;
            this.b = match ? Number(match[3]) : 0;
            this.a = match ? Number(match[4]) : 1;
            if (g !== undefined && typeof g === 'number') {
                this.a = g;
            }
        } else {
            this.r = r;
            this.g = g as number;
            this.b = b as number;
            this.a = a as number;
        }
    }
    toString() {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }
}

const combineProperties = () => {
    const classes = MobileClasses();
    return {
        ...classes,
        constants: MobileStylesConstants,
        gridItem: {
            padding: 1,
        },
    };
};

export default combineProperties;
