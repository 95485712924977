import React, { ChangeEvent } from 'react';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import DoctorAnalytics from 'client/components/Analytics/DoctorAnalytics';
import ProcedureAnalytics from 'client/components/Analytics/ProcedureAnalytics';
import RoomAnalytics from 'client/components/Analytics/RoomAnalytics';
import Panel from 'client/components/UI/Panel';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';

interface ITabPanelProps {
    children: React.ReactNode;
    value: number;
    index: number;
}

const TabPanel: React.FC<ITabPanelProps> = ({ children, value, index }) => (
    <div role="tabpanel" hidden={value !== index} id={`analytics-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
        {value === index && <Box p={3}>{children}</Box>}
    </div>
);

const AnalyticsTabPanel = () => {
    const [value, setValue] = React.useState<number>(0);
    const { reset } = useQueryErrorResetBoundary();
    const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Panel>
            <Box width="100%">
                <Box borderBottom={1} borderColor="divider">
                    <Tabs value={value} onChange={handleChange} aria-label="analytics-tabs">
                        <Tab label="By Procedure" id={`analytics-tab-${0}`} />
                        <Tab label="By Room or Facility" id={`analytics-tab-${1}`} />
                        {/* <Tab label="By Doctor" id={`analytics-tab-${2}`} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ProcedureAnalytics />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ErrorBoundary
                        onReset={reset}
                        fallbackRender={({ resetErrorBoundary }) => (
                            <div>
                                <p>Oops! Something went wrong...</p>
                                <Button onClick={() => resetErrorBoundary()}>Try again</Button>
                            </div>
                        )}
                    >
                        <React.Suspense fallback={<ProcessingOverlay enabled caption="Loading..." />}>
                            <RoomAnalytics />
                        </React.Suspense>
                    </ErrorBoundary>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <DoctorAnalytics />
                </TabPanel>
            </Box>
        </Panel>
    );
};

const AnalyticsPage = (): JSX.Element => (
    <>
        <AnalyticsTabPanel />
    </>
);

export default AnalyticsPage;
