import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, ListItem, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { useUpdateAttendee } from 'circulator/hooks/attendee/useUpdateAttendee';
import usePreauthorizedUsersStore from 'circulator/hooks/usePreauthorizedUsersStore';
import { useGetUser } from 'circulator/hooks/user/useGetUser';
import { AttendeeStatus } from 'common/types';
import type { Attendee } from 'common/types/circulator';
import { CheckIcon, CloseIcon } from 'common/utils/Icons';
import RoleListItem from '../RoleListItem';
import { UserListItem } from './UserListItem';

const styles = {
    buttonContainer: {
        display: 'flex',
        width: '63%',
        justifyContent: 'flex-end',
    },
    acceptBtn: {
        width: '25%',
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        marginLeft: theme.spacing(),
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
        '&:active': {
            backgroundColor: theme.palette.success.light,
            borderColor: theme.palette.success.dark,
        },
    },
    rejectBtn: {
        width: '25%',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        marginLeft: theme.spacing(),
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
        '&:active': {
            backgroundColor: theme.palette.error.light,
            borderColor: theme.palette.error.dark,
        },
    },
};
const useStyles = makeStyles(styles);

const { REJECTED, ACCEPTED } = AttendeeStatus;

interface Props {
    procedureId: number;
    attendee: Attendee;
}

export const ProcedureRequestItem = ({ procedureId, attendee }: Props) => {
    const classes = useStyles();

    const { user } = useGetUser(attendee.userId);
    const { updateAttendee } = useUpdateAttendee(procedureId, attendee.id);
    const [isAccepting, setIsAccepting] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);
    const acceptedRoles = usePreauthorizedUsersStore(state => state.acceptedRoles);

    const handleAcceptRequest = useCallback(async () => {
        setIsAccepting(true);
        await updateAttendee({ status: ACCEPTED });
    }, [updateAttendee]);

    useEffect(() => {
        if (attendee.status === AttendeeStatus.REQUESTED) {
            if (attendee.roles.some(x => acceptedRoles.includes(x))) {
                handleAcceptRequest();
            }
        }
    }, [acceptedRoles, attendee, handleAcceptRequest]);

    const handleRejectRequest = async () => {
        setIsRejecting(true);
        await updateAttendee({ status: REJECTED });
    };

    return (
        <ListItem>
            <UserListItem user={user} />
            <RoleListItem attendee={attendee} />
            <ListItemSecondaryAction className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    aria-label="Accept"
                    className={classes.acceptBtn}
                    startIcon={<CheckIcon />}
                    disabled={attendee.status === ACCEPTED}
                    onClick={handleAcceptRequest}
                >
                    Accept
                    {isAccepting && (
                        <>
                            &nbsp;&nbsp;
                            <div>
                                <CircularProgress size={20} thickness={10} color="primary" />
                            </div>
                        </>
                    )}
                </Button>
                <Button
                    variant="contained"
                    aria-label="Deny"
                    className={classes.rejectBtn}
                    startIcon={<CloseIcon />}
                    disabled={attendee.status === REJECTED}
                    onClick={handleRejectRequest}
                >
                    Reject
                    {isRejecting && (
                        <>
                            &nbsp;&nbsp;
                            <div>
                                <CircularProgress size={20} thickness={10} color="primary" />
                            </div>
                        </>
                    )}
                </Button>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
