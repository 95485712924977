import React from 'react';
import { Collapse, Dialog, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import Message from 'common/models/Message';
import CommonUtils from 'common/utils/CommonUtils';
import { CloseIcon, UploadIcon } from 'common/utils/Icons';
import Dropzone from 'react-dropzone';
import ImageEditor from './ImageEditor';

// @ts-ignore
const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        position: 'relative',
    },
    paper: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(),
        paddingLeft: theme.spacing(),
        textAlign: 'center',
    },
    dropzone: {
        textAlign: 'center',
        padding: '4em 2em',
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: 8,
        borderStyle: 'dashed',
        borderWidth: 4,
        fontWeight: 400,
        fontSize: '1.4em',
        background: theme.palette.primary.light,
        cursor: 'pointer',
    },
    dropzoneIcon: {
        display: 'block',
        margin: 'auto',
        height: 80,
        width: 80,
    },
    closeIcon: {
        position: 'absolute',
        right: 8,
        top: 0,
    },
    centerIt: {
        textAlign: 'center',
    },
    img: {
        maxWidth: 150,
        borderRadius: '50%',
    },
    btn: {
        margin: theme.spacing(2),
    },
    requirements: {
        textAlign: 'left',
    },
    imgBox: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // height: 174,
        padding: theme.spacing(1.5),
    },
    logo: {
        maxWidth: 150,
    },
});
// @ts-ignore
const useStyles = makeStyles(styles);

// Maximum upload file size: 5 MB
const maxFileSize = 5 * 1024 * 1024;

export enum ImageField {
    picture = 'picture',
    companyLogo = 'companyLogo',
}

interface Props {
    imageField: ImageField;
    onUpload: (imageField: ImageField, image: Blob) => Promise<void>;
    onNotifier: (message: Message) => void;
    onClose: () => void;
}

export const PhotoUploader: React.FC<Props> = ({ imageField, onUpload, onNotifier, onClose }) => {
    const classes = useStyles();

    const [image, setImage] = React.useState<File>();

    const handleClose = () => {
        setImage(undefined);
        onClose();
    };

    const handleReset = () => {
        setImage(undefined);
    };

    const handleFileDrop = (files: File[]) => {
        const file = files.at(0);

        if (!file) {
            onNotifier(
                new Message({
                    title: 'No acceptable files.',
                    type: 'error',
                })
            );
            return;
        }

        if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
            onNotifier(
                new Message({
                    title: 'Invalid File Type: Only JPEG, PNG and GIF files are allowed.',
                    type: 'error',
                })
            );
            return;
        }

        if (file.size > maxFileSize) {
            onNotifier(
                new Message({
                    title: 'File Too Large: Max file size is 5MB.',
                    type: 'error',
                })
            );
            return;
        }

        setImage(file);
    };

    const handleUpload = async (editedImage: Blob) => {
        await onUpload(imageField, editedImage);
        handleClose();
    };

    return (
        <Dialog open onClose={handleClose}>
            <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
                <Grid item xs={12}>
                    <IconButton className={classes.closeIcon} color="inherit" onClick={handleClose} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography component="h1" variant="h5">
                        Upload Photo
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.centerIt}>
                    <Collapse in={!image}>
                        <Typography component="div" variant="body1" className={classes.requirements}>
                            <ul>
                                <li>Only JPEG, PNG and GIF files are allowed.</li>
                                <li>Maximum file size allowed is 5MB.</li>
                            </ul>
                        </Typography>
                        <Dropzone multiple={false} accept={['image/jpeg', 'image/png', 'image/gif']} onDrop={handleFileDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p className={classes.dropzone}>
                                            <UploadIcon className={classes.dropzoneIcon} />
                                            {CommonUtils.isMobileDevice()
                                                ? 'Tap to upload or take a photo'
                                                : 'Drag & drop your photo here, or click to upload one'}
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Collapse>
                    <Collapse in={!!image}>
                        <div className={classes.imgBox}>
                            {image && (
                                <ImageEditor
                                    original={image}
                                    onUpload={handleUpload}
                                    onReset={handleReset}
                                    // Special case for rounded square picture upload.
                                    aspect={imageField === ImageField.picture ? 1 : undefined}
                                    cropShape={imageField === ImageField.picture ? 'round' : undefined}
                                />
                            )}
                        </div>
                    </Collapse>
                </Grid>
            </Grid>
        </Dialog>
    );
};
