import { config } from 'client/hooks/config';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type useUpdateTitleProps = {
    title: string;
    jobId: string;
};

type Response = any;
export const useUpdateTitle = () => {
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key, path } = config.videoexport.updateTitle();
    const { mutateAsync, isLoading } = useMutation(
        key,
        async ({ title, jobId }: useUpdateTitleProps) => {
            const results = Api.put<Response>({ path, body: { title, jobId } });
            return results;
        },
        {
            onSuccess: () => {
                notify(new Message({ title: 'Title updated successfully', type: 'success' }));
                queryClient.invalidateQueries(key);
                queryClient.invalidateQueries(config.videoexport.jobList().key);
            },
            onError: () => {
                notify(new Message({ title: 'Unable to update title', type: 'error' }));
                queryClient.invalidateQueries(key);
            },
        }
    );
    return { updateTitle: mutateAsync, isLoading };
};
