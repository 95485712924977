import { useCallback, useEffect } from 'react';
import { useUpdateAttendee } from 'circulator/hooks/attendee/useUpdateAttendee';
import usePreauthorizedUsersStore from 'circulator/hooks/usePreauthorizedUsersStore';
import { AttendeeStatus } from 'common/types';
import type { Attendee } from 'common/types/circulator';

interface Props {
    procedureId: number;
    attendee: Attendee;
}

export const AutoJoin = ({ procedureId, attendee }: Props) => {
    const { updateAttendee } = useUpdateAttendee(procedureId, attendee.id);
    const acceptedRoles = usePreauthorizedUsersStore(state => state.acceptedRoles);

    const handleAcceptRequest = useCallback(async () => {
        await updateAttendee({ status: AttendeeStatus.ACCEPTED });
    }, [updateAttendee]);

    useEffect(() => {
        if (attendee.status === AttendeeStatus.REQUESTED) {
            if (attendee.roles.some(x => acceptedRoles.includes(x))) {
                handleAcceptRequest();
            }
        }
    }, [acceptedRoles, attendee, handleAcceptRequest]);

    return <></>;
};
