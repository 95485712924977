import { useState } from 'react';
import { Button, CircularProgress, ListItem, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import theme from 'App/theme';
import { useUpdateAttendee } from 'circulator/hooks/attendee/useUpdateAttendee';
import { useGetUser } from 'circulator/hooks/user/useGetUser';
import { AttendeeStatus } from 'common/types';
import type { Attendee } from 'common/types/circulator';
import { DeleteIcon, PlayArrowIcon, StopIcon } from 'common/utils/Icons';
import useOrientation from '../../../common/hooks/useOrientation';
import RoleListItem from '../RoleListItem';
import { UserListItem } from './UserListItem';

const useStyles = (props: { isLandscape: boolean }) =>
    makeStyles({
        stopBtn: {
            width: 230,
            marginLeft: theme.spacing(),
            boxShadow: 'none',
            border: 'none',
            background: red[600],
            '&:hover': {
                background: red[500],
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                background: red[500],
            },
            animationName: 'pulseDanger',
            animationDuration: '1s',
            animationIterationCount: 'infinite',
        },
        startBtn: {
            width: 230,
            marginLeft: theme.spacing(),
            boxShadow: 'none',
            border: 'none',
            background: green[600],
            '&:hover': {
                background: green[500],
                boxShadow: 'none',
            },
            '&:active': {
                boxShadow: 'none',
                background: green[500],
            },
        },
        iconBtn: {
            color: grey[100],
        },
        btnText: {
            marginRight: theme.spacing(),
            color: grey[100],
        },
        attendeeItem: {
            display: 'flex',
            flexWrap: 'wrap',
            height: '100%',
            '&[disabled]': {
                filter: 'grayscale(1)',
            },
        },
        ejectBtn: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            },
            '&:active': {
                backgroundColor: theme.palette.error.light,
                borderColor: theme.palette.error.dark,
            },
        },
        buttonContainer: {
            right: 0,
            width: '42%',
        },
    });

const { REJECTED } = AttendeeStatus;

interface Props {
    procedureId: number;
    attendee: Attendee;
    enablePublishActions: boolean;
}

export const ProcedureSubscriberItem = ({ procedureId, attendee, enablePublishActions }: Props) => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape })();

    const { user } = useGetUser(attendee.userId);
    const { updateAttendee } = useUpdateAttendee(procedureId, attendee.id);
    const [isEjecting, setIsEjecting] = useState(false);
    const [isToggling, setIsToggling] = useState(false);

    const handleEject = async () => {
        setIsEjecting(true);
        await updateAttendee({ status: REJECTED });
    };

    const handleTogglePublishAccess = async () => {
        // allow stopping publish always, but only enabling based on the prop value
        if (!attendee.publishAccess && !enablePublishActions) {
            return;
        }

        setIsToggling(true);
        const publishAccess = !attendee.publishAccess;
        await updateAttendee({ publishAccess });
        setIsToggling(false);
    };

    return (
        <ListItem className={classes.attendeeItem}>
            <UserListItem user={user} grayedOut={attendee.status === AttendeeStatus.ACCEPTED} />
            <RoleListItem attendee={attendee} />
            <ListItemSecondaryAction className={classes.buttonContainer}>
                {attendee.publishAccess ? (
                    <Button
                        variant="contained"
                        aria-label="Deny publish access"
                        color="secondary"
                        className={classes.stopBtn}
                        onClick={handleTogglePublishAccess}
                    >
                        <span className={classes.btnText} style={{ whiteSpace: 'nowrap' }}>
                            Deselect Moderator
                        </span>
                        <StopIcon className={classes.iconBtn} />
                        {isToggling && (
                            <>
                                &nbsp;&nbsp;
                                <div>
                                    <CircularProgress size={15} thickness={8} color="primary" />
                                </div>
                            </>
                        )}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        aria-label="Allow publish access"
                        color="secondary"
                        className={classes.startBtn}
                        onClick={handleTogglePublishAccess}
                        disabled={!enablePublishActions}
                    >
                        <span className={classes.btnText} style={{ whiteSpace: 'nowrap' }}>
                            Select Moderator
                        </span>
                        <PlayArrowIcon className={classes.iconBtn} />
                        {isToggling && (
                            <>
                                &nbsp;&nbsp;
                                <div>
                                    <CircularProgress size={15} thickness={8} color="primary" />
                                </div>
                            </>
                        )}
                    </Button>
                )}
                <Button variant="text" color="primary" aria-label="Eject" onClick={handleEject} startIcon={<DeleteIcon />}>
                    Eject
                    {isEjecting && (
                        <>
                            &nbsp;&nbsp;
                            <div>
                                <CircularProgress size={20} thickness={10} color="primary" />
                            </div>
                        </>
                    )}
                </Button>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
