export const formatVideoLength = (lengthInSeconds: number | undefined): string => {
    if (!lengthInSeconds) return '0s';
    const hours = Math.floor(lengthInSeconds / 3600);
    const minutes = Math.floor((lengthInSeconds % 3600) / 60);
    const seconds = lengthInSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
};

export const formatVideoLengthTime = (lengthInSeconds: number | undefined): string => {
    if (!lengthInSeconds) return '00:00 mins';
    const hours = Math.floor(lengthInSeconds / 3600)
        .toString()
        .padStart(2, '0');
    const minutes = Math.floor((lengthInSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
    return `${hours}:${minutes} mins`;
};
