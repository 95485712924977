/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import { MessageBox } from 'common/components/UI/MessageBox';
import Message from 'common/models/Message';

const styles = {
    root: {},
    btn: {
        verticalAlign: 'baseline',
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: 'none',
    },
    documentWrapper: {
        position: 'relative',
        margin: 0,
        padding: 0,
        '&:first-child': {
            margin: 0,
            padding: 0,
        },
    },
    document: {
        position: 'absolute',
        top: 0,
        bottom: 52,
        width: '100%',
        height: '100%',
        minHeight: 400,
        margin: 0,
        padding: 0,
        borderSize: 1,
        borderStyle: 'solid',
        borderColor: '#efefef',
    },
};

const useStyles = makeStyles(styles);
export const PrivacyPolicyDialog = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button variant="text" className={classes.btn} onClick={() => setIsOpen(true)}>
                Privacy Policy
            </Button>
            <Dialog open={isOpen} onClose={() => setIsOpen(true)} fullScreen>
                <DialogContent className={classes.documentWrapper}>
                    <MessageBox
                        message={
                            new Message({
                                title: 'Content Not Available',
                                details: 'This content needs to be implemented before app release.',
                                type: 'warn',
                            })
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={() => setIsOpen(false)}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
