import { makeStyles } from '@material-ui/core';
import { useQueryErrorResetBoundary } from 'react-query';
import { useUser } from '../../client/hooks/user/useUser';
import useOrientation from '../../common/hooks/useOrientation';
import logo from '../../img/logo-black.svg';
import { useGetPrimaryAttendee } from '../hooks/attendee/useGetPrimaryAttendee';
import { useGetActiveProcedure } from '../hooks/procedure/useGetActiveProcedure';

const usePanelStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        height: '45vh',
        maxHeight: '45vh',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}));

const useStyles = (props: { isLandscape: boolean }) =>
    makeStyles(theme => ({
        root: {
            position: 'relative',
            height: props.isLandscape ? '100%' : '45vh',
            maxHeight: props.isLandscape ? '100%' : '45vh',
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        shadeMain: {
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60%',
            position: 'absolute',
            zIndex: 1111,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        logoSubhead: {
            color: 'black',
            position: 'relative',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            top: '15%',
            letterSpacing: theme.spacing(0.5),
            textTransform: 'uppercase',
        },
        chatBox: {
            height: props.isLandscape ? '100%' : '42vh',
        },
    }));

export const useGetCirculatorHomePage = () => {
    const { isLandscape } = useOrientation();
    const classes = useStyles({ isLandscape })();
    const panelClasses = usePanelStyles();
    const { procedure } = useGetActiveProcedure();
    const { primaryAttendee } = useGetPrimaryAttendee(procedure?.id);
    const { reset } = useQueryErrorResetBoundary();
    const { user } = useUser();

    return {
        user,
        reset,
        classes,
        procedure,
        isLandscape,
        panelClasses,
        primaryAttendee,
    };
};
