import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export interface UserListRespoonse {
    roles: string[];
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
}

export const useListRoles = (): UserListRespoonse => {
    const { key, path } = config.user.roles();
    const result = useQuery(key, () => Api.get<string[]>({ path }), {
        refetchOnWindowFocus: false,
        suspense: true,
    });

    return {
        roles: result.data ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
};
