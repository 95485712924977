import React from 'react';

interface Props<T> {
    data: T[];
    children: React.ReactNode;
}

const NoDataWrapper = <T,>({ data, children }: Props<T>) => {
    if (data.length === 0) {
        return (
            <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <p>No data yet</p>
            </div>
        );
    }

    return <div>{children}</div>;
};

export default NoDataWrapper;
