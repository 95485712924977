import { config } from 'client/hooks/config';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteExport = () => {
    const { notify } = useNotifier();
    const queryClient = useQueryClient();
    const { key, path } = config.videoexport.deleteExport();
    const { isLoading, mutateAsync } = useMutation(
        key,
        async (jobId: string) => {
            const deleteExportPath = path(jobId);
            const results = Api.delete({ path: deleteExportPath });
            return results;
        },
        {
            onSuccess: data => {
                notify(new Message({ title: 'Export removed successfully', type: 'success' }));
                queryClient.invalidateQueries({ queryKey: key });
                queryClient.invalidateQueries({ queryKey: config.videoexport.jobList().key });
            },
            onError: () => {
                notify(new Message({ title: 'Unable to remove export', type: 'error' }));
                queryClient.invalidateQueries({ queryKey: key });
            },
        }
    );
    return { isLoading, removeExport: mutateAsync };
};
