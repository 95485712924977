import { useMemo, useState } from 'react';
import { Button, CircularProgress, TextField, Modal, Box, AppBar, Toolbar, Typography, IconButton, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import theme from 'App/theme';
import { useGetProcedureStreamsForExport } from 'client/hooks/procedure/useGetProcedureStreamsForExport';
import { useUser } from 'client/hooks/user/useUser';
import { useGetExportList } from 'client/hooks/videoExport/useGetExportList';
import { useUpdateTitle } from 'client/hooks/videoExport/useUpdateTitle';
import DataGrid from 'common/components/DataGrid/DataGrid';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { getUserInAdminUsers } from 'common/services/userServices';
import CommonUtils from 'common/utils/CommonUtils';
import { CloseIcon } from 'common/utils/Icons';
import AmplifyDownloadButton from 'common/utils/S3DownloadLink/S3DownloadLink';
import MUIDataTable, { MUIDataTableColumn, Responsive, SelectableRows } from 'mui-datatables';
import { ExportDeleteButton } from './ExportDeleteButton';
import { ExportFavoriteIcon } from './ExportFavoriteIcon';
import VideoPreview from './VideoPreview';

const formatMilliseconds = CommonUtils.formatMilliseconds;

type procedureStreamsPanelProps = {
    procedureId: number;
    enabledAPI?: boolean;
    download?: boolean;
    passOnInfo: {
        doctorName?: string;
        companyName?: string;
        brandName?: string;
        procedureTitle: string;
        deviceName?: string;
        patientInitials?: string;
        recordingStartTime?: string;
        timeZone?: string;
    };
};

const useStyles = makeStyles({
    outlinedWhite: {
        '& .MuiFormLabel-root': {
            color: 'white !important',
        },
        '& > .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white !important',
            },
            color: 'white !important',
        },
    },
    inputs: {
        '& .MuiInputBase-root': {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            '& input': {
                textAlign: 'right',
            },
        },
    },
    button: {
        width: '10px',
        height: '30px',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    modalContainer: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        height: 'auto',
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: '24',
        padding: 4,
    },
    flexGrow: { flexGrow: 1 },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999,
        color: 'white',
        opacity: 1,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
        },
    },
});

const BytesToMBConverter = ({ bytes }: { bytes: number }): JSX.Element => {
    const bytesToMB = (bytes: number) => {
        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2);
    };

    return (
        <div>
            <p>{`${bytesToMB(bytes)} MB`}</p>
        </div>
    );
};

const ExportProcedureStreamsPanel: React.FC<procedureStreamsPanelProps> = ({ procedureId, enabledAPI, download = true, passOnInfo }) => {
    const classes = useStyles();
    const { user } = useUser();
    const userAdmin = useMemo(() => getUserInAdminUsers(user), [user]);
    const { streams, isLoading } = useGetProcedureStreamsForExport({ procedureId: procedureId });
    const { data: exportData = [], isLoading: exportIsLoading } = useGetExportList();
    const [isTitleOpen, setIsTitleOpen] = useState(false);
    const [tempJobId, setTempJobId] = useState<string>('');
    const [tempTitle, setTempTitle] = useState<string>('');
    const { notify } = useNotifier();
    const { updateTitle, isLoading: isTitleLoding } = useUpdateTitle();

    function handleOpenClose() {
        setIsTitleOpen(!isTitleOpen);
    }

    const handleUpdate = async () => {
        try {
            await updateTitle({ title: tempTitle, jobId: tempJobId });
            notify(new Message({ title: 'Title updated successfully', type: 'success' }));
            setTempTitle('');
            setIsTitleOpen(!isTitleOpen);
        } catch (err) {
            console.log(err);
            notify(new Message({ title: 'Error updating title', type: 'error' }));
        }
    };

    const EXPORT_LIST_COLUMN_OPTIONS: MUIDataTableColumn[] = [
        {
            name: 'jobId',
            label: 'Job ID',
            options: {
                customHeadLabelRender: columnMeta => {
                    return <span style={{ fontWeight: 500 }}>{columnMeta.label}</span>;
                },
                display: false,
            },
        },
        {
            name: 'favoritesId',
            options: {
                display: false,
            },
        },
        {
            name: 'procedureId',
            options: {
                display: false,
            },
        },
        {
            name: 'isFavorite',
            label: 'Favorite',
            options: {
                customHeadLabelRender: columnMeta => {
                    return <span style={{ display: 'flex', justifyContent: 'center', fontWeight: 500 }}>{columnMeta.label}</span>;
                },

                customBodyRender: (value, tableMeta, updateValue) => {
                    const jobId = tableMeta.rowData[0];
                    const favoritesId = tableMeta.rowData[1];
                    const procedureId = tableMeta.rowData[2];

                    return <ExportFavoriteIcon procedureId={procedureId} isFavorite={value} favoritesId={favoritesId} jobId={jobId} />;
                },
            },
        },
        {
            name: 'title',
            label: 'Title',
            options: {
                customHeadLabelRender: columnMeta => {
                    return <span style={{ paddingLeft: '6%', fontWeight: 500 }}>{columnMeta.label}</span>;
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    const jobId = tableMeta.rowData[0];
                    return (
                        <Button
                            id={jobId}
                            style={{ width: '100%', paddingLeft: 0 }}
                            onClick={() => {
                                setIsTitleOpen(!isTitleOpen);
                                setTempTitle(value || '');
                                setTempJobId(jobId);
                            }}
                        >
                            {value}
                        </Button>
                    );
                },
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customHeadLabelRender: columnMeta => {
                    return <span style={{ fontWeight: 500 }}>{columnMeta.label}</span>;
                },
            },
        },
        {
            name: 'createdAt',
            label: 'Date Created',
            options: {
                customHeadLabelRender: columnMeta => {
                    return <span style={{ fontWeight: 500 }}>{columnMeta.label}</span>;
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    const timeZone = tableMeta.rowData[10];
                    return new Date(value).toLocaleString('en-US', { timeZone: timeZone || 'UTC' });
                },
                sortDirection: 'desc',
            },
        },
        {
            name: 'updatedAt',
            label: 'Date Updated',
            options: {
                customHeadLabelRender: columnMeta => {
                    return <span style={{ fontWeight: 500 }}>{columnMeta.label}</span>;
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    const timeZone = tableMeta.rowData[10];
                    return new Date(value).toLocaleString('en-US', { timeZone: timeZone || 'UTC' });
                },
            },
        },
        {
            name: 'videoLength',
            label: 'Length',
            options: {
                customHeadLabelRender: columnMeta => {
                    return (
                        <span style={{ fontWeight: 500 }}>
                            {columnMeta.label}
                            <br />
                            {'(hh:mm:ss.mmm)'}
                        </span>
                    );
                },
                setCellProps: () => {
                    return {
                        style: {
                            paddingLeft: '50px',
                        },
                    };
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return formatMilliseconds(value);
                },
            },
        },
        {
            name: 'streamLocation',
            label: 'Video',
            options: {
                customHeadLabelRender: columnMeta => {
                    return <span style={{ fontWeight: 500 }}>{columnMeta.label}</span>;
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    const title = tableMeta.rowData[4];
                    const timeZone = tableMeta.rowData[10];
                    const isAudioOnly = tableMeta.rowData[11];
                    // return download link to value
                    if (value === undefined || !value) return <></>;
                    const s3Key = 'clips/' + value.split('/').pop();
                    //return value;
                    return (
                        <>
                            <AmplifyDownloadButton s3Key={s3Key} s3FileName={''} /> |{' '}
                            <VideoPreview
                                isAudioOnly={isAudioOnly || false}
                                timeZone={timeZone}
                                jobTitle={title}
                                s3Key={s3Key}
                                s3Filename={'View'}
                                exporting={false}
                            />
                        </>
                    );
                },
            },
        },
        {
            //Using this timezone display to show the delete button
            name: 'timeZone',
            label: ' ',
            options: {
                setCellProps: () => {
                    return {
                        style: {
                            width: '20px',
                            padding: '0px',
                        },
                    };
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    const jobId = tableMeta.rowData[0];
                    const title = tableMeta.rowData[4];

                    return <ExportDeleteButton isUserAdmin={!!userAdmin} jobId={jobId} title={title} />;
                },
            },
        },
        {
            name: 'isAudioOnly',
            label: '',
            options: {
                display: false,
            },
        },
    ];

    const columns: GridColDef[] = [
        {
            field: 'fileKey',
            headerName: 'File Name',
            flex: 1.25,
            renderCell: c => {
                return c.value.useDownload ? (
                    <AmplifyDownloadButton s3Key={c.value.s3Key} s3FileName={c.value.s3FileName} />
                ) : (
                    <VideoPreview
                        s3Key={c.value.s3Key}
                        s3Filename={c.value.s3FileName}
                        procedureId={c.row.procedureId}
                        doctorName={passOnInfo?.doctorName}
                        companyName={passOnInfo?.companyName}
                        brandName={passOnInfo?.brandName}
                        procedureTitle={passOnInfo?.procedureTitle}
                        deviceName={passOnInfo?.deviceName}
                        patientInitials={passOnInfo?.patientInitials}
                        timeZone={passOnInfo?.timeZone}
                        exporting={true}
                        isAudioOnly={c.row.isAudioOnly}
                    />
                );
            },
        },
        { field: 'streamName', headerName: 'Stream Name', flex: 0.33 },
        { field: 's3Size', headerName: 'File Size', flex: 0.4, renderCell: c => <BytesToMBConverter bytes={c.value} /> },
        { field: 'streamLocation', headerName: 'Source', flex: 1 },
        { field: 'streamOrder', headerName: 'Order', flex: 0.25 },
        {
            field: 'featuredStream',
            headerName: 'Moderator',
            flex: 0.5,
            renderCell: c => <>{c.value.streamIsFeatured ? `${c.value.user}` : 'No'}</>,
        },
        {
            field: 'isAudioOnly',
        },
    ];
    const exportListDataTableOptions = {
        elevation: 1,
        selectableRows: 'none' as SelectableRows,
        download: false,
        print: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10, 20, 50, 100],
        rowsPerPage: 10,
        empty: true,
        sortThirdClickReset: true,
        responsive: 'simple' as Responsive,
    };

    const rows: GridRowsProp = useMemo(() => {
        if (!streams) return [];
        return streams.map((value, index) => ({
            id: index,
            ...value,
            fileKey: {
                s3FileName: value.s3FileName,
                s3Key: value.s3Key,
                useDownload: download,
            },
            procedureId: procedureId,
            featuredStream: { streamIsFeatured: value.streamIsFeatured, user: value.streamUserId },
            isAudioOnly: value.isAudioOnly,
        }));
    }, [streams, download, procedureId]);
    return (
        <>
            <Modal open={isTitleOpen} onClose={handleOpenClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className={classes.modalContainer}>
                    <AppBar>
                        <Toolbar className={classes.header}>
                            <Typography variant="h6" component="div" className={classes.flexGrow}>
                                Update Title
                            </Typography>
                            <IconButton aria-label="close" onClick={handleOpenClose} className={classes.closeIcon}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Toolbar />
                    <Container className={classes.container}>
                        <TextField
                            id="outlined-basic"
                            label="Title"
                            variant="outlined"
                            className={classes.inputs}
                            value={tempTitle}
                            onChange={e => setTempTitle(e.target.value)}
                            disabled={isTitleLoding}
                        />
                        <Button disabled={isTitleLoding} variant="contained" color="primary" onClick={() => handleUpdate()}>
                            Update
                        </Button>
                    </Container>
                </Box>
            </Modal>
            <DataGrid
                loading={isLoading}
                sortModel={[{ field: 'streamOrder', sort: 'desc' }]}
                actions={[]}
                rows={rows}
                columns={columns}
                hideFooterSelectedRowCount={true}
                sx={{ bgcolor: 'background.paper' }}
            />
            <br />
            {download ? null : ( // displayed only if download is false meaning we are in the video export page
                <MUIDataTable
                    title={
                        <Typography variant="h6">
                            Exported Videos
                            {exportIsLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                        </Typography>
                    }
                    data={exportData.filter(item => item.procedureId === procedureId)}
                    columns={EXPORT_LIST_COLUMN_OPTIONS}
                    options={exportListDataTableOptions}
                />
            )}
        </>
    );
};

export default ExportProcedureStreamsPanel;
