import { config } from 'client/hooks/config';
import { IProcedureVideoClip } from 'common/types';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export function useDoctorClips(doctorId: number) {
    const { key, path } = config.doctors.listClips(doctorId);
    const result = useQuery([key], () => Api.get<IProcedureVideoClip[]>({ path }));
    return {
        data: result?.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
