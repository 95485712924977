import type { Stream } from 'common/types';
import { create } from 'zustand';

interface StreamTime {
    streamId: number;
    currentTime: number;
}

interface PlayerControlsStoreState {
    activeStream?: Stream;
    setActiveStream: (stream: Stream) => void;

    activeAudioStreamIds: number[];
    toggleAudioActive: (streamId: number) => void;

    streamCurrentTimes: StreamTime[];
    setStreamCurrentTime: (streamId: number, currentTime: number) => void;

    procedureCleanup: () => void;

    volume: number;
    setVolume: (volume: number) => void;
}

const usePlayerControlsStore = create<PlayerControlsStoreState>()((set, get) => ({
    activeStream: undefined,
    setActiveStream: stream => {
        set({ activeStream: stream });
    },

    activeAudioStreamIds: [],
    toggleAudioActive: streamId => {
        set(state => ({
            activeAudioStreamIds: state.activeAudioStreamIds.includes(streamId)
                ? state.activeAudioStreamIds.filter(id => id !== streamId)
                : [...state.activeAudioStreamIds, streamId],
        }));
    },

    streamCurrentTimes: [],
    setStreamCurrentTime: (streamId, currentTime) => {
        set(state => {
            const newStreamTime = {
                streamId,
                currentTime,
            };

            const newValue = [...state.streamCurrentTimes.filter(x => x.streamId !== streamId), newStreamTime];

            return { streamCurrentTimes: newValue };
        });
    },

    procedureCleanup: () => {
        set({ activeStream: undefined, activeAudioStreamIds: [], streamCurrentTimes: [] });
    },

    volume: 100,
    setVolume: volume => {
        set({ volume: volume });
    },
}));

export const isActiveStream = (activeStream: Stream | undefined, streamId: number) => {
    return activeStream?.id === streamId;
};

export const isAudioActive = (activeAudioStreamIds: number[], streamId?: number) => {
    if (streamId === undefined) {
        return false;
    }

    return activeAudioStreamIds.includes(streamId);
};

export const getStreamCurrentTime = (streamCurrentTimes: StreamTime[], streamId?: number) => {
    if (streamId === undefined) {
        return undefined;
    }

    return streamCurrentTimes.find(x => x.streamId === streamId)?.currentTime;
};

export default usePlayerControlsStore;
