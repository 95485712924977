import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Response = api.HubSpotContactResponse;

export interface ContactInfo {
    username: string;
    firstname: string;
    lastname: string;
}

export const useHubspot = () => {
    const { path: registerContact } = config.hubspot.registerContact();
    const submitContact = useMutation((data: ContactInfo) => Api.post<Response>({ path: registerContact, body: data }), {
        onSuccess: res => {
            console.log(res);
        },
        onError(error, variables, context) {
            console.log(error);
        },
    });
    return {
        submitContact: submitContact.mutateAsync,
    };
};
