import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.AssistantListResponse;

export function useListAssistants(doctorId: number) {
    const { key, path } = config.doctors.listAssistant(doctorId);

    const result = useQuery([key], () => Api.get<Response>({ path }), { enabled: doctorId > 0 });

    return {
        assistants: result?.data?.assistants ?? [],
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
