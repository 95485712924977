import React from 'react';
import { Paper, PaperProps, withStyles } from '@material-ui/core';

interface IPanelProps extends PaperProps {
    children: React.ReactNode;
    classes: Record<string, string>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
const styles = (theme: any): any => ({
    root: {
        padding: theme.spacing(2),
        textAlign: 'center',
        margin: theme.spacing(2),
    },
});

const Panel: React.FC<IPanelProps> = ({ children, elevation = 3, classes }) => (
    <Paper className={classes.root} elevation={elevation} variant={elevation ? 'elevation' : 'outlined'}>
        {children}
    </Paper>
);

export default withStyles(styles)(Panel);
