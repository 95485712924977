import React from 'react';
import { Button } from '@material-ui/core';
import Panel from '../UI/Panel';

interface Props {
    resetErrorBoundary: (...args: unknown[]) => void;
}

export default function ErrorBoundaryFallback({ resetErrorBoundary }: Props) {
    return (
        <Panel>
            <p>Oops! Something went wrong...</p>
            <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save" color="primary">
                Try again
            </Button>
        </Panel>
    );
}
