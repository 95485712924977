import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.GetAttendeeResponse;

export function useGetAttendee(procedureId: number) {
    const { key, path } = config.attendee.detail(procedureId);

    const result = useQuery(key, () => Api.get<Response>({ path }), {
        enabled: Boolean(procedureId),
        refetchInterval: 4000,
    });

    return {
        attendee: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
