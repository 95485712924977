import { useMemo, useState } from 'react';
import { Avatar, Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { NavigateBefore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { useListAttendees } from 'circulator/hooks/attendee/useListAttendees';
import { useGetUser } from 'circulator/hooks/user/useGetUser';
import { AVPanel } from 'client/components/Players/AVPanel';
import { MainPlayer } from 'client/components/Players/MainPlayer';
import { ProcedureSummary } from 'client/components/ProcedureSummary';
import { ProfileInfo } from 'client/components/ProfileInfo';
import { SurveyPanel } from 'client/components/Questionnaire/SurveyPanel';
import { useGetAttendee } from 'client/hooks/attendee/useGetAttendee';
import { useUpsertAttendee } from 'client/hooks/attendee/useUpsertAttendee';
import { useGetProcedure } from 'client/hooks/procedure/useGetProcedure';
import { useUser } from 'client/hooks/user/useUser';
import { Chat } from 'common/components/Chat/Chat';
import { ChatMembers } from 'common/components/Chat/ChatMembers';
import usePlayerControlsStore from 'common/hooks/usePlayerControlsStore';
import { getUserInAdminUsers } from 'common/services/userServices';
import { AttendeeStatus } from 'common/types';
import profilePlaceholder from 'img/profile-placeholder-colors.png';

const useStyles = makeStyles({
    chatBox: {
        height: '31rem',
    },
    listViewer: {
        position: 'absolute',
        right: 15,
        zIndex: 10,
        color: '#eee',
        padding: 5,
    },
    avatar: {
        width: 25,
        height: 25,
    },
    participantExpander: {
        width: 35,
        height: '100%',
        float: 'right',
        paddingTop: 35,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        rowGap: 10,
        backgroundColor: '#4a6473',
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        overflow: 'hidden',
    },
    participantDrawer: {
        transform: 'none',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
});

interface Props {
    procedureId: number;
}

const { JOINED } = AttendeeStatus;

export const DisplayRoom = ({ procedureId }: Props) => {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(true);
    const { user } = useUser();
    const { procedure } = useGetProcedure(procedureId);
    const userAdmin = useMemo(() => getUserInAdminUsers(user), [user]);
    const { attendee } = useGetAttendee(procedureId);
    const { upsertAttendee } = useUpsertAttendee();
    const { isShadeDown: obscure = false } = procedure ?? {};
    const procedureCleanup = usePlayerControlsStore(state => state.procedureCleanup);

    const { attendees } = useListAttendees(procedureId);
    const joined = useMemo(() => attendees.filter(attendee => attendee.status === JOINED), [attendees]);

    const handleLeaveRoom = async () => {
        await upsertAttendee({ procedureId, body: { status: AttendeeStatus.ACCEPTED } });
        procedureCleanup();
    };

    const handleHidingParticipant = async () => {
        setCollapse(true);
    };

    const handleViewingParticipant = async () => {
        setCollapse(false);
    };

    return (
        <>
            <ProcedureSummary procedure={procedure} onLeaveRoom={handleLeaveRoom} />
            <Grid container spacing={2} style={{ padding: '15px' }}>
                <Grid item xs={12} md={3}>
                    <Box mb={2}>
                        <ProfileInfo />
                    </Box>
                    <Box className={classes.chatBox}>{procedure?.id ? <Chat procedure={procedure} user={user} isRoom={true} /> : <></>}</Box>
                </Grid>
                {attendee?.viewAccess === true && (
                    <Grid item xs={12} md={9}>
                        {/*<Panel elevation={0}>*/}
                        <Box display="flex" flexDirection="column">
                            <Box mb={1}>
                                <Grid container>
                                    <Grid lg={12} md={12} sm={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Box style={{ flex: '1 auto' }}>
                                            <MainPlayer
                                                enablePTZControls={attendee?.publishAccess || userAdmin}
                                                obscure={obscure}
                                                procedureId={procedureId}
                                            />
                                        </Box>
                                        <Collapse in={!collapse} orientation="horizontal" collapsedSize={40}>
                                            <Box className={classes.participantDrawer} style={{ marginLeft: 5, height: '100%' }}>
                                                {!collapse ? (
                                                    <>
                                                        <Box style={{ width: 200, height: '100%' }}>
                                                            <ChatMembers participants={joined} handleHiding={handleHidingParticipant} />
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Box style={{ height: '100%' }}>
                                                            <IconButton
                                                                className={classes.listViewer}
                                                                title="Show Participants"
                                                                onClick={handleViewingParticipant}
                                                            >
                                                                <NavigateBefore style={{ color: '#fff', fontSize: 25 }} />
                                                            </IconButton>
                                                            <Box className={classes.participantExpander}>
                                                                {joined.map(member => (
                                                                    <AvatarItem userId={member.userId} />
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box>
                                <AVPanel procedureId={procedureId} />
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <SurveyPanel procedureId={procedure?.id} />
        </>
    );
};

export interface AttendeeInfo {
    userId: string;
}

export const AvatarItem = (info: AttendeeInfo) => {
    const classes = useStyles();
    const { user } = useGetUser(info.userId);
    const name = `${user?.firstName} ${user?.lastName}`;
    return <Avatar key={info.userId} alt="Avatar" src={profilePlaceholder} className={classes.avatar} title={name} />;
};
