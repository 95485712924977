/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore
import { listAllDevices } from 'common/services/deviceServices';
import { useQuery, useQueryClient } from 'react-query';

export const useListAllDevices = () => {
    const queryClient = useQueryClient();
    const result = useQuery(['device', 'list'], listAllDevices, {
        onSuccess: ({ data }) => {
            if (data) {
                data.forEach((element: any) => {
                    queryClient.setQueryData(['device', element.id], element);
                });
            }
        },
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    const devices = result.data ? result.data.data : [];
    return {
        devices,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
};
