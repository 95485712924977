import { useMemo } from 'react';
import { config } from 'circulator/hooks/config';
import { useUserLogin } from 'circulator/hooks/user/useUserLogin';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

export const useDeviceLogin = () => {
    const { login } = useUserLogin();
    const uuid = useMemo(() => {
        // Find matching device by getting device uuid from local storage.
        const key = 'DeviceUuid';
        const localUUID = localStorage.getItem(key);
        if (localUUID === null) {
            // Create new uuid.
            const newUuid = uuidv4().toLowerCase();
            localStorage.setItem(key, newUuid);
            return newUuid;
        }
        // Return found uuid.
        return localUUID;
    }, []);
    const { key, path } = config.device.login(uuid);

    // Get device username and password for Cognito login.
    const deviceLogin = useMutation(key, () => Api.get({ path }), {
        onSuccess: async data => {
            /// Login through Cognito.
            const { username, password } = data;
            await login({ username, password });
        },
    });

    return {
        uuid: uuid,
        deviceLogin: deviceLogin.mutateAsync,
        isLoading: deviceLogin.isLoading,
    };
};
