/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { generateHeader } from 'common/utils/APIUtils';
import { useQuery } from 'react-query';

const getFacilityById = (facilityId: number) => {
    const headers = generateHeader();
    return axios.get(`/facilities/${facilityId}`, { headers });
};

export const useGetDeviceFacility = (facilityId: number, enableAPI: boolean = false) => {
    const result = useQuery(['device', 'facility', facilityId, enableAPI], () => getFacilityById(facilityId), {
        enabled: enableAPI,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    const facility = result.data ? result.data.data : [];
    return {
        facility,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
};
