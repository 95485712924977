import BasicDomain from './BasicDomain';
import Procedure from './Procedure';
import Profile from './Profile';

export default class Attendee extends BasicDomain {
    static ACCESS_NONE = 'NONE';

    static ACCESS_FULL = 'FULL';

    // static ACCESS_VIDEO = 'VIDEO';

    // static ACCESS_AUDIO = 'AUDIO';

    static STATUS_ACTIVE = 'ACTIVE';

    static STATUS_INACTIVE = 'INACTIVE';

    static STATUS_REJECTED = 'REJECTED';

    get className() {
        return 'Attendee';
    }

    get myClass() {
        return Attendee;
    }

    constructor(props = {}) {
        super(props);

        this.id = props.id || props.objectId || null;
        this.inputAccess = props.inputAccess || Attendee.ACCESS_FULL;
        this.outputAccess = props.outputAccess || Attendee.ACCESS_NONE;
        this.profile = new Profile(props.profile);
        this.procedure = new Procedure(props.procedure);
        this.status = props.status || Attendee.STATUS_ACTIVE;
        this.createdAt = props.createdAt || null;
        this.updatedAt = props.updatedAt || null;
        this.isShadeDown = props.procedure?.isShadeDown || props.isShadeDown || false;
        this.connectionLost = props.connectionLost || false;
    }

    isSame = target =>
        target != null &&
        target.id === this.id &&
        target.inputAccess === this.inputAccess &&
        target.outputAccess === this.outputAccess &&
        target.profile === this.profile &&
        target.procedure === this.procedure &&
        target.status === this.status &&
        target.isShadeDown === this.isShadeDown &&
        target.connectionLost === this.connectionLost;

    getSaveToken = () => ({
        profile: this.profile.pointer,
        procedure: this.procedure.pointer,
        inputAccess: this.inputAccess,
        outputAccess: this.outputAccess,
        status: this.status,
        isShadeDown: this.isShadeDown,
        connectionLost: this.connectionLost,
    });
}
