import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PurchaseIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 32 32">
        <g transform="translate(-70.000000, -516.000000)">
            <path d="M70,516 L70,544.600036 L74.7703593,549 L78.0536779,546.307198 L81.276839,548.947195 L84.5,546.307198 L87.723161,548.947195 L90.9463221,546.307198 L94.2296407,549 L99,544.600036 L99,516 L70,516 Z M78,539 L75,539 L75,536 L78,536 L78,539 Z M85,539 L82,539 L82,536 L85,536 L85,539 Z M87,533 L75,533 L75,530 L87,530 L87,533 Z M92,539 L89,539 L89,536 L92,536 L92,539 Z M94,533 L89,533 L89,530 L94,530 L94,533 Z M95,526 L75,526 L75,523 L95,523 L95,526 Z" />
        </g>
    </SvgIcon>
);
