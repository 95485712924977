import BasicDomain from './BasicDomain';

export default class Camera extends BasicDomain {
    getMyClass() {
        return Camera;
    }

    constructor(props = {}) {
        super(props);

        this.id = props.objectId || props.id || null;
        this.name = props.name || '';
        this.sourceId = props.sourceId || '';
        this.device = props.device != null ? props.device.id || props.device.objectId || props.device : null;

        // Transient Values
        this.token = props.token || null;
        this.isActive = props.isActive || false;
        this.type = props.type || 'broadcast';
    }

    getSaveToken = () => ({
        id: this.id,
    });
}
