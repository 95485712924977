import { useState } from 'react';
import {
    CircularProgress,
    makeStyles,
    Typography,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core';
import { ProcedureCard } from 'client/components/ProcedureCard';
import { useListProcedures } from 'client/hooks/procedure/useListProcedures';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { BackgroundWrapper } from 'mobile/components/UI/BackgroundWrapper';
import { useJoinProcedure } from 'mobile/hooks/procedure/useJoinProcedure';
import useJoinStore from 'mobile/hooks/useJoinStore';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import { useNavigate } from 'react-router-dom';
import CutsLogo from '../../img/cuts-logo.png';

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: '1rem',
        padding: '0 20px',
        textAlign: 'center',
        fontWeight: 300,
    },
    text2: {
        fontSize: '0.7rem',
        padding: '10px 20px',
        textAlign: 'center',
        fontWeight: 300,
    },
    text3: {
        fontSize: '1rem',
        padding: '30px 20px',
        textAlign: 'center',
        fontWeight: 300,
    },
}));

export const ActiveProcedureList = () => {
    const { procedures, isLoading } = useListProcedures();
    const layouts = useMobileLayouts();
    const classes = useStyles();
    const [showJoinDialog, setShowJoinDialog] = useState(false);
    const [joinProcedureId, setJoinProcedureId] = useState<number | null>(null);
    const [joinCode, setJoinCode] = useState('');
    const [isJoinCodeValid, setIsJoinCodeValid] = useState(false);
    const { joinProcedure } = useJoinProcedure();
    const { notify } = useNotifier();
    const setJoinDetails = useJoinStore(state => state.setJoinDetails);
    const navigate = useNavigate();

    const handleOnJoinStart = async (procedureId: number) => {
        setJoinProcedureId(procedureId);
        setJoinCode('');
        setIsJoinCodeValid(false);
        setShowJoinDialog(true);
    };

    const handleOnJoinCancel = () => {
        setShowJoinDialog(false);
    };

    const handleOnJoinComplete = async () => {
        setShowJoinDialog(false);

        if (!joinProcedureId) {
            return;
        }

        try {
            const response = await joinProcedure({ procedureId: joinProcedureId, joinCode });
            setJoinDetails(joinProcedureId, response);
            navigate('/mobile/join');
        } catch (error) {
            console.log(error);
            notify(new Message({ title: 'Unable to join procedure.', type: 'error' }));
        }
    };

    const handleJoinCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setJoinCode(event.target.value.toUpperCase());
        setIsJoinCodeValid(event.target.value.length === 4);
    };

    return (
        <BackgroundWrapper backButtonVisible={true} backButtonHref="/mobile/create-or-archives" headerImage={CutsLogo}>
            <div className={layouts.verticalContainer} style={{ justifyContent: 'flex-start' }}>
                <div>
                    <Typography className={`${classes.text}`}>
                        Pick the active procedure below to join and stream the audio/video from your mobile device.
                    </Typography>
                    <Typography className={`${classes.text2}`}>To join you will need the procedure's unique 4 letter join code.</Typography>
                </div>
                {isLoading ? (
                    <div>
                        <CircularProgress size={50} />
                    </div>
                ) : procedures.length === 0 ? (
                    <div>
                        <Typography className={`${classes.text3}`}>There are no active procedures to join.</Typography>
                    </div>
                ) : (
                    <Paper style={{ maxHeight: '70vh', overflow: 'auto', background: 'none', width: '95%' }}>
                        {procedures.map(procedure => (
                            <ProcedureCard
                                key={procedure.id}
                                procedure={procedure}
                                isAdmin={false}
                                isCutsJoin={true}
                                onRequestAccess={async x => {}}
                                onCancelRequest={async x => {}}
                                onJoinRequest={handleOnJoinStart}
                            />
                        ))}
                    </Paper>
                )}
            </div>
            <Dialog open={showJoinDialog}>
                <DialogTitle>
                    <Typography variant="h6" component="h6" display="block">
                        Join Procedure
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color="primary">
                        <Typography variant="caption" display="block" gutterBottom>
                            Enter the procedure's unique 4 letter join code to begin streaming from your mobile device.
                        </Typography>
                    </DialogContentText>
                    <TextField label="Join Code" value={joinCode} onChange={handleJoinCodeChange} autoFocus fullWidth required />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnJoinCancel} variant="outlined" size="small">
                        Cancel
                    </Button>
                    <Button disabled={!isJoinCodeValid} onClick={handleOnJoinComplete} autoFocus variant="contained" size="small">
                        Join
                    </Button>
                </DialogActions>
            </Dialog>
        </BackgroundWrapper>
    );
};
