import { useCallback } from 'react';
import { Box, Button, Tooltip, Typography, makeStyles } from '@material-ui/core';
import theme from 'App/theme';
import { Player } from 'common/components/Player';
import usePlayerControlsStore, { isActiveStream, isAudioActive } from 'common/hooks/usePlayerControlsStore';
import { StreamType, type ProcedureDetail, type Stream } from 'common/types';
import { SpeakersIcon, SpeakersOffIcon } from 'common/utils/Icons';
import noVideo from 'img/video-placeholder.jpg';
import { shallow } from 'zustand/shallow';
import { useLayouts } from '../../hooks/styles/useLayouts';
import { useStyles } from '../../hooks/styles/useStyles';

const useCustomStyles = makeStyles({
    videoTitle: {
        color: 'white',
        flex: '0 0 auto',
    },
    audioOnlyLabel: {
        color: 'black',
        verticalAlign: 'middle',
        padding: '20%',
        fontWeight: 500,
        textAlign: 'center',
        display: 'block',
    },
});

interface Props {
    stream: Stream;
    obscure: boolean;
    iceServers: ProcedureDetail['device']['iceServers'];
}

export function AVPlayer({ stream, obscure, iceServers }: Props) {
    const customClasses = useCustomStyles();
    const classes = useStyles();
    const layouts = useLayouts();
    const [activeStream, setActiveStream, activeAudioStreamIds, toggleAudioActive] = usePlayerControlsStore(
        state => [state.activeStream, state.setActiveStream, state.activeAudioStreamIds, state.toggleAudioActive],
        shallow
    );

    const isManuallyMuted = !isAudioActive(activeAudioStreamIds, stream.id);
    const isActive = isActiveStream(activeStream, stream.id);

    const toggleAudioOnClick = useCallback(
        ev => {
            toggleAudioActive(stream.id);
            ev.stopPropagation();
        },
        [stream, toggleAudioActive]
    );
    const setActiveStreamOnClick = useCallback(() => {
        setActiveStream(stream);
    }, [stream, setActiveStream]);

    const streamIsAudioOnly = stream.type === StreamType.AUDIO_ONLY;
    const streamHasAudio = stream.type !== StreamType.VIDEO_ONLY;

    return (
        <div className={layouts.verticalContainer}>
            <Box bgcolor={theme.palette.primary.dark} width={'100%'} paddingLeft={'1em'}>
                <Typography variant="caption" className={customClasses.videoTitle}>
                    {isActive ? `Active - ${stream.name}` : stream.name}
                </Typography>
            </Box>
            <Tooltip title={streamIsAudioOnly ? '' : isActive ? 'Stream is playing' : 'Watch this stream'}>
                <div
                    onClick={!isActive && !streamIsAudioOnly ? setActiveStreamOnClick : undefined}
                    className={classes.videoFrameSmall}
                    style={{
                        background: streamIsAudioOnly ? 'white' : undefined,
                        cursor: !isActive && !streamIsAudioOnly ? 'pointer' : 'default',
                    }}
                >
                    {streamIsAudioOnly && <span className={customClasses.audioOnlyLabel}>Audio Only</span>}
                    <Player
                        className={streamIsAudioOnly ? layouts.hidden : ''}
                        isActive={isActive}
                        src={stream.src}
                        width={'100%'}
                        height={'100%'}
                        iceServers={JSON.stringify(iceServers)}
                        poster={noVideo}
                        obscure={obscure}
                        isMainPlayer={false}
                        muted={isManuallyMuted || isActive}
                        streamId={stream.id}
                    />
                    {streamHasAudio && (
                        <Button className={[layouts.bottomLeft, classes.glassyButton].join(' ')} onClick={toggleAudioOnClick}>
                            {isManuallyMuted ? <SpeakersOffIcon /> : <SpeakersIcon />}
                        </Button>
                    )}
                </div>
            </Tooltip>
        </div>
    );
}
