import { makeStyles } from '@material-ui/core';
import useScreenInformation from './useScreenInformation';

const container = {
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    maxHeight: '100%',
} as const;

export const useMobileLayouts = () => {
    const screenInformation = useScreenInformation();
    return makeStyles(theme => ({
        padded: {
            padding: theme.spacing(2),
        },
        screen: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: screenInformation.isLandscape ? undefined : 'center',
            flexDirection: 'column',
            height: '100vh',
            width: '100vw',
            paddingBottom: '0px',
        },
        verticalContainer: {
            flexDirection: 'column',
            height: '0px', //let flex box do this, but we must specify it
            width: '100%',
            ...container,
        },
        horizontalContainer: {
            flexDirection: 'row',
            height: '100%',
            width: '0px', //let flex box do this, but we must specify it
            ...container,
        },
        dynamicContainer: {
            flexDirection: screenInformation.isLandscape ? 'row' : 'column',
            height: '0px', //let flex box do this, but we must specify it
            width: '100%',
            ...container,
        },
        titleContainer: {
            width: '100%',
            margin: '0px',
            padding: '0px',
            display: 'flex',
            alignItems: 'center',
            '& > div:nth-child(2) > img': {
                padding: '0px 10px',
                flex: '1 1 auto',
            },
            '& > div': {
                margin: `${screenInformation.isLandscape ? theme.spacing(1) : theme.spacing(4)}px 0px`,
                flex: '1 1 33%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            },
        },
    }))();
};

export default useMobileLayouts;
