import { Button, makeStyles } from '@material-ui/core';
import { MessageBox } from 'common/components/UI/MessageBox';
import Message from 'common/models/Message';
import type { Mode } from './Layout';

const useStyles = makeStyles(theme => ({
    msg: {
        padding: theme.spacing(2),
        textIndent: 30,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

interface Props {
    onChangeMode: (mode: Mode) => void;
}

export const ForVerifyPanel: React.FC<Props> = ({ onChangeMode }) => {
    const classes = useStyles();

    return (
        <>
            <MessageBox
                message={
                    new Message({
                        title: 'Verification Process',
                        details:
                            'Your account is still being verified. Please give us some time to confirm the information you have provided. If more than 48 hours have passed and you have not received any communication from us, please email us at accounts@vsurgic.com. Thank you',
                        type: 'info',
                    })
                }
            />
            <br />
            <div className={classes.actions}>
                <Button size="small" onClick={() => onChangeMode('login')}>
                    Back to Sign In Page
                </Button>
            </div>
        </>
    );
};
