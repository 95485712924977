import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type Response = api.UserRolesResponse;

export interface UserRolesUpdateRequest {
    userId: string;
    roles: string[];
}

export const useUpsertUserRoles = () => {
    const { path: updatePath } = config.user.updateRoles();
    const queryClient = useQueryClient();

    const updateUserRoles = useMutation((data: UserRolesUpdateRequest) => Api.post<Response>({ path: updatePath, body: data }), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: config.user.list().key });
        },
    });
    return {
        updateUserRoles: updateUserRoles.mutateAsync,
    };
};
