import React, { useImperativeHandle, forwardRef } from 'react';
import { Box, Typography, Grid, IconButton, Tooltip, IconButtonProps, FabProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid as XDataGrid, DataGridProps as XDataGridProps, GridColDef, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

const GridContainer = styled('div')(() => ({
    maxWidth: '100vw',
    backgroundColor: 'inherit',
    display: 'flow-root',
}));

export interface DataGridAction {
    component?: React.JSXElementConstructor<any>;
    key: string;
    color?: IconButtonProps['color'] | FabProps['color'];
    tooltip: React.ReactNode;
    label: IconButtonProps['children'] | FabProps['children'];
    onClick: () => void;
    disabled?: boolean;
    enabled?: boolean;
}

export interface DataGridProps extends XDataGridProps {
    rows: GridRowsProp;
    columns: GridColDef[];
    rowSelected?: boolean;
    onRowSelectionModelChange?: any;
    checkboxSelection?: boolean;
    disableRowSelectionOnClick?: boolean;
    hideFooterSelectedRowCount?: boolean;
    gridTitle?: string;
    actions?: DataGridAction[];
    isLoading?: boolean;
}

const DataGrid = forwardRef<GridApiCommunity, DataGridProps>(
    (
        { actions, rows, columns, onRowSelectionModelChange, checkboxSelection = false, rowSelected = false, gridTitle, isLoading = false, ...props },
        ref
    ) => {
        const apiRef = useGridApiRef();
        useImperativeHandle(ref, () => ({
            ...apiRef.current,
            getRows: () => {
                if (apiRef.current && apiRef.current.getSelectedRows) {
                    const rows = apiRef.current.getSelectedRows();
                    return rows;
                }
                return [];
            },
        }));
        return (
            <GridContainer>
                <Box sx={{ ...props.sx, minWidth: '10px' }}>
                    <Grid container>
                        <Grid item xs={6}>
                            {gridTitle && (
                                <Typography variant="h6" justifyItems={'center'}>
                                    {gridTitle}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Box sx={{ pb: 2 }}>
                                {actions &&
                                    actions.map(action => {
                                        const C = action.component ?? IconButton;
                                        return (
                                            <Tooltip key={action.key} title={action.tooltip}>
                                                <C
                                                    size="small"
                                                    color={action.color ?? 'default'}
                                                    aria-label="open drawer"
                                                    onClick={action.onClick}
                                                    disabled={action.enabled ? !action.enabled : !rowSelected || action.disabled}
                                                    edge="start"
                                                    sx={{ ml: 2 }}
                                                >
                                                    {action.label}
                                                </C>
                                            </Tooltip>
                                        );
                                    })}
                            </Box>
                        </Grid>
                    </Grid>
                    <XDataGrid
                        loading={isLoading}
                        apiRef={apiRef}
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        autoHeight
                        pageSizeOptions={[10]}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        checkboxSelection={checkboxSelection}
                        sx={{ width: '100%' }}
                        {...props}
                    />
                </Box>
            </GridContainer>
        );
    }
);

export default DataGrid;
