import { useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { ShareModal } from './ShareModal';

interface Props {
    procedureId: number;
}

export const ShareVideoButton = ({ procedureId }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    };

    return isOpen ? <ShareModal procedureId={procedureId} isOpen={isOpen} setIsOpen={setIsOpen} /> : <ShareIcon onClick={() => openModal()} />;
};
