import { detectOverflow, type MiddlewareReturn, type MiddlewareState, type Middleware } from '@floating-ui/dom';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

/**
 * Custom middleware to handle overflow of the date picker popper.
 * This just makes sure it stays on the screen, by adjusting the x and y values to within the limits of the screen.
 * Not really an issue on desktop, but on mobile, it can be a problem.
 */
const customMiddleWare: Middleware = {
    name: 'custom-middleware',
    options: {
        detectOverflow: true,
    },
    fn: async (data: MiddlewareState): Promise<MiddlewareReturn> => {
        const overflow = await detectOverflow(data, { boundary: document.body });
        data.x += Math.max(overflow.left, 0) - Math.max(overflow.right, 0);
        data.y += Math.max(overflow.top, 0) - Math.max(overflow.bottom, 0);
        return data;
    },
};

export const DatePicker = (props: ReactDatePickerProps) => {
    return <ReactDatePicker {...props} popperModifiers={[customMiddleWare]} />;
};
