import React, { useMemo } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = {
    root: {
        textAlign: 'center',
        position: 'fixed',
        backgroundColor: 'rgba(18, 18, 18, 0.87)',
        color: 'white',
        paddingTop: '33vh',
        fontSize: '1.6em',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
    },
};

const useStyles = makeStyles(styles);
export const ProcessingOverlay = props => {
    const classes = useStyles();
    const isFinding = false; // useSelector(store => store.procedure.isFinding);
    const isCreating = false; // useSelector(store => store.procedure.isCreating);

    const processingOverlayEnabled = useMemo(() => Boolean(isFinding || isCreating || props.enabled), [isFinding, isCreating, props.enabled]);
    const { caption, size, thickness, icon } = props;

    const result = useMemo(() => {
        if (processingOverlayEnabled) {
            return (
                <div className={classes.root}>
                    {icon}
                    {!icon && <CircularProgress size={size} thickness={thickness} />}
                    <p>{caption}</p>
                </div>
            );
        }

        return <></>;
    }, [caption, classes.root, icon, processingOverlayEnabled, size, thickness]);
    return result;
};

ProcessingOverlay.propTypes = {
    enabled: PropTypes.bool,
    caption: PropTypes.string,
    size: PropTypes.number,
    thickness: PropTypes.number,
    icon: PropTypes.element,
};

ProcessingOverlay.defaultProps = {
    enabled: false,
    caption: 'Processing...',
    size: 60,
    thickness: 5,
    icon: null,
};
