export const htmlAgreement: string = `
	<div>
		<h3 class="indent" style="text-align: center;">VSURGIC SOFTWARE ACCESS AGREEMENT </h3>
		<p class="indent">
			This ACCESS AGREEMENT (this "Agreement"), effective as of the date signed below (the "Effective Date"),
			is entered into between RRC Washington, Inc., a Delaware corporation ("Provider"), and the User name
			listed below ("User"). Provider and User may be referred to herein collectively as the "Parties" or
			individually as a "Party."
		</p>
		<p class="indent">
			WHEREAS, Provider has installed, or will install, the VSURGIC System in various operating rooms of its
			Customers;
		</p>
		<p class="indent">
			WHEREAS, User desires to access the VSURGIC System to allow User to remotely access the operating room
			environments; and
		</p>
		<p class="indent">
			WHEREAS, Provider desires to provide User access to the VSURGIC System, subject to the terms and
			conditions of this Agreement.
		</p>
		<p class="indent">
			NOW, THEREFORE, in consideration of the mutual covenants, terms, and conditions set forth herein, and
			for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged,
			the Parties agree as follows:
		</p>
		<p class="indent">
			1. Definitions.
		</p>
		<p class="indent">
			(a) "Customer(s)" means a customer of Provider, and such customer is generally a hospital or an
			ambulatory surgery center.
		</p>
		<p class="indent">
			(b) "Documentation" means Provider’s user manuals, handbooks, and guides relating to the VSURGIC System
			provided by Provider to User either electronically or in hard copy form.
		</p>
		<p class="indent">
			(c) "Provider IP" means the VSURGIC System, the Documentation, and any and all intellectual property
			provided to User or any Customer in connection with the foregoing. For the avoidance of doubt, Provider
			IP includes any information, data, or other content derived from Provider’s monitoring of User’s access
			to or use of the VSURGIC System.
		</p>
		<p class="indent">
			(d) "VSURGIC System" means: (i) the software provided by Provider to User as software-as-a-service,
			which allows User to remotely access operating room environments, (ii) the software provided by Provider
			to a Customer as software-as-a-service, which allows the Customer to grant access to an operating room
			environment, and (iii) the hardware provided to a Customer enabling the User’s access to an operating
			room environment.
		</p>
		<p class="indent">
			2. Access and Use.
		</p>
		<p class="indent">
			(a) Provision of Access. Subject to and conditioned on User’s payment of Fees and compliance with all
			other terms and conditions of this Agreement, Provider hereby grants User a non-exclusive,
			non-transferable (except in compliance with Section 12(g)) right to access and use the VSURGIC System
			during the Term, solely for use in connection with User’s remote access to the operating room
			environments in accordance with the terms and conditions imposed by the applicable Customer. Provider
			shall provide to User the necessary passwords and network links or connections to allow User to access
			the VSURGIC System for such purposes.
		</p>
		<p class="indent">
			(b) Documentation License. Subject to the terms and conditions contained in this Agreement, Provider hereby
			grants to User a non-exclusive, non-sublicensable, non-transferable (except in compliance with Section
			12(g))
			license to use the Documentation during the Term solely for User’s business purposes in connection with its
			use
			of the VSURGIC System.
		</p>
		<p class="indent">
			(c) Use Restrictions. User shall not use the access to the VSURGIC System or the Documentation for any
			purposes
			beyond the scope of the access granted in this Agreement. User shall not at any time, directly or
			indirectly:
			(i) copy, modify, or create derivative works of the VSURGIC System or Documentation, in whole or in part;
			(ii)
			rent, lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make
			available
			the VSURGIC System or Documentation; (iii) reverse engineer, disassemble, decompile, decode, adapt, or
			otherwise
			attempt to derive or gain access to any software component of the VSURGIC System, in whole or in part; (iv)
			remove any proprietary notices from the VSURGIC System or Documentation; (v) use the VSURGIC System or
			Documentation in any manner or for any purpose that infringes, misappropriates, or otherwise violates any
			intellectual property right or other right of any person, or that violates any applicable law; (vi) use the
			VSURGIC System or Documentation in any manner or for any purpose that violates any policy, procedure, or
			restriction of any applicable Customer; or (vii) permit or allow anyone other than User to access the
			VSURGIC
			System using User’s access granted pursuant to this Agreement.
		</p>
		<p class="indent">
			(d) Reservation of Rights. Provider reserves all rights not expressly granted to User in this Agreement.
			Except
			for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement
			grants, by
			implication, waiver, estoppel, or otherwise, to User or any third party any intellectual property rights or
			other right, title, or interest in or to the Provider IP.
		</p>
		<p class="indent">
			(e) Suspension. Notwithstanding anything to the contrary in this Agreement, Provider may temporarily suspend
			User’s access to any portion or all of the use the VSURGIC System if: (i) Provider reasonably determines
			that
			(A) there is a threat or attack on any of the Provider IP; (B) User’s use of the Provider IP disrupts or
			poses a
			security risk to the Provider IP or to any Customer or vendor of Provider; (C) User is using the Provider IP
			for
			fraudulent or illegal activities, or in violation of this Agreement; or (D) subject to applicable law, User
			has
			ceased to continue its business in the ordinary course, made an assignment for the benefit of creditors or
			similar disposition of its assets, or become the subject of any bankruptcy, reorganization, liquidation,
			dissolution, or similar proceeding; (ii) any Customer has suspended or terminated User’s access to or use of
			the
			VSURGIC System; or (iii) in accordance with Section 4(a)(iii) (any such suspension described in subclause
			(i),
			(ii), or (iii), a "Service Suspension"). Provider shall use commercially reasonable efforts to provide
			written
			notice of any Service Suspension to User and to provide updates regarding resumption of access to the
			VSURGIC
			System following any Service Suspension. Provider shall use commercially reasonable efforts to resume
			providing
			access to VSURGIC System as soon as reasonably possible after the event giving rise to the Service
			Suspension is
			cured. Provider will have no liability for any damage, liabilities, losses (including any loss of data or
			profits), or any other consequences that User may incur as a result of a Service Suspension. Service
			Suspensions
			by Provider shall be in addition to, and shall not limit in any way, Provider’s right to terminate this
			Agreement pursuant to Section 4(a) or Section 10.
		</p>
		<p class="indent">
			3. User Responsibilities.
		</p>
		<p class="indent">
			(a) General. User is responsible and liable for User’s uses of the VSURGIC System and Documentation
			resulting
			from access provided by Provider and Customers, directly or indirectly, whether such access or use is
			permitted
			by or in violation of this Agreement.
		</p>
		<p class="indent">
			(b) Equipment and Internet Service. User will solely be responsible for the provision and maintenance of
			adequate internet service and quality equipment in order to use the VSURGIC System. User understands and
			agrees
			that it is solely User’s responsibility to confirm the adequacy and compatibility of User’s internet service
			and
			equipment for use with the VSURGIC System, and not the responsibility of Provider.
		</p>
		<p class="indent">
			(c) Access to Customers. User will solely be responsible for securing from Customers access to Customers’
			operating room environments through the VSURGIC System. User understands and agrees that such access is not
			within the control of Provider and will be only allowed or approved by Customer in Customer’s sole
			discretion.
			User will comply with any requirements of Customers or of Provider for the purposes of Customers’
			confirmation
			of the identity of User when accessing the VSURGIC System.
		</p>
		<p class="indent">
			(d) Compliance with Terms of Services and Customer Policies. User agrees that it will comply with Provider’s
			general terms of services and with all terms of this Agreement when using use the VSURGIC System. While
			remotely
			accessing Customer’s locations and premises, User shall follow all of Customer’s policies, procedures, and
			requirements in all respects.
		</p>
		<p class="indent">
			(e) Health Insurance Portability and Accountability Act ("HIPAA"). The User agrees to perform this Agreement
			and
			the use of the VSURGIC System in a manner that complies in all respects with HIPAA and other federal and
			state
			laws and regulations governing the protection, use, and disclosure of confidential and protected patient
			information. If User becomes aware of a violation of HIPAA by itself or on the part of one of its directors,
			officers, shareholders, employees or agents, then User shall immediately notify Provider and Customer of
			such
			HIPAA violation and shall take all actions necessary to mitigate the damage caused by such violation.
		</p>
		<p class="indent">
			(i) Each party agrees to comply with the Health Information Technology for Economic and Clinical Health Act
			of
			2009 (the "HITECH Act"), the Administrative Simplification Provisions of the Health Insurance Portability
			and
			Accountability Act of 1996, as codified at 42 U.S.C.A. §1320d et seq. ("HIPAA") and any current and future
			regulations promulgated under the HITECH Act or HIPAA, including without limitation the federal privacy
			regulations contained in 45 C.F.R. Parts 160 and 164 (the "Federal Privacy Regulations"), the federal
			security
			standards contained in 45 C.F.R. Parts 160, 162 and 164 (the "Federal Security Regulations"), the federal
			standards for electronic transactions contained in 45 C.F.R. Parts 160 and 162 (the "Federal Electronic
			Transaction Regulations"), and the health information breach notification regulations contained in 45 C.F.R.
			Parts 160 and 164 (the "Federal Breach Notification Regulations"), all as amended from time to time and
			collectively referred to herein as the "HIPAA Requirements". Each party agrees not to use or further
			disclose
			any "Protected Health Information," including "Electronic Protected Health Information," (as such terms are
			defined in the HIPAA Requirements) other than as permitted by the HIPAA Requirements and the terms of this
			Agreement. Each party will make its internal practices, books, and records relating to the use and
			disclosure of Protected Health Information available to the Secretary of Health and
			Human Services to the extent required for determining compliance with the HIPAA Requirements.
		</p>
		<p class="indent">
			4. Fees and Payment.
		</p>
		<p class="indent">
			(a) Fees. User shall pay to Provider the fees ("Fees") as set forth in Exhibit A. User shall make all
			payments
			hereunder in US dollars on or before the due date set forth in Exhibit A. If User fails to make any payment
			when
			due, without limiting Provider’s other rights and remedies: (i) Provider may charge interest on the past due
			amount at the rate of 1.5% per month calculated daily and compounded monthly or, if lower, the highest rate
			permitted under applicable law; (ii) User shall reimburse Provider for all reasonable costs incurred by
			Provider
			in collecting any late payments or interest, including attorneys’ fees, court costs, and collection agency
			fees;
			and (iii) if such failure continues for 15 days or more, Provider may suspend User’s access to any portion
			or
			all of the VSURGIC System until such amounts are paid in full, or may terminate this Agreement.
		</p>
		<p class="indent">
			(b) Taxes. All Fees and other amounts payable by User under this Agreement are exclusive of taxes and
			similar
			assessments. User is responsible for all sales, use, and excise taxes, and any other similar taxes, duties,
			and
			charges of any kind imposed by any federal, state, or local governmental or regulatory authority on any
			amounts
			payable by User hereunder, other than any taxes imposed on Provider’s income.
		</p>
		<p class="indent">
			5. Confidential Information
		</p>
		<p class="indent">
			From time to time during the Term, either Party may disclose or make available to the other Party
			information about its business affairs, products, confidential intellectual property, trade secrets,
			third-party confidential information, and other sensitive or proprietary information, whether orally or in
			written, electronic, or other form or media/in written or electronic form or media, whether or not marked,
			designated, or otherwise identified as “confidential” (collectively, “Confidential Information”).
			Confidential Information does not include information that, at the time of disclosure is: (a) in the public
			domain; (b) known to the receiving Party at the time of disclosure; (c) rightfully obtained by the receiving
			Party on a non-confidential basis from a third party; or (d) independently developed by the receiving Party.
			The receiving Party shall not disclose or use the disclosing Party’s Confidential Information to any person
			or entity, except to the receiving Party’s employees who have a need to know the Confidential Information
			for the receiving Party to exercise its rights or perform its obligations hereunder. Notwithstanding the
			foregoing, each Party may disclose Confidential Information to the limited extent required (i) in order to
			comply with the order of a court or other governmental body, or as otherwise necessary to comply with
			applicable law, provided that the Party making the disclosure pursuant to the order shall first have given
			written notice to the other Party and made a reasonable effort to obtain a protective order; or (ii) to
			establish a Party’s rights under this Agreement, including to make required court filings. On the expiration
			or termination of the Agreement, the receiving Party shall promptly return to the disclosing Party all
			copies, whether in written, electronic, or other form or media, of the disclosing Party’s Confidential
			Information, or destroy all such copies and certify in writing to the disclosing Party that such
			Confidential Information has been destroyed. Each Party’s obligations of non-disclosure with regard to
			Confidential Information are effective as of the Effective Date and will expire five years from the date
			first disclosed to the receiving Party; provided, however, with respect to any Confidential Information that
			constitutes a trade secret (as determined under applicable law), such obligations of non-disclosure will
			survive the termination or expiration of this Agreement for as long as such Confidential Information remains
			subject to trade secret protection under applicable law. Each Party shall be responsible for breaches of
			this Section 5 by such Party’s employees, consultants, contractors, or agents.
		</p>
		<p class="indent">
			6. Intellectual Property Ownership; Feedback.
		</p>
		<p class="indent">
			(a) Provider IP. User acknowledges that, as between User and Provider, Provider owns all right, title, and
			interest, including all intellectual property rights, in and to the Provider IP.
		</p>
		<p class="indent">
			(b) Feedback. If User sends or transmits any communications or materials to Provider by mail, email,
			telephone,
			or otherwise, suggesting or recommending changes to the Provider IP, including without limitation, new
			features
			or functionality relating thereto, or any comments, questions, suggestions, or the like (“Feedback”),
			Provider
			is free to use such Feedback irrespective of any other obligation or limitation between the Parties
			governing
			such Feedback. User hereby assigns to Provider on User’s behalf, all right, title, and interest in, and
			Provider
			is free to use, without any attribution or compensation to any party, any ideas, know-how, concepts,
			techniques,
			or other intellectual property rights contained in the Feedback, for any purpose whatsoever, although
			Provider
			is not required to use any Feedback.
		</p>
		<p class="indent">
			7. Limited Warranty and Warranty Disclaimer.
		</p>
		<p class="indent">
			(a) Provider does not make any representations or guarantees regarding uptime or availability of the VSURGIC
			System. PROVIDER STRICTLY DISCLAIMS ALL WARRANTIES, WITH RESPECT TO ANY THIRD-PARTY PRODUCTS.
		</p>
		<p class="indent">
			(b) THE VSURGIC SYSTEM IS PROVIDED “AS IS” AND PROVIDER HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
			IMPLIED, STATUTORY, OR OTHERWISE. PROVIDER SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
			FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF
			DEALING, USAGE, OR TRADE PRACTICE. PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE VSURGIC SYSTEM, OR ANY
			PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET USER’S OR ANY OTHER PERSON’S REQUIREMENTS, OPERATE WITHOUT
			INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER
			SERVICES,
			OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.
		</p>
		<p class="indent">
			8. Indemnification.
		</p>
		<p class="indent">
			(a) Provider Indemnification.
		</p>
		<p class="indent">
			(i) Provider shall indemnify, defend, and hold harmless User from and against any and all losses, damages,
			liabilities, costs including reasonable attorneys’ fees (“Losses”) incurred by User resulting from any
			third-party claim, suit, action, or proceeding (“Third-Party Claim”) that the VSURGIC System used in
			accordance
			with this Agreement, infringes or misappropriates such third party’s US intellectual property rights/US
			patents,
			copyrights, or trade secrets, provided that User promptly notifies Provider in writing of the claim,
			cooperates
			with Provider, and allows Provider sole authority to control the defense and settlement of such claim. If
			such a
			claim is made or appears possible, User agrees to permit Provider, at Provider’s sole discretion, to (A)
			modify
			or replace the VSURGIC System, or component or part thereof, to make it non-infringing, or (B) obtain the
			right
			for User to continue use. If Provider determines that neither alternative is reasonably available, Provider
			may
			terminate this Agreement, in its entirety or with respect to the affected component or part, effective
			immediately on written notice to User.
		</p>
		<p class="indent">
			(ii) This Section 8a will not apply to the extent that the alleged infringement arises from: (A) use of the
			VSURGIC System in combination with data, software, hardware, equipment, or technology not provided by
			Provider
			or authorized by Provider in writing; or (B) modifications to the VSURGIC System not made by Provider.
		</p>
		<p class="indent">
			(iii) Sole Remedy. THIS 68 SETS FORTH USER’S SOLE REMEDIES AND PROVIDER’S SOLE LIABILITY AND OBLIGATION FOR
			ANY
			ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE VSURGIC SYSTEM INFRINGES, MISAPPROPRIATES, OR OTHERWISE
			VIOLATES
			ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY.
		</p>
		<p class="indent">
			(b) User Indemnification. User shall indemnify, hold harmless, and, at Provider’s option, defend Provider
			from
			and against any Losses resulting from any Third-Party Claim based on User’s (i) negligence or willful
			misconduct; (ii) use of the VSURGIC System in a manner not authorized by this Agreement, or arising from or
			relating to any breach of this Agreement; (iii) use of the VSURGIC System in combination with data,
			software,
			hardware, equipment, or technology not provided by Provider or authorized by Provider in writing; or (iv)
			modifications to VSURGIC System not made by Provider, provided that User may not settle any Third-Party
			Claim
			against Provider unless Provider consents to such settlement, and further provided that Provider will have
			the
			right, at its option, to defend itself against any such Third-Party Claim or to participate in the defense
			thereof by counsel of its own choice.
		</p>
		<p class="indent">
			9. Limitations of Liability. IN NO EVENT WILL PROVIDER BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT
			UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
			LIABILITY, AND OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR
			PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR
			PROFITS;
			(c) LOSS OF GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR RECOVERY OF ANY
			DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE
			REGARDLESS OF WHETHER PROVIDER WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR
			DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL PROVIDER’S AGGREGATE LIABILITY ARISING OUT OF OR
			RELATED TO
			THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
			NEGLIGENCE),
			STRICT LIABILITY, AND OTHERWISE EXCEED THE TOTAL FEES PAID TO PROVIDER UNDER THIS AGREEMENT IN THE 12-MONTH
			PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
		</p>
		<p class="indent">
			10. Term and Termination.
		</p>
		<p class="indent">
			(a) Term. The initial term of this Agreement begins on the Effective Date and, unless terminated earlier
			pursuant to this Agreement’s express provisions, will continue in effect until one year from such date (the
			“Initial Term”). This Agreement will automatically renew for successive one-year terms unless earlier
			terminated
			pursuant to this Agreement’s express provisions or either Party gives the other Party written notice of
			non-renewal at least 90 days prior to the expiration of the then-current term (each a “Renewal Term” and
			together with the Initial Term, the “Term”).
		</p>
		<p class="indent">
			(b) Termination. In addition to any other express termination right set forth in this Agreement:
		</p>
		<p class="indent">
			(i) Provider may terminate this Agreement, effective on written notice to User, if User fails to pay any
			amount
			when due hereunder, and such failure continues more than 15 days after Provider’s delivery of written notice
			thereof; or
		</p>
		<p class="indent">
			(ii) either Party may terminate this Agreement, effective on written notice to the other Party, if the other
			Party materially breaches this Agreement, and such breach: (A) is incapable of cure; or (B) being capable of
			cure, remains uncured 30 days after the non-breaching Party provides the breaching Party with written notice
			of
			such breach.
		</p>
		<p class="indent">
			(c) Effect of Expiration or Termination. Upon expiration or earlier termination of this Agreement, User shall
			immediately discontinue use of the Provider IP and Documentation and, without limiting User’s obligations under
			45, User shall delete, destroy, or return all copies of the Provider IP and certify in writing to the Provider
			that the Provider IP has been deleted or destroyed. No expiration or termination will affect User’s obligation
			to pay all Fees that may have become due before such expiration or termination. User will not be entitled to a
			refund of the Fee upon termination of this Agreement; provided, however, in the event User terminates this
			Agreement pursuant to Section 10(b)(ii) (due to material breach), Provider shall refund to User a pro-rated
			amount of the Fee based on the effective date of the termination.
		</p>
		<p class="indent">
			(d) Survival. This Section 10d and 11, 4, 5, 6, 7, 8, 9, 10(c) and 11 survive any termination or expiration
			of this Agreement. No other provisions of this Agreement survive the expiration or earlier termination of this
			Agreement.
		</p>
		<p class="indent">
			11. Miscellaneous.
		</p>
		<p class="indent">
			(a) Entire Agreement. This Agreement, together with any other documents incorporated herein by reference and all
			related exhibits, constitutes the sole and entire agreement of the Parties with respect to the subject matter of
			this Agreement and supersedes all prior and contemporaneous understandings, agreements, and representations and
			warranties, both written and oral, with respect to such subject matter.
		</p>
		<p class="indent">
			(b) Notices. All notices required or permitted to be given under this Agreement shall be in writing. Notices may
			be served by certified or registered mail, postage paid with return receipt requested; by private courier,
			prepaid; by facsimile, email, or other telecommunication device capable of transmitting or creating a written
			record; or personally. Mailed notices shall be deemed delivered upon receipt or, if earlier, five days after
			mailing, properly addressed. Couriered notices shall be deemed delivered on the date that the courier warrants
			that delivery will occur. Faxed, emailed, or telecommunicated notices shall be deemed delivered when receipt is
			either confirmed electronically or acknowledged by the addressee or its office. Personal delivery shall be
			effective when accomplished. Unless a Party changes its address by giving notice as provided herein, notices
			shall be delivered to the Parties at addresses and numbers set forth on the signature page hereof.
		</p>
		<p class="indent">
			(c) Force Majeure. In no event shall Provider be liable to User, or be deemed to have breached this Agreement,
			for any failure or delay in performing its obligations under this Agreement, if and to the extent such failure
			or delay is caused by any circumstances beyond Provider’s reasonable control, including but not limited to acts
			of God, flood, fire, earthquake, explosion, war, terrorism, invasion, pandemic, riot or other civil unrest,
			strikes, labor stoppages or slowdowns or other industrial disturbances, or passage of law or any action taken by
			a governmental or public authority, including imposing an embargo.
		</p>
		<p class="indent">
			(d) Amendment and Modification; Waiver. No amendment to or modification of this Agreement is effective unless it
			is in writing and signed by an authorized representative of each Party. No waiver by any Party of any of the
			provisions hereof will be effective unless explicitly set forth in writing and signed by the Party so waiving.
			Except as otherwise set forth in this Agreement, (i) no failure to exercise, or delay in exercising, any rights,
			remedy, power, or privilege arising from this Agreement will operate or be construed as a waiver thereof, and
			(ii) no single or partial exercise of any right, remedy, power, or privilege hereunder will preclude any other
			or further exercise thereof or the exercise of any other right, remedy, power, or privilege.
		</p>
		<p class="indent">
			(e) Severability. If any provision of this Agreement, or the application thereof to any person or circumstance,
			shall be held by a court of competent jurisdiction to be invalid, unenforceable, or void, the remainder of this
			Agreement and such provisions as applied to other persons and circumstances shall remain in full force and
			effect.
		</p>
		<p class="indent">
			(f) Governing Law; Jurisdiction. This Agreement shall be governed by and construed in accordance with the laws
			of the State of Washington, without regard to its choice of law principles. The parties hereby agree that any
			suit, action or proceeding seeking to enforce any provision of, or based on any matter arising out of or in
			connection with, this Agreement or the transactions contemplated hereby, whether in contract, tort or otherwise,
			shall be brought in a United States District Court in the State of Washington, so long as one of such courts
			shall have subject-matter jurisdiction over such suit, action or proceeding, and that any case of action arising
			out of this Agreement shall be deemed to have arisen from a transaction of business in the State of Washington.
			Each of the parties hereby irrevocably consents to the jurisdiction of such courts (and of the appropriate
			appellate courts therefrom) in any such suit, action or proceeding and irrevocably waives, to the fullest extent
			permitted by law, any objection that it may now or hereafter have to the laying of the venue of any such suit,
			action or proceeding in any such court or that any such suit, action or proceeding that is brought in any such
			court has been brought in an inconvenient forum.
		</p>
		<p class="indent">
			(g) Assignment. User may not assign any of its rights or delegate any of its obligations hereunder, in each case
			whether voluntarily, involuntarily, by operation of law or otherwise, without the prior written consent of
			Provider, which consent shall not be unreasonably withheld, conditioned, or delayed. Any purported assignment or
			delegation in violation of this Section will be null and void. No assignment or delegation will relieve the
			assigning or delegating Party of any of its obligations hereunder. This Agreement is binding upon and inures to
			the benefit of the Parties and their respective permitted successors and assigns.
		</p>
		<p class="indent">
			(h) Equitable Relief. Each Party acknowledges and agrees that a breach or threatened breach by such Party of any
			of its obligations under this Agreement would cause the other Party irreparable harm for which monetary damages
			would not be an adequate remedy and agrees that, in the event of such breach or threatened breach, the other
			Party will be entitled to equitable relief, including a restraining order, an injunction, specific performance,
			and any other relief that may be available from any court, without any requirement to post a bond or other
			security, or to prove actual damages or that monetary damages are not an adequate remedy. Such remedies are not
			exclusive and are in addition to all other remedies that may be available at law, in equity, or otherwise.
		</p>
		<p class="indent">
			(i) Counterparts. This Agreement may be executed in any number of counterparts, all of which when taken together
			shall constitute one agreement binding on all Parties, notwithstanding that all Parties are not signatories to
			the same counterpart. Counterparts may be delivered via facsimile, electronic mail (including PDF or any
			electronic signature complying with the U.S. federal ESIGN Act of 2000) or other transmission method and any
			counterpart so delivered shall be deemed an original and shall be binding upon each of the undersigned as if
			signed and delivered in the original.
		</p>
	<div>
`;
