import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation } from 'react-query';

type Request = api.CreateOrderRequest;
type Response = api.CreateOrderResponse;

export const useCreateOrder = () => {
    const { path } = config.orders.create();

    const createOrder = useMutation(
        (body: Request) =>
            Api.post<Response>({
                path,
                body: {
                    id: body.licenseId,
                },
            }),
        {
            onSuccess: ({ url }) => {
                // Navigate to Stripe checkout url on successful order creation.
                window.location.assign(url);
            },
        }
    );

    return {
        createOrder: createOrder.mutateAsync,
        isOrderLoading: createOrder.isLoading,
    };
};
