import React, { useState } from 'react';
import { Button, FormHelperText, makeStyles, TextField, Typography } from '@material-ui/core';
import { MessageBox } from 'common/components/UI/MessageBox';
import Message from 'common/models/Message';
import CommonUtils from 'common/utils/CommonUtils';
import { GoIcon } from 'common/utils/Icons';
import type { Mode } from './Layout';

export interface ResetCredentials {
    username: string;
}

export interface ResetConfirmCredentials {
    username: string;
    password: string;
    recoveryCode: string;
}

interface Props {
    mode: Mode;
    onReset: (credentials: ResetCredentials) => void;
    onConfirm: (credentials: ResetConfirmCredentials) => void;
    onChangeMode: (mode: Mode) => void;
    isResetting?: boolean;
}

type Key = keyof ResetCredentials | keyof ResetConfirmCredentials;
const initialCredentials: ResetCredentials | ResetConfirmCredentials = { username: '', password: '', recoveryCode: '' };

export const RecoverPanel: React.FC<Props> = ({ mode, onReset, onConfirm, onChangeMode, isResetting = false }) => {
    const classes = useStyles();

    const [credentials, setCredentials] = useState(initialCredentials);
    const [focus, setFocus] = useState('');

    const handleChange = (key: Key) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(e => ({ ...e, [key]: event.target.value }));
    };

    const handleSetFocus = (key: Key) => () => setFocus(key);

    const handleSetBlur = (key: Key) => () => {
        if (key === focus) {
            setFocus('');
        }
    };

    const handleReset = () => onReset(credentials);
    const handleConfirm = () => onConfirm(credentials);

    return (
        <form>
            {mode === 'reset' && (
                <>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <MessageBox
                        message={
                            new Message({
                                title: 'Password Reset Required',
                                details:
                                    'We have upgraded our security protocols. We apologize for the inconvenience, but due to this higher security, you will need to reset your password. Please provide your email address below, to begin the short reset process.',
                                type: 'warning',
                            })
                        }
                        enabled={isResetting}
                    />
                    <TextField
                        id="reset-email"
                        name="reset-email"
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="email"
                        autoFocus
                        value={credentials.username}
                        onChange={handleChange('username')}
                        onFocus={handleSetFocus('username')}
                        onBlur={handleSetBlur('username')}
                    />
                    <FormHelperText
                        error={true}
                        hidden={CommonUtils.isEmailValid(credentials.username) || credentials.username.trim().length <= 0 || focus === 'username'}
                    >
                        Please enter a valid email.
                    </FormHelperText>
                    <Button
                        variant="contained"
                        aria-label="Send Recovery Email"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={!CommonUtils.isEmailValid(credentials.username)}
                        onClick={handleReset}
                    >
                        Reset Password
                        <GoIcon />
                    </Button>
                    <div className={classes.links}>
                        <Button size="small" onClick={() => onChangeMode('login')}>
                            Have an account? Sign In
                        </Button>
                        <Button size="small" onClick={() => onChangeMode('register')}>
                            Need an account? Sign Up
                        </Button>
                    </div>
                </>
            )}
            {mode === 'reset-confirm' && (
                <>
                    <MessageBox
                        message={
                            new Message({
                                title: 'Password Reset - Please Check Your Email',
                                details:
                                    'A message has been sent to your email with a verification code. Please enter this code below to reset your password.',
                                type: 'info',
                            })
                        }
                    />
                    <TextField
                        id="recovery-code"
                        name="recovery-code"
                        label="Code"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        autoComplete="off"
                        value={credentials.recoveryCode}
                        onChange={handleChange('recoveryCode')}
                        onFocus={handleSetFocus('recoveryCode')}
                        onBlur={handleSetBlur('recoveryCode')}
                    />
                    <TextField
                        id="recovery-password"
                        name="recovery-password"
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        type="password"
                        required
                        fullWidth
                        autoComplete="new-password"
                        value={credentials.password}
                        onChange={handleChange('password')}
                        onFocus={handleSetFocus('password')}
                        onBlur={handleSetBlur('password')}
                    />
                    <MessageBox
                        message={
                            new Message({
                                title: 'Password Requirements',
                                details:
                                    'At least 8 characters long with at least one lowercase letter, uppercase letter, special character (!, @, #, $, %, ^, &, or *), and a number.',
                                type: 'info',
                            })
                        }
                        enabled={!CommonUtils.isPasswordValid(credentials.password) && focus === 'password'}
                    />
                    <MessageBox
                        message={
                            new Message({
                                title: 'Invalid Password',
                                details:
                                    'At least 8 characters long with at least one lowercase letter, uppercase letter, special character (!, @, #, $, %, ^, &, or *), and a number.',
                                type: 'error',
                            })
                        }
                        enabled={!CommonUtils.isPasswordValid(credentials.password) && credentials.password.trim().length > 0 && focus !== 'password'}
                    />
                    <Button
                        variant="contained"
                        aria-label="Reset Password"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={credentials.recoveryCode.trim().length <= 0 || !CommonUtils.isPasswordValid(credentials.password)}
                        onClick={handleConfirm}
                    >
                        Reset Password
                        <GoIcon />
                    </Button>
                    <div className={classes.links}>
                        <Button size="small" onClick={() => onChangeMode('login')}>
                            Have an account? Sign In
                        </Button>
                        <Button size="small" onClick={() => onChangeMode('register')}>
                            Need an account? Sign Up
                        </Button>
                    </div>
                </>
            )}
        </form>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
    },
    submit: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    links: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
