import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';
import { config } from '../config';

type Response = { canUseCuts: boolean; isDoctor: boolean; userDisplayName: string };

export function useCanUseCuts(registeredUser?: boolean) {
    const { key, path } = config.mobile.canUseCuts();

    const result = useQuery(
        key,
        () => {
            return Api.get<Response>({ path });
        },
        {
            enabled: registeredUser,
        }
    );

    return {
        canUseCuts: result.isError ? false : result.data?.canUseCuts ?? false,
        isDoctor: result.isError ? false : result.data?.isDoctor ?? false,
        userDisplayName: result.isError ? '' : result.data?.userDisplayName ?? '',
        isLoading: result.isLoading,
    };
}
