import * as cognito from 'common/utils/cognito';
import CommonUtils from 'common/utils/CommonUtils';
import type { UserAttributes } from '../../common/services/userServices';
import { Address } from './Address';
import BasicDomain from './BasicDomain';
import File from './File';
import Procedure from './Procedure';

export default class Profile extends BasicDomain {
    // @ts-expect-error - BasicDomain is still JS and does not have correct types.
    get className() {
        return 'Profile' as const;
    }
    // @ts-expect-error - BasicDomain is still JS and does not have correct types.
    get myClass() {
        return Profile;
    }

    static fromCognitoAttributes(attributes: UserAttributes) {
        const profile = new Profile({
            id: attributes.sub,
            fname: cognito.getString(attributes.given_name),
            lname: cognito.getString(attributes.family_name),
            email: cognito.getString(attributes.email),
            phone: cognito.getString(attributes.phone_number),
            picture: {
                name: 'profile-picture',
                url: cognito.getString(attributes.picture),
            },
            position: cognito.getString(attributes['custom:position']),
            company: cognito.getString(attributes['custom:companyName']),
            companyLogo: {
                name: 'companyLogo',
                url: cognito.getString(attributes['custom:companyLogo']),
            },
            notifyProcStart: cognito.getBool(attributes['custom:notifyProcStart'], true),
            facilityName: cognito.getString(attributes['custom:facilityName']),
            facilityCity: cognito.getString(attributes['custom:facilityCity']),
            facilityState: cognito.getString(attributes['custom:facilityState']),
            ...Address.fromJsonString(attributes.address),
        });

        return profile;
    }

    id: string | null;
    fname: string;
    lname: string;
    email: string;
    phone: string;
    company: string;
    Credentials: any;
    CredentialsFile: File | '';
    companyLogo: File;
    position: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    picture: File;
    procedure: Procedure | null;
    acceptedTC: Date;
    acceptedPP: Date;
    createdAt: Date | null;
    updatedAt: Date | null;
    notifyProcStart: boolean;
    facilityName: string;
    facilityCity: string;
    facilityState: string;
    user: any | null;

    // @ts-expect-error - BasicDomain is still JS and does not have correct types.
    // this methods merely overrides the clone from BasicDomain until we do something about that, if ever.
    clone(): Profile {
        return new Profile(this);
    }

    constructor(
        props: {
            id?: string | null;
            objectId?: string | null;
            fname?: string;
            lname?: string;
            email?: string;
            phone?: string;
            company?: string;
            Credentials?: any;
            companyLogo?: any;
            position?: string;
            address1?: string;
            address2?: string;
            city?: string;
            state?: string;
            postalCode?: string;
            country?: string;
            picture?: any;
            procedure?: any;
            acceptedTC?: Date;
            acceptedPP?: Date;
            createdAt?: Date | null;
            updatedAt?: Date | null;
            notifyProcStart?: boolean;
            facilityName?: string;
            facilityCity?: string;
            facilityState?: string;
            user?: any;
        } = {}
    ) {
        super(props);
        this.id = props.id || props.objectId || null;
        this.fname = props.fname || '';
        this.lname = props.lname || '';
        this.email = props.email || '';
        this.phone = props.phone || '';
        this.company = props.company || '';
        this.Credentials = props.Credentials || null;
        this.CredentialsFile = this.Credentials !== null ? new File(this.Credentials?.file) : '';
        // this.Credentials.file = this.Credentials !== null ? new File(this.Credentials?.file) : null;
        this.companyLogo = new File(props.companyLogo);
        this.position = props.position || '';
        this.address1 = props.address1 || '';
        this.address2 = props.address2 || '';
        this.city = props.city || '';
        this.state = props.state || '';
        this.postalCode = props.postalCode || '';
        this.country = props.country || 'United States of America';
        this.picture = new File(props.picture);
        this.procedure = props.procedure != null ? new Procedure(props.procedure) : null;
        this.acceptedTC = props.acceptedTC || new Date();
        this.acceptedPP = props.acceptedPP || new Date();
        this.createdAt = props.createdAt || null;
        this.updatedAt = props.updatedAt || null;
        this.notifyProcStart = props.notifyProcStart === undefined ? true : !!props.notifyProcStart;
        this.facilityName = props.facilityName || '';
        this.facilityCity = props.facilityCity || '';
        this.facilityState = props.facilityState || '';

        if (props.user != null) {
            this.user = props.user || props.user.id || props.user.objectId || null;
        }
        // if (props.Credentials != null) {
        //     this.Credentials = props.Credentials;
        //     this.credentialsFile = new File(props.Credentials.file);
        // }
    }

    set newPhone(value: string | null | undefined) {
        this.phone = (value ?? '').replace(/\s+/g, '');
    }

    toCognitoAttributes() {
        const attributes = {
            given_name: cognito.putString(this.fname),
            family_name: cognito.putString(this.lname),
            phone_number: cognito.putString(this.phone),
            picture: cognito.putString(this.picture.url),
            address: new Address({
                address1: this.address1,
                address2: this.address2,
                city: this.city,
                state: this.state,
                postalCode: this.postalCode,
                country: this.country,
            }).toJsonString(),
            'custom:position': cognito.putString(this.position),
            'custom:companyName': cognito.putString(this.company),
            'custom:companyLogo': cognito.putString(this.companyLogo.url),
            'custom:notifyProcStart': cognito.putBool(this.notifyProcStart, true),
            'custom:facilityName': cognito.putString(this.facilityName),
            'custom:facilityCity': cognito.putString(this.facilityCity),
            'custom:facilityState': cognito.putString(this.facilityState),
        };

        return attributes;
    }

    isEmailValid = () => CommonUtils.isEmailValid(this.email);

    isPhoneValid = () => CommonUtils.isPhoneValid(this.phone) && this.phone.trim() !== '';

    isSame = (target?: Profile) =>
        target != null &&
        target.id === this.id &&
        target.fname === this.fname &&
        target.lname === this.lname &&
        target.phone === this.phone &&
        target.company === this.company &&
        target.position === this.position &&
        target.address1 === this.address1 &&
        target.address2 === this.address2 &&
        target.city === this.city &&
        target.state === this.state &&
        target.postalCode === this.postalCode &&
        target.country === this.country &&
        target.facilityName === this.facilityName &&
        target.facilityCity === this.facilityCity &&
        target.facilityState === this.facilityState;

    isAccountSavable = () => this.fname.trim() !== '' && this.lname.trim() !== '';

    isProfileSavable = () => this.fname.trim() !== '' && this.lname.trim() !== '' && this.isPhoneValid() && this.company.trim() !== '';

    isDifferentOrIsNotificationChange = (target: Profile) => {
        return target.notifyProcStart !== this.notifyProcStart || !this.isSame(target);
    };
    // @ts-expect-error - BasicDomain is still JS and does not have correct types.
    getSaveToken = () => ({
        fname: this.fname,
        lname: this.lname,
        phone: this.phone,
        company: this.company,
        companyLogo: this.companyLogo.isEmpty() ? undefined : this.companyLogo.getAPIToken(),
        position: this.position,
        notifyProcStart: this.notifyProcStart,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        country: this.country,
        acceptedPP: this.acceptedPP,
        acceptedTC: this.acceptedTC,
        procedure: this.procedure != null ? this.procedure.pointer : null,
        picture: this.picture.isEmpty() ? undefined : this.picture.getAPIToken(),
    });

    getProcedureUpdateSaveToken = () => ({
        procedure: this.procedure != null ? this.procedure.pointer : null,
    });

    getLabel = () => `${this.fname} ${this.lname}`;

    getSubLabel = () => (
        <>
            {this.company} - {this.phone}
            <br />
            {this.user && this.user.username ? this.user.username : ''}
        </>
    );
}
