import { config } from 'circulator/hooks/config';
import { logInUser } from 'common/services/userServices';
import { useMutation, useQueryClient } from 'react-query';

export const useUserLogin = () => {
    const queryClient = useQueryClient();

    const login = useMutation(logInUser, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(config.user.me());
        },
    });

    return {
        login: login.mutateAsync,
        isLoading: login.isLoading,
    };
};
