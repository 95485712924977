import { useEffect } from 'react';
import { useMutateCameraPosition } from 'client/hooks/camera/useMutateCameraPosition';
import { Stream } from 'common/types';

interface Props {
    stream: Stream;
    waitSeconds: number;
}

export const CirculatorPTZHome: React.FC<Props> = ({ stream, waitSeconds }) => {
    const { gotoHome } = useMutateCameraPosition();

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        console.log(`CirculatorPTZHome prep for ${stream.id} ${stream.name} ${stream.isPTZ}`);

        if (stream.isPTZ && stream.id) {
            timeout = setTimeout(() => {
                console.log(`sending PTZ home command for ${stream.id} ${stream.name}`);
                gotoHome.mutate(stream.id);
            }, waitSeconds * 1000);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
        // only run this once always
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
