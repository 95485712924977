import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Modal, Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { useGetUsersSharedWith } from 'client/hooks/videoExport/useGetUsersSharedWith';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { IVideoExportJob } from 'common/types';
import { formatVideoLength } from 'common/utils/helper';
import { CloseIcon } from 'common/utils/Icons';

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: 470,
        backgroundColor: 'white',
        boxShadow: '24',
        padding: 4,
    },
    flexGrow: { flexGrow: 1 },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999,
        color: 'white',
        opacity: 1,
    },
    link: {
        color: '#508bc5',
        textDecoration: 'none',
        fontColor: '#fff',
        cursor: 'pointer',
        fontSize: '15px',
        textTransform: 'lowercase',
    },
    iconCopy: {
        color: '#2b333f',
    },
});

interface ShareModalProps {
    isShare: boolean;
    clip?: IVideoExportJob;
    onClose: () => void;
    onSend: () => void;
}

const getBaseURL = (clip: IVideoExportJob | undefined) => {
    if (process.env.NODE_ENV === 'development') return `http://localhost:3000/media/${clip?.jobId}`;
    const url = `${clip?.host ?? 'dev.v2.vsurgic.com'}/media/${clip?.jobId}`;
    return url.startsWith('http') ? url : `https://${url}`;
};

export const ClipShareModal = ({ isShare, clip, onClose, onSend }: ShareModalProps) => {
    const classes = useStyles();
    const linkURL = getBaseURL(clip);
    const { notify } = useNotifier();
    const { users } = useGetUsersSharedWith(clip?.procedureId ?? 0);

    if (clip === undefined) {
        onClose();
        return null;
    }

    const copyToClipboard = async () => {
        console.log('env', process.env.NODE_ENV);
        try {
            await navigator.clipboard.writeText(linkURL);
            notify(new Message({ title: 'Link Copied' }));
        } catch (err) {
            notify(new Message({ title: 'Failed to copy link' }));
        }
    };

    return (
        <Modal open={isShare} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className={classes.modalContainer}>
                <AppBar sx={{ backgroundColor: '#617c8b' }}>
                    <Toolbar className={classes.header}>
                        <Typography variant="h6" component="div" className={classes.flexGrow}>
                            Share Clip
                        </Typography>
                        <IconButton aria-label="close" onClick={onClose} className={classes.closeIcon}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Box sx={{ padding: 3 }} display="flex" gap="10px">
                    <Box display="flex" flexDirection="column" gap="10px">
                        <Box>
                            <Typography variant="h5" component="div" fontSize={18} textTransform="capitalize" marginBottom="5px" color="#333333">
                                Title
                            </Typography>
                            <Typography variant="body1" component="div" fontSize={15} textTransform="capitalize">
                                {clip?.title}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" component="div" fontSize={18} textTransform="capitalize" marginBottom="5px" color="#333333">
                                Video Length
                            </Typography>
                            <Typography variant="body1" component="div" fontSize={15}>
                                <Box>{formatVideoLength(clip?.videoLength)}</Box>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" component="div" fontSize={18} textTransform="capitalize" marginBottom="5px" color="#333333">
                                Link
                            </Typography>
                            <Box display="flex" justifyContent="space-around">
                                <a
                                    aria-label={clip?.title}
                                    href={linkURL}
                                    rel="noopener noreferrer external"
                                    target="_blank"
                                    className={classes.link}
                                >
                                    {linkURL}
                                </a>
                                <Box>
                                    <IconButton onClick={copyToClipboard}>
                                        <FileCopyIcon className={classes.iconCopy} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="flex-1">
                        {users && users.length > 0 ? (
                            <Box sx={{ width: '200px' }}>
                                <Typography variant="h5" component="div" fontSize={18} textTransform="capitalize" marginBottom="5px" color="#333333">
                                    Shared With Users
                                </Typography>
                                <Box display="flex" flexDirection="column" gap="3" maxWidth="400px" overflow="auto">
                                    {users.map((user: any) => (
                                        <Typography key={user.name}>{user.name}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Typography>No User Shared</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
