import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';

type Response = api.GetChatsResponse[];

export const getChatList = async (procedureId: number) => {
    const { path } = config.chats.list(procedureId);
    return await Api.get<Response>({ path });
};
