import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import * as file from 'common/hooks/file';
import Cropper from 'react-easy-crop';
import type { CropperProps } from 'react-easy-crop';
import type { Area, Point } from 'react-easy-crop/types';

// @ts-ignore
const styles = theme => ({
    container: {
        position: 'relative',
        height: 300,
        width: '100%',
        background: '#333',
    },
    btn: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
});
// @ts-ignore
const useStyles = makeStyles(styles);

interface Props extends Partial<Pick<CropperProps, 'aspect' | 'minZoom' | 'maxZoom' | 'cropShape' | 'cropSize' | 'objectFit' | 'showGrid'>> {
    original: Blob;
    onUpload: (image: Blob) => void;
    onReset: () => void;
}

export default function ImageEditor({ original, onUpload, onReset, ...props }: Props) {
    const classes = useStyles();

    const [croppedArea, setCroppedArea] = React.useState<Area>();
    const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);

    const originalImage = file.useReadAsDataUrl(original);

    function handleCropComplete(_: Area, croppedAreaPixels: Area) {
        setCroppedArea(croppedAreaPixels);
    }

    async function handleUpload() {
        const cropped = await cropImage(originalImage, croppedArea, original.type);
        onUpload(cropped ?? original);
    }

    return (
        <>
            <div className={classes.container}>
                <Cropper
                    {...props}
                    image={originalImage}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onCropComplete={handleCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <Button variant="contained" color="primary" className={classes.btn} onClick={handleUpload}>
                Upload
            </Button>
            <Button variant="contained" className={classes.btn} onClick={onReset}>
                Reset
            </Button>
        </>
    );
}

async function createImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = src;
        image.onload = () => resolve(image);
        image.onerror = error => reject(error);
    });
}

async function cropImage(original: string | undefined, crop: Area | undefined, type: string): Promise<Blob | null> {
    if (!original || !crop) return null;

    const image = await createImage(original);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return null;

    canvas.width = image.width;
    canvas.height = image.height;

    context.drawImage(image, 0, 0);

    const data = context.getImageData(crop.x, crop.y, crop.width, crop.height);

    canvas.width = crop.width;
    canvas.height = crop.height;

    context.putImageData(data, 0, 0);

    return toBlob(canvas, type);
}

async function toBlob(canvas: HTMLCanvasElement, type: string): Promise<Blob | null> {
    return new Promise(resolve => {
        canvas.toBlob(resolve, type);
    });
}
