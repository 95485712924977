import { Typography } from '@material-ui/core';
import { CreateProcedureButton } from 'circulator/components/CreateProcedureButton/CreateProcedureButton';
import Panel from 'common/components/UI/Panel';

export const NoProcedureFoundPanel = () => (
    <Panel>
        <Typography variant="h2">Circulator Home</Typography>
        <Typography paragraph>Currently there is no procedure active in this room.</Typography>
        <CreateProcedureButton />
    </Panel>
);
