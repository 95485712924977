export interface DynamicAVDevice {
    groupId: string;
    videoId?: string;
    audioId?: string;
    label: string;
}

const getDevicePermissions = async () => {
    try {
        console.log('getting permission for audio + video devices');

        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        return;
    } catch (error) {
        console.error(error);
    }

    try {
        console.log('getting permission for audio devices');

        await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
        console.error(error);
    }

    try {
        console.log('getting permission for video devices');

        await navigator.mediaDevices.getUserMedia({ video: true });
    } catch (error) {
        console.error(error);
    }
};

export const getDynamicAVDevices = async (): Promise<DynamicAVDevice[]> => {
    try {
        await getDevicePermissions();

        const allDevices = await navigator.mediaDevices.enumerateDevices();

        console.log(`found ${allDevices.length} total devices`, allDevices);

        // combine into unique devices
        const uniqueDevices = new Map<string, MediaDeviceInfo[]>();

        allDevices
            // ignore browser/os default device, since it will also be listed in the non-default devices
            .filter(device => device.deviceId !== 'default')
            .forEach(device => {
                if (uniqueDevices.has(device.groupId)) {
                    uniqueDevices.get(device.groupId)!.push(device);
                } else {
                    uniqueDevices.set(device.groupId, [device]);
                }
            });

        console.log(`found ${uniqueDevices.size} unique devices`, uniqueDevices);

        uniqueDevices.forEach((devices, groupId) => {
            console.log(`  name ${devices[0].label}`);
            console.log(`    groupId ${groupId}`);
            console.log(`    has audio ${devices.some(device => device.kind === 'audioinput')}`);
            console.log(`    has video ${devices.some(device => device.kind === 'videoinput')}`);
        });

        // map to DynamicAVDevice
        const results: DynamicAVDevice[] = Array.from(uniqueDevices)
            .sort(([_, deviceInfoA], [__, deviceInfoB]) => deviceInfoA[0].label.localeCompare(deviceInfoB[0].label))
            .map(([groupId, devices]) => {
                return {
                    groupId: groupId,
                    videoId: devices.find(device => device.kind === 'videoinput')?.deviceId,
                    audioId: devices.find(device => device.kind === 'audioinput')?.deviceId,
                    //Just get the first word of the label; including underscores, commas, dashes, etc.
                    label: (devices[0].label.match(/^\s*([^\s]+)/gi)?.[0] ?? devices[0].label).trim(),
                };
            })
            // remove any devices that have no audio and no video
            .filter(device => device.videoId !== undefined || device.audioId !== undefined)
            .map((device, idx, arr) => {
                // if there are multiple devices (that are of the same type) with the same label, append a number to the label.
                // this is not especially likely, but possible, and because we are now taking only the first word, we may have stripped off any identifier that the browser had placed on to the label.
                // sort by label before hand, to hopefully keep them in the same order that the browser would have labeled them.
                if (arr.some(d => d.label === device.label && d !== device && !!device.videoId === !!d.videoId)) {
                    device.label = `${device.label} (${
                        arr.filter((d, didx) => didx < idx && d.label === device.label && d !== device && !!device.videoId === !!d.videoId).length + 1
                    })`;
                }
                return device;
            });

        console.log(`found ${results.length} mapped devices`, results);

        return results;
    } catch (error) {
        console.error(error);
    }

    return [];
};
