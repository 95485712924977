import { config } from 'circulator/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { isNotNullOrUndefined } from 'common/utils/ObjectUtils';
import { useQuery } from 'react-query';

type Response = api.GetUserResponse;

export function useGetUser(id: string) {
    const { key, path } = config.user.detail(id);

    const result = useQuery(key, () => Api.get<Response>({ path }), {
        enabled: isNotNullOrUndefined(id),
        refetchOnReconnect: true,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        suspense: true,
    });

    return {
        user: result.data,
    };
}
