import React, { useState } from 'react';
import { Button, Checkbox, CircularProgress, FormHelperText, makeStyles, TextField, Typography, Box, Card, CardContent } from '@material-ui/core';
import { PrivacyPolicyDialog } from 'client/components/PrivacyPolicyDialog';
import TCDialog from 'client/components/TCDialog';
import { MessageBox } from 'common/components/UI/MessageBox';
import Message from 'common/models/Message';
import CommonUtils from 'common/utils/CommonUtils';
import { GoIcon } from 'common/utils/Icons';
import type { Mode } from './Layout';

export interface RegisterCredentials {
    username: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    acceptedTerms: boolean;
    doctorAccount: boolean;
    facilityName?: string;
    facilityCity?: string;
    facilityState?: string;
}

export interface RegisterConfirmCredentials {
    username: string;
    emailConfirmCode: string;
}

interface Props {
    mode: Mode;
    onRegister: (credentials: RegisterCredentials) => void;
    onConfirm: (credentials: RegisterConfirmCredentials) => void;
    onChangeMode: (mode: Mode) => void;
    isConfirmRegistration: boolean;
}

type Key = keyof RegisterCredentials | keyof RegisterConfirmCredentials;
const initialCredentials: RegisterCredentials & RegisterConfirmCredentials = {
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    acceptedTerms: false,
    emailConfirmCode: '',
    doctorAccount: false,
};

export const RegistrationPanel: React.FC<Props> = ({ mode, onRegister, onConfirm, onChangeMode, isConfirmRegistration }) => {
    const classes = useStyles();

    const [credentials, setCredentials] = useState(initialCredentials);
    const [focus, setFocus] = useState('');

    const passwordsMatch = credentials.password === credentials.confirmPassword;
    const registrationValid = () => {
        const isValid =
            CommonUtils.isEmailValid(credentials.username) &&
            CommonUtils.isPasswordValid(credentials.password) &&
            passwordsMatch &&
            credentials.acceptedTerms;

        if (credentials.doctorAccount) {
            return (
                isValid &&
                credentials.facilityName !== undefined &&
                credentials.facilityCity !== undefined &&
                credentials.facilityState !== undefined &&
                credentials.facilityName !== '' &&
                credentials.facilityCity !== '' &&
                credentials.facilityState !== ''
            );
        }

        return isValid;
    };

    const handleChange = (key: Key) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(e => ({ ...e, [key]: event.target.value }));
    };

    const handleToggle = (key: Key) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(e => ({ ...e, [key]: event.target.checked }));
    };

    const handleRegister = () => {
        if (registrationValid()) {
            onRegister(credentials);
        }
    };

    const handleConfirm = () => onConfirm(credentials);

    const handleSetFocus = (key: Key) => () => setFocus(key);

    const handleSetBlur = (key: Key) => () => {
        if (key === focus) {
            setFocus('');
        }
    };

    return (
        <form>
            {mode === 'register' && (
                <>
                    <Typography component="h1" variant="h5">
                        Create Account
                    </Typography>
                    <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="given-name"
                        value={credentials.firstName}
                        onChange={handleChange('firstName')}
                        onFocus={handleSetFocus('firstName')}
                        onBlur={handleSetBlur('firstName')}
                    />
                    <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="family-name"
                        value={credentials.lastName}
                        onChange={handleChange('lastName')}
                        onFocus={handleSetFocus('lastName')}
                        onBlur={handleSetBlur('lastName')}
                    />
                    <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="email"
                        value={credentials.username}
                        onChange={handleChange('username')}
                        onFocus={handleSetFocus('username')}
                        onBlur={handleSetBlur('username')}
                    />
                    <FormHelperText
                        error={true}
                        hidden={CommonUtils.isEmailValid(credentials.username) || credentials.username.trim().length <= 0 || focus === 'username'}
                    >
                        Please enter a valid email.
                    </FormHelperText>
                    <FormHelperText>
                        <Typography color="primary" variant="caption">
                            Note: We suggest using your professional or university email address when creating your account. This will allow us to
                            more easily validate your identity on the platform. Thank you.
                        </Typography>
                    </FormHelperText>
                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="new-password"
                        type="password"
                        inputProps={{
                            passwordrules: 'required: lower; required: upper; required: digit; minlength: 21;',
                        }}
                        value={credentials.password}
                        onChange={handleChange('password')}
                        onFocus={handleSetFocus('password')}
                        onBlur={handleSetBlur('password')}
                    />
                    <MessageBox
                        message={
                            new Message({
                                title: 'Password Requirements',
                                details:
                                    'At least 8 characters long with at least one lowercase letter, uppercase letter, special character (!, @, #, $, %, ^, &, or *), and a number.',
                                type: 'info',
                            })
                        }
                        enabled={!CommonUtils.isPasswordValid(credentials.password) && focus === 'password'}
                    />
                    <MessageBox
                        message={
                            new Message({
                                title: 'Invalid Password',
                                details:
                                    'At least 8 characters long with at least one lowercase letter, uppercase letter, special character (!, @, #, $, %, ^, &, or *), and a number.',
                                type: 'error',
                            })
                        }
                        enabled={!CommonUtils.isPasswordValid(credentials.password) && credentials.password.trim().length > 0 && focus !== 'password'}
                    />
                    <TextField
                        id="confirm-password"
                        name="confirm-password"
                        label="Password (confirm)"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="new-password"
                        type="password"
                        inputProps={{
                            passwordrules: 'required: lower; required: upper; required: digit; minlength: 21;',
                        }}
                        value={credentials.confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        onFocus={handleSetFocus('confirmPassword')}
                        onBlur={handleSetBlur('confirmPassword')}
                    />
                    <FormHelperText error={true} hidden={passwordsMatch || focus === 'password' || focus === 'confirmPassword'}>
                        The passwords do not match.
                    </FormHelperText>

                    <Typography variant="body2">
                        <Checkbox color="primary" checked={credentials.doctorAccount} onChange={handleToggle('doctorAccount')} />
                        I'm a doctor
                    </Typography>

                    {credentials.doctorAccount && (
                        <>
                            <Card>
                                <CardContent>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        Please fill in the name and location of at least one of the facilities where you have privileges. If there are
                                        multiple locations that you would like to associate with your account, please email us at{' '}
                                        <a href="mailto: CS@vsurgic.com">CS@vsurgic.com</a> with that information or add it to your account profile
                                        after logging in. Thank you.
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    maxWidth: '100%',
                                }}
                            >
                                <Box sx={{ flex: 1, margin: '0 5px' }}>
                                    <TextField
                                        id="facilityName"
                                        name="facilityName"
                                        label="Facility Name"
                                        variant="outlined"
                                        margin="normal"
                                        required={credentials.doctorAccount}
                                        multiline
                                        fullWidth
                                        autoComplete="given-name"
                                        value={credentials.facilityName}
                                        onChange={handleChange('facilityName')}
                                        onFocus={handleSetFocus('facilityName')}
                                        onBlur={handleSetBlur('facilityName')}
                                    />
                                </Box>

                                <Box sx={{ flex: 1, margin: '0 5px' }}>
                                    <TextField
                                        id="facilityCity"
                                        name="facilityCity"
                                        label="Facility City"
                                        variant="outlined"
                                        margin="normal"
                                        multiline
                                        fullWidth
                                        required={credentials.doctorAccount}
                                        autoComplete="given-name"
                                        value={credentials.facilityCity}
                                        onChange={handleChange('facilityCity')}
                                        onFocus={handleSetFocus('facilityCity')}
                                        onBlur={handleSetBlur('facilityCity')}
                                    />
                                </Box>

                                <Box sx={{ flex: 1, margin: '0 5px' }}>
                                    <TextField
                                        id="facilityState"
                                        name="facilityState"
                                        label="Facility State"
                                        variant="outlined"
                                        margin="normal"
                                        multiline
                                        fullWidth
                                        required={credentials.doctorAccount}
                                        autoComplete="given-name"
                                        value={credentials.facilityState}
                                        onChange={handleChange('facilityState')}
                                        onFocus={handleSetFocus('facilityState')}
                                        onBlur={handleSetBlur('facilityState')}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                    <Typography variant="body2">
                        <Checkbox color="primary" checked={credentials.acceptedTerms} onChange={handleToggle('acceptedTerms')} />
                        {'I have read & accept the '}
                        <PrivacyPolicyDialog />
                        {' and '}
                        <TCDialog />
                    </Typography>
                    <Button
                        variant="contained"
                        aria-label="Login"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={!registrationValid()}
                        onClick={handleRegister}
                        endIcon={<GoIcon />}
                    >
                        Register
                    </Button>
                    <div className={classes.links}>
                        <Button size="small" onClick={() => onChangeMode('register-confirm')}>
                            Confirm Account
                        </Button>
                        <Button size="small" onClick={() => onChangeMode('login')}>
                            Already have an account? Sign In
                        </Button>
                    </div>
                </>
            )}
            {mode === 'register-confirm' && (
                <>
                    <MessageBox
                        message={
                            new Message({
                                title: 'Registration Complete - Account Verification Needed',
                                details:
                                    'A message has been sent to your email to verify the address. Please enter the code from this email to finalize your account setup. You will not be able to log in until you have verified your email address.',
                                type: 'info',
                            })
                        }
                    />
                    <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="email"
                        value={credentials.username}
                        onChange={handleChange('username')}
                        onFocus={handleSetFocus('username')}
                        onBlur={handleSetBlur('username')}
                    />
                    <FormHelperText
                        error={true}
                        hidden={CommonUtils.isEmailValid(credentials.username) || credentials.username.trim().length <= 0 || focus === 'username'}
                    >
                        Please enter a valid email.
                    </FormHelperText>
                    <TextField
                        id="email-confirm-code"
                        name="email-confirm-code"
                        label="Code"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        value={credentials.emailConfirmCode}
                        onChange={handleChange('emailConfirmCode')}
                        onFocus={handleSetFocus('emailConfirmCode')}
                        onBlur={handleSetBlur('emailConfirmCode')}
                    />
                    <Button
                        variant="contained"
                        aria-label="Confirm"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        disabled={
                            !CommonUtils.isEmailValid(credentials.username) ||
                            credentials.emailConfirmCode.trim().length <= 0 ||
                            isConfirmRegistration
                        }
                        onClick={handleConfirm}
                    >
                        Confirm
                        <GoIcon />
                        {isConfirmRegistration && (
                            <>
                                &nbsp;&nbsp; <CircularProgress size={15} thickness={8} color="primary" />
                            </>
                        )}
                    </Button>
                    <Button size="small" onClick={() => onChangeMode('login')}>
                        Already have an account? Sign In
                    </Button>
                </>
            )}
        </form>
    );
};

const useStyles = makeStyles(theme => ({
    submit: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        transition: 'color .01s',
    },
    links: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
