const TOKEN_KEY = '9nCAhCODjVF4MlPgjuhGDydpAb1yZU8r';

/**
 * Utility used to store token in session storage so we can recover the session between browser refreshes.
 */
export default class SessionUtils {
    static getSessionToken = () => sessionStorage.getItem(TOKEN_KEY);

    static setSessionToken = sessionToken => {
        if (!sessionToken) {
            // eslint-disable-next-line no-console
            console.warn('Attempting to set empty session token.');
            return;
        }

        sessionStorage.setItem(TOKEN_KEY, sessionToken);
    };

    static clearSessionToken = () => {
        // eslint-disable-next-line no-console

        sessionStorage.clear();
    };

    // this method is used by a device to validate a devie authentication response
    static doesDeviceAuthenticationResponseContainSessionToken = payload => {
        if (payload.result !== null && payload.result !== undefined && typeof payload.result === 'string' && payload.result.length > 0) {
            return true;
        }

        return false;
    };

    static setSessionTokenFromDeviceAuthenticationResponse = payload => {
        if (!SessionUtils.doesDeviceAuthenticationResponseContainSessionToken(payload)) {
            // eslint-disable-next-line no-console
            console.warn('Attempting to set session token from invalid device authentication response.');
            return;
        }

        SessionUtils.setSessionToken(payload.result);
    };
}
