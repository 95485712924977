/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore
import { useRef } from 'react';
import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.GetPaymentResponse;
type Request = api.GetPaymentRequest;

export function useGetPaymentLiveInfo(body: Request) {
    const countRef = useRef(0);
    const { key, path } = config.payments.detailsFromIntent(body.paymentIntent);

    const result = useQuery(
        [key, body],
        () => {
            countRef.current += 1;
            if (countRef.current >= 10) {
                throw new Error('Payment failed');
            }
            return Api.get<Response>({ path, body });
        },
        {
            refetchInterval: data => (data?.paymentStatus === 'PAID' ? false : 2000),
        }
    );

    return {
        payment: result.data ?? null,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
    };
}
