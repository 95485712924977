import { config } from 'circulator/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useMutation, useQueryClient } from 'react-query';

type Request = api.UpdateAttendeeRequest;
type Response = api.UpdateAttendeeResponse;

export function useUpdateAttendee(procedureId: number, attendeeId: number) {
    const { path } = config.attendee.update(procedureId, attendeeId);
    const queryClient = useQueryClient();

    const update = useMutation((body: Request) => Api.patch<Response>({ path, body }), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(config.attendee.all());
        },
    });

    return {
        updateAttendee: update.mutateAsync,
    };
}
