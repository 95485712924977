import React, { useState } from 'react';
import { Button, Box, CircularProgress, FormControl, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { useGetAcceptance } from 'client/hooks/acceptance/useGetAcceptance';
import { useSignAgreementPdf } from 'client/hooks/agreement/useSignPdfAgreement';
import { ProcessingOverlay } from 'common/components/UI/ProcessingOverlay';
import { useGetPublicNetwork } from 'common/hooks/network';
import { htmlAgreement } from 'common/utils/templates/agreement';
import parse from 'html-react-parser';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
        marginTop: theme.spacing(8),
        maxWidth: 1000,
        padding: theme.spacing(4),
    },
    paddingTop: {
        paddingTop: theme.spacing(4),
    },
    agreementBox: {
        height: 200,
        overflow: 'auto',
        border: '1px solid #617c8b',
        borderRadius: 4,
        padding: 10,
    },
    progress: {
        marginLeft: 7,
    },
}));

interface Props {
    children?: JSX.Element | JSX.Element[];
}

export function RequireAgreement({ children }: Props) {
    const classes = useStyles();

    const [isProgress, setProgress] = useState(false);

    const { acceptance, isLoading: acceptanceIsLoading } = useGetAcceptance();
    const { generatePdf } = useSignAgreementPdf();

    const { network, isLoading: networkIsLoading } = useGetPublicNetwork();

    if (acceptanceIsLoading) {
        return <ProcessingOverlay enabled />;
    }
    if (acceptance?.status === 'signed') {
        return <>{children ? children : <Outlet />}</>;
    }
    if (networkIsLoading) {
        return <ProcessingOverlay enabled />;
    }
    const signAgreement = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setProgress(true);
        const data = new FormData(event.currentTarget);
        const obj = Object.fromEntries(data);
        const pdfInfo = {
            name: obj.name.toString(),
            ip: network.ip.toString(),
        };
        await generatePdf(pdfInfo);
    };

    return (
        <Paper className={classes.root}>
            <Typography align="center" variant="h4">
                Please sign the following agreement to continue.
            </Typography>
            <Grid className={classes.paddingTop} container justifyContent="center">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        width: '500px',
                    }}
                    component="form"
                    onSubmit={signAgreement}
                >
                    <FormControl variant="standard">
                        <div className={classes.agreementBox}>{parse(htmlAgreement)}</div>
                        <Box sx={{ m: 2 }}></Box>
                        <TextField id="name" name="name" label="Signature" variant="outlined" required={true} />
                        <Box sx={{ m: 2 }}></Box>
                        <Typography align="center">
                            By clicking accept and sign, I agree that this signature is the electronic representative of my personal signature for use
                            on this agreement.
                        </Typography>
                        <Box sx={{ m: 2 }}></Box>
                        <Button type="submit" variant="contained" color="primary" disabled={isProgress}>
                            Accept & Sign
                            <Box sx={{ m: 2 }}>{isProgress ? <CircularProgress size={'1.2rem'} color="inherit" /> : <></>}</Box>
                        </Button>
                    </FormControl>
                </Box>
            </Grid>
        </Paper>
    );
}
