import Attendee from './Attendee';
import BasicArray from './BasicArray';

export default class AttendeeArray extends BasicArray {
    get myClass() {
        return AttendeeArray;
    }

    get myItemClass() {
        return Attendee;
    }

    constructor(items = []) {
        super(items);
    }

    comparator = (a, b) => {
        if (a && b) {
            const nameA = `${a.profile.fname}${a.profile.lname}`;
            const nameB = `${b.profile.fname}${b.profile.lname}`;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
        }
        return 0;
    };
}
