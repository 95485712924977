import { useState } from 'react';
import { Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetShareableUsersList } from 'client/hooks/videoExport/useGetShareableUsers';
import { useMutateShare } from 'client/hooks/videoExport/useMutateShare';

export default function AutoCompleteBox({ procedureId }: { procedureId: number }) {
    const { data, isLoading } = useGetShareableUsersList(procedureId);
    const [value, setValue] = useState<{ name: string; username: string; category: string } | null>({ name: '', username: '', category: '' });
    const { mutate: shareVideo, isLoading: isSharing } = useMutateShare();
    return isLoading ? (
        <Skeleton />
    ) : (
        <div>
            <Autocomplete
                disablePortal
                onChange={(event, value: { name: string; username: string; category: string } | null) => setValue(value)}
                id="users-list"
                options={data.sort(
                    (a: { name: string; username: string; category: string }, b: { name: string; username: string; category: string }) =>
                        -b.category.localeCompare(a.category)
                )}
                groupBy={option => option.category}
                getOptionLabel={option => option.name}
                sx={{ width: '100%' }}
                renderInput={params => <TextField {...params} label="Users" />}
                key={data.length} // this will cause the component to re-render when the data changes
            />
            <Button
                style={{ position: 'relative', left: '56%', marginTop: '4%' }}
                onClick={() => {
                    shareVideo({ procedureId: procedureId, shareTo: value?.username, sharedType: value?.category.toLowerCase() });
                    setValue({ name: '', username: '', category: '' });
                }}
                variant="contained"
                color="primary"
                disabled={isSharing || !value?.name}
            >
                Share
            </Button>
        </div>
    );
}
