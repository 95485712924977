import { config } from 'client/hooks/config';
import type * as api from 'common/types/api';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

type Response = api.DoctorsListResponse;

export function useListDoctorsUserAssists() {
    const { key, path } = config.doctors.listDoctorsUserAssists();

    const result = useQuery([key], () => Api.get<Response>({ path }));

    return {
        doctors: result?.data?.doctors ?? [],
    };
}
