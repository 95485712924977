import { useCallback, useState, useEffect } from 'react';
import { Button, TextField, Modal, Box, AppBar, Toolbar, Typography, IconButton, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'App/theme';
import { useCreateVideoExportJob } from 'client/hooks/videoExport/useMutateCreateVideoExportRequest';
import { Player } from 'common/components/Player';
import { useNotifier } from 'common/hooks/useNotifier';
import Message from 'common/models/Message';
import { CloseIcon } from 'common/utils/Icons';
const MAX_NUMBER_OF_CHARACTERS_FIT_IN_SCREEN = 145;

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState<'landscape' | 'portrait'>(
        window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait'
    );
    const handler = useCallback(() => {
        setOrientation(window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait');
    }, []);
    useEffect(() => {
        window.screen.orientation.addEventListener('change', handler);
        return () => {
            window.screen.orientation.removeEventListener('change', handler);
        };
    }, [handler]);
    return orientation;
};

const useStyles = makeStyles({
    outlinedWhite: {
        '& .MuiFormLabel-root': {
            color: 'white !important',
        },
        '& > .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white !important',
            },
            color: 'white !important',
        },
    },
    modalWrapper: {},
    inputs: {
        '& .MuiInputBase-root': {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            '& input': {
                textAlign: 'right',
            },
        },
    },
    button: {
        width: '10px',
        height: '30px',
        cursor: 'pointer',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    modalContainer: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        height: 'auto',
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: '24',
        padding: 4,
    },
    detailsRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    videoRow: {
        height: 350,
        width: 600,
    },
    flexGrow: { flexGrow: 1 },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999,
        color: 'white',
        opacity: 1,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
        },
    },
});

const useMobileStyles = makeStyles({
    outlinedWhite: {
        '& .MuiFormLabel-root': {
            color: 'white !important',
        },
        '& > .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white !important',
            },
            color: 'white !important',
        },
    },
    inputs: {
        '& .MuiInputBase-root': {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    },
    button: {
        width: '10px',
        height: '30px',
        cursor: 'pointer',
    },
    header: {
        display: 'none',
    },
    modalContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vw',
        maxWidth: '100vw',
        height: '80vh',
        maxHeight: '80vh',
        backgroundColor: 'white',
        padding: 0,
        '& > div': {
            paddingLeft: '0px!important',
            paddingRight: '0px!important',
        },
    },
    detailsRow: {
        flex: '0 0 auto',
        gap: theme.spacing(1),
        '&:not(:first-child)': { paddingBottom: theme.spacing(1) },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    videoRow: {
        flex: '1 1 auto',
        width: '100%',
        minHeight: '50px',
    },
    flexGrow: { flexGrow: 1 },
    closeIcon: {
        position: 'absolute',
        top: 50,
        right: 15,
        zIndex: 99999,
        color: 'white',
        opacity: 1,
    },
    modalWrapper: {
        padding: 0,
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
    },
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(0),
        '& > *': {
            margin: theme.spacing(0),
        },
    },
});

export interface VideoPreviewModalProps {
    setStartTime: (startTime: number) => void;
    setEndTime: (endTime: number) => void;
    setCaptureTime: (captureTime: number) => void;
    setIsOpen: (isOpen: boolean) => void;
    setTempStartTime: (tempStartTime: string) => void;
    setTempEndTime: (tempEndTime: string) => void;
    handleOpenClose: () => void;
    setTitle: (title: string) => void;
    exporting?: boolean;
    isOpen: boolean;
    presignedUrl: string;
    tempStartTime: string;
    tempEndTime: string;
    startTime: number;
    endTime: number;
    captureTime: number;
    s3Key: string;
    s3Filename: string;
    procedureId?: number;
    doctorName?: string;
    companyName?: string;
    brandName?: string;
    procedureTitle?: string;
    deviceName?: string;
    patientInitials?: string;
    videoTitle?: string;
    timeZone?: string;
    isAudioOnly?: boolean;
    isMobile?: boolean;
}

const VideoPreviewModal: React.FC<VideoPreviewModalProps> = props => {
    const mobileClasses = useMobileStyles();
    const desktopClasses = useStyles();
    console.log(props.isMobile);
    const classes = props.isMobile ? mobileClasses : desktopClasses;
    const { notify } = useNotifier();
    const { createOverlay, isLoading: creatingJob } = useCreateVideoExportJob();

    const {
        setCaptureTime,
        setIsOpen,
        setTempStartTime,
        setTempEndTime,
        setStartTime,
        setEndTime,
        handleOpenClose,
        setTitle,
        isOpen,
        tempStartTime,
        tempEndTime,
        presignedUrl,
        startTime,
        endTime,
        captureTime,
        s3Key,
        s3Filename,
        procedureId,
        doctorName,
        companyName,
        brandName,
        procedureTitle,
        deviceName,
        patientInitials,
        exporting,
        videoTitle,
        timeZone,
        isAudioOnly,
    } = props;

    const handlePreviewJob = () => {
        setStartTime(formatTime(tempStartTime));
        setEndTime(formatTime(tempEndTime));
    };

    const formatTime = (time?: string) => {
        if (!time || time === '') return 0;
        let firstIndexLocation = time.indexOf(':');
        let secondIndexLocation = time.indexOf(':', firstIndexLocation + 1);
        let seconds = 0;
        let minutes = 0;
        let hours = 0;
        if (firstIndexLocation === -1) {
            return Number(time);
        }
        if (secondIndexLocation === -1 && firstIndexLocation !== -1) {
            seconds = Number(time.substring(firstIndexLocation + 1));
            minutes = Number(time.substring(0, firstIndexLocation));
        }
        if (secondIndexLocation !== -1) {
            seconds = Number(time.substring(secondIndexLocation + 1));
            minutes = Number(time.substring(firstIndexLocation + 1, secondIndexLocation));
            hours = Number(time.substring(0, firstIndexLocation));
        }
        return hours * 3600 + minutes * 60 + seconds;
    };

    function getTimeString(time: number, forJob: boolean = false): string | undefined {
        if (time >= 0) {
            let hours = Math.floor(time / 3600).toString();
            let minutes = Math.floor((time % 3600) / 60).toString();
            let seconds = Math.floor((time % 3600) % 60).toString();
            if (hours.toString().length === 1) hours = '0' + hours;
            if (minutes.toString().length === 1) minutes = '0' + minutes;
            if (seconds.toString().length === 1) seconds = '0' + seconds;
            if (!hours && !minutes && !seconds) return undefined;
            if (forJob) {
                return `${hours}:${minutes}:${seconds}:00`;
            }
            return `${hours}:${minutes}:${seconds}`;
        }
    }

    const createSpacing = (wordLength: number, maxLength: number) => {
        let spacingString = '';
        let numberOfSpaces = maxLength / 2 - wordLength / 2 - 1;
        for (let i = 1; i < numberOfSpaces; i++) {
            spacingString += ' ';
        }
        return spacingString;
    };
    const handleCreateJob = async () => {
        notify(new Message({ title: 'Export Creation Started' }));
        handlePreviewJob();
        const captionsString = `Procedure: ${procedureTitle || 'N/A'}\nCompany: ${companyName || 'N/A'}\nDevice: ${deviceName || 'N/A'}\nBrand: ${
            brandName || 'N/A'
        }\n${patientInitials}\n${createSpacing(doctorName?.length || 0, MAX_NUMBER_OF_CHARACTERS_FIT_IN_SCREEN)}${doctorName || ''}`;
        const results = await createOverlay({
            caption: captionsString,
            inputVideoLocation: {
                s3Filename: s3Filename,
                s3Key: s3Key,
            },
            procedureId: procedureId,
            startTime: getTimeString(formatTime(tempStartTime), true),
            endTime: getTimeString(formatTime(tempEndTime), true),
            title: videoTitle || 'N/A',
            timeZone: timeZone || 'UTC',
            lengthOfExport: getTimeString(formatTime(tempEndTime) - formatTime(tempStartTime)),
            patientInitials: patientInitials || 'N/A',
            company: companyName || 'N/A',
            device: deviceName || 'N/A',
            brand: brandName || 'N/A',
            isAudioOnly: isAudioOnly,
        });
        notify(new Message({ title: 'Export Created' }));
        setIsOpen(false);

        if (results.status && results.status !== 201) {
            notify(new Message({ title: 'Export Creation Failed' }));
        }
    };

    const orientation = useScreenOrientation();

    return (
        <Modal
            className={classes.modalWrapper}
            open={isOpen}
            onClose={handleOpenClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.modalContainer}>
                {props.isMobile ? (
                    <IconButton aria-label="close" onClick={handleOpenClose} className={classes.closeIcon}>
                        <CloseIcon />
                    </IconButton>
                ) : (
                    <AppBar>
                        <Toolbar className={classes.header}>
                            <Typography variant="h6" component="div" className={classes.flexGrow}>
                                Video Preview{videoTitle && ` - ${videoTitle}`}
                            </Typography>
                            <IconButton aria-label="close" onClick={handleOpenClose} className={classes.closeIcon}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                )}
                {props.isMobile ? null : <Toolbar />}
                <Container className={classes.container}>
                    <Player
                        id="videoPlayer"
                        src={presignedUrl}
                        loop={false}
                        controls
                        className={classes.videoRow}
                        playerStartTime={startTime}
                        playerEndTime={endTime}
                        setCaptureTime={setCaptureTime}
                    />
                    {exporting && (
                        <>
                            <Typography className={classes.detailsRow}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setTempStartTime(getTimeString(captureTime) || '');
                                    }}
                                    className={classes.button}
                                >
                                    Set
                                </Button>
                                <TextField
                                    className={classes.inputs}
                                    id={'outlined-basic'}
                                    name={'startTime'}
                                    label={'Start Time  (HH:MM:SS)'}
                                    variant={'outlined'}
                                    value={tempStartTime}
                                    onChange={e => setTempStartTime(e.target.value)}
                                />
                                {!props.isMobile || orientation === 'landscape' ? (
                                    <>
                                        <TextField
                                            className={classes.inputs}
                                            id={'outlined-basic'}
                                            name={'endTime'}
                                            label={'End Time  (HH:MM:SS)'}
                                            variant={'outlined'}
                                            value={tempEndTime}
                                            onChange={e => setTempEndTime(e.target.value)}
                                        />
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setTempEndTime(getTimeString(captureTime) || '');
                                            }}
                                            className={classes.button}
                                        >
                                            Set
                                        </Button>
                                        <TextField
                                            id="title-field"
                                            label="Title"
                                            variant="outlined"
                                            className={classes.inputs}
                                            onChange={e => setTitle(e.target.value)}
                                            value={videoTitle}
                                        />
                                    </>
                                ) : (
                                    ''
                                )}
                            </Typography>
                            {props.isMobile && orientation === 'portrait' ? (
                                <>
                                    <Typography className={classes.detailsRow}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setTempEndTime(getTimeString(captureTime) || '');
                                            }}
                                            className={classes.button}
                                        >
                                            Set
                                        </Button>
                                        <TextField
                                            className={classes.inputs}
                                            id={'outlined-basic'}
                                            name={'endTime'}
                                            label={'End Time  (HH:MM:SS)'}
                                            variant={'outlined'}
                                            value={tempEndTime}
                                            onChange={e => setTempEndTime(e.target.value)}
                                        />
                                    </Typography>
                                    <Typography className={classes.detailsRow}>
                                        <TextField
                                            id="title-field"
                                            label="Title"
                                            variant="outlined"
                                            className={classes.inputs}
                                            onChange={e => setTitle(e.target.value)}
                                            value={videoTitle}
                                        />
                                    </Typography>
                                </>
                            ) : (
                                ''
                            )}
                            <Typography className={classes.detailsRow} style={{ marginBottom: '10px' }}>
                                <Button disabled={creatingJob} variant="contained" color="secondary" onClick={handleCreateJob}>
                                    Create Export
                                </Button>
                                <Button variant="contained" color="primary" onClick={handlePreviewJob}>
                                    Preview Export
                                </Button>
                                {props.isMobile ? (
                                    <IconButton aria-label="close" onClick={handleOpenClose} className={classes.closeIcon}>
                                        <CloseIcon />
                                    </IconButton>
                                ) : (
                                    ''
                                )}
                            </Typography>
                        </>
                    )}
                </Container>
            </Box>
        </Modal>
    );
};

export default VideoPreviewModal;
