import { config } from 'client/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery /*, useQueryClient*/ } from 'react-query';

export const useGetShareableUsersList = (procedureId: number) => {
    const { key, path } = config.sharing.listOfAvailableToShare(procedureId);
    // const queryClient = useQueryClient();
    const results = useQuery(key, async () => await Api.get({ path }), {
        onSuccess() {
            // queryClient.invalidateQueries(key);
        },
        onError() {
            // queryClient.invalidateQueries(key);
        },
    });

    return { data: results.data, isLoading: results.isLoading };
};
