import React, { useEffect } from 'react';
import { Avatar, Box, Button, Chip, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ChevronLeft } from '@mui/icons-material';
import Panel from 'client/components/UI/Panel';
import { useGetFacilityById } from 'client/hooks/facilities/useGetFacilityById';
import EmptyStateHandler from 'common/components/UI/EmptyStateHandler';
import PurchaseOptionsDialog from 'common/components/UI/PurchaseOptionsDialog';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import { Facility, FacilityUsers } from 'common/types';
import { Doctor } from 'common/types/doctor';
import { FacilityIcon } from 'common/utils/Icons';
import { isNullOrUndefined } from 'common/utils/ObjectUtils';
import profilePlaceholder from 'img/profile-placeholder.png';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

// @ts-ignore
const styles = theme => ({
    root: {
        textAlign: 'left',
        color: theme.palette.primary.main,
        padding: theme.spacing(1.5),
    },
    facilityDoctorsBox: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },
    btnBack: {
        fontSize: '12px',
        color: '#fff',
    },
    boxPaper: {
        padding: '15px',
        borderRadius: '10px',
    },
    txtAddress: {
        marginTop: '10px',
    },
    txtDoctorTitle: {
        fontSize: '14px',
        marginBottom: '10px',
        fontWeight: '700',
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
    },
    boxDoctorAvatar: {
        width: '200px',
        height: '200px',
        background: '#bbb',
        borderRadius: '50%',
        border: '1px solid #bbb',
    },
    boxDoctorName: {
        color: '#333',
        fontWeight: '700',
        fontSize: '1.1rem',
        flex: '1',
    },
    boxDoctorScpecialty: {
        marginTop: '-20px',
    },
    boxDoctorChipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        '& .MuiChip-root': {
            background: '#cee2f3',
            padding: '7px 7px',
            color: '#618db3',
            fontSize: '12px',
        },
    },
    boxDoctorBio: {
        margin: '10px 0',
        maxHeight: '120px',
        overflow: 'hidden',
    },
    boxDoctorFullBio: {
        margin: '10px 0',
    },
    websiteLink: {
        color: '#ff5722',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '12px',
    },
    dpMenu: {
        '& ul': {
            width: '120px',
        },
    },
    txtCounter: {
        fontSize: '15px',
    },
    textSecondary: {
        color: '#ff5722',
        fontSize: '15px',
    },
});

// @ts-ignore
const useStyles = makeStyles(styles);

interface FacilityDoctorsItemProps {
    doctor: Doctor;
}

const FacilityDoctorsItem = ({ doctor }: FacilityDoctorsItemProps) => {
    const classes = useStyles();
    const doctorPhoto = useGetProtectedObject(doctor.photoUrl);
    const doctorAvatar = doctorPhoto ?? profilePlaceholder;
    return (
        <Box sx={{ display: 'flex', padding: '15px' }} key={doctor.id}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                <Link to={`/doctors/${doctor.id}`}>
                    <img src={doctorAvatar} alt={doctor.name} className={classes.boxDoctorAvatar} />
                </Link>
            </Box>
            <Box sx={{ display: 'flex', padding: '0 15px 0 30px', flex: 'auto', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ flex: 'auto' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box className={classes.boxDoctorName} sx={{ flex: 'auto' }}>
                                {doctor.name}
                            </Box>
                            <Box
                                sx={{
                                    padding: '10px',
                                    border: '1px solid #dadfe1',
                                    borderRadius: '15px',
                                    display: 'flex',
                                    flex: 'auto',
                                    maxWidth: '65%',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>All: </strong>
                                    <strong className={classes.textSecondary}>{doctor?.procedures?.length ?? 0}</strong>
                                </Box>
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>This Week: </strong>
                                    <strong className={classes.textSecondary}>
                                        {doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'w')).length ?? 0}
                                    </strong>
                                </Box>
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>This Month: </strong>
                                    <strong className={classes.textSecondary}>
                                        {doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'M')).length ?? 0}
                                    </strong>
                                </Box>
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>This Year: </strong>
                                    <strong className={classes.textSecondary}>
                                        {doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'y')).length ?? 0}
                                    </strong>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.boxDoctorScpecialty}>
                            <Typography variant="caption">{doctor.specialty}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" mb={2}>
                            {doctor.websiteUrl && (
                                <Box display="flex" flexDirection="row">
                                    <a href={doctor.websiteUrl} target="_blank" rel="noopener noreferrer external" className={classes.websiteLink}>
                                        {doctor.websiteUrl}
                                    </a>
                                </Box>
                            )}
                        </Box>
                        <Box className={classes.boxDoctorChipRoot}>
                            {doctor.surgeryTypes.map((type: string) => (
                                <Chip size="small" label={type} />
                            ))}
                        </Box>
                    </Box>
                </Box>

                <Typography variant="caption" paragraph className={classes.boxDoctorBio}>
                    <strong>Biography </strong>
                    {doctor.bio}
                </Typography>
            </Box>
        </Box>
    );
};

interface FacilityDoctorsProps {
    facility: Facility;
}

const FacilityDoctorsList = ({ facility }: FacilityDoctorsProps) => {
    const classes = useStyles();
    return (
        <Box>
            <div className={classes.txtDoctorTitle}>Related Doctors</div>
            <EmptyStateHandler data={facility.FacilityUsers} keyword="facilities">
                {facility.FacilityUsers.map((row: FacilityUsers) => (
                    <div key={row.doctor.id} style={{ padding: '10px' }}>
                        <FacilityDoctorsItem doctor={row.doctor} />
                    </div>
                ))}
            </EmptyStateHandler>
        </Box>
    );
};

interface FacilityDetailsProps {
    id: number;
}

const FacilityDetails = ({ id }: FacilityDetailsProps) => {
    const classes = useStyles();
    const { facility } = useGetFacilityById(id);
    const facilityPhoto = useGetProtectedObject(facility?.photoUrl);
    const getFacilityStats = () => {
        const response = {
            lastWeek: 0,
            lastMonth: 0,
            lastYear: 0,
            allProcedures: 0,
        };
        facility?.FacilityUsers.forEach(item => {
            response.lastWeek += item.doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'w')).length ?? 0;
            response.lastMonth += item.doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'M')).length ?? 0;
            response.lastYear += item.doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'y')).length ?? 0;
            response.allProcedures += item.doctor?.procedures?.length ?? 0;
        });

        return response;
    };

    return (
        <Grid container spacing={2}>
            {facility && (
                <>
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper className={classes.boxPaper}>
                            <Box sx={{ display: 'flex', width: '100%', height: 'auto', maxHeight: '600px', justifyContent: 'center' }}>
                                <Avatar variant="square" style={{ width: 'auto', height: 'auto' }} src={facilityPhoto ?? ''}>
                                    <FacilityIcon style={{ maxWidth: '750px', height: 'auto' }} />
                                </Avatar>
                            </Box>
                            <Box sx={{ display: 'flex', padding: '15px 0' }}>
                                <Box sx={{ width: '250px', display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ color: '#333', fontWeight: '700', fontSize: '1rem' }}>
                                        {facility.name} ({facility.idNumber})
                                    </Box>
                                    <Typography variant="caption" className={classes.txtAddress}>
                                        {facility.streetAddress},&nbsp;{facility.city},&nbsp;{facility.state}&nbsp;{facility.zip}
                                    </Typography>
                                    <Typography variant="caption" className={classes.textSecondary}>
                                        {facility.phone}
                                    </Typography>
                                    {facility.websiteUrl && (
                                        <Box display="flex" flexDirection="row">
                                            <a
                                                href={facility.websiteUrl}
                                                target="_blank"
                                                rel="noopener noreferrer external"
                                                className={classes.websiteLink}
                                            >
                                                {facility.websiteUrl}
                                            </a>
                                        </Box>
                                    )}
                                    <Box
                                        sx={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            marginRight: '15px',
                                            padding: '15px',
                                            border: '1px solid #dadfe1',
                                            borderRadius: '15px',
                                        }}
                                    >
                                        <Box className={classes.txtCounter} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <strong>All</strong>
                                            <strong className={classes.textSecondary}>{getFacilityStats().allProcedures}</strong>
                                        </Box>
                                        <Box className={classes.txtCounter} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <strong>This Week</strong>
                                            <strong className={classes.textSecondary}>{getFacilityStats().lastWeek}</strong>
                                        </Box>
                                        <Box className={classes.txtCounter} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <strong>This Month</strong>
                                            <strong className={classes.textSecondary}>{getFacilityStats().lastMonth}</strong>
                                        </Box>
                                        <Box className={classes.txtCounter} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <strong>This Year</strong>
                                            <strong className={classes.textSecondary}>{getFacilityStats().lastYear}</strong>
                                        </Box>
                                    </Box>
                                    <Box sx={{ position: 'relative' }}>
                                        <PurchaseOptionsDialog facility={facility} isPrimary={false} />
                                    </Box>
                                </Box>
                                <Box sx={{ flex: 1, fontSize: '11px', color: '#8a98a3' }}>
                                    <span>
                                        <strong>History :</strong> {facility.bio}
                                    </span>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper className={classes.boxPaper}>
                            <FacilityDoctorsList facility={facility} />
                        </Paper>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const FacilityDetailsPage = () => {
    const { id } = useParams<{ id: string }>();
    const classes = useStyles();
    const navigate = useNavigate();
    const { reset } = useQueryErrorResetBoundary();

    useEffect(() => {
        if (isNullOrUndefined(id)) navigate(-1);
    }, [id, navigate]);

    if (!id) return <></>;

    return (
        <ErrorBoundary
            onReset={reset}
            // eslint-disable-next-line react/no-unstable-nested-components
            fallbackRender={({ resetErrorBoundary }) => (
                <Panel>
                    <p>Oops! Something went wrong...</p>
                    <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save">
                        Try again
                    </Button>
                </Panel>
            )}
        >
            <div className={classes.root}>
                <Box mb={1}>
                    <Button onClick={() => navigate(-1)} size="medium" aria-label="Go back" className={classes.btnBack}>
                        <ChevronLeft fontSize="small" /> Back
                    </Button>
                </Box>
                <React.Suspense fallback={<Skeleton />}>
                    <FacilityDetails id={+id} />
                </React.Suspense>
            </div>
        </ErrorBoundary>
    );
};

export default FacilityDetailsPage;
