import { useGetObject } from './useGetObject';

export function useGetProtectedObject(url: string | null | undefined) {
    const { key, identityId } = separateUrl(url);

    return useGetObject(key, { level: 'protected', identityId });
}

function separateUrl(url: string | null | undefined): { key: string | undefined; identityId: string | undefined } {
    if (!url) {
        return { key: undefined, identityId: undefined };
    }

    const baseUrl = url.split('?')[0];
    const index = baseUrl.indexOf('|');

    return {
        key: baseUrl.slice(index + 1),
        identityId: index > 0 ? baseUrl.slice(0, index) : undefined,
    };
}
