import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useCanUserDeleteExport } from 'client/hooks/videoExport/useCanUserDeleteExport';
import { useDeleteExport } from 'client/hooks/videoExport/useDeleteExport';
import { DeleteModal } from 'common/components/DeleteModal/DeleteModal';
import { DeleteIcon } from 'common/utils/Icons';
interface ExportDeleteButtonProps {
    jobId: string;
    isUserAdmin: boolean;
    title?: string;
}

export const ExportDeleteButton: React.FC<ExportDeleteButtonProps> = ({ jobId, isUserAdmin, title }) => {
    const { isLoading, removeExport } = useDeleteExport();
    const { canDelete } = useCanUserDeleteExport(jobId);
    const [open, setOpen] = useState(false);
    if (isLoading) {
        return <CircularProgress size={15} thickness={8} color="primary" />;
    }
    return canDelete || isUserAdmin ? (
        <>
            <Button onClick={() => setOpen(true)} size="small" aria-label="Delete" color="error">
                <DeleteIcon />
            </Button>
            <DeleteModal
                isOpen={open}
                setIsOpen={setOpen}
                nameOfObjectToBeDeleted={title || 'No Title'}
                deleteMutateFn={removeExport}
                jobId={jobId}
            />
        </>
    ) : (
        <></>
    );
};
